import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import {
  CurrencyMain,
  durationMedArray,
  getLength,
  mediaDeliver,
  PluralFunc,
  priceEnd,
  priceMed,
  CurrencyConvo,
  sectoHours,
} from "./GlobalFunctions";

export function getPriceMedia({
  mediaList,
  perMinute,
  countryVar = "US",
  discountVar = 0,
  includeDiscount,
  timeVar,
  // requestSeries
}) {
  //
  //
  var ccvar = require("iso-country-currency");

  // mediaList =
  // perMinute = perMinute ? perMinute : minastos;

  // const currencyMark = ijwae[currencyVar.lowercase()];
  const currencyMark =
    //
    // "";
    ccvar.getParamByISO(countryVar, "symbol");
  // ccvar.getAllInfoByISO(currencyVar)["symbol"];

  logs.logga("___ currencyMark ___", currencyMark);

  // 1duration
  const fullDuration = timeVar
    ? timeVar
    : mediaList
    ? durationMedArray(mediaList)
    : 0;

  const durationString = sectoHours(fullDuration);
  const durationHM =
    //
    durationString;

  // costVar = costVar ? costVar : timeVar ** perMinute;
  // costVar = gens.priceMed(costVar, perMinute, "$"),

  logs.logga("___ perMinute ___", perMinute);

  const perMinuteTime = priceEnd(perMinute);

  function countsit(timeasdowe) {
    //
    // const costSeconds = obj * multVar
    const priceOs = priceMed(timeasdowe, perMinute) - discountVar;
    const noMoney = priceOs < 0 || priceOs == 0;
    const priceBase = noMoney ? 0 : priceOs;
    const priceFloat =
      //
      priceBase;
    // CurrencyConvo(countryVar, priceBase);

    logs.logga("___ priceFloat ___", priceFloat);

    const priceFloatDecimal = priceFloat.toFixed(2);
    const priceInt = Math.ceil(priceFloat);
    const priceFloatString = priceFloatDecimal;

    // const priceCurreny = ()
    const priceWithSign =
      //
      //
      CurrencyMain(countryVar, priceFloat);
    //   currencyMark + priceFloatString;

    const asijdwe = { noMoney, priceFloat, priceInt, priceWithSign };

    return asijdwe;
  }

  function asije(medVoas) {
    const fureoa = medVoas.mediaDuration;
    const { ...ased } = countsit(fureoa);
    const aijsew = {
      ...medVoas,
      ...ased,
    };

    return aijsew;
  }

  const {
    priceFloat,
    priceInt,
    noMoney,
    priceWithSign,
    priceFloatString,
  } = countsit(fullDuration);

  const listWithPrices = mediaList && mediaList.map(asije);
  const numberMedia = getLength(mediaList);
  const numberWithEpisodes = PluralFunc("episode", numberMedia);

  //
  const belowPrice = 2 - 0.05 * numberMedia;
  const overHour = fullDuration > 3600;

  const yeqwe = {
    priceInt: priceInt,
    priceFloat: priceFloat,
    priceFloatString: priceFloatString,

    noMoney: noMoney,

    //
    //
    numberWithEpisodes: numberWithEpisodes,
    numberMedia: numberMedia,
    perMinuteTime: perMinuteTime,
    //
    //
    //
    fullDuration: fullDuration,
    //
    durationHM: durationHM,
    durationString: durationString,
  };

  logs.logga("___ getPriceMedia ___", yeqwe);

  const uheqwe = {
    //
    priceInt,
    priceFloat,
    priceFloatString,
    priceWithSign,
    //
    //
    overHour,
    currencyMark,
    fullDuration,
    durationString,
    //
    noMoney,
    //
    //
    numberMedia,
    numberWithEpisodes,
    listWithPrices,
    perMinuteTime,
    // ...odfgokr,
  };

  return uheqwe;
}
