import React, {
  //
  useState,
  useContext,
} from "react";

import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import HeroFront from "../containers/HeroFront";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";
import SignChak from "../containers/register/SignChak";
import PageBase from "../components/PageBase";
import ListMain from "../components/ListMain";
import SignBase from "../containers/SignBase";

export default function RegisterPage({
  //
  seriesObj,
  ...args
}) {
  // 1scriptcontext
  const { aaaaaa } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  const dfsgret = (
    <>
      {/*  */}
      Timings here are done.
    </>
  );

  const fdgrefdg = {
    obj: dfsgret,
    style: { fontSize: "24px" },
  };

  const jsder = <Divo {...fdgrefdg} />;

  const singod = (
    <>
      {/*  */}
      {/* dsfaewra dsafaewr */}
      <SignBase />
    </>
  );

  const sodkfer = {
    obj: singod,
    style: { padding: "0 15%" },
  };

  const dsijfr = {
    listvar: [jsder, <Divo {...sodkfer} />],
    returnTrue: true,
    horizTrue: true,
  };

  const sdijfd = <ListMain {...dsijfr} />;

  const okfdsd = (
    <>
      {/*  */}
      {sdijfd}
      {/* <SignIn /> */}
      {/* <HeroFront /> */}
      {/* <SignChak /> */}
    </>
  );

  const sisdwe = {
    obj: okfdsd,
    helmetConfig: { titlevar: "Register" },
    // noHeader: true,
    // fastestvar: "to save and share highlights.",
    // ...sdfe,
  };

  return <PageBase {...sisdwe} />;
}
