import React, {
  //
  useState,
  useContext,
} from "react";
import * as logs from "../../consts/logFuncs";
import {
  downloadChaps,
  getMediaSeries,
  joinString,
  mapChapterToText,
} from "../../functions/GlobalFunctions";
import { ScriptContext } from "../../contexts/ScriptContext";
import HistoryFunc from "../../functions/HistoryFunc";
import { useHistory } from "react-router-dom";

export default function HighRightList({
  //
  //   mediaObj,
  typevar,
  chapterList,
  ...args
}) {
  // 1scriptcontext
  const {
    getCurrentTime,
    currMedia,
    listChapters,
    setHistory,
    focusHighlight,
    playCurrentHighlight,
    currStartTime,
    changeTimePlace,
    orderTrue,
  } = useContext(ScriptContext);
  let history = useHistory();

  let mediaObj = currMedia;

  // 1const
  const [type, setType] = useState("");

  const sesobsd = getMediaSeries(mediaObj);

  function aodje(sdfijr) {
    const adsdio = sesobsd && "sr=" + sesobsd.slugURL;
    const finlao =
      //
      "/order";
    // joinString(
    //   [
    //     //
    //     "/order",
    //     adsdio,
    //   ],
    //   "?"
    // );

    history.push(finlao);
    logs.logga("___ sdfijr ___", sdfijr);
  }

  //   1create
  function creatHigh() {
    const asdjwe = getCurrentTime();

    function focCrea() {
      const ijsaewa = {
        startTime: asdjwe,
        media: mediaObj,
        id: "new",
      };

      //
      //   focusHighlight(ijaew);
    }

    function medCreao() {
      const oorte = joinString([
        "/m/",
        mediaObj.slugURL,
        "/highlights/new?start=",
        asdjwe,
      ]);

      logs.logga("___ creatHigh medCreao ___", oorte);

      history.push(oorte);
    }

    const osakdwe = {
      TIME: asdjwe,
      MEDIA: mediaObj,
    };

    logs.logga("___ creatHigh AAAA ___", osakdwe);

    // focCrea()
    medCreao();
  }

  function downloadTxtFile() {
    const dkfwr =
      //
      listChapters;
    // chapterList

    downloadChaps(dkfwr, mediaObj);
  }

  function getBasePo(sdofkwa) {
    let asdaew = "";
    switch (sdofkwa) {
      // 1download
      case "download":
        asdaew = {
          background: "darkred",
          obj: "Download .txt",
          onClick: downloadTxtFile,
        };
        break;
      // 1save
      case "save":
        asdaew = {
          obj: "Save",
          onClick: aodje,
        };
        break;
      // 1create
      case "create":
        const nsadoe = (
          <>
            {/*  */}
            Create at current time
            {/* {sweqasd} */}
          </>
        );

        asdaew = {
          bediaTrue: true,
          obj: nsadoe,
          onClick: creatHigh,
        };
        break;

      // 1about
      case "about":
        asdaew = {
          // background
          obj: "About Beida",
          // onClick: downloadTxtFile,
        };
        break;
    }

    return asdaew;
  }

  typevar = "chapters";

  let endlist = "";
  switch (typevar) {
    case "chapters":
      const showSave =
        //
        // true;
        orderTrue;
      // orderTrue

      const asidjwe = [
        //
        "create",
        !showSave && "download",
        // "about",
        showSave && "save",
      ];
      endlist = asidjwe;
      break;
  }

  args = {
    dictFunc: getBasePo,
    listvar: endlist,
    ...args,
  };

  // 1HTML
  return args;
}
