import React, {
  //
  useState,
  useContext,
} from "react";
import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import {
  checkEmptyArray,
  makeStripeArgs,
  OrderFormStripe,
} from "../functions/GlobalFunctions";
import ConfirmButton from "../components/ConfirmButton";
import { ScriptContext } from "../contexts/ScriptContext";
import { getPriceMedia } from "../functions/GetPriceMedia";

export default function OrderButton({
  detailFill,
  underHalf,
  onClick,
  priceConfig,
  orderObj,
  errorTrue,
  endProcess,
  editMed,
  buttonConfig,
  ...args
}) {
  const {
    typeProcess,
    currBasketMedia,
    requestMedia,
    requestSeries,
    orderConfig,
    orderDetails,
    mediaFound,
  } = useContext(ScriptContext);

  let stageArgs = "";
  let butFig = {};
  let buttFace = "";
  let disTrue = "";
  let allowPay = "";
  logs.logga("___ orderDetails ___", orderDetails);

  switch (typeProcess) {
    case "media":
      disTrue =
        editMed ||
        underHalf ||
        //
        checkEmptyArray(currBasketMedia);

      break;
    case "details":
      disTrue = !detailFill;

      break;
    case "final":
      allowPay = true;

      break;
  }

  logs.logga("___ orderConfig ___", orderConfig);

  const {
    //
    priceWithSign,
    priceFloat,
    noMoney,
  } = getPriceMedia(orderConfig);

  const sdfgre =
    //
    // "sadokseqwe";
    "Pay ";
  // priceWithSign;
  // "Confirm and Pay";
  // "Pay with Stripe"
  // "Pay Securely With Stripe (" + priceWithSign + ")";

  const ijqw =
    //
    "😲";
  // 🤢
  // 😎

  const faceDict = {
    media: {
      face: "🙂",
      text: "Checkout",
    },
    options: {
      face: "😎",
      text: "Complete",
    },
    details: {
      face: "😀",
      text: sdfgre,
      //   "Confirm Order",
    },
    final: {
      face: "😀",
      text: sdfgre,
    },
  };

  const ijaew =
    //
    "";
  // faceDict[typeProcess];

  const sidad =
    //
    // ijaew["face"];
    disTrue ? "🙂" : "😀";

  logs.logga("___ sidad ___", sidad);

  const faceObjo = errorTrue ? ijqw : sidad;
  const paymess = (
    //
    <>Pay ({priceWithSign})</>
  );
  // "Checkout";
  // faceObjo + " " + ijaew && ijaew["text"];

  const disabit =
    //
    errorTrue || noMoney;

  const classBut = disabit && "disabled";

  function asidjwe() {
    // logs.logga("___ onClick ___", onClick);

    onClick(typeProcess);
  }

  logs.logga("___ buttonConfig ___", buttonConfig);
  logs.logga("___ butFig ___", butFig);

  const ckvmsf = {
    height: "60px",
    width: "100%",
    // padding: "20px 10px",
    fontSize: "30px",
  };

  const u8wqewqe = {
    style: ckvmsf,
    chakTrue: true,
    onClick: asidjwe,
    onlyText: paymess,
    bediaTrue: true,
    className: classBut,
    ...butFig,
    ...buttonConfig,
  };

  function sdfoewre() {
    logs.logga("___ aisdjwe ___");
  }

  const lastaPPR =
    //
    allowPay;
  // false;
  // true;

  const isajdwe = {
    failFunc: sdfoewre,
    onClick: onClick,
    ...stageArgs,
    ...u8wqewqe,
    ...args,
  };

  // 1console
  logs.logga("___ ORDERBUTTON ___", isajdwe);
  logs.logga("___ ORDERBUTTON__lastaPPR ___", lastaPPR);

  const aisjdwe = <OrderFormStripe {...isajdwe} />;
  // const aisjdwe = lastaPPR ? (
  //   <OrderFormStripe {...isajdwe} />
  // ) : (
  //   <ConfirmButton {...u8wqewqe} />
  // );

  const adijwe = (
    <>
      {/* <ScaleFade initialScale={0.9} in={isOpen}> */}
      {/* <OrderFormStripe {...isajdwe} /> */}
      {aisjdwe}
      {/* </ScaleFade> */}
    </>
  );

  return adijwe;
}
