import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
  Component,
  createContext,
} from "react";

import * as gens from "../consts/GenStyled";

import Divo from "../components/Divo";

import * as logs from "../consts/logFuncs";

import FloatMainAll from "../components/FloatMainAll";
import {
  DateTimeAgo,
  getMediaDuration,
  WrapMain,
} from "../functions/GlobalFunctions";
import ImageTextDiv from "../components/ImageTextDiv";
import ThumbnailMedia from "./ThumbnailMedia";

// var momentDurationFormatSetup = require("moment-duration-format");

export default function MediaList({
  obj,
  noBelow,
  loadtrue,
  numberOfSpeakers,
  popRequestTrue,
  bottomObj,
  allSpeakers,
  showMedia,
  styleVar,
  thumbnailSize,
  listTrue,
  popRequest,
  noClass,
  noThumbnail,
  noLink,
  speakerFilt,
  seriesFilt,
  linkBase,
  filterDateUpcoming,
  disableAccess,
  speakerConfig,
  belowObj,
  lineBelow,
  noDate,
  disableVar,
  seriesTrue,
  wrapName,
  nameFunc,
  withSpeakers,
  rightItem,
  // rightIcons,
  rightIconConfig,
  highVar,
  withQuotes,
  overObj,
  emptyTrue,
  bottomType = "series",
  noBottom,
  noAccess,
  searchTrue,
  selectedSpeaker,
  selectedArray,
  filterSpeaker,
  noPlay,
  thumbnailTrue,
  showExtras,
  floatConfig,
  thumbnailConfig,
  nameConfig,
  bioConfig,
  linkConfig = {},
  belowConfig,
  topConfig,
  ...varso
  //
}) {
  logs.logga("___ obj ___", obj);

  const ksdfa = {
    obj: obj,
    medObj: obj,
  };

  // 1series
  const mainserObjo = obj.mainSeries;

  // 1selected
  const sfdsa =
    //
    "";
  // watchEmbedMedia

  // onClick = onClick ? onClick : noPlay ? "" : sfdsa;
  const transTrue = obj.transcribedTrue;
  const durationVar = getMediaDuration(obj.mediaDuration);
  const medDes = obj.videoDescription;
  const highObjs = obj.HighlightVideo;
  const noHighs =
    //
    "";
  // checkLenArray(obj.HighlightVideo);

  const showQuotes = noHighs && withQuotes;

  const dateMe = isDateBeforeToday(new Date(obj.videoReleasedate));
  const truDateFilt = filterDateUpcoming ? dateMe : true;

  const highInner = highVar ? highVar : obj.highVar;

  function addHigh(firstVar) {
    firstVar["highTrue"] = true;
    firstVar["highVar"] = highInner;
    return firstVar;
  }

  // 1style
  const stylOne = {
    textAlign: "left",
  };

  const asduj9032 = {
    textAlign: "left",
    padding: "5px 5px",
    // width: "100px",
    ...styleVar,
  };

  const normImage = gens.mobileView ? 45 : 45;

  const itemStg = {
    // padding: "0 5px",
    width: gens.mobileView ? "75px" : "55px",
    // width: "100%",
  };
  const showSt = {
    // background: "yellow",
    textAlign: "right",
    fontSize: gens.mobileView ? "12px" : "16px",
  };

  const showpop = {
    textAlign: "right",
    // padding: "0 5px 0 5px",
    // paddingLeft: "55px",
  };

  const asoidmsa = {
    asudgw___selectedArray: selectedArray,
    // asudgw___speakerFilt: speakerFilt,
    // asudgw___selectedSpeaker: selectedSpeaker,
    // asudgw___asduj9032: asduj9032,
    //  asudgw___speaksSingFilt: speaksSingFilt,
    //  asudgw___speakerFilt: speakerFilt,
    //  xxx___mainThumb: mainThumb,
    //  xxx___parentProd: parentProd,
    //  eeee_showExtras: showExtras,
    //  affff_seriesTrue: seriesTrue,
    //  eeee_speakerYes: speakerYes,
    //  yyttq_speaksList: speaksList,
    //  yyttq_speaksListy: speaksListy,
    //  yyttq_speaksHere: speaksHere,
    yyttq_obj: obj,
    transTrue: transTrue,
    //  yyttq_noHighs: noHighs,
    //  yyttq_withQuotes: withQuotes,
    //  yyttq_showQuotes: showQuotes,
    //  yyttq_fullSpeak: fullSpeak,
    // videoSpeakers: obj.videoSpeakers,
    // videoType: videoType,
  };

  //
  // 1console
  // logs.logga("medialist___obj ___", obj);
  logs.logga("mediaList-zzz", asoidmsa);

  // 1function
  function IconCheck() {
    //
    const siwqj = {
      style: {
        // fontSize: "50px",
        fontSize: gens.mobileView ? "26px" : "36px",
        textAlign: "right",
      },
      disVar: disableAccess,
      obj: obj,
      positionType: "topLeft",
      // disabled={disableVar ? true : false}
    };

    return "sadfokwe";
  }

  function segmentEpisodeClickProd(obj, clickVar) {
    window.analytics.track("Episode Clicked From Producer  List", {
      episode: obj,
      itemClicked: clickVar,
    });
  }

  // 1function
  function DurationObj() {
    return <div>{durationVar}</div>;
  }

  function DateObj() {
    //
    const okdsf = (
      <DateTimeAgo
        //
        // shortTrue={cutDate}
        dateVar={obj.videoReleasedate}
        loadtrue={loadtrue}
      />
    );
    return okdsf;
  }

  var comingVar = false;

  function isDateBeforeToday(dateObj) {
    return (
      new Date(dateObj.toDateString()) < new Date(new Date().toDateString())
    );
  }

  function checkComing(dateVar) {
    // logs.logga("aa:  ", isDateBeforeToday(new Date(dateVar)));

    if (comingVar === false) {
      if (!isDateBeforeToday(new Date(dateVar))) {
        comingVar = true;
        return <Fragment>Coming Soon...</Fragment>;
      }
    }

    // if (comingVar === true) {
    //   if (isDateBeforeToday(new Date(dateVar))) {
    //     comingVar = false;
    //   }
    // }

    // if (comingVar === true) {
    //   return <Fragment>Coming Soon...</Fragment>;
    // }
  }

  const ghConfo = {
    highVar: highInner,
  };

  function sdofker(aaod) {
    //
    const xcbkfg = {
      innerTrue: true,
      obj: obj,
      attrVar: aaod,
      ...ghConfo,
    };

    logs.logga("___ xcbkfg ___", xcbkfg);

    const ksdfasd =
      //
      // obj;
      obj ? obj[aaod] : "";
    // <HighParse {...xcbkfg} />

    return ksdfasd;
  }

  // 1description 1meta
  function DescNo() {
    //
    // const endVes = medDes
    //   ? extractContentHTML(medDes)
    //   : //
    //     "No information available.";
    // // ""

    const okzdfas =
      //
      sdofker("description");
    // endVes

    bioConfig = {
      obj: okzdfas,
      lineCount: 2,
      style: {
        fontSize: gens.mobileView ? "14px" : "16px",
      },
      ...bioConfig,
    };
    return <WrapMain {...bioConfig} />;
  }

  const showSer = showExtras && !gens.mobileView;

  function ThumbObj() {
    //
    const gfh = {
      //
      paddingRight: "10px",
      marginRight: "20px",
    };

    const xfvoks =
      //
      // 120;
      gens.mobileView ? 60 : 80;

    const ofkher = thumbnailSize ? thumbnailSize : xfvoks;

    const cglbkdfg =
      //
      // 1.7
      // 1.4;
      // 1.3333;
      1.7;

    const bnklfd =
      //
      ofkher * cglbkdfg;
    // 300;

    const lskvse = {
      width: bnklfd,
    };

    const fkvsa = {
      style: lskvse,
      imageSize: ofkher,
    };

    const gokhre = {
      imageConfig: fkvsa,
      style: gfh,
      ...ksdfa,
      ...thumbnailConfig,
    };

    logs.logga("___ ThumbnailMedia ___", gokhre);

    const okasd = !noThumbnail && <ThumbnailMedia {...gokhre} />;

    return <>{okasd}</>;
  }

  function NameObj() {
    //

    const fgoty =
      //
      // obj.name;
      // sdofker("name");
      nameFunc ? nameFunc(obj) : sdofker("name");

    const olkzdfsao =
      //
      // 90;
      gens.mobileView ? "20px" : "26px";

    const blmsda = {
      // float: "right",
      textAlign: "left",
      // fontStyle: "normal",
      // fontWeight: "bold",
      // color: "green",

      fontSize: olkzdfsao,
    };

    const ijsda = !wrapName && {
      lineCount: 2,
    };

    // 1name
    const ijsaewsad = {
      obj: fgoty,
      style: blmsda,
      ...ijsda,
      ...nameConfig,
    };

    const ijase = (
      //
      // <Divo {...ijsaewsad} />
      <WrapMain {...ijsaewsad} />
    );

    const ckbsfs = {
      obj: ijase,
      style: { margin: "0 10px" },
    };

    const bkxczvd = <Divo {...ckbsfs} />;

    return bkxczvd;
  }

  // function BottoBold(){
  //   const iujaew ={
  //     className: "italics"
  //   }
  // }

  logs.logga("___ mainserObjo ___", mainserObjo);

  function Bttomo() {
    let endValue = "";
    switch (bottomType) {
      case "date":
        endValue = <DateObj />;
        break;
      case "meta":
        endValue = <DescNo />;
        break;
      case "series":
        endValue = mainserObjo ? mainserObjo.name : "";

        break;
      // default:
      //   endValue = quoteFullInvertedNormal;
    }

    const bvlxcd = {
      // fontSize: "1em",
    };

    const okdffds = {
      obj: endValue,
      className: "bold",
      style: bvlxcd,
    };

    return <Divo {...okdffds} />;
  }

  noBottom = "";
  //
  const aijdwe =
    //
    // !noDate && <DateObj />
    !noBottom && bottomObj ? bottomObj : <Bttomo />;

  const cvbplse = (
    <>
      {/* wsokerwe */}
      <NameObj />
      {aijdwe}
      {/* sdforwe */}
      {/* {!noDate && <DateObj />} */}
    </>
  );

  function InfoPush(typevar) {
    const sasidj = {
      iconvar: "info",
    };

    logs.logga("zzzzz-sasidj", sasidj, "asd9qmweq");

    return <ImageTextDiv {...sasidj} />;
  }

  function LinkBut(typevar) {
    const sasidj = {
      iconvar: "transcript",
    };

    logs.logga("zzzzz-sasidj", sasidj, "asd9qmweq");

    return <ImageTextDiv {...sasidj} />;
  }

  function MainLiner() {
    //
    //
    const vbnlfg =
      //
      "";
    // showQuotes ? <WithQuotes /> : <WithDescr />;

    const ldbsdf = {
      textAlign: "left",
      minHeight: gens.mobileView ? "60px" : "75px",
      // margin: "0 10px",
    };

    const onert = {
      ...topConfig,
      wrapLines: 2,
      // flexTrue
      styleVar: stylOne,
      centerObj: cvbplse,
    };

    const rutiue4 = (
      //
      // cvbplse;
      <FloatMainAll {...onert} />
    );

    const topoConfo = {
      obj: rutiue4,
    };

    const bottoConfo = {
      style: ldbsdf,
      obj: vbnlfg,
    };

    const sdlfkae = (
      <>
        {/* 1top */}
        {/*  */}
        <Divo {...topoConfo} />
        {/* 1bottom */}
        {/* <Divo {...bottoConfo} /> */}
      </>
    );

    return sdlfkae;
  }

  // 1floatmaina
  function FlotMain() {
    const dictAll = {
      image: <ThumbObj />,
      thumbnail: <ThumbObj />,
      info: <InfoPush />,
      // speaker: <SpeakNo />,
      // link: LinkPush(<LinkBut />),
      // access: "asdijqwewq",
      access: !noAccess && <IconCheck />,
    };

    const leftDicto = {
      ...dictAll,
    };
    const rightDicto = {
      ...dictAll,
    };

    const leftFill =
      //
      "thumbnail";

    const rghtFill =
      //
      "";
    // "speaker";
    // "access";

    const vcxbod = {
      leftDict: leftDicto,
      rightDict: rightDicto,
      style: asduj9032,
      // rightObj: rghtFill,
      leftItem: leftFill,
      leftObj: <ThumbObj />,
      centerObj: <MainLiner />,
      ...floatConfig,
    };

    // 1style
    // noVertAlign

    logs.logga("vcxbod-zzz", vcxbod);

    const iajsdw = {
      obj: <FloatMainAll disvar {...vcxbod} />,
    };

    return <Divo {...iajsdw} />;
  }

  const itr583 = (
    //
    // <MainLiner />
    <FlotMain />
  );

  const cvoksa =
    //
    itr583;
  // "sdofkweqwa"
  // ewr9324;

  logs.logga("___ overObj ___", overObj);

  const vckbds = (
    //
    <>
      {/*  */}
      {/* {cvoksa} */}
      {/* <MainLiner /> */}
      {cvbplse}
      {/* <WithBelow /> */}
      {/* <ResultBelow noToggle {...sdfp} />; */}
      {/* {noBelow ? cvoksa : <ResultBelow noToggle {...sdfp} />} */}
    </>
  );

  const sdovkwqe = (
    //
    //
    <>
      {/* asdlw */}
      {/* {okasd} */}
      {/* {vckbds} */}
      {/* {obj.id} */}
      {cvoksa}
    </>
  );
  // cvoksa;

  // truDateFilt ? LinkPush(vckbds) : "";
  // "sdpfkwe";

  const q6etqwas =
    //
    // "";
    !noClass && gens.butClass;

  // linkBase = true;
  const aisdjwe = linkBase && {
    linkConfig: {
      linkvar: obj.mediaFileLink,
      outsideTrue: true,
    },
  };

  const sidjwqe = {
    obj: sdovkwqe,
    className: q6etqwas,
    ...aisdjwe,
    ...varso,
  };

  return <Divo {...sidjwqe} />;
}
