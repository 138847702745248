import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Flex,
  Link,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {
  //
  useState,
  useContext,
} from "react";
import ModalMain from "../components/ModalMain";
import * as gens from "../consts/GenStyled";
import {
  //
  useTransition,
  animated,
  useSpring,
} from "react-spring";

import LinkGlobal from "./LinkGlobal";
import TransHookDiv from "./TransHookDiv";

export default function Divo({
  obj,
  spanTrue,
  boolTrue,
  noShow,
  noLink,
  blackTrue,
  linkvar,
  linkConfig,
  popConfig,
  //
  //
  centerTrue,
  centerConfig,
  badgeConfig,
  badgevar,
  //
  //
  modalContent,
  modalClick,
  modalConfig,
  offModalFunc,
  noBadge,
  copyConfig = {},
  loadConfig = {},
  toolConfig = {},
  buttonConfig = {},
  editableConfig,
  boxTrue,
  floatConfig,
  baseStyle,
  buttonTrue,
  testFrontend,
  faderTrue,
  noDiv,
  toolVar,
  flexTrue,
  spaceTrue,
  arrVar,
  arrText,
  noStyle,
  topObj,
  textvar,
  bottomObj,
  badgeTrue,
  onClick,
  bool,
  fadeBool,
  bediaTrue,
  connectVar,
  // className,
  style,
  popContent,
  singleStyle,
  newLineTrue,
  chakTrue,
  //
  //
  noTitle,
  titleConfig,
  titlevar,
  ...zxcvkne
}) {
  //
  //
  popConfig = {
    content: popContent ? popContent : toolVar,
    ...popConfig,
  };
  


  const [shoeModdo, setshoeModdo] = useState();

  function TitloBas({ style, ...asdw }) {
    const nsaidjae = {
      fontSize: "1.5em",
      textAlign: "left",
      paddingLeft: "15px",
      ...style,
    };
    const ijsdae = {
      className: "bold ",
      style: nsaidjae,
      ...asdw,
    };
    const asdijwe = <div {...ijsdae}>{titlevar}</div>;

    return asdijwe;
  }

  // badgevar

  const showeo =
    //
    // !bool;
    boolTrue && !bool;

  const ijasd = bediaTrue && gens.bediaMainStyle;

  const stfill = {
    fontFamily: "Calibri",
    // color: linkConfig "black",
    textDecoration: "",
    ...ijasd,
    ...style,
    // padding: "40px 0",
  };
  // const styBase = noStyle ? {} : stfill
  const styBase = !baseStyle ? {} : stfill;
  const sfdgret = onClick;
  const classClick = eitherClick ? "pointer" : "";

  function eitherClick() {
    if (modalClick) {
      // loggo("___ Modlo ___", "");
      setshoeModdo(true);
    }
    if (sfdgret) {
      sfdgret();
    }
  }

  // 1style
  const funcso = {
    onClick: eitherClick,
    style: styBase,
    style: style,
    style: stfill,
    // className: classClick + className,
    ...zxcvkne,
  };

  // logga("funcso--zzz", funcso);

  function skdas(zxcsf) {
    const asijdwe = <div>{zxcsf}</div>;

    return asijdwe;
  }

  const asd9j912 = arrText ? arrText.map(skdas) : obj || textvar;
  // (
  //   <>
  //     {topObj}
  //     {obj}
  //     {bottomObj}
  //   </>
  // );

  const [toggle, set] = useState(false);
  const bollset =
    //
    "";
  // boolvar;
  // toggle

  const origStloe = {
    opacity: 0,
    // ...originalStyle,
  };

  const transitions = useTransition(fadeBool, null, {
    from: origStloe,
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });


  const fidjew = boxTrue || chakTrue
// 1div
  const vnhjfdfe = noDiv ? (
    asd9j912
  ) : badgeTrue ? (
    <Badge>{asd9j912}</Badge>
  ) : fidjew ? (
    <Box {...funcso}>{asd9j912}</Box>
  ) : spanTrue ? (
    <span {...funcso}>{asd9j912}</span>
  ) : (
    <div {...funcso}>{asd9j912}</div>
  );
  // {spaceTrue ? " " : connectVar}

  // flexTrue= true
  const xvcbfds = flexTrue ? <Flex>{vnhjfdfe}</Flex> : vnhjfdfe 

  function saoew({ item, key, props }) {
    const dictoes =
      //
      // fadein
      props;

    const dfgxarews = (
      //
      <animated.div style={dictoes}>{xvcbfds}</animated.div>
    );

    const czbagdfsw =
      //
      // "";
      dfgxarews;
    // ijawew;

    // logs.logga("___ obj ___", obj);
    // logs.logga("___ czbagdfsw ___", czbagdfsw);

    return czbagdfsw;
  }

  // function EditableMain() {
  //   const sijwe = {
  //     defaultValue: obj,
  //     ...editableConfig,
  //   };

  //   const iajwew = (
  //     <>
  //       <chakras.Editable {...sijwe}>
  //         <chakras.EditablePreview />
  //         <chakras.EditableInput />
  //       </chakras.Editable>
  //     </>
  //   );

  //   return iajwew;
  // }

  function CopLink() {
    // return (
    //   <PopMain
    //     obj={<CopyMain obj={<PanLink />} {...copyConfig} />}
    //     disVar={!popConfig}
    //     {...popConfig}
    //     // disVar
    //   />
    // );
  }

  // 1badge
  const dfokg =
    //
    badgevar;
  // 4;

  const badgIt =
    //
    // "s";
    !noBadge && (badgevar || badgeConfig);

  // badgeConfig = {
  //   ...badgeConfig,
  //   obj: <LinkGret />,
  //   countVar: dfokg,
  // };

  const asodqw =
    //
    linkConfig || linkvar;
  // noLink && linkConfig;

  const mcvdewr =
    //
    // xvcbfds;
    fadeBool ? transitions.map(saoew) : xvcbfds;

  

  const okdas = {
    toVar: linkvar,
    textvar: mcvdewr,
    // outsideTrue
    ...linkConfig,
  };

  const xczaeewqa =
    //
    //
    asodqw ? <LinkGlobal {...okdas} /> : mcvdewr;

  function noClick() {
    setshoeModdo(false);
    if (offModalFunc) {
      offModalFunc();
    }
  }

  function ModFinno() {
    const ijasew = {
      ...modalConfig,
      obj: modalContent,
      cancelFunc: noClick,
    };

    const iajsdw = <ModalMain {...ijasew} />;

    return iajsdw;
  }

  const ijawsa =
    //
    // true
    // "";
    modalClick && shoeModdo;

  const ModalBase = ijawsa && <ModFinno />;

  const adijwe = !noTitle && titlevar;
  const irjgerwe = adijwe ? <TitloBas {...titleConfig} /> : "";

  const ijweas = <>
        {irjgerwe}
        {xczaeewqa}
        {/* {xczaeewqa} */}
        {/* <LinkGret /> */}
        {/* {badgIt ? <BadgeMain {...badgeConfig} /> : <LinkGret />} */}
        {newLineTrue ? <br /> : ""}
        {ModalBase}
      </>

  const oxdfsad =
    //
    // "apsdlwqeqw";
    showeo || noShow ? (
      ""
    ) : centerTrue ? <Center {...centerConfig}>{ijweas}</Center> : ijweas

  

  // return obj;
  return oxdfsad;
}
