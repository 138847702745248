import * as xmps from "../consts/exampConst";
import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";

export default function whatyouget(typeo = "") {
  // 1unclear
  const unclearaos = `
        
          HE
        
        `;

  // 1timestamp
  const etimsaoe = `
          ad = 
        `;

  // phy2go

  const okasew = (
    //
    <>
      {xmps.trnsmao}
      {/* <MarkdownMain obj={xmps.transExxm} /> */}
    </>
  );

  const dokewqe = (
    <>
      {/*  */}
      Say Bedia's transcripts change. Customisable styles, interactive text, and
      the easiest way to share quotes to social media.
    </>
  );

  // const transPage =

  const iajwe =
    //
    "An interactive page you can embed on your website.";
  // "A basic text file you can add to your blog/website.";

  const sdvoxzcms = (
    <>
      Click on any sentence to play the moment that sentence is said in the
      video.
      <br />
      Click on any person's name at the beginning of a paragraph to visit their
      Twitter or Instagram (whichever they prefer).
      {/* Bedia also includes a  */}
    </>
  );

  const lxvkdsa = {
    obj: sdvoxzcms,
  };

  const transFiles = {
    type: "transcript",
    obj: okasew,
    subtitle: sdvoxzcms,
    // media:
    title: "Transcripts",
    textBeside: iajwe,
    emoji: "📜",
  };

  const cltObj =
    //
    unclearaos;

  const iokwe =
    //
    "A separate file of unclears words or sections, which have links to the moment in the episode.";

  const quesFile = {
    type: "unclear",
    title: "Unclears",
    obj: cltObj,
    textBeide: iokwe,
    emoji: "❓",
  };

  const sbimdaewr =
    //
    etimsaoe;

  const bifsaere =
    //
    "A transcript with a link to the right moment in the episode at the top of every paragraph.";

  // 1timestamp
  const tmeStaFile = {
    title: "Timestamps",
    obj: sbimdaewr,
    // link:
    type: "timestamp",
    textBeside: bifsaere,
    emoji: "⏰",
  };

  const kasew =
    //
    xmps.sbmsMas;

  const okfhrre = (
    <>
      An .srt subtitle file you can add to your video.
      {/*  */}
    </>
  );

  const cvlxzxdas =
    "Click here to watch a guide on how to add subtitles to Youtube.";

  const oscxvbkda = {
    linkConfig: {
      outsideTrue: true,
      linkvar: "https://www.youtube.com/watch?v=Q8Sq9r50gc0",
    },
    obj: cvlxzxdas,
    style: { color: "blue" },
  };

  const oksada = <Divo {...oscxvbkda} />;

  const subFile = {
    type: "subtitle",
    subtitle: oksada,
    textBeside: okfhrre,
    title: "Subtitles",
    obj: kasew,
    emoji: "💬",
  };

  const adsfokewr = xmps.chaspwqasm;
  const ijasdqwe = (
    <>
      {/* based off the trancript */}
      {/* easily added to Youtube-friendly */}A timestamped topic list you can
      add to your description.
      {/*  */}
    </>
  );

  const bnsdf =
    "Click here to watch a guide on how to add chapters to Youtube.";

  const asdfwqersd = {
    linkConfig: {
      outsideTrue: true,
      linkvar: "https://www.youtube.com/watch?v=pvkTC2xIbeY",
    },
    obj: bnsdf,
    style: { color: "blue" },
  };

  const cvbnsd = <Divo {...asdfwqersd} />;

  // 1chapter
  const chapFile = {
    title: "Chapters",
    obj: adsfokewr,
    type: "chapter",
    textBeside: ijasdqwe,
    subtitle: cvbnsd,
    emoji: "📚",
  };

  const iaewas =
    //
    "dosfkeqw";

  const lbfdos =
    //
    "Both transcripts and subtitles translated into any other language you need.";

  // 1translatio
  const trnsFileso = {
    type: "translation",
    obj: iaewas,
    usePlace: lbfdos,
    textBeside: lbfdos,
    title: "Translations",
    emoji: "✍️",
  };

  const adsfwes =
    //
    "dosfkeqw";

  const cngdfxc =
    //
    "Individual videos of sections you can post.";

  // 1highlights
  const hghFIleo = {
    type: "highlights",
    textBeside: cngdfxc,
    obj: adsfwes,
    title: "Highlights",
    emoji: "📺",
  };
  // usePlace: lbfdos,

  // 📺

  const vbndfdf =
    //
    "dosfkeqw";

  const vxcbfdg =
    //
    "Individual videos of sections you can post.";

  const kywose = {
    type: "keywords",
    textBeside: vxcbfdg,
    obj: vbndfdf,
    title: "Highlights",
    emoji: "✍️ ",
  };

  const zcvzsdfs = {
    transcript: transFiles,
    timestamp: tmeStaFile,
    chapter: chapFile,
    subtitle: subFile,
    unclear: quesFile,
    keyword: kywose,
    translation: trnsFileso,
    highlights: hghFIleo,
  };

  const xokcdsa = typeo ? zcvzsdfs[typeo] : zcvzsdfs;

  return xokcdsa;
}
