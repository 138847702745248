import React, {
  //
  useState,
  useContext,
} from "react";
import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import FormMain from "../components/FormMain";
import PriceCards from "./PriceCards";

import {
  getSHortSet,
  OrderCostDrop,
  //
} from "../functions/GlobalFunctions";
import { ScriptContext } from "../contexts/ScriptContext";
import { Flex } from "@chakra-ui/core";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function OrderDetails({
  //
  genConfig,
  formObj,
  ...args
}) {
  const {
    requestSeries,
    orderDetails,
    costConfig,
    seriesOver,
    detailFill,
  } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  const sadoqwea =
    //
    // "What's your name?";
    // "Your name?";
    "Name";

  const namDcito = {
    required: true,
    name: "name",
    // iconvar: "user",
    titlevar: sadoqwea,
  };

  const emDicto = {
    textvar: seriesOver?.mainEmail,
    name: "email",
  };

  const asdijwqeq = {
    titlevar: "My organisation",
    // iconvar: "home",
    name: "company",
  };

  const commentos = {
    titlevar: "Any general comments?",
    // subtitle:
    name: "comments",
  };

  const counDICOT = {
    titlevar: "My organisatcion",
    // iconvar: "home",
    name: "company",
  };

  const socDict = {
    name: namDcito,
    email: emDicto,
    company: asdijwqeq,
    country: counDICOT,
    comments: asdijwqeq,
  };

  const compFinao = !seriesOver ? "company" : "";

  const asodos = [
    // { horizTrue: ["firstname", "lastname"] },
    "name",
    "email",
    // "country",
    // compFinao,
    // "comments",
    // socDict[currSoc],
    // emDicto,
  ];

  const sadjnwew =
    //
    // seriesOver
    orderDetails;

  const linputConfig = {
    // hookConfig: ,
    listvar: asodos,
    dictvar: socDict,
    typevar: "details",
    logTrue: true,
    overObj: sadjnwew,
    ...args,
  };

  logs.logga("___ linputConfig ___", linputConfig);

  const formios = <FormMain {...linputConfig} />;

  const bndsasdf = (
    <>
      {/*  */}
      Hello
    </>
  );

  const cbnkos = {
    //
  };

  const extCooa = {
    obj: bndsasdf,
    // style: cbnkos,
  };

  // 1console
  logs.logga("___ orderDetails---FORM ___", linputConfig);

  function Alredos() {
    const okawe = (
      <>
        {seriesOver.name}, we've already got some details about you!
        <br />
        If these aren't accurate, please enter the correct ones below.
        {/* before ordering. */}
      </>
    );

    const kcvbsf = {
      fontSize: "24px",
      textAlign: "left",
      margin: "20px 0",
      padding: "10px",
      // ...gens.borderMain("grey"),
      // padding: "0 50px",
      // minWidth: "300px",
    };

    const oaksdewr = {
      obj: okawe,
      style: kcvbsf,
      className: "shadowHover",
    };

    return <Divo {...oaksdewr} />;
  }

  const aijewe =
    //
    "";
  // !detailFill && seriesOver;

  const goSeros = aijewe && <Alredos />;

  const r0hrty = {
    obj: <AiOutlineInfoCircle />,
    className: "expandHover",
    linkConfig: {
      linkvar: "/pricing",
      outsideTrue: true,
    },
    style: {
      //
      // fontSize: "1.1em",
      margin: "0 10px",
    },
  };

  const weyeer = (
    <>
      {/*  */}
      Plan <Divo {...r0hrty} />
    </>
  );

  const asidje = (
    <Flex>
      {/*  */}
      {weyeer}
    </Flex>
  );

  const aijsdwe = {
    obj: asidje,
  };

  const doListio = {
    // style:
    // totalTime: 100,
    listvar: ["basic", "curated"],
    genConfig: { titlevar: <Divo {...aijsdwe} /> },
    chosenConfig: {
      // bediaTrue: true,
      // style: {border: "2px border blue"},
      className: "chooseList",
    },
    itemConfig: { className: "pointer shadowHover" },
    // obj: asdij,
  };

  const findos = (
    <>
      {/*  */}
      <PriceCards {...doListio} />
      <hr />
    </>
  );

  const formos = (
    <>
      {findos}
      {goSeros}
      {formios}
      {/* <Divo {...extCooa} /> */}
    </>
  );

  const uahswe = {
    obj: formos,

    ...genConfig,
  };

  // 1HTML
  return <Divo {...uahswe} />;
}
