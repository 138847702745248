import React, {
  //
  useState,
  useContext,
} from "react";
import {
  //
  CurrencyMain,
  mediaDeliver,
} from "../functions/GlobalFunctions";
// import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import { Flex, HStack, Spacer } from "@chakra-ui/core";
// import { Stack, HStack, VStack } from "@chakra-ui/react";
import { Row, Col } from "reactstrap";
import ListMain from "../components/ListMain";
import { getPriceMedia } from "../functions/GetPriceMedia";

export default function PriceMedList({
  //   mediaList,
  //   timeVar,
  //   perMinute = 2,
  costVar,
  genConfig,
  countryVar,
  loadtrue,
  orderObj,
  ...argo
}) {
  //
  const {
    countryCurr,
    // deliveryDate,
    currBasketMedia,
    deliveryHours,
    deliveryString,
    discountVar,
    orderConfig,
  } = useContext(ScriptContext);

  const xkcsad = {
    ...argo,
    ...orderConfig,
  };

  logs.logga("___ xkcsad ___", xkcsad);

  const {
    //
    perMinuteTime,
    priceFloatString: priceVar,
    priceWithSign,
    priceFloat,
    durationString,
    fullDuration,
    noMoney,
    timeOrder,
    numberMedia,
    perMinute,
  } = getPriceMedia(xkcsad);

  costVar = costVar ? costVar : priceVar;

  const csdapw = !noMoney && priceFloat + discountVar;
  const aijdw =
    //
    CurrencyMain(countryVar, csdapw);
  // priceWithSign;

  logs.logga("___ aijdw ___", aijdw);

  // const jadwe =

  // const okawewqwe = {
  //   obj: ijadwe,
  //   className: "wrapTrue",
  // };
  // const ijwe = <Divo {...okawewqwe} />;

  const costConfig = {
    iconvar: "money-bill",
    leftObj: "Overall Price",
    textvar: aijdw,
    titlevar: "💵 Cost",
  };

  logs.logga("___ costConfig ___", costConfig);

  const timConfo = {
    iconvar: "clock",
    textvar: durationString,
    titlevar: "🕑 Time",
  };

  const epeConf = {
    iconvar: "video",
    textvar: numberMedia,
    titlevar: "📹 Episodes",
  };

  const ofkvdfere =
    //
    "sdokfwer kisamdwe ";

  const pmConfo = {
    iconvar: "dollar-sign",
    popContent: ofkvdfere,
    // leftObj: "Per Minute Cost",
    textvar: perMinuteTime + " p/m",
    titlevar: "💲 Rate",
  };

  // getLength(higList) * 5
  // const highStringo = getPrice;

  // 1highligar: "⭐ Hights
  const higsdofs = {
    textvar: "asijdwe",
    titlevar: "⭐ Highlights",
  };

  const delivweq =
    //
    // "N/A";
    deliveryHours;

  // 1delivery
  // 📬  ✉️
  const dliverep = {
    // iconvar: "",
    // popContent: ofkvdfere,
    // leftObj: "Per Minute Cost",
    textvar: delivweq,
    titlevar: "📬 Delivery",
  };

  const discMain = "🏷️ Request";
  const minoso = CurrencyMain(countryVar, discountVar, "-");

  const ijasdwe = {
    titlevar: discMain,
    textvar: minoso,
    colorvar: "green",
    // style: {
    //   color: "green",
    // },
  };

  const topDict = {
    highlights: higsdofs,
    episodes: epeConf,
    duration: timConfo,
    time: timConfo,
    discount: ijasdwe,
    perminute: pmConfo,
    cost: costConfig,
    delivery: dliverep,
  };

  const sizeo =
    //
    60;

  const lxkcas = {
    textAlign: "center",
    background: "green",
    fontSize: sizeo,
  };

  const imtoMons = {
    style: lxkcas,
    // className: "bold borderHover",
    // popTextTrue: true,
    // popTrue: false,
  };

  logs.logga("___ argo ___", argo);

  const [countFunc, setcountFunc] = useState("US");

  const werfsafe = {
    amount: priceFloat,
    obj: countFunc,
    optionsSize: sizeo,
    selectedSize: sizeo,
    fullWidth: false,

    onSelect: (code) => setcountFunc(code),
  };

  function Rendios({ titlevar, textvar, colorvar, ...sdofk }) {
    //
    const kdawe = {
      obj: titlevar,
      // style: {}
      // className: "bold",
    };

    logs.logga("___ sdofk ___", sdofk);

    const aushdwe =
      //
      titlevar;
    // <Divo {...kdawe} />;

    const jvdsvcbc = {
      obj: textvar,
      // style: { textAlign: "right" },
    };
    const ikasdwe =
      //
      textvar;
    // <Divo {...jvdsvcbc} />;

    const askwe = [
      //
      aushdwe,
      // <hr />,
      ikasdwe,
    ];

    const adsofke = {
      style: {
        textAlign: "left",
        fontSize: "24px",
        color: colorvar,
      },
      // className: "bold",
    };

    logs.logga("___ sdofk ___", sdofk);

    //   const vxobmfe = <HStack spacing={8}>
    //   {/* <Flex> */}
    //   {aushdwe}
    //   {/* <Spacer /> */}
    //   {ikasdwe}
    //   {/* </Flex> */}
    // </HStack>

    // const iajsdwe = (
    //   <Row>
    //     <Col xs="6">{aushdwe}</Col>
    //     <Col xs="6">{ikasdwe}</Col>
    //   </Row>
    // );

    const ifjwwew = {
      listvar: askwe,
      horizTrue: true,
      returnTrue: true,
      itemConfig: adsofke,
    };

    const iajsdwe = <ListMain {...ifjwwew} />;

    return iajsdwe;
  }

  // const easEosak = <ImageTextList noTitle borderTrue {...kasdwq} />;

  const kasdwq = {
    dictvar: topDict,
    itemConfig: imtoMons,
    // horizTrue: false,
    ...argo,
  };

  const oksaxcv = {
    ...kasdwq,
    // returnTrue: true,
    // objFuncTrue: true,
    // horizTrue: true,
    obj: Rendios,
    // listvar:
  };

  logs.logga("___ oksaxcv ___", oksaxcv);

  const qwsdqw = (
    <>
      {/* asdfk */}
      <ListMain {...oksaxcv} />
      {/* <Rendios */}
      {/* <CurrencyMain {...werfsafe} /> */}
    </>
  );

  const asduwe = {
    // border: "2px solid blue",
    color: "black",
    //
    fontSize: "16px",
    textAlign: "center",
  };

  const audhw = {
    // popStyle: {
    //   height: "100px",
    // },
    style: asduwe,
    obj: qwsdqw,
    ...genConfig,
  };
  const dijswe = <Divo {...audhw} />;

  return dijswe;
}
