import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { helmetSearch, bediaLinePush } from "../functions/GlobalFunctions";
import { ScriptContext } from "../contexts/ScriptContext";

import * as gens from "../consts/GenStyled";

import * as divs from "../components/Divo";

import * as logs from "../consts/logFuncs";

export default function HelmetInfo({
  titlevar,
  metavar,
  removeSite,
  bool,
  loadtrue,
  descrInfo,
  searchObject,
  noSearch,
  keywordInfo,
  linkIcon,
  ...args
  //
}) {
  function checkPresent(varInput, varStandard) {
    return varInput ? varInput : varStandard;
  }

  const {
    //
    loadAny,
    requestMedia,
    currentScribe,
  } = useContext(ScriptContext);

  const siteFull = gens.homeFull;

  const connectSear = "on";
  // const connectSear = "about"

  const mainSearchy = helmetSearch(searchObject, titlevar, connectSear);
  const TitleSetting = "Bedia";

  const TitleLoad = "Bedia";
  const DescLoad =
    //
    "";
  // gens.desDescr;
  const KeywordLoad = "";

  // loadtrue = false;
  // 1name
  const nameFrip = removeSite ? titlevar : bediaLinePush(titlevar);
  const nameInfo = titlevar ? nameFrip : TitleSetting;
  const nameMain = noSearch ? nameInfo : mainSearchy ? mainSearchy : nameInfo;
  const finalName = loadAny
    ? ""
    : gens.testDev
    ? ""
    : loadtrue
    ? TitleLoad
    : nameMain;
  // const finalName = nameMain;

  // 1description
  const desFrip = removeSite ? titlevar : bediaLinePush(titlevar);
  const desInfo = titlevar ? nameFrip : TitleSetting;
  // const desMain = descrInfo ? descrInfo : gens.DescSetting;
  const finalDes =
    //
    gens.testDev ? "" : loadtrue ? DescLoad : metavar ? metavar : "";

  // 1image
  const imgMeddo = requestMedia?.videoThumbnail;
  const metaImage =
    //
    // currentScribe.imageAttr;
    // gens.BediaMagBlack;
    imgMeddo ? imgMeddo : gens.BediaMagBlack;
  // searchObject ? searchObject.imageAttr : "";

  logs.logga("___ metaImage ___", metaImage);

  // 1keyword
  const finalKey = loadtrue ? keywordInfo : keywordInfo;
  //   : gens.KeywordSetting
  // : gens.KeywordSetting;

  // const twitCarVar = "summary_large_image"
  const twitCarVar = "summary";

  const samdsa = {
    mainSearchy: mainSearchy,
    noSearch: noSearch,
    titlevar: titlevar,
    finalName: finalName,
    // //  removeSite :
    // // //  loadtrue :
    // //  descrInfo :
    // //  descrInfo :
    // //  keywordInfo :
    // //  linkIcon :
    //  searchObject :
    // finalName:
    // finalDes:
  };

  logs.logga("helmetInfo-zz", samdsa);

  return (
    <Helmet>
      <title>
        {/*  */}
        {finalName}
        {/* bbbbbbb */}
      </title>
      <meta charSet="utf-8" />
      <meta name="description" content={finalDes} />
      <meta name="keywords" content={finalKey} />
      <meta property="og:title" content={finalName} />
      <meta property="og:description" content={finalDes} />
      <meta property="og:site_name" content="Bedia" />
      <meta property="og:image" itemProp="image" content={metaImage} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteFull} />
      <meta property="twitter:title" content={finalName} />
      <meta property="twitter:description" content={finalDes} />
      <meta property="twitter:image" itemProp="image" content={metaImage} />
      <meta property="twitter:card" content={twitCarVar} />
      <link rel="canonical" href={siteFull} />
      <link rel="icon" type="image/png" href={metaImage} sizes="16x16" />
    </Helmet>
  );
}
