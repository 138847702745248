import { SiTwitter } from "react-icons/si";

// 1social
const facebookMessage = "https://www.facebook.com/messages/t/";
const twitLink = "https://twitter.com/";
const plsusew = "";
const twitterShareLink = twitLink + "intent/tweet" + plsusew;
const twittDirectLink = twitLink + "messages/compose" + plsusew;

export const testLinks = {
  vimeo: "https://vimeo.com/358305100",
  youtube: "https://www.youtube.com/watch?v=n326K1AzSCw",
  youtubeSubs: "https://www.youtube.com/watch?v=n326K1AzSCw",
  mp3:
    "https://ipaudio5.com/wp-content/uploads/HP/FRY%20AZKABAN/05%20THE%20DEMENTOR.mp3",
};

export const socialListDict = {
  facebook: {
    linkBase: "https://www.facebook.com/sharer/sharer.php",
    // textConnect: "u",
    linkConnect: "u",
    iconObj: <SiTwitter />,
  },
  telegram: {
    linkBase: "https://t.me/share/url",
    textConnect: "text",
    linkConnect: "url",
  },
  // messenger: {
  //   iconvar: "messenger",
  //   linkBase: "https://www.messenger.com/t/",
  //   // textConnect: "t",
  //   // linkConnect: "u",
  // },

  // copy: {
  //   // toolText: "Copy link to Clipboard",
  //   // noLink: true,
  //   toolText: "Copy Link",
  //   noLink: true,
  //   iconvar: "link",
  // },
  // // embed: {
  // //   iconvar: "embed",
  // //   toolText: "Copy embed link to Clipboard",
  // //   noLink: true,
  // // },
  // quote: {
  //   noLink: true,
  //   iconvar: "quote",
  //   toolText: "Copy Quote",
  // },
  whatsapp: {
    // linkBase: "https://api.whatsapp.com/send/?phone",
    linkBase: "https://web.whatsapp.com/send",
    textConnect: "text",
  },
  email: {
    linkBase: "mailto:",
    titlevar: "subject",
    textConnect: "body",
  },
  //   1twitter
  twitter: {
    linkBase: twitterShareLink,
    iconvar: "twitter",
    textConnect: "text",
    linkConnect: "url",
    iconObj: <SiTwitter />,
    styleIcon: { color: "white", background: "#1da1f2" },
  },
  twitterdm: {
    linkBase: twittDirectLink,
    iconvar: "twitter",
    toolText: "Twitter DM",
    textConnect: "text",
    iconObj: <SiTwitter />,
    styleIcon: { color: "white", background: "#1da1f2" },
  },
  reddit: {
    linkBase: "https://www.reddit.com/submit",
    linkConnect: "url",
    textConnect: "title",
  },
  hackerNews: {
    iconvar: "y-combinator",
    linkBase: "https://news.ycombinator.com/submitlink",
    textConnect: "t",
    linkConnect: "u",
  },
  linkedin: {
    linkBase: "https://www.linkedin.com/sharing/share-offsite",
    // textConnect: "url",
    linkConnect: "url",
    summaryVar: "summary",
    styleIcon: { color: "white", background: "#0e76a8" },
  },
  google: {
    toolText: "Google",
    linkBase: "https://www.google.com/search",
    textConnect: "q",
  },
  slack: {
    //
  },
  skype: {
    //
  },
  instagram: {
    //
  },
  wechat: {
    iconvar: "weixin",
  },
  weibo: {
    //
  },
  qq: {
    //
  },
};
