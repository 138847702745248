import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";
import ConfirmButton from "../components/ConfirmButton";
import FloatMainAll from "../components/FloatMainAll";
import { backgroundMain, selectOn } from "../functions/GlobalFunctions";
import * as gens from "../consts/GenStyled";

import Divo from "../components/Divo";

import * as logs from "../consts/logFuncs";

// IMPORT OTHER COMPONENTS HERE
// import IconMainy from "./IconMain";
import ImageCheckReplacey from "./ImageCheckReplace";
import ImageCheckReplace from "./ImageCheckReplace";

export default function ImageTextDiv({
  //
  onClick,
  floatConfig,
  noTitle,
  textArray,
  subtitlevar,
  subtitleConfig,
  popContent,
  popConfig,
  iconConfig,
  lineBelow,
  linkConfig,
  imageConfig,
  newLineTrue,
  imagevar,
  divTrue,
  iconvar,
  confirmTrue,
  noImage,
  iconOver,
  noIcon,
  textobj,
  iconObj,
  containFunc,
  imageObj,
  imageSize,
  messvar,
  imageTrue,
  textvar,
  titleConfig,
  titlevar,
  linkTextTrue,
  chooseitTrue,
  popTextTrue,
  popTrue,
  loadtrue,
  toolVar,
  noClass,
  badgeIcon,
  countvar,
  textConfig,
  swapItems,
  padVar,
  bediaTrue,
  color,
  noText,
  className,
  style,
  badgevar,
  ...sdAS
}) {
  logs.logga("___ sdAS ___", sdAS);

  logs.logga("___ linkConfig ___", linkConfig);

  // 1const
  // iconOver = "y";
  imageTrue = imageTrue || imageConfig || imagevar;
  imageTrue = !iconOver && imageTrue;

  const iawesad = bediaTrue && gens.bediaMainStyle;
  const xkfs = {
    textAlign: "center",
    // fontSize: "10px",
    ...iawesad,
    ...style,
  };

  logs.logga("___ xkfs ___", xkfs);

  const lgkhdfg = {
    // styleItem: xkfs,
    style: xkfs,
  };

  const xcvmds = {
    ...linkConfig,
  };

  const poop = popContent ? popContent : popTextTrue ? <TextPart /> : "";

  const daskdow = {
    // content: "oaijdsd",
    content: poop,
    ...popConfig,
  };

  logs.logga("___ popConfig ___", popConfig);

  const clasaseio =
    //
    // "";
    // className;
    // "buttonHover shadowHover bold";
    noClass ? "" : className;
  const args = {
    linkConfig: xcvmds,
    popConfig: daskdow,
    className: clasaseio,

    ...lgkhdfg,
    ...sdAS,
  };

  // 1console
  logs.logga(textvar + " - IMAGETEXTDIV args ___", args);

  const bageFin = {
    badgevar: badgevar,
  };
  const badgeShIc = badgeIcon ? bageFin : {};
  const badgeShText = badgeIcon ? {} : bageFin;
  const imageAll = popTrue ? args : {};

  function TestImage({ truInclude, ...sdf }) {
    const showAllso = truInclude ? args : {};

    const maino = {
      ...imageAll,
      ...showAllso,
      ...badgeShIc,
      ...sdf,
    };

    const okd = {
      imageAll: imageAll,
      showAllso: showAllso,
      // maino:maino,
    };

    logs.logga("___ TestImage imageConfig ___", okd);

    const imageCASWO = {
      imagevar: imagevar,
      imageSize: imageSize,
      ...maino,
      ...imageConfig,
      // style: {
      //   background: "black",
      // },
    };

    const iconVaraso = {
      iconvar: iconvar,
      // fontSize: imageSize,
      // fontSize: "120px",
      ...maino,
      ...iconConfig,
    };

    logs.logga("iconConfig-zzzz", iconVaraso);
    logs.logga("imageConfig-zzzzz", imageCASWO);

    const oxkfgdsf =
      //
      // "";
      imageTrue && <ImageCheckReplace {...imageCASWO} />;

    // <IconMainy {...iconVaraso} />

    // noIcon ? (
    //   ""
    // ) : iconObj ? (
    //   iconObj
    // ) :

    const khfdsv =
      //
      noImage ? "" : noIcon ? "" : oxkfgdsf;

    // return <IconPart />;
    // return <ImagePart />
    return khfdsv;
  }

  function TextPart({ ...sdf }) {
    //
    //
    function dfogkd() {
      const cvbcsa = textArray.map((sweetItem) => {
        return <div>{sweetItem}</div>;
      });
      return cvbcsa;
    }

    // function chckoa() {
    //   return checkTrueOther(textobj, textvar);
    // }

    const textFinso =
      //
      // "sdfksdf";
      loadtrue ? "" : textvar;
    // chckoa();
    // lcvkghdf;
    // dfogkd;
    // textArray ? dfogkd : chckoa;

    const okaew = {
      ...lgkhdfg,
      ...textConfig,
      ...badgeShText,
      obj: textFinso,
      ...sdf,
    };

    gens.messCheck("odqewqcoadqweqw", messvar, "textConfig", okaew);

    // confirmTrue = true;

    const oskdw = confirmTrue ? (
      <ConfirmButton {...okaew} />
    ) : (
      <Divo {...okaew} />
    );
    //

    const lkdfa = {
      style: {
        // background: "blue",
        margin: "0 10px",
      },
      obj: oskdw,
    };

    const plcvbds = (
      //
      // textobj;
      // textvar;
      // textFinso;
      // oskdw;
      <Divo {...lkdfa} />
    );

    const sodkfa = plcvbds ? plcvbds : "";

    return sodkfa;
  }

  function ChkASW() {
    //
    // const ICosasa = "";
    // const txspw =
    //   //
    //   // <TextPart />;
    //   checkTrueOther(textobj, textvar);
    // const textos = noText ? "" : txspw;
    // const asidjwe = swapItems ? { leftIcon: ICosasa } : { rightIcon: ICosasa };
    // const qywgqe = "";

    // // 1chakra

    // // isLoading:
    // // loadingText="Submitting"
    // // colorScheme="teal"
    // // variant="outline"

    // const wijqe = {
    //   colorScheme: "teal",
    //   variant: "solid",
    //   colorScheme: "blue",
    //   // ...asidjwe,
    //   ...args,
    // };

    // logs.logga("___ ChkASW ___", textos);
    // logs.logga("___ wijqe ___", wijqe);

    // const wuehqwe = (
    //   <gens.ButtonChak {...wijqe}>
    //     {/*  */}
    //     {textos}
    //   </gens.ButtonChak>
    // );
    // const ijade = {
    //   obj: wuehqwe,
    //   ...args,
    // };

    // const asdjwe = (
    //   <>
    //     {/* {wuehqwe} */}
    //     <Divo {...ijade} />
    //   </>
    // );

    const asdjwe = "";

    return asdjwe;
  }

  function FloatO() {
    //
    const lfto = swapItems ? "" : <TestImage />;
    const mddlo = noText ? "" : <TextPart />;
    const rghto = swapItems ? <TestImage /> : "";

    const osdkfew = [
      //
      lfto,
      mddlo,
      rghto,
    ];

    const asdnow8q = {
      // leftObj: "COVERED IN FLOATVARS DICT BELOW",
      leftObj: lfto,
      centerObj: mddlo,
      rightObj: rghto,
      // nowap: popTrue,
      // namebottom
      newLineTrue: newLineTrue,
      ...floatConfig,
    };

    const kdaw = {
      listvar: osdkfew,
      horizTrue: !newLineTrue,
      oSlider: !newLineTrue,
    };

    logs.logga("asdnow8q", asdnow8q, "asdh8912");

    const dfsgzxcv = (
      <>
        {lfto}
        {mddlo}
        {rghto}
        {/* <gens.ButtonChak
          //
          {...kdfssd}
        >
          {mddlo}
        </gens.ButtonChak> */}
      </>
    );

    const okxfgfd = newLineTrue ? "" : "flex";
    const rti349 = {
      display: okxfgfd,
      // ...lgkhdfg,
    };

    const kcbf = {
      obj: dfsgzxcv,
      style: rti349,
    };

    const sdfijwer = <Divo {...kcbf} />;
    const lkpdsfs =
      //
      // dfsgzxcv
      sdfijwer;
    // "sdfijwer";

    function CHakit() {
      const oksdf = {
        style: {
          alignItems: "center",
          justifyContent: "center",
        },
      };

      // newLineTrue = true;
      const fghkfd =
        //
        "asdfokwer";

      // newLineTrue ? (
      //   lkpdsfs
      // ) : (
      //   //
      //   <gens.FlexChakra {...oksdf}>{lkpdsfs}</gens.FlexChakra>
      // );

      return fghkfd;
    }

    // function LIisti() {
    //   const ertsa = <ListMain returnTrue {...kdaw} />;
    //   return ertsa;
    // }

    // function Butit() {
    //   const ogkbd = (
    //     <FloatMainAll
    //       //
    //       nowap
    //       imageIcon
    //       {...asdnow8q}
    //     />
    //   );

    //   return ogkbd;
    // }

    function FlotIt() {
      const ogkbd = (
        <FloatMainAll
          //
          nowap
          imageIcon
          {...asdnow8q}
        />
      );

      return ogkbd;
    }

    logs.logga("___ asdnow8q ___", asdnow8q);

    const ohkgfg = (
      //
      // <CHakit />
      // <LIisti />
      <FlotIt />
    );

    return ohkgfg;
  }

  // titlevar = "idjsadqwewq";

  function TitlePass() {
    const fisjw = {
      // color: "green",

      textAlign: "center",
      // fontSize: "1em",
      // ...gens.cea(titleConfig, "style"),
    };
    const oskdaw = {
      textvar: titlevar,
      ...titleConfig,
      style: fisjw,
    };

    const sdkfwqe = (
      //
      <Divo {...oskdaw} />
      // <ImageTextDiv {...oskdaw} />
    );

    return sdkfwqe;
  }

  function CountObj() {
    const fdgoker = {
      background: "red",
      color: "white",
      borderRadius: "50%",
    };

    const okssad = {
      style: fdgoker,
      obj: countvar,
    };

    const sijas = (
      //
      // <Divo {...okssad} />;
      <TextPart {...okssad} />
    );

    return sijas;
  }

  function MainBase() {
    const asdkw = !noTitle && titlevar;
    const titmOVE = asdkw && <TitlePass />;

    // 1chakra
    const asdije = (
      //
      // <ChkASW />
      <FloatO />
    );

    const hbxcsd =
      //
      popTrue ? (
        <TestImage truInclude />
      ) : divTrue ? (
        <TextPart
        //
        // {...args}
        />
      ) : (
        asdije
      );

    const bstOvb = (
      <>
        {/*  */}
        {/* <TextPart /> */}
        {/* <TestImage truInclude /> */}
        {/* <>sdfsd</> */}
        {hbxcsd}
      </>
    );

    const sdfowkqe =
      //
      // "";
      chooseitTrue && selectOn();
    // oksdfw;

    const cntobj = countvar && <CountObj />;

    const oksfdk = {
      display: "flex",
    };

    const oksdfsa = {
      style: oksfdk,
      obj: (
        <>
          {bstOvb}
          {cntobj}
        </>
      ),
    };

    const asodk = (
      //
      <Divo {...oksdfsa} />
      // <ListMain returnTrue horizTrue noSlider {...oksdfsa} />;
    );

    const bdflkdsf = (
      <>
        {/* sdofkrwe */}
        {titmOVE}
        {bstOvb}
        {/* asodk; */}
      </>
    );

    const asodjs = popTrue ? {} : args;

    const aksdnwqo = {
      // popTrue: popTrue,
      obj: bdflkdsf,
      ...sdfowkqe,
      ...asodjs,
    };

    // 1console
    logs.logga("___ IMAGETEXTDIV MAIN ___", aksdnwqo);

    const safdszxc = <Divo {...aksdnwqo} />;

    // 1ImageTextDiv
    return safdszxc;
  }

  function SubtObj() {
    const asdojwqs = {
      style: {
        padding: "10px 0 5px",
        // color: "red",
        // textAlign: "center",
        // fontSize: "1.2em",
        // fontStyle: "bold",
      },
      obj: subtitlevar,
      ...subtitleConfig,
      // noImage: true,
      // noText: true,
      // disVar: true,
    };

    logs.logga("___ asdojwqs ___", asdojwqs);
    const seokwer = <Divo {...asdojwqs} />;

    return seokwer;
  }

  const jsewfvds = subtitlevar && <SubtObj />;
  //
  const iwjewq = (
    <>
      <MainBase />
      {jsewfvds}
      {/* "asdokqwe" */}
    </>
  );

  // const cskoew = onClick || onClick;

  const iakew = {
    obj: iwjewq,
    onClick: onClick,
  };

  logs.logga("___ iakew ___", iakew);

  const ajswe = (
    //
    <>
      {/*  */}
      {/* asdawe */}
      {/* {iwjewq} */}
      <Divo {...iakew} />
    </>
  );

  return ajswe;
}
