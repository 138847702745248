import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  getLength,
  Sersyt,
  mapFunc,
  setGlobalSource,
  checkTick,
  changePage,
  checkFullArray,
  segReport,
  //
} from "../functions/GlobalFunctions";
import ListMain from "../components/ListMain";
// import Steps, { Step } from "rc-steps";
import { Steps } from "antd";
import TabMain from "../components/TabMain";
const { Step } = Steps;

export default function OrderStep({
  //
  paidTrue,
  genConfig,
  ...args
}) {
  // 1scriptcontext
  const {
    typeProcess,
    setTypeProcess,
    processComplete,
    detailFill,
    currBasketMedia,
    currBasketHighlights,
    requestSeries,
    fillComplete,
    setFillComplete,
  } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  const mediaList = currBasketMedia;

  const asdowe = [
    //
    // "add",
    "media",
    "details",
    "final",
    "success",
  ];
  const currPorc = asdowe.indexOf(processComplete);
  const curro = asdowe.indexOf(typeProcess);

  function trueEnab(twow, exto = true) {
    const lesso = currPorc < twow - 1;
    return lesso;
  }
  function chekTrue(twow, exto = true) {
    const lesso = currPorc < twow;
    return lesso;
  }

  logs.logga("___ typeProcess ___", typeProcess);
  logs.logga("___ curro ___", curro);

  // const lengtos;
  const sdfgret =
    //
    "Highlights (" +
    getLength(currBasketHighlights) +
    ") " +
    checkTick(checkFullArray(currBasketHighlights));

  const kvxdsf =
    //
    "Order (" +
    getLength(mediaList) +
    ") " +
    checkTick(checkFullArray(mediaList));

  const xckbfg =
    //
    // "Add Episodes";
    "Add ";

  const greeOrder =
    //
    true;
  // chekTrue(0)

  // const checkit = ()
  const saijwe =
    //
    // detailFill
    fillComplete;

  const deetos = "👤 Details " + checkTick(saijwe);

  const epiConfi = {
    add: {
      icon: "smile",
      // subTitle: "Left 00:00:08",
      // description: "This is a description.",
      textvar: xckbfg,
      // disabled: trueEnab(0),
      // checkTrue: chekTrue(0),
    },
    media: {
      icon: "smile",
      // subTitle: "Left 00:00:08",
      // description: "This is a description.",
      textvar: kvxdsf,
      // disabled: trueEnab(0),
      checkTrue: greeOrder,
    },
    highlights: {
      textvar: kvxdsf,
      // disabled: trueEnab(0),
      checkTrue: greeOrder,
    },
    details: {
      icon: "user",
      textvar: deetos,
      // disabled: trueEnab(1),
      checkTrue: chekTrue(1),
    },

    final: {
      icon: "smile",
      textvar: "Pay",
      checkTrue: trueEnab(2),
    },
    success: {
      icon: "smile",
      textvar: "Complete",
      checkTrue: trueEnab(3),
    },
  };

  function setChange(typoes) {
    logs.logga("___ typoes ___", typoes);

    // if (!paidTrue) {
    // }

    const kasdeq = {
      type: typoes,
    };
    segReport("Order Tab Clicked", kasdeq);
    setTypeProcess(typoes);
    // changePage();
  }

  const cvkbmdsf = {
    //
    // color: "red",
    // fontSize: "48px",
  };

  const cvlxb = [
    //
    // "add",
    // "scribe",
    "media",
    "details",
  ];

  const xcvlkf = {
    fontSize: "30px",
    margin: "0 10px",
  };

  const asodwe = {
    // direction: "horizontal",
    onChange: setChange,
    style: cvkbmdsf,
    styleTab: xcvlkf,
    // itemConfig: xcvlkf,
    // percent: 60,
    // size: "small",
    dictvar: epiConfi,
    listvar: cvlxb,
    progressDot: true,
    stretchTrue: true,
    current: curro,
    chosenItem: curro,
    orientation: gens.mobileView && "vertical",
    ...args,
  };

  // 1conso;e
  logs.logga("___ ORDERSTEP  ___", asodwe);

  const okfdsd = (
    <>
      {/*  */}
      {/* {lissaoc} */}
      {/* adfplkerwer */}
      {/* <TabMain / */}
      <TabMain {...asodwe} />
    </>
  );

  args = {
    obj: okfdsd,
    // fadeTrue: true,
    // style: { border: "2px solid green", width: "2000px" },
    ...genConfig,
  };

  // 1HTML
  return <Divo {...args} />;
}
