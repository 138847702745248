import EditContainer from "../containers/EditContainer";
export default function MediaPage({
  //
  match: { params: paramsSo },
}) {
  const isdjwe = {
    ...paramsSo,
  };

  return <EditContainer {...isdjwe} />;
}
