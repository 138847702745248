import React, {
  //
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  getLength,
  getMediaChapter,
  GraphPage,
  segReport,
  findHighString,
  setGlobalSource,
  getParamVar,
  createHighlight,
  //
} from "../functions/GlobalFunctions";
import PageBase from "../components/PageBase";
import MediaAlter from "../containers/MediaAlter";
import * as logs from "../consts/logFuncs";
import * as gens from "../consts/GenStyled";
import HighlightList from "../containers/HighlightList";
import { useEffect } from "react";

export default function EditPage({
  //
  slugURL,
  typevar,
  typeSlug,
  editTrue,
  ...args
}) {
  let history = useHistory();
  const {
    orderDetails,
    // editTrue,
    loadAny,
    currMedia,
    setcurrMedia,
    setcurrHighlight,
    editRedirect,
    alterMeddAdd,
    setlistChapters,
    setChapBase,
  } = useContext(ScriptContext);

  function getHighos(endiai) {
    const startoi = getParamVar("start");
    const sdfjewe = {
      startTime: startoi && parseFloat(startoi),
      media: endiai,
    };

    const newOne = typeSlug == "new";
    const ijfdg = newOne
      ? createHighlight(sdfjewe)
      : getMediaChapter(endiai, typeSlug);

    return ijfdg;
  }

  function okdsar(dfgre) {
    const dfsadsd = dfgre.chapterList;

    logs.logga("___ currMedia ___", currMedia);
    logs.logga("___ dfsadsd ___", dfsadsd);

    if (typeSlug) {
      switch (typevar) {
        case "highlights":
          if (dfgre) {
            let saokwae = getHighos(dfgre);

            logs.logga("___ curreHigh ___", saokwae);

            // setbssoHigh(saokwae);
            // setcurrHighlight(saokwae);
          }
          break;
      }
    }

    if (!currMedia) {
      // setcurrMedia(mediaObj, "HIGLHLIGHT LISE USEEFECT");

      //
      setChapBase(dfsadsd);
      setcurrMedia(dfgre, "LOADING EDIT PAGE");
    }
  }

  const nsifjsd = {
    fetchFunction: okdsar,
  };

  const { dataImport: ediMedia, loadTrue: losiow } =
    //
    // "";
    GraphPage("media", slugURL, nsifjsd);

  const endiai = currMedia ? currMedia : ediMedia;

  const [sdfgtr, setbssoHigh] = useState();
  const bssoHigh =
    //
    endiai && getHighos(endiai);
  // sdfgtr

  // 1const
  const [type, setType] = useState("");

  function AltMeddo() {
    //
    //

    function changeEdit(vidVals) {
      const setosa =
        //
        // "/order";
        editRedirect;

      logs.logga("___ changeEdit ___", vidVals);
      logs.logga("___ setosa ___", setosa);

      segReport("Added Media Meta", vidVals);
      alterMeddAdd(vidVals);
      history.push(setosa);
    }

    const okazcxewwe = {
      backFunc: changeEdit,
    };

    const a7yqwe = {
      padding: "0 10%",
      fontSize: "20px",
    };

    const srdNos =
      orderDetails &&
      orderDetails["noteJSON"] &&
      orderDetails["noteJSON"]["optionsMedia"];
    const xmcsda = srdNos && srdNos[endiai.id];

    const vcxobdf = {
      style: a7yqwe,
      mediaObj: endiai,
      saveFunc: changeEdit,
      topConfig: okazcxewwe,
      orderDetails: orderDetails,
      noteJSON: xmcsda,
      // backFunc: changeEdit,
      // ...saveMediaConfig,
    };

    logs.logga("___ EDITPAGE -- MediaAlter ___", vcxobdf);

    const nblmofd = (
      //
      // <>"aoskdwqew"</>
      <MediaAlter {...vcxobdf} />
    );

    return nblmofd;
  }

  const askdae = {
    editRedirect: editRedirect,
  };

  logs.logga("___ slugURL ___", slugURL);
  logs.logga("___ currMedia ___", currMedia);

  const sdewse = {
    description: null,
    duration: 23,
    endTime: 130,
    id: 6508725098837027000,
    name: "Take more photos than you think you should",
    startTime: 107,
    tags: null,
    __typename: "HighlightType",
  };

  // 1console
  const dsjfew = {
    MEDIA: endiai,
  };

  logs.logga("___ EDITPAGE ___", dsjfew);

  //
  useEffect(() => {
    //
  });

  function HighBase() {
    const uhfgert = {
      mediaObj: endiai,
      noNameAdd: true,
      highlightSelect: bssoHigh,
    };

    logs.logga("___ uhfgert ___", uhfgert);
    const sidjfaeq = <HighlightList {...uhfgert} />;
    return sidjfaeq;
  }

  function GoDioa() {
    let endValue = "";

    switch (typevar) {
      case "highlights":
        endValue = <HighBase />;
        break;
      case "chapters":
        endValue = <HighBase />;
        break;
      case "details":
        endValue = <AltMeddo />;
        break;
      default:
        endValue = <HighBase />;
    }

    return endValue;
  }

  function helmFunc() {
    const sdije = {
      highlights: findHighString(endiai),
      typeSlug: typeSlug,
    };

    logs.logga("___ sdije ___", sdije);

    const donso =
      //
      typevar ? sdije[typevar] : endiai.name;

    return donso;
  }

  const dyqweq = !loadAny && endiai;
  const qetwqw = dyqweq && helmFunc();

  const wiejqwe = {
    titlevar: qetwqw,
  };

  const ijsde = {
    // listvar,
  };

  const oksdwe =
    //
    !losiow && endiai;

  function getFasteo() {
    const sdijfre =
      //
      "to make timestamped chapters.";
    // "to make episode timestamps.";
    // "to make timestamped episode chapters."

    const djgiewr = {
      highlights: "to save & share highlights.",
      chapters: sdijfre,
      // ijtewo
    };

    const sadijs = typevar && djgiewr[typevar];

    return sadijs;
  }

  const ijsdaew = {
    obj: oksdwe && <GoDioa />,
    pageInfo: "EDIT",
    helmetConfig: wiejqwe,
    fastestvar: editTrue && getFasteo(),
    // loadTrue: losiow,
    // loadObj: ""
    // sidebarConfig:
    // noTitle: !succTrue,
    // footerTrue: true,
    // footerConfig: {
    //   obj:  <Aceoa/>,
    // },
    ...args,
  };

  const gfhtre = {
    typevar: typevar,

    typeSlug: typeSlug,
  };

  logs.logga("___ EDITPAGE ___", gfhtre);

  const jdfsa = (
    //
    // "asodwem o";
    <PageBase fadeTrue infoTrue {...ijsdaew} />
  );
  // const jdfsa = <Divo {...dsfgsdawe} />;

  // 1HTML
  return jdfsa;
}
