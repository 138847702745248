import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";

import Divo from "../components/Divo";
import TextareaAutosize from "react-textarea-autosize";
import CreatableSelect from "react-select/creatable";

import * as logs from "../consts/logFuncs";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";

export default function InputBase({
  textConfig,
  titleConfig,
  textvar,
  errorobj,
  noInput,
  onInput,
  noTitle,
  checked,
  selectTrue,
  style,
  onChange,
  obj,
  ref,
  required,
  name,
  initialValue,
  selectvar,
  typevar,
  rows,
  titlevar,
  placeholder,
  subtitlevar,
  errors,
  iconvar,
  checkList,
  checklistTitle,
  ...dfsgre
}) {
  logs.logga("___Asidnw obj ___", obj);

  // const [checkBoxo, setcheckBoxo] = useState();
  // const [chekBxTrue, setchekBxTrue] = useState(checked);

  logs.logga("___ errors ___", errors);

  const [valInit, setvalInit] = useState(initialValue);

  const asw =
    //
    // "";
    errors && errors[name] && errors[name]["message"];

  // .required:after {
  //   content:" *";
  //   color: red;
  // }

  // {
  //   padding: "10px 0",
  //   color: "black",
  //   fontFamily: "Arial, Helvetica",
  //
  // },

  const ashwe = {
    width: "95%",
    fontSize: "18px",
    padding: "0px 5px",
    border: "2px solid black",
    // margin: "2px 100px",
    // color: "red",
    // width: "1000px",
    // height: textTrue ? "20px" : "50px",
    width: "100%",
    // margin: "0 5%",
    ...style,
  };

  const slasso = required ? "required" : "";

  function chaneeo(adowe) {
    const valows = adowe.target.value;

    // logs.logga("___ adowe ___", adowe);
    logs.logga("___ valows ___", valows);

    setvalInit(valows);

    if (onChange) {
      onChange(valows);
    }
  }

  const changBssoe = {
    onChange: chaneeo,
    value: valInit,
  };

  const objbase = {
    ...dfsgre,
    errorobj: asw,
    name: name,
    id: name,
    textvar: textvar,
    style: ashwe,
    required: required,
    innerRef: { required: true },
    ref: ref,
    // onBlur: chaneeo,
    className: slasso,
    // ...changBssoe,
  };

  // 1console
  // logs.logga("___ ijsdfasd ___", ijsdfasd);
  logs.logga("formMain--INPUT------zzz", name, objbase);

  //
  //
  let ujsdqwe = "";
  //

  switch (typevar) {
    case "select":
      ujsdqwe = (
        <select {...objbase}>
          {selectvar.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      );

      break;

    case "selectable":
      //
      // autoFocus - focus the control when it mounts
      // className - apply a className to the control
      // classNamePrefix - apply classNames to inner elements with the given prefix
      // isDisabled - disable the control
      // isMulti - allow the user to select multiple values
      // isSearchable - allow the user to search for matching options
      // name - generate an HTML input with this name, containing the current value
      // onChange - subscribe to change events
      // options - specify the options the user can select from
      // placeholder - change the text displayed when no option is selected
      // value - control the current value

      const dfdsawe = {
        ...objbase,
        isClearable: true,
        // onInputChange={this.handleInputChange}
        // options={colourOptions}
      };
      ujsdqwe = <CreatableSelect {...dfdsawe} />;

      //
      break;
    case "textarea":
      // const gfknht =  ref={(tag) => (this.textarea = tag)}
      rows = rows ? rows : 3;
      const ijsdfasd = {
        rows: rows,
        minRows: rows,
        defaultValue: textvar,
        ...objbase,
        ...textConfig,
        // ref={(tag) => (this.textarea = tag)}
      };

      // const sadije = innerRef({ required: required }

      logs.logga(textvar + "___ INPUTBASE TEXTAREA ___", ijsdfasd);

      const okasewe =
        //
        "";
      // placeholder

      // ujsdqwe = <TextareaAutosize autoFocus />;
      ujsdqwe = <TextareaAutosize {...ijsdfasd} />;
      // {textvar}</TextareaAutosize>;
      // ujsdqwe = <textarea {...ijsdfasd} />;
      // ujsdqwe = <textarea {...ijsdfasd}>{textvar}</textarea>;
      // ujsdqwe = "";

      break;

    case "checkboxList":
      //
      ujsdqwe = "ijadqwoek";
    // ujsdqwe = <input {...dsoadkw0} />;

    case "checkbox":
      const dsoadkw0 = {
        type: "checkbox",
        // onChange: (e) => setchekBxTrue(!chekBxTrue),
        ...objbase,
      };

      // const okaswe;
      const isawe = {
        type: "checkbox",
        // {...dsoadkw0}
      };
      const ijasdwe = {
        obj: textvar,
        style: {
          marginRight: "10px",
        },
      };

      const dsf9jewr = <Divo {...ijasdwe} />;

      ujsdqwe = (
        <>
          {/*  */}
          {/* <input {...dsoadkw0} />; */}
          <input {...isawe} /> {dsf9jewr}
          {/* </input> */}
          {/* <gens.CheckboxMain {...dsoadkw0}>{obj["textvar"]}</gens.CheckboxMain> */}
        </>
      );

      break;

    default:
      // textvar = "DEFAULT TEXT";
      const ijawe = {
        // type: "text",
        // height: "10px"
        rows: 1,
        className: "noresize",
        // defaultValue: textvar,
        // placeholder: textvar,
        // value: textvar,
        // value: textvar ? textvar : undefined,
        ...objbase,
      };

      logs.logga("___ inputBase--INPUT ___", ijawe);

      // 1input
      ujsdqwe = noInput ? (
        ""
      ) : textvar ? (
        //
        // <gens.StInput {...objbase}>{textvar}</gens.StInput>
        <textarea {...ijawe}>{textvar}</textarea>
      ) : (
        // <input {...ijawe}>{textvar}</input>
        // <input {...ijawe} />
        // <textarea {...ijawe}>{textvar}</textarea>
        // <gens.StInput {...ijawe} />
        // <input {...ijawe} />
        <input {...ijawe} />
      );
  }

  function TitleOBbi() {
    //

    const sdaew =
      //
      // "1.5em"
      "24px";

    const iawqe = {
      // padding: "12px 0 2px",
      // color: "red",

      fontSize: sdaew,
      fontWeight: "bold",
      ...titleConfig?.style,
    };

    const ijweq = {
      obj: " *",
      style: {
        color: "red",
      },
    };

    const aokdwe = required ? <Divo spanTrue {...ijweq} /> : "";

    const olbknfr = {
      fontSize: "0.7em",
      color: "red",
      marginLeft: "10px",
      // className: "error",
    };

    const asdwe = {
      obj: asw,
      className: "error",
      style: olbknfr,
    };
    const bnkifg = <Divo spanTrue {...asdwe} />;

    const ioakawe = (
      <>
        {titlevar}
        {aokdwe}
        {bnkifg}
      </>
    );

    const asdojwqs = {
      obj: ioakawe,
      // textvar: ioakawe,
      iconvar: iconvar,
      noImage: !iconvar,
      noIcon: !iconvar,
      ...titleConfig,
      style: iawqe,
      // noText: true,
      // disVar: true,
    };

    // logs.logga("___ asdojwqs ___", asdojwqs);

    const seokwer = (
      //
      // <ImageTextDiv {...asdojwqs} />
      // <BediaTextDiv {...asdojwqs} />
      <Divo {...asdojwqs} />
    );

    function Subtoter() {
      const ikawed = {
        fontSize: "0.8em",
      };

      const okawe = {
        obj: subtitlevar,
        style: ikawed,
      };
      const uiajwe = <Divo {...okawe} />;

      return uiajwe;
    }

    const ijawe = subtitlevar && <Subtoter />;

    const sdfgret = (
      <>
        {seokwer}
        {ijawe}
        {/* <hr /> */}
      </>
    );

    const kase = {
      padding: "10px 0",
      textAlign: "left",
    };

    const isawqe = {
      obj: sdfgret,
      style: kase,
    };

    return <Divo {...isawqe} />;
  }

  const showTit =
    //
    // true;
    !noTitle && titlevar;

  const oksdwqe = showTit && <TitleOBbi />;

  const asdijwe = (
    //
    <>
      {ujsdqwe}
      {/*  */}
    </>
  );

  logs.logga("___ ujsdqwe ___", ujsdqwe);
  logs.logga("___ asdijwe ___", asdijwe);

  const ijdas = (
    <>
      {oksdwqe}
      {/* Please Enter your {asw} */}
      {/* sweqeq */}
      {asdijwe}

      {/* {PushForm(lobj)} */}
      {/* <Divo {...iajsda} />; */}
    </>
  );

  return ijdas;
  // }
}
