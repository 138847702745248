import React, {
  //
  useState,
  useEffect,
  useContext,
} from "react";
import { ScriptContext } from "../contexts/ScriptContext";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import { useHistory } from "react-router-dom";

// import aaaa from "./components/aaaa";
import {
  getParamVar,
  GraphArgo,
  GraphFuncBase,
  saveModel,
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";
import FormMain from "../components/FormMain";
import PageBase from "../components/PageBase";
import CreateHighlight from "../containers/CreateHighlight";

export default function CreatePage({ youtubeid, ...sdfe }) {
  // 1scriptcontext
  const { createcurrHighlight } = useContext(ScriptContext);
  let history = useHistory();

  const youtubeFind = youtubeid ? youtubeid : getParamVar("youtube");
  const startFind = getParamVar("startTime");

  const youtubeArgs = youtubeFind && {
    mediaLink: "https://www.youtube.com/watch?v=" + youtubeFind,
  };

  const fethicio = {
    ...youtubeArgs,
  };

  const [fetchArgs, setfetchArgs] = useState(fethicio);

  const fdijrt = {
    createTrue: true,
    ...fethicio,
  };

  const anyios = youtubeFind;

  function afterFthc(medOVis) {
    const ijdse = {
      media: medOVis,
      startTime: parseFloat(startFind),
    };
    const endLnko = createcurrHighlight(ijdse);

    logs.logga("___ afterFthc ___", ijdse);
    logs.logga("___ endLnko ___", endLnko);

    history.push(endLnko);
  }

  const dsjfrfr = {
    boolTrue: true,
    boolVar: anyios,
    fetchFunction: afterFthc,
  };

  const ofdk = {
    anyios: anyios,
    ARGS: fdijrt,
  };

  //   1console
  logs.logga("___ ofdk ___", ofdk);

  // 1const
  const [type, setType] = useState("");

  function saveOss(fdijrt) {
    logs.logga("___ saveOss ___", fdijrt);
    saveModel("media", fdijrt, afterFthc);
  }

  useEffect(() => {
    //
    if (anyios) {
      saveOss(fdijrt);
    }
  }, []);

  function SubmtAll() {
    //
    const idsewe = {
      onSubmit: afterFthc,
    };
    // genConfig: { style: { padding: "0 10%" } },
    const ijsdsd = <CreateHighlight {...idsewe} />;

    return ijsdsd;
  }

  function FetchProcess() {
    //
    return "Just a tick...";
  }

  const inputFind = anyios ? <FetchProcess /> : <SubmtAll />;

  const okfdsd = (
    <>
      {/*  */}
      {inputFind}
    </>
  );

  const dfijdfsa = {
    //
    fontSize: "30px",
    padding: "10 10%",
  };

  const okhytry = {
    obj: okfdsd,
    style: dfijdfsa,
  };

  const sisdwe = {
    obj: <Divo {...okhytry} />,
    helmetConfig: "New",
    fastestvar: "to save and share highlights.",
    ...sdfe,
  };

  return <PageBase {...sisdwe} />;
}
