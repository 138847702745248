import React, {
  //
  useState,
  useContext,
} from "react";
import {
  Heading,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Link,
  HStack,
  IconButton,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  parseHourString,
  CurrencyMain,
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";

export default function BasePriceMod({
  scheduleType = "minute",
  scheduleAdd,
  baseType,
  monthTrue,
  timeSave,
  timeBase,
  accuracy,
  monthTime,
  totalTime,
  noAccuracy,
  noBasic,
  totalCostTrue,
  plusCost = 0,
  titlevar,
  cost,
  ...sdwe
}) {
  // 1scriptcontext
  const { orderCountry } = useContext(ScriptContext);

  // 1order
  const aurrcosObj = (
    <Text fontSize="3xl" color="black.500">
      {accuracy}% Accuracy
    </Text>
  );

  const ifdjwe = {
    month: plusCost,
    minute: cost,
  };

  const dfhugu = ifdjwe[scheduleType];

  const sdfowe = plusCost ? plusCost : cost;
  const ijsfer =
    //
    // "$" + dfhugu;
    CurrencyMain(orderCountry, sdfowe);

  const plseus = timeSave;
  const timePlsueo = parseHourString(plseus);

  function TimeGoBase() {
    const plusoaw = totalTime * 60 * cost + plusCost;
    const fghijrtr = CurrencyMain(orderCountry, plusoaw);
    const dfigjd = (
      <HStack justifyContent="center">
        <Text fontSize="3xl" fontWeight="600">
          {fghijrtr}
        </Text>
        <Text fontSize="3xl" color="gray.500">
          / month
        </Text>
      </HStack>
    );

    return dfigjd;
  }

  const dijgfd = totalCostTrue && totalTime;
  const totalTimeObj = dijgfd && <TimeGoBase />;

  // 1console
  const mghkjer = {
    totalTime: totalTime,
    totalTimeObj: totalTimeObj,
  };

  logs.logga("totalTimeObj-zzz", totalTimeObj);

  const perMinObj = (
    <HStack justifyContent="center">
      <Text fontSize="3xl" fontWeight="600">
        {ijsfer}
      </Text>
      <Text fontSize="3xl" color="gray.500">
        / {scheduleType}
      </Text>
    </HStack>
  );

  // monthTrue = true;

  const fdigfd = monthTrue && "/ month";
  const saveisTime = (
    <Text fontSize="4xl" fontWeight="600">
      {timePlsueo} saved {fdigfd}
    </Text>
  );

  const asdijw = (
    <Box
      marginBottom={10}
      margin="10 0"
      className="borderShadow"
      fontWeight="500"
      fontSize="4xl"
    >
      <Text
        //
        bg={useColorModeValue("grey")}
        color="white"
      >
        Monthly Stats
      </Text>
      {/* {totalTimeObj} */}
      {saveisTime}
    </Box>
  );

  const dfijds =
    //
    saveisTime;
  // monthTrue ? asdijw : saveisTime;

  const baseTexxo = (
    <Box py={4} px={12}>
      <Text fontWeight="500" fontSize="4xl">
        {titlevar}
      </Text>
      {!noBasic && <Text fontSize="3xl">{baseType}</Text>}
      {perMinObj}
      {/* {aurrcosObj} */}
      {!noAccuracy && aurrcosObj}
      {dfijds}
    </Box>
  );

  const sadjae = {
    obj: baseTexxo,
    ...sdwe,
  };

  return <Divo {...sadjae} />;
}
