import React, {
  //
  useState,
  useContext,
} from "react";
import ConfirmButton from "../components/ConfirmButton";
import { ScriptContext } from "../contexts/ScriptContext";

import * as gens from "../consts/GenStyled";

import Divo from "../components/Divo";

import * as logs from "../consts/logFuncs";

// import AppMarkdown from "../consts/about.md";
import {
  normalText,
  borderMain,
  getParamVar,
  Mappo,
  boldTitle,
  QuoteFromGuy,
} from "../functions/GlobalFunctions";
import AboutTypes from "./AboutTypes";
import ListMain from "../components/ListMain";
import OrderProcess from "./OrderProcess";

export default function AboutClients({
  //
  dataImport,
  ...args
}) {
  // prettier-ignore
  // const { aaaaaa } = useContext(ScriptContext);

  const typoes = getParamVar("typ");
  const pglfgds = typoes ? typoes : "neither";
  const [clienType, setclienType] = useState(pglfgds);

  // 1const
  const [type, setType] = useState("");

  // useEffect(() => {
  //   fetch(AppMarkdown)
  //     .then((res) => res.text())
  //     .then((text) => setType(text));
  // }, []);

  const okasdw = `


  
    `;

  const hvufns =
    //
    type;
  // okasdw

  const adomaqew = {
    obj: hvufns,
  };

  function JAwA(listo) {
    const ijwe = {
      //
      typevar: "card",
      listvar: listo,
    };
    const ijaww = <AboutTypes {...ijwe} />;
    return ijaww;
  }

  function BasetTop() {
    const basico = JAwA(["transcript", "subtitle", "chapter"]);

    const okxvzas = {
      //
      padding: "0 10px",
      margin: "30px 0",
      height: "80px",
      fontSize: "36px",
    };

    const yqwgeqws = {
      //
      bediaTrue: true,
      style: okxvzas,
    };

    const bvcxver = {
      onlyText: "Order Episodes",
      genConfig: { linkvar: gens.orderLink },
      ...yqwgeqws,
      // fontSize:
    };

    const xcvbfdgasdz = {
      ...yqwgeqws,
      onlyText: "Connect Channel",
      // disableTrue: true,
      style: { background: "darkred" },
      genConfig: { linkConfig: gens.gettouchconf },
      // fontSize:
    };

    const gfoker =
      //
      "files/EXAMPLE FILES - Bedia/";

    const iawevzxc =
      //
      // "files/a.txt";
      // gfoker + "a.zip";
      // gfoker +
      // "/FILES - Psych2Go - 10 Behaviors that Destroy Relationships.zip";
      "https://github.com/SillyScribe95/bedia-public/blob/master/FILES%20-%20Psych2Go%20-%2010%20Behaviors%20that%20Destroy%20Relationships.zip?raw=true";

    const xcvbsda = {
      downloadLink: iawevzxc,
      onlyText: "Download Examples",
      style: {
        ...okxvzas,
        background: "darkgreen",
      },
      // fontSize:
    };

    function ConnButots() {
      const okdwe = {
        order: bvcxver,
        download: xcvbsda,
        connect: xcvbfdgasdz,
      };

      const cvmbdf = [
        //
        "order",
        // "download",
        // "connect",
      ];

      const okawe = {
        listvar: cvmbdf,
        dictvar: okdwe,
        itemConfig: yqwgeqws,
        horizTrue: true,
        obj: ConfirmButton,
      };

      const okawef = (
        //
        <ListMain objFuncTrue {...okawe} />
        // <ConfirmButton {...iawe} />
      );

      const aoskew = (
        <>
          {/* What are you waiting for? */}
          {okawef}
        </>
      );

      return aoskew;
    }

    const oakwew = {
      style: {
        //
        padding: "10px 0 30px",
        // fontSize: "12px",
      },
    };

    function TetShow() {
      //
      function Manota({ listType, emojis, iconvar, style, descText, ...adas }) {
        //   function Manota(obj) {
        // logs.logga("___ Manota---obj ___", obj);

        const odksxcvds = {
          iconvar: iconvar ? iconvar : listType,
        };

        const hokdswe = {
          // style:
          //   emojis + " " +
          obj: descText,
        };

        const owase = (
          <>
            {/* asdfokewrw */}
            {/* <ImageTextDiv {...odksxcvds} /> */}
            {emojis}
            <Divo {...hokdswe} />
          </>
        );

        const osaeasd = {
          fontSize: "20px",
          padding: "10px 20px",
          margin: "0 10px",
          textAlign: "center",
          height: "160px",
          border: "2px solid grey",
          ...style,
        };

        const oikcvsd =
          //
          gens.butClassHover;
        //   + " shadeTrue";
        //   " pointer";

        const ijawe = {
          className: oikcvsd,
          style: osaeasd,
          obj: owase,
          onClick: () => setclienType(listType),
          ...adas,
        };

        return <Divo {...ijawe} />;
      }

      // const ksadae =

      // 1audio
      const audaBout = "I have transcripts on my blog, but no subtitles.";

      // 1video
      const vidaBout = "I have subitles on my videos, but no transcripts.";

      const asdfoew = {
        video: {
          emojis: "💬",
          descText: vidaBout,
        },
        audio: {
          emojis: "📜",
          descText: audaBout,
        },
        neither: {
          emojis: "❓",
          iconvar: "question",
          descText: "I don't use transcripts or subtitles.",
        },
      };

      const xijas = {
        //
        ...borderMain("darkgrey"),
      };

      const okdfsfa = {
        style: xijas,
        bediaTrue: true,
        // className:
      };

      const okvxcbds = {
        className: gens.butClass + " pointer",
      };

      const okdga = {
        dictvar: asdfoew,
        itemConfig: okvxcbds,
        chosenItem: clienType,
        chosenConfig: okdfsfa,
        obj: Manota,
        listvar: [
          //
          "video",
          "audio",
          "neither",
        ],
      };

      const asokwe = (
        <>
          <ListMain
            //
            horizTrue
            // objFuncTrue
            {...okdga}
          />
          {boldTitle("Why not have both?")}
        </>
      );

      return asokwe;
    }

    // through SEO
    // <gens.BU>and</gens.BU>
    const doflsa = (
      <>
        Bedia is the easiest way to make your content accessible and grow your
        audience.
      </>
    );

    const findBsow = (
      <>
        Our curated transcribers (called Scribes) are trained to use a range of
        technologies to make sure you get 100% accurate files for your episodes,
        every time.
      </>
    );

    //
    const gfbsdff = (
      //
      <>Just send over a link to your video/podcast and we'll send you...</>
    );

    const oksdda = [
      //
      doflsa,
      findBsow,
      gfbsdff,
    ];

    const okfdsgd = {
      padding: "10px 0",
    };

    const okvsdad = {
      arrVar: oksdda,
      style: okfdsgd,
    };

    const sdfgqerws = (
      <>
        <Mappo {...okvsdad} />
      </>
    );

    const oskdaa = "Our Scribes: Personal & Professional";

    const bsfdds = (
      <>
        If you have a lot of content and don't want to order every time,
        {/*  */} {/*  */}
        Bedia can also track your channel and email the files for each new
        episode automatically.
        {/*  */}
        {/* We can even generate a podcast fee */}
        <br />
        {/*  */}
        {/*  */}
        {/* We make sure our Scribes are trained to the highest capacity before. */}
        {/* Once a Scribe gets verified, they can even reach to channels they like */}
      </>
    );

    const vlbgd = (
      <>
        {/*  */}
        That's it! We like to keep things simple. 😊
      </>
    );

    const ictop = !gens.mobileView && <TetShow />;

    const xcvsd = {
      // padding: ""
    };
    const yeqwe = (
      <>
        {/* <OrderProcess /> */}
        {/* {ictop} */}
        {normalText(<QuoteFromGuy {...oakwew} />)}
        {normalText(sdfgqerws)}
        {basico}
        <ConfirmButton {...xcvbsda} />
        {/* {boldTitle(oskdaa)} */}
        <br />
        <br />
        {normalText(bsfdds)}
        {normalText(vlbgd, "center")}
        {/* <ConnButots /> */}
        {/* <ConfirmButton {...xcvbfdgasdz} /> */}
        <ConfirmButton {...bvcxver} />
        {/* {boldTitle("It's that simple.")} */}
        {/* {xcvbas} */}
      </>
    );

    return yeqwe;
  }

  function bidsewsad(obj) {
    //

    logs.logga("___ obj ___", obj);

    return "aokaweeq";
  }

  function Testimon() {
    const skdmwqe = (
      <>
        {/* asdkaweqe */}
        {/*  */}
      </>
    );

    return skdmwqe;
  }

  function ExtBelow() {
    //
  }

  const okfdsd = (
    <>
      <BasetTop />
      <Testimon />

      {/* <ExtBelow /> */}
    </>
  );

  const okawew =
    // "0 " + gens.mobview("5%", "10%"),
    "0 2%";

  const gfhoker = {
    textAlign: "center",
    padding: okawew,
    fontSize: "20px",
    fontFamily: "Arial",
  };

  args = {
    obj: okfdsd,
    style: gfhoker,
    ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
