import React, {
  //
  useState,
  useContext,
  useEffect,
} from "react";
import { useRef } from "react";
// import * as gens from "../consts/GenStyled";
import { VideoSeekSlider } from "react-video-seek-slider";
import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import ReactPlayer from "react-player";
import {
  getLinkPlay,
  floatMeddo,
  getMedRef,
  geFDrEF,
  ImageTextList,
  //   BlankWrap,
  //   setGlobalSource,
  //
} from "../functions/GlobalFunctions";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import { useCallback } from "react";

export default function PlayerBase({
  //
  mediaObj,
  playerConfig,
  startLine,
  startTime = 0,
  endLine,
  ...args
}) {
  // // 1context
  // 1scriptcontext
  // prettier-ignore
  const { playingTrue, playerRef, playerMedLink, currStartTime, getCurrentTime  } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");
  const [timPlaro, settimPlaro] = useState();
  const { fdgadsf, setplayerTime } = "";
  // function playerTime() {
  //   return getMedRef(playerRef);
  // }

  function downHandler({ ...event }) {
    logs.logga("___ event ___", event);

    // event.preventDefault();
    // logs.logga("___ key ___", key);

    // switch (attrVar) {
    //   case "a":
    //     endValue = ""
    //     break;

    //   default:
    //     endValue = "";
    // }
  }

  // const handleUserPress = useCallback((event) => {
  //   logs.logga("___ event ___", event);

  //   downHandler(event);
  // }, []);

  // window.addEventListener("keydown", handleUserPress);
  // return () => {
  //   window.removeEventListener("keydown", handleUserPress);
  // };

  // // Add event listeners
  // useEffect(() => {
  //   window.addEventListener("keydown", downHandler);
  //   window.addEventListener("keyup", downHandler);
  //   // Remove event listeners on cleanup
  //   return () => {
  //     window.removeEventListener("keydown", downHandler);
  //     window.removeEventListener("keyup", downHandler);
  //   };
  // }, []);

  // function
  let timeoaswe = 0;

  function aidwe(soad) {
    const sadje =
      //
      floatMeddo(soad, 1);

    timeoaswe = sadje;

    const ijsadwe = {
      soad: soad,
      sadje: sadje,
    };

    // settimPlaro(timeoaswe);
    // logs.logga("___ playerTime ___", playerTime());
    // logs.logga("___ ijsadwe ___", ijsadwe);

    // setType(sadje);
    // setskfnw(sadje);
    // setplayerTime(sadje);
  }

  const sdijae =
    //
    playerMedLink ? playerMedLink : mediaObj.mediaFileLink;

  logs.logga("___ startTime AAA___", startTime);

  startTime = startTime ? startTime : currStartTime;

  logs.logga("___ startTime BBBBB___", startTime);

  const pktimPlaroa =
    //
    // mediaObj.mediaFileLink;
    getLinkPlay(sdijae, startTime);

  const okwasd = {
    progressInterval: 1,
    url: pktimPlaroa,
  };

  // logs.logga("___ PLAYERBASE ___", sdfsd);
  // logs.logga("___ playerTime ___", playerTime);

  function ijdsf() {
    // changePlayerTime(playerRef, 30);
  }

  const butosad = {
    onClick: ijdsf,
  };

  const ahdw = (
    <>
      <hr />
      {type}
      {playerTime()}
      <button {...butosad}>sdfjewro</button>
      aaaa
      {}
    </>
  );

  const maxBaseo = 1152;

  const ddsir = {
    //

    // max: maxBaseo,
    currentTime: timPlaro,
    progress: 100,
    // onChange: sdferE,
    //         offset={0}
    //         secondsPrefix="00:00:"
    //         minutesPrefix="00:"
  };

  const sdfsd = {
    // height: "10px"
    width: "100%",
    playing: playingTrue,
    onProgress: aidwe,
    // onProgress: playerTime,
    ref: playerRef,
    ...okwasd,
    ...args,
    // playing: true,
    // muted: true,
    // controls: true,
  };

  logs.logga("___ sdfsd ___", sdfsd);

  const ijsdw = {
    id: "mainPlayerVar",
  };

  const jdfgisdf = (
    <>
      {/* <br/>
      <br/>
      <br/> */}
      {timPlaro}
      <VideoSeekSlider {...ddsir} />
    </>
  );

  function Butajsdw() {
    //     const ijade = [
    //   "back",
    //   "play",
    //   "forward"
    // ]
    //     const kijsa = {
    // back:
    // play:
    // forward:
    //     }
    // const fterasew ={
    //   // style:
    // }
    //     const ijsade = {
    //       dictvar:kijsa,
    //       listvar: ijade,
    //       itemConfig:
    //     }
    //     const aisjew = <ImageTextList {...ijsade} />
    //     return aisjew
  }

  const playerObj = (
    <div {...ijsdw}>
      <ReactPlayer
        //
        // controls
        {...sdfsd}
      />
      {/* <Butajsdw /> */}
      {/* {jdfgisdf} */}
    </div>
  );

  function playerTime(soad) {
    //
    const sadje =
      //
      getMedRef(playerRef);

    return sadje;
  }

  const saeww =
    //
    // playerObj;
    {
      playerObj,
      playerTime,
      // playerRef,
      // addPlayerTime,
      // getCurrentTime,
      // changePlayerTime,
    };

  // 1HTML
  return playerObj;
}
