import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";

import InputBase from "./InputBase";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";

// IMPORT OTHER COMPONENTS HERE
// import aaaa from "./components/aaaa";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { mapFunc, turnarray } from "../functions/GlobalFunctions";
import ConfirmButton from "../components/ConfirmButton";
import TabMain from "../components/TabMain";
import { isEmpty } from "lodash";

export default function FormMain({
  noButton,
  noText,
  listDict,
  buttonText,
  subtitleConfig,
  textConfig,
  overObj,
  listvar,
  buttonSize = "35px",
  inputConfig,
  buttonConfig,
  noForm,
  dictvar,
  headerObj,
  headerConfig,
  logTrue,
  tabConfig,
  schemavar,
  hookConfig,
  noTitle,
  typevar,
  topButtonConfig,
  ImageTextList,
  topButtonTrue,
  onSubmit,
  topObj,
  titleConfig,
  ...args
}) {
  //
  //

  schemavar = {
    name: yup
      .string()
      //
      // .match("")
      .required("Please add your name."),
    email: yup
      .string()
      .email("This is not a valid email.")
      .required("Please add your email."),
    // age: yup.number().required(),
    ...schemavar,
  };

  const finsaShc =
    //
    // schemavar
    {};

  function asifjew(asdofj) {
    finsaShc[asdofj] = schemavar[asdofj];
  }

  listvar.map(asifjew);
  // mapFunc(listvar, asifjew);

  const schemabas = yup.object().shape(finsaShc);

  logs.logga("___ finsaShc ___", finsaShc);
  logs.logga("___ schemabas ___", schemabas);

  //
  const { handleSubmit, register, errors, ...asdwe } = useForm({
    // validationSchema: schemaYup
    // mode: "onBlur",
    // reValidateMode: "onChange",
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schemabas),
    ...hookConfig,
  }); // initialise the hook

  //
  //
  function subbTo(values) {
    //
    const emptiosa = !isEmpty(values);
    const trudsoe = emptiosa && onSubmit;

    logs.logga("___ values ___", values);
    logs.logga("___ emptiosa ___", emptiosa);

    if (trudsoe) {
      values["typevar"] = typevar;
      logs.logga("vak", values);
      onSubmit(values);
    }
  }

  logs.logga("argwwwwws-zzzzzz", args, "asdy89io211");
  logs.logga("___ args AAAAAA ___", args);

  args = {
    ...args,
    onSubmit: handleSubmit(subbTo),
  };

  function Buttiona({ ...siwerew }) {
    //

    const aewsadw = {
      style: {
        margin: "20px",
      },
    };
    //
    const jsadcvx = {
      // width: "100%",
      fontSize: buttonSize,
    };

    //
    const dvbijkrw = {
      type: "submit",
      textAlign: "center",
      // color: "black",
      // height: "60px",
      // padding: "10px 20px",
      style: jsadcvx,
      genConfig: aewsadw,
      obj: buttonText,
      longTrue: true,
      ...siwerew,
      // marginTop: ""
    };

    logs.logga("___ dvbijkrw ___", dvbijkrw);

    const adhwdse = (
      //
      <ConfirmButton {...dvbijkrw} />
      // <button {...dvbijkrw}>sdfjwerw</button>
    );

    const asidja = {
      obj: adhwdse,
      style: {
        marginBottom: "20px",
        textAlign: "center",
      },
    };

    return <Divo {...asidja} />;
  }

  const uasdw = {
    required: "Required",
    //   pattern: {
    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    //     message: "invalid email address",
    //   },
  };
  const objbase = {
    ref: register(uasdw),
    // errorobj: asw,
    name: "name",
    // textvar: textvar,
    // ...args,
    // ...inputConfig,
    // ...obj,
  };
  // const asidjwe = {
  //   regDict:
  //   name: "medialink",
  //   noTitle: true,
  //   // onChange: saodkw,
  //   // onInput: saodkw,
  //   textTrue: true,
  //   // style: sdufhqwe,
  //   rows: 2,
  // };

  function getMainaoo(objsoa) {
    const vbijdf9te =
      //
      "Email";
    // ("Email address");
    // "What's your email address?";
    // "Where should we send the transcript to?";

    // // "/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/"
    const emailPatto = {
      //   // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      //   message: "invalid email address",
    };

    const emalBaso = {
      required: true,
      // pattern: emailPatto,
      titlevar: "Email address",
      titlevar: vbijdf9te,
      iconvar: "email",
      // iconvar: "email",
    };

    const nasmwoBL = {
      // required: true,
      // pattern: emailPatto,
      titlevar: "Name",
      // titlevar: vbijdf9te,
      iconvar: "person",
      // iconvar: "email",
    };

    const diewrsm = {
      required: true,
      titlevar: "Password",
      type: "password",
    };

    const nvcbxf = {
      email: emalBaso,
      name: nasmwoBL,
      password: diewrsm,
      // firstname:
      // lastname:
    };

    const ocvbds = nvcbxf[objsoa];

    return ocvbds;
  }

  function asdiwe(obj, baseObj) {
    logs.logga("___ baseObj ___", baseObj);

    const nameeo =
      //
      // obj
      baseObj["name"];
    const existo = overObj && overObj[nameeo];

    const regDict = baseObj["regDict"];
    const pattBase = getMainaoo(obj);
    const asokwe = {
      ...pattBase,
      ...regDict,
    };

    const ijawe = existo ? existo : baseObj["textvar"];

    function sdifnew(asdf) {
      logs.logga("___ asdf ___", asdf);
    }

    const odkasew = {
      // ...asdwe,
      errors: errors,
      overObj: overObj,
      ...baseObj,
      ...inputConfig,
      ...asokwe,
      onChange: sdifnew,
      ref: register(asokwe),
      textvar: ijawe,
    };

    const asodkwe = {
      baseObj: baseObj,
      existo: existo,
      ijawe: ijawe,
    };

    const fhgsdf =
      //
      logTrue;
    // true;

    if (fhgsdf) {
      // logs.logga("___ regDict ___", regDict);
      logs.logge("___ InputBase TEXT ___", asodkwe);
      // logs.logge(obj + "___ InputBase ___", odkasew);
    }

    // 1inputbase
    const oiaswe = InputBase(odkasew);

    return oiaswe;
  }

  function sdfok(obj) {
    // const horzTrue = obj?.horizTrue
    const baseObj = dictvar ? dictvar[obj] : obj;
    const fdjgre = baseObj?.obj;
    const obvdsaf = fdjgre ? fdjgre : baseObj ? asdiwe(obj, baseObj) : "";

    return obvdsaf;
  }

  const firsteo = listvar[0];

  const [currForm, setcurrForm] = useState(firsteo);
  const [currTab, setcurrTab] = useState();

  const listRend = listDict && listDict[currForm];
  const atbosa = listRend ? listRend : currForm;

  const listaros = topButtonTrue ? atbosa : listvar;

  const ijawesdafr = {
    dictvar: dictvar,
    listvar: listvar,
  };

  const mappit =
    //
    //  {/* {mapFunc(listvar, Asidnw)} */}
    // topButtonTrue ?
    turnarray(listaros).map(sdfok);

  const buttio = !noButton && <Buttiona {...buttonConfig} />;

  function TitMaino() {
    const ijweaeokwq = {
      obj: headerObj,
      ...headerConfig,
    };

    const ijsdwwe = <Divo {...ijweaeokwq} />;

    return ijsdwwe;
  }

  function vijsd9(as) {
    const iase = listvar[as];

    // logs.logga("___ as ___", as);
    // logs.logga("___ iase ___", iase);

    setcurrForm(iase);
    setcurrTab(as);
  }

  function iasjdwe(obj) {
    // const chsersd = checkTick()

    const kadwesd = (
      <>
        {/*  */}
        {/* spwleqweqw */}
        {obj["tabTitle"]}
      </>
    );

    return kadwesd;
  }

  function Tabios() {
    const aisjdwe = {};

    const asodwe = {
      onChange: vijsd9,
      chosenItem: currTab,
      // titleAttr: "tabTitle",
      titleFunc: iasjdwe,
      ...ijawesdafr,
      ...tabConfig,
    };

    logs.logga("___ asodwe ___", asodwe);

    const okfdsd = (
      <>
        {/*  */}
        {/* {lissaoc} */}
        {/* adfplkerwer */}
        {/* <TabMain / */}
        <TabMain {...asodwe} />
      </>
    );

    return okfdsd;
  }

  const sudhawe = topButtonTrue && <Tabios />;

  const aidjwe = (
    <>
      {topObj}
      <TitMaino />
      {sudhawe}

      {/* <PushForm {...asidjwe} /> */}
      {/* <gens.StInput {...objbase} /> */}
      {/* <input {...objbase} /> */}
      {/* <textarea {...objbase}></textarea> */}
      {mappit}
      {buttio}
    </>
  );

  logs.logga("___ args BBBBB ___", args);

  const aweuw = noForm ? aidjwe : <form {...args}>{aidjwe}</form>;
  const isjdwesdfoek =
    //
    aweuw;
  // <div {...genConfig}>{aweuw}</div>;

  return isjdwesdfoek;
}
