import React, {
  //
  useState,
  useContext,
} from "react";
// import s from "react-aws-s3";
import EmailConst from "../consts/emailConst";
import {
  getFirstArr,
  tranSet,
  getMediaSeries,
  GraphPage,
  joinString,
  mapSpace,
} from "../functions/GlobalFunctions";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

export default function EmailContainer(
  {
    //
    // match: {
    //   params: { id, orderType },
    // },
  }
) {
  // prettier-ignore
  // const { aaaaaa } = useContext(ScriptContext);

  // const sdofkwe =
  //   //
  //   // "";
  //   "2864b06420";
  // // "3c14cba6d2";

  const ORDERMAINO = 
  // 
  "d4684b09d2"

  const id =
    //
    "";

  const { dataImport: requestMed } = getFirstArr(GraphPage("media", id));
  const { dataImport: orderOss } = GraphPage("order", ORDERMAINO);

  const ijewe = {
    requestMed: requestMed,
    orderOss: orderOss,
  };

  logs.logga("___ EMAIL ARGS ___", ijewe);

  // 1series 1medai
  const serioObj = getMediaSeries(requestMed);

  logs.logga("___ id ___", id);
  logs.logga("___ requestMed ___", requestMed);

  // const lbdsf = requestMed.emailRequestArgs;
  const ijaew =
    //
    // requestMed;
    requestMed && tranSet(requestMed.emailRequestArgs);

  // 1const
  const [type, setType] = useState("");

  // 1console
  logs.logga("___ requestMed ___", requestMed);
  logs.logga("___ ijaew ___", ijaew);

  // hiIntro =  "Hi " + serObj.name +  ", thanks for reaching out. Just replying to your questions about Bedia earlier:",
  // reqqeo, video = orderSerLink(serObj, getVid="y")

  const linkReqqo =
    requestMed && "https://bedia.tv/request?" + "rq=" + requestMed.slugURL;

  const newAcorrs = (
    <>
      <br />
      <br />
    </>
  );

  const kasjae =
    //
    "you have different guests each episode";

  function scribeFunc({ ...asdfew }) {
    //
    const getTouch = mapSpace([
      <>Because</>,
      kasjae,
      <> there's a discount on my work as well;</>,
      <>
        If you're interested we can keep chatting on Bedia about a test job and
        go from there?
      </>,
      <>I've linked my request below.</>,
    ]);

    const embedWeb = (
      <>
        Bedia makes it really easy to embed a transcript link onto
        {serioObj.name}
      </>
    );

    const skdfeqw = <></>;

    const aijsdwe = mapSpace([
      //
      <>
        What's your goals for growing your channel through SEO?
        {/*  */}
      </>,
      <>Subtitles are great for boosting your presence on Youtube too:</>,
      <>and you get them both on Bedia for $1.50 a minute.</>,
    ]);

    const aidwe = <></>;

    const basehI = mapSpace([
      <>Hi {serioObj.shortNameAttr}!</>,
      <>
        I'm a podcast transcriber who also makes subtitles/Youtube chapters for
        channels like {serioObj.name} and
      </>,
      <>thought you might be interested.</>,
    ]);

    const sadjawe = {
      introInterest: basehI,
      goalGrow: aijsdwe,
      getTouch: getTouch,
      anyQuestion: "Any questions, just ask!",
    };

    const sofksdd = [
      //
      basehI,
      // "introInterest",
      // {/* //  featureConst, */}
      getTouch,
      <a href={linkReqqo}>{linkReqqo}</a>,
      "Any questions, just ask!",
    ];

    const scrbieeMEss =
      //
      "asodsadwe";
    // mapSpace(sofksdd, newAcorrs);

    const xockvsd = (
      <>{serioObj.shortNameAttr}, Andrew replied to your message on Bedia.</>
    );

    const adsfwewer = {
      scribeMessage: scrbieeMEss,
      obj: xockvsd,
    };

    logs.logga("___ adsfwewer ___", adsfwewer);

    return adsfwewer;
  }

  const scribeConst = requestMed ? scribeFunc() : {};

  function ordFin() {
    const ijasw = orderOss.orderSeries;
    const xockvsd = (
      <>{ijasw.shortNameAttr}, Andrew replied to your message on Bedia.</>
    );

    const ijsade = (
      <>
        {/*  */}
        {/* Hi Brian, thanks for your interest! */}
        Hi {ijasw.shortNameAttr}! Have you had the chance to look at my
        messages? I'd be happy to transcribe and subtitle your videos for $0.5 a
        minute.
        {/* Are you interested in having older episodes transcribed as well?  */}
        {newAcorrs}
        {/*  */}
        {/* if you're interested in having older episodes done as well.  */}
        {/* If you're interested,  */}
        When would you like to start from? I've prepared a potential list of
        your interviews from the past week; you can add/remove more videos
        directly from your channel and add details about each of them.
        {/*  */}
        {/* This doesn't include short highlight videos so you don't pay twice for */}
        {/* the same content. */}
        {/* the I can't wait to If you follow the link below I've prepared an order of your latest video; */}
        {/*  */}
        {newAcorrs}
        I'll send you the text and subtitle files as soon as I'm finished. If
        there are any unclear references, I can double-check them with you
        beforehand so the files are 100% accurate.
        {/* and don't have to do any work. */}
        {newAcorrs}
        Let me know if you have any other questions!
        {/* get start */}
      </>
    );

    const linkeos = "https://bedia.tv/order?" + "id=" + orderOss.slugURL;
    const sidje = "Andrew's Order";

    const adsfwewer = {
      scribeMessage: ijsade,
      obj: xockvsd,
      butText: sidje,
      butLink: linkeos,
    };

    return adsfwewer;
  }

  const orderFigs = orderOss ? ordFin() : {};

  // 1MESSAGE

  const accuros = mapSpace([
    <>Our service is based upon 100% transcription accuracy, every time. </>,
    <>
      That's why we use human transcribers who correct an autogenerated text, to
      focus on proofreading rather than speed.{" "}
    </>,
    <>
      We also send you a list of any sections in the episode they may be unsure
      of to be checked before completing the job.
      {/*  */}
    </>,
  ]);

  const neioaw = (
    <>
      Bedia turns that work into all the features listed on our website;
      subtitles, chapters, video highlights for social media etc.
      {/* , small video highlights for
      social media if you've specified any,, etc.,  */}{" "}
      Andrew reached out because they saw you were trying out some of these
      features already, and might want an assistant to do the full package.
      {newAcorrs}
      You can look at their suggested fee on the request page and take things
      from there. Let us know if you have any other questions!
    </>
  );

  const obnkdgf = (
    <>
      Hi James, thanks for reaching out! Just answering your questions about
      Bedia:
      {newAcorrs}
      {accuros}
      {newAcorrs}
      {neioaw}
    </>
  );

  function butoes() {
    const buteos = (
      //
      <>
        {/*  */}
        Chat with Andrew
      </>
    );

    const lneos =
      //
      linkReqqo;

    const ijasdwe = {
      butText: buteos,
      butLink: lneos,
    };

    return ijasdwe;
  }

  const asdew = requestMed ? butoes() : {};

  const zxcivjsd = {
    // / 1html
    // htmlTrue: true,
    ...asdew,
    // ...scribeConst,
    ...orderFigs,
  };

  logs.logga("___ zxcivjsd ___", zxcivjsd);

  const okfdsd = (
    <>
      {/*  */}
      <EmailConst {...zxcivjsd} />
    </>
  );

  const args = {
    obj: okfdsd,
    // ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
