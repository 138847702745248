import React, {
  //
  useState,
  useContext,
} from "react";
import { SiTwitter } from "react-icons/si";

import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
// import ListSocial from "./ListSocial";

import { ScriptContext } from "../contexts/ScriptContext";
import OrderObject from "../containers/OrderObject";
import {
  getLinkPlay,
  getLinkSocial,
  joinString,
  highEditLink,
  setGlobalSource,
  timeDecim,
  WrapQuotes,
  //
} from "../functions/GlobalFunctions";
import FloatMainAll from "../components/FloatMainAll";

export default function OrderHighlight({
  obj,
  //   noBefore
  beforeText,
  addTrue,
  numberVar,
  playFunc,
  afterText,
  addFunc,
  mediaObj,
  index,
  nameFunc,
  ...asfew
}) {
  const { focusHighlight } = useContext(ScriptContext);

  function asadsowe(ojvsf) {
    //
  }

  //   const noHigh

  logs.logga("___ OrderHighlight ___", obj);

  const combineString = joinString([
    //
    beforeText,
    obj["name"],
    afterText,
  ]);

  const descroppo = obj["description"];
  const combineLink = descroppo
    ? joinString([descroppo, " '", combineString, "'"])
    : combineString;

  const nameos = (
    <>
      {combineString}
      {/* {beforeText}
      {obj["name"]}
      {afterText} */}
    </>
  );

  // 1name
  const oasaow = {
    obj: nameFunc ? nameFunc(nameos) : nameos,
    // style: { padding: "0 10px" },
    className: "bold",
  };

  const sdfewra = {
    obj: timeDecim(obj["duration"]),
    style: { fontSize: "0.8em" },
  };

  const vbfdgf = obj["index"];
  // "2";

  const fhetw = {
    obj: vbfdgf,
    // badgeTrue: true,
    className: "italics",
    // bediaTrue: true,
    style: {
      // color: "darkblue",
      fontSize: "1.3em",
      borderRadius: "50%",
    },
  };

  const BadgeGoHere = <Divo {...fhetw} />;

  const asidje = (
    <>
      {/* <Badge colorScheme="purple">{vbfdgf}</Badge> */}
      {/* {BadgeGoHere} */}
      <Divo {...sdfewra} />
    </>
  );

  const sjewewij =
    //
    "";
  // "No description"

  const isdjf = obj.description ? obj.description : sjewewij;

  // 1descr
  const descrCon = {
    obj: isdjf,
    // className: " wrapTrue",
    style: { fontSize: "0.8em" },
  };

  const kmaons = obj.keyQuote;
  const keyoQuo = {
    obj: kmaons,
    className: "italics",
    style: { marginTop: "-10px", fontSize: "0.6em" },
  };

  const rightisa = {
    obj: asidje,
  };

  const saijwe = <Divo {...rightisa} />;

  const isjde = {
    marginBottom: "15px",
  };

  const idsfer = kmaons && <WrapQuotes {...keyoQuo} />;
  const ijdsse = {
    obj: (
      <>
        <Divo {...oasaow} />
        {idsfer}
      </>
    ),
    style: isjde,
  };

  const centros = (
    <>
      {/* <Divo {...keyoQuo} /> */}
      {/* <hr /> */}
      <Divo {...ijdsse} />
      {/* <hr /> */}
      <Divo {...descrCon} />
    </>
  );

  const rigsdo = (
    <>
      {BadgeGoHere}
      {saijwe}
    </>
  );
  const iaewsad = {};

  const gwews = {
    // leftObj: BadgeGoHere,
    centerObj: centros,
    centerConfig: { style: { padding: "5px 20px" } },
    rightObj: rigsdo,
    alignTrue: true,
    noVertAlign: true,
  };

  const fvidsf = <FloatMainAll {...gwews} />;
  const uhfdds = {
    //
    padding: "0 10px",
    mihHeight: "100px",
  };

  const aisjdew = {
    obj: fvidsf,
    style: uhfdds,
  };

  const asudwe = <Divo {...aisjdew} />;

  // 1playO
  const playCOd = {};

  const ijasew = {
    // onClick: ewqe
  };

  const saidje = (
    <>
      {/*  */}
      <SiTwitter />
      {/* Tweet */}
      {/* <FloatMainAll {...fdjgir} /> */}
    </>
  );

  const [shareo, setshareo] = useState();

  const sajdae =
    //
    combineLink;
  // combineString;

  // obj.name;
  // ("aoskdwew");
  // "Just joined Bedia to make our content accessible! Which episodes should we do first? " +
  // gens.linkModel("series", seriesObj),

  const mediso = obj["media"]?.mediaFileLink;

  const timeViddod =
    //
    getLinkPlay(mediso, obj["startTime"], obj["endTime"]);
  // ("http://localhost:3000/m/4fffa0b194/highlights");

  const gfkhor = getLinkSocial("twitter", timeViddod, sajdae);
  const ijweqw = {
    play: playCOd,
    // edit: {}
    // remove:
    tweet: {
      genConfig: gfkhor,
      obj: saidje,
      style: { background: "#1da1f2" },
    },
  };

  function Listosa() {
    //

    const asdijwe = {
      linkvar: timeViddod,
      textvar: sajdae,
    };

    const sdowe =
      //
      "";
    // <ListSocial {...asdijwe} />;

    return sdowe;
  }

  // <Listosa />

  const awsaew = [
    //
    "edit",
    "tweet",
    // "play",
    "remove",
  ];

  const asidjwe = [
    //
    "twitter",
    "linkedin",
  ];

  const adweew = {
    listvar: awsaew,
    dictvar: ijweqw,
  };

  const nsijasd = highEditLink(obj);

  logs.logga("___ nsijasd ___", nsijasd);

  function sdijsde() {
    focusHighlight(obj);
  }

  const saawe = {
    topObj: asudwe,
    obj: obj,
    ...asfew,
    // noButton: true,
    // editConfig:
    editFunc: sdijsde,
    editLink: nsijasd,
    // editBefore: "/edit/" + mediaObj.slugURL + "/highlights",
    // editAfter: obj.id,
    buttonConfig: adweew,
    onClick: asadsowe,
  };

  logs.logga("___ Highlight - OrrObject ___", saawe);

  const ijwaewe = (
    <>
      {/* asdkweq */}
      {/* {asudwe} */}

      <OrderObject {...saawe} />
    </>
  );

  return ijwaewe;
}
