import React, {
  //
  useState,
  useContext,
} from "react";
import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";

import { ScriptContext } from "../contexts/ScriptContext";
import { useMediaQuery } from "react-responsive";
import {
  ListMain,
  selectOn,
  //   setGlobalSource,
  //
} from "../functions/GlobalFunctions";

const okawea = !gens.mobileView;

const isjdae =
  //
  // 300
  "48vw";

export default function SideFixObj({
  widthvar = isjdae,
  woidthOas = 0,
  padSide = 40,
  //   sideTrue = okawea,
  hideSide,
  alignType="right",
  fixConfig,
  topvar = 100,
  fixBottom,
  mainObj,
  sideObj,
  disTrue,
  noColorSide,
  sideConfig,
  mainConfig,
  ...asdo
}) {
  //

  const ofasxcv =
    //
    // 200;
    // 767;
    1800;

  const lcvzsads =
    //
    !gens.mobileView;
  // useMediaQuery({ maxWidth: ofasxcv });

  // const Desktop = ({ children }) => {
  //   const isDesktop = useMediaQuery({ minWidth: 992 });
  //   return isDesktop ? children : null;
  // };
  // const Tablet = ({ children }) => {
  //   const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  //   return isTablet ? children : null;
  // };
  // const Mobile = ({ children }) => {
  //
  //   const mobTrue =
  //     //
  //     // okawea;
  //     useMediaQuery({ maxWidth: ofasxcv });

  //   return mobTrue ? children : null;
  // };
  // const Default = ({ children }) => {
  //   const isNotMobile = useMediaQuery({ minWidth: 768 });
  //   return isNotMobile ? children : null;
  // };

  const sideTrue =
    //
    // true;
    // "";
    okawea ? lcvzsads : "";

  const fixTop =
    //
    // 100
    topvar;

  const padRight =
    //
    // 80;
    padSide;
  // "3vw";

  const posito =
    //
    // "absolute";
    disTrue ? "absolute" : "fixed";

  // alignType= "left"
  const asdijwe = {
    [alignType]: padRight,
  }

  const sideRightSty = {
    // ...selectOn(),
    style: {
      position: posito,
      top: fixTop,
      ...asdijwe,
      ...fixConfig,
    },
  };

  //   fixBottom = true;
  const fixBtto = fixBottom && {
    position: "fixed",
    bottom: 0,
  };

  const sideVertSty = {
    style: {
      margin: "20px 10px",
      ...fixBtto,
      ...fixConfig,
    },
  };

  const sticko = sideTrue ? sideRightSty : sideVertSty;

  const pdWdi =
    //
    // "48vw";
    widthvar;
  // widthvar + woidthOas + 15;

  const aisjwe = sideTrue && {
    width: pdWdi,
    // maxWidth: pdWdi,
    // background: "blue",
    // textAlign: "center",
    // paddingRight: "5vw",
  };

  const wqewqye = {
    style: aisjwe,
    obj: sideObj,
    ...sideConfig,
  };

  const seweds =
    //
    // "";
    "white";
  // useColorModeValue("white", "gray.900")

  const ijdawe = !noColorSide && {
    bg: seweds,
    boxShadow: "2xl",
    rounded: "lg",
  };

  const chkraB = {
    boxTrue: true,
    ...ijdawe,
    ...wqewqye,
  };

  // 1side
  const sidecxsfd = (
    //
    <Divo
      //
      {...chkraB}
      // {...wqewqye}
    />
  );

  const osojwe = {
    obj: sidecxsfd,
    ...sticko,
  };

  const xogf = {
    wqewqye: wqewqye,
    sticko: sticko,
  };

  logs.logga("___ SIDECONFIG ___", xogf);

  const isadwe = {
    left: {paddingLeft: pdWdi},
    right: {paddingRight: pdWdi},
  }
  
  const xcdsfq = isadwe[alignType]

  const mainsaS = sideTrue && {
    textAlign: "left",
    ...xcdsfq
  };

  const mainConsd = {
    ...mainConfig,
    obj: mainObj,
    style: mainsaS,
    // ...mainsaS,
  };

  const rightsdfj = <Divo {...osojwe} />;
  const mainaiew = <Divo {...mainConsd} />;

  const firstObjs = sideTrue ? mainaiew : rightsdfj;
  const sceoOjb = sideTrue ? rightsdfj : mainaiew;
  const oaikewqe = {
    horizTrue: sideTrue,
    listvar: [firstObjs, sceoOjb],
    // centerObj: mainaiew,
    // rightObj: rightsdfj,
  };

  const d9kh94 = sideTrue && "adsfokwrwep";
  //   hideSide = true;

  let showNot =
    //
    (sideObj || sideConfig) && !hideSide;
  // icxvjs = icxvjs && sideTrue;

  //

  //   const showit =

  const iakwe = (
    <>
      {mainaiew}
      {showNot && rightsdfj}
      {/* {d9kh94} */}
      {/* {showNot ? "" : rightsdfj} */}
      {/* <ListMain noSlider returnTrue {...oaikewqe} />; */}
    </>
  );

  const nokgfofdg = {
    obj: iakwe,
    // obj: "iakwe",
    ...asdo,
  };

  return <Divo {...nokgfofdg} />;
}
