import React, {
  //
  useState,
  useContext,
} from "react";
import {
  //
  padline,
  normalText,
  ScribeRate,
  setLocal,
  padhorz,
  changePage,
  openCrisp,
  boldTitle,
} from "../functions/GlobalFunctions";
import OrderList from "./OrderList";
import * as gens from "../consts/GenStyled";
import { ScriptContext } from "../contexts/ScriptContext";

import Divo from "../components/Divo";

import * as logs from "../consts/logFuncs";
import ConfirmButton from "../components/ConfirmButton";
import PriceMedList from "./PriceMedList";

export default function OrderConfirm({
  //
  ...args
}) {
  // prettier-ignore
  const { currentOrder, requestSeries } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  logs.logga("___ currentOrder ___", currentOrder);

  const listMsdoa = currentOrder.media;
  const baseObs = {
    currentOrder: currentOrder,
    mediaList: listMsdoa,
  };

  function ListEdit() {
    //
    const isjew0 = {
      ...baseObs,
      orderConfig: {
      noButton: true,
      },
      noAddMore: true,
    };

    const ogfhmrt = <OrderList {...isjew0} />;

    return ogfhmrt;
  }

  const scribeIt = gens.ScribeAndy;

  const cbcvbkfd = {
    //
  };

  const kmxcvse = {};

  const vcxvsdf = ["episodes", "duration", "cost"];
  const aijsdwe = {
    ...baseObs,
    // loadtrue: loadtrue,
    // perMinute={costPero}
    // style: oxvcdsf,
    // itemConfig: duhwew,
    listvar: vcxvsdf,
  };

  logs.logga("___ aijsdwe ___", aijsdwe);

  const oksdae = (
    <>
      {currentOrder.name}, we'll let you know as soon as {scribeIt.name}'s
      started working on your files.
    </>
  );

  const cbmcbf = {
    // padding:
  };

  const cvbcvb = <>{oksdae}</>;

  const lkdsd = {
    bottomObj: cvbcvb,
  };

  const jvndsf = (
    <ScribeRate
      //
      // messageTrue
      matchTrue
      bottomTrue
    />
  );

  // const q6teqw = orderName ? ", " + orderName : "";
  const okfdsd = (
    <>
      {/*  */}
      {/*  */}
      {/* <PriceMedList
        //
        horizTrue
        {...aijsdwe}
      /> */}
      {boldTitle("Thanks for your order!")}
      {normalText(jvndsf, "center")}
      {/* {padline("50px")} */}
      {normalText(oksdae)}
      {/* {padline("20px")} */}
      {/* {normalText(<MessButo />, "center")} */}
      <ListEdit />
    </>
  );

  const ijasewe = {
    fontSize: "20px",
    ...padhorz(),
  };

  args = {
    obj: okfdsd,
    style: ijasewe,
    ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
