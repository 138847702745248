import React, {
  //
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  GraphPage,
  segReport,
  setGlobalSource,
  getMediaChapter,
  //
} from "../functions/GlobalFunctions";
import PageBase from "../components/PageBase";
import MediaAlter from "../containers/MediaAlter";
import * as logs from "../consts/logFuncs";
import * as gens from "../consts/GenStyled";
import HighlightList from "../containers/HighlightList";
import CreateHighlight from "../containers/CreateHighlight";
import VideoOver from "../containers/VideoOver";

export default function HighlightPage({
  //
  match: {
    params: { slugURL, typevar },
  },
  // ...args
}) {
  const {
    //
    currHighlight,
    setcurrHighlight,
    mediaWithChapters,
  } = useContext(ScriptContext);

  const currMedia = mediaWithChapters;

  const idfjsr = {
    currHighlight: currHighlight,
    currMedia: currMedia,
    slugURL: slugURL,
  };

  logs.logga("___ idfjsr ___", idfjsr);

  function okdsar(dfgre) {
    setcurrHighlight(dfgre);
    // if (!currMedia) {
    //   setcurrMedia(dfgre, "LOADING HIGHLIGHT PAGE");
    // }
  }

  const nsifjsd = {
    fetchFunction: okdsar,
  };

  const { dataImport: ediMedia, loadTrue: losiow } =
    //
    // "";
    GraphPage("media", slugURL, nsifjsd);

  const fudsf = currHighlight
    ? currHighlight
    : currMedia && getMediaChapter(currMedia, slugURL);

  const eitherHigh = slugURL || currMedia;

  function HigOJAS() {
    const iasjdwe = {
      mediaObj: currMedia,
      highlightSelect: fudsf,
    };
    logs.logga("___ iasjdwe ___", iasjdwe);

    const uijerwe = {
      // obj: isajdwe,
      // mediaObj: mediaObj,
      // chapterList: chapterListBase,
      // highlightSelect: highlightSelect,
    };

    const okfdsd = (
      //
      <HighlightList {...iasjdwe} />
      // <VideoOver {...uijerwe} />
    );

    return okfdsd;
  }

  const hgiosa = eitherHigh ? <HigOJAS /> : <CreateHighlight />;

  const dfjgisd = {
    edit: "to make ",
  };

  const fihhtr =
    //
    "to save and share highlights.";

  const dfijsae =
    //
    fihhtr;
  // typevar ? dfjgisd[typevar] : fihhtr;

  const sisdwe = {
    obj: hgiosa,
    helmetConfig: "Edit",
    fastestvar: dfijsae,
  };

  return <PageBase {...sisdwe} />;
}
