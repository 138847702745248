import React, {
  //
  useState,
  useContext,
} from "react";

import * as logs from "../../consts/logFuncs";
import Divo from "../../components/Divo";

import { ScriptContext } from "../../contexts/ScriptContext";
import TabMain from "../../components/TabMain";

export default function HighlightMain({
  //
  obj,
  typevar = "recommend",
  ...args
}) {
  // 1scriptcontext
  const { highView, sethighView } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  function Tabiosas() {
    const asiew = [
      //
      "add",
      //   "info",
      //   "comments",
      "recommend",
      "playlist",
    ];

    const endPliayios = "";

    const asidjew = {
      comments: {
        typeMain: "comments",
        textvar: "Comments",
      },
      info: {
        typeMain: "info",
        textvar: "Info",
      },
      playlist: {
        typeMain: "comments",
        textvar: "Playlists",
      },
      add: {
        typeMain: "add",
        textvar: "Add",
      },
      recommend: {
        typeMain: "comments",
        textvar: "Recommended",
      },
    };

    function isade(asarew) {
      logs.logga("___ asarew ___", asarew);

      // asodkwe
    }

    const sadersd = {
      onClick: isade,
    };

    const nkasdae = {
      listvar: asiew,
      dictvar: asidjew,
      itemConfig: sadersd,
    };

    const isadsew = <TabMain horizTrue {...nkasdae} />;

    return isadsew;
  }

  function AddPlayeos() {
    const cvbmdfg = (
      <>
        {/*  */}
        asdeqw
      </>
    );

    return cvbmdfg;
  }

  function MainObjs() {
    let endValue = "";
    switch (typevar) {
      case "comments":
        endValue = "COMMENTS";
        break;
      case "edit":
        endValue = "edit";
        break;
      case "recommend":
        endValue = "recommend";
        break;
      case "addPlaylist":
        endValue = <AddPlayeos />;
        break;
      //   case "recommend":
      //     endValue = "recommend";
      //     break;
      default:
        endValue = "";
    }

    return endValue;
  }
  const okfdsd = (
    <>
      {/*  */}
      <Tabiosas />
      <MainObjs />
    </>
  );

  args = {
    obj: okfdsd,
    ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
