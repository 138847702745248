import React, { useState, useEffect } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { hot } from "react-hot-loader/root";
import "./App.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import ScriptContextProvider from "./contexts/ScriptContext";
import { BrowserRouter, Route, Link, Switch, Redirect } from "react-router-dom";
import Cohere from "cohere-js";

// import TestRunsy from "./containers/TestRuns";
import { ThemeProvider } from "@chakra-ui/core";
import * as logs from "./consts/logFuncs";

//
import EmailContainer from "./containers/EmailContainer";
import HomeChannel from "./containers/HomeChannel";
// import * as logs from "../consts/logFuncs";

import {
  getLocal,
  getMediaSeries,
  getParamVar,
  turnDict,
  GraphPage,
  setLocal,
} from "./functions/GlobalFunctions";

import OrderPage from "./pages/OrderPage";
import AboutPage from "./pages/AboutPage";
import PricePage from "./pages/PricePage";
import CreatePage from "./pages/CreatePage";
import EditPage from "./pages/EditPage";
import HighlightPage from "./pages/HighlightPage";
import ChapterPage from "./pages/ChapterPage";
import MediaPage from "./pages/MediaPage";
import YoutubePage from "./pages/YoutubePage";
import RegisterPage from "./pages/RegisterPage";

function App() {
  const [vidstype, setType] = useState();
  const [requestFound, setrequestFound] = useState();

  useEffect(() => {
    Cohere.init(process.env.REACT_APP_COHERE_WEBID);
  });

  // while (!requestFound) {
  //   logs.logga("___ requestFound ___", "requestFound");

  //  setTimeout(() => {
  //   if (graphRequest) {
  //     logs.logga("___ graphRequest ___", graphRequest);

  //     // medSetsoa(graphRequest);

  //     setrequestFound(true);
  //   }
  //   }, 3000);
  // }

  function aodwe(oewrw) {
    const sdfij = oewrw + "/:slugURL?/:typevar?/:typeSlug?";
    return sdfij;
  }

  const brwosao = (
    <BrowserRouter>
      <Switch>
        {/* <RouteAll /> */}
        <Route path path={aodwe("/chapters")} component={ChapterPage} />;
        <Route path path={aodwe("/m")} component={MediaPage} />;
        <Route path={aodwe("/edit")} component={EditPage} />
        <Route path path={aodwe("/h")} component={HighlightPage} />;
        <Route path="/new" component={CreatePage} />;
        <Route path="/youtube-:typePage/:youtubeid?" component={YoutubePage} />;
        <Route path={aodwe("/request")} component={OrderPage} />;
        <Route path={aodwe("/order")} component={OrderPage} />;
        <Route path="/about" component={AboutPage} />;
        <Route path="/connect" component={HomeChannel} />;
        <Route path="/pricing" component={PricePage} />;
        <Route path={aodwe("/register")} component={RegisterPage} />;
        {/* <Route path="/highlights/" component={highlights} />; */}
        <Route path="/email/" component={EmailContainer} />;
        <Route path="/" component={OrderPage} />;
        {/* <Route path="/" component={HomePage} />; */}
      </Switch>
    </BrowserRouter>
  );

  // export const appMainBody = "red";
  const appMainBody =
    //

    // "red";
    // "white";
    "#F3F3F3";

  const xcvbgdf = {
    minHeight: "100vh",
    background: appMainBody,
  };

  const awuhee =
    //
    vidstype;
  // true;

  // const zvdsfsa = awuhee && uahwe;

  const i8uweq = {
    // className: "backMain",
    style: xcvbgdf,
  };

  const okbvsdaf = (
    <>
      <ScriptContextProvider>
        <ThemeProvider>
          <div {...i8uweq}>
            {/*  */}
            {/* sqdplqweqw */}
            {/*  */}
            {/* <TestRunsy /> */}
            {brwosao}
          </div>
        </ThemeProvider>
      </ScriptContextProvider>
    </>
  );

  return okbvsdaf;
}

export default hot(App);
// export default App;
