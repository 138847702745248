import React, {
  //
  useState,
  useContext,
} from "react";

import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import TimeButtons from "../components/TimeButtons";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  Sersyt,
  pageMedia,
  filterChapter,
  joinString,
  saveModel,
  //
} from "../functions/GlobalFunctions";
import { CreateSelect, EmojiList } from "../components/GlobalComps";

import FormMain from "../components/FormMain";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import MediaList from "./MediaList";
import { SelectMain } from "../components/GlobalComps";

export default function AlterHighlight({
  //
  obj,
  mediaObj,
  seriesObj,
  submitFunc,
  submitLink,
  noSaveMedia,
  noEnd,
  typevar,
  ...args
}) {
  noEnd = true;

  // 1scriptcontext
  const {
    changePlayerTime,
    currHighlight,
    setcurrMedia,
    setcurrHighlight,
    listChapters,
    setlistChapters,
    getCurrentTime,
    setHistory,
  } = useContext(ScriptContext);

  // 1const
  const [saveHgsh, setsaveHgsh] = useState("");
  const [loadSave, setloadSave] = useState();

  const vbnd = obj["startTime"];
  const asidjw = obj["endTime"];
  const dfoke = vbnd ? vbnd : 0;

  const [staro, setstaro] = useState(dfoke);

  const okfgrer = noEnd ? "" : asidjw ? asidjw : staro + 30;
  const [endklp, setendklp] = useState(okfgrer);

  let history = useHistory();
  const chapList =
    //
    listChapters;
  // mediaObj.chapterList;

  const newHgho = {
    ...obj,
    startTime: staro,
    endTime: endklp,
  };

  function joinwithmedia(dignos) {
    const asidjaew = {
      ...obj,
      ...dignos,
    };

    const newChap = filterChapter(chapList, asidjaew);

    const ijyfgt = {
      obj: obj,
      dignos: dignos,
      chapList: chapList,
      newChap: newChap,
      asidjaew: asidjaew,
    };

    logs.logga("___ ijyfgt ___", ijyfgt);
    logs.logga("___ newChap ___", newChap);

    setlistChapters(newChap, mediaObj);
  }

  function pushDownlad(zxcvdfd) {
    return saveModel("highlight", zxcvdfd);
  }

  function Vcbod0e(dsofkwr) {
    setloadSave(true);
    typevar = "chapters";
    let linkGo = "";

    linkGo = "/m/" + mediaObj.slugURL + "/highlights";
    submitLink = submitLink ? submitLink : linkGo;

    const asidjwe = {
      dsofkwr: dsofkwr,
      linkGo: linkGo,
    };

    logs.logga("___ asidjwe ___", asidjwe);

    if (!noSaveMedia) {
      joinwithmedia(dsofkwr);
    }

    if (submitFunc) {
      submitFunc(dsofkwr);
    }

    history.push(submitLink);
  }

  // // 1console
  logs.logga("___ newgho ___", newHgho);

  const baseName =
    //
    joinString(
      //
      [
        mediaObj.name,
        //
        // "(" + ijsewe + ")",
      ],
      " "
    );

  const ijofds = {
    typevar: "textarea",
    rows: 1,
    // minRows: 1,
    // maxRows: 2,
  };

  const okfty = {
    style: {
      background: "red",
      color: "white",
      fontSize: "40px",
    },
  };

  const inputMinni = {
    typevar: "input",
  };

  const jgjgfdg =
    //
    "none";
  // false;

  const ijawewaw = {
    //
    required: true,
    mustHave: true,
    name: "name",
    noExpand: true,
    autocomplete: jgjgfdg,
    noTitle: true,
    placeholder: baseName,
    ...ijofds,
    // ...okfty,
    // ...inputMinni,
  };

  const sadifj = Sersyt(seriesObj, "?sub_confirmation=1");

  logs.logga("___ sadifj ___", sadifj);

  const desConfo = {
    // tabTitle: "🛈 General",
    titlevar: "Description",
    // subtitlevar: descSubbo,
    typevar: "textarea",
    name: "description",
    minRows: 3,
  };

  const baseisdo = {
    titlevar: "Tags",
    // typevar: "textarea",
    name: "tags",
  };

  const selcTIas = {
    titlevar: "Other Titles",
    typevar: "selectable",
    // options: "",
    name: "tags",
  };

  const keyjsaew = {
    titlevar: "Key Quote",
    typevar: "textarea",
    minRows: 2,
    maxRows: 2,
    name: "keyQuote",
    // placeHolder: "The most shareable quote in the chapter.",
  };

  const ijasdwe = {
    name: ijawewaw,
    otherTitles: selcTIas,
    keyQuote: keyjsaew,
    description: desConfo,
    tags: baseisdo,
  };

  const [showRest, setshowRest] = useState("y");
  const isjdwe = showRest
    ? [
        //
        "keyQuote",
        "description",
        "tags",
      ]
    : [];

  const ksdwe = [
    //
    "name",
    // { mainObj: <ButtoTop /> },
    // "otherTitles",
    ...isjdwe,
  ];

  const sdijre = {
    marginBottom: "20px",
    fontSize: "20px",
  };

  const ijdaswqe = {
    style: sdijre,
  };

  // 1speakers
  function SpekaFind() {
    const ijsae = {
      //
    };

    return <SelectMain {...ijsae} />;
  }

  // 1playlist
  function PlayaFinso() {
    const ijsae = {
      //
    };

    return <SelectMain {...ijsae} />;
  }

  // noEnd = "";
  //
  // 1TimeButtons
  function ButtoTop() {
    //
    const bknmvkf = [
      //
      "start",
      "play",
      // "duration",
      !noEnd && "end",
    ];

    function ifdjger(aspecto, timeea) {
      newHgho[aspecto] = timeea;
      const duraion = newHgho["endTime"] - newHgho["startTime"];
      newHgho["duration"] = duraion;

      // setcurrHighlight(newHgho);
    }

    function enStarto() {
      // logs.logga("___ enStarto ___");
      // setstaro(getCurrentTime());
      // getTimeo("startTime");
      // //
    }

    function enEndo(timeea) {
      const endBefore = timeea < newHgho["startTime"];
      logs.logga("___ endBefore ___", endBefore);

      if (endBefore) {
        // const sdifjer = neHgho["startTime"] + 3;
        // setendklp(sdifjer);
      } else {
        // getTimeo("endTime");
      }
    }

    const dfkgret = {
      listvar: bknmvkf,
      showTime: noEnd,
      startFunc: setstaro,
      endFunc: setendklp,
      // bothFunc: ifdjger,
      ...newHgho,
    };

    logs.logga("___ TieButtons ___", dfkgret);

    const ijsadwqe = <TimeButtons {...dfkgret} />;

    const hutry = {
      obj: ijsadwqe,
      style: {
        marginBottom: "20px",
      },
    };

    return <Divo {...hutry} />;
  }

  function AddLists() {
    const ppolDico = {
      //
    };

    const plyDica = {
      //
    };

    const difjsd = {
      people: ppolDico,
      playlists: plyDica,
    };

    const jsae = [
      //
      "people",
      "playlists",
    ];

    const ijdswe = {
      listvar: jsae,
      dict: difjsd,
    };

    const asud8we = <FormMain {...ijdswe} />;

    return asud8we;
  }

  const kaedfgokew = {
    id: "highaltID",
    dictvar: ijasdwe,
    listvar: ksdwe,
    overObj: newHgho,
    onSubmit: Vcbod0e,
    inputConfig: ijdaswqe,
    noButton: true,
  };

  const isjde = <FormMain {...kaedfgokew} />;

  const nisfe = {
    obj: isjde,
    buttonConfig: {
      obj: "More Options",
      onClick: () => setshowRest(!showRest),
    },
  };

  const finObj =
    //
    isjde;
  // <CollapseMain {...nisfe} />

  // 1name
  function NameBaseo() {
    //
    // value

    const aisjdew = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ];
    const fhdgf =
      //
      // [];
      aisjdew;

    const [sdofker, setsdofker] = useState([]);
    // const [allVals, setallVals] = useState();

    const jfdgtr = {
      //
      // value: selVallo,
      value: sdofker,
      options: sdofker,
      onChange: setsdofker,
      // onInputChange: inptoe,
    };

    const bvnkmfg = <CreateSelect {...jfdgtr} />;

    return bvnkmfg;
  }

  // 1emoji
  function EmojiBas() {
    //

    const aijde = {
      fontSize: "24px",
    };
    const sdijf = {
      style: aijde,
      className: "expandHover borderHover",
    };

    const iasjdwe = {
      itemConfig: sdijf,
    };

    const asjdwe = <EmojiList {...iasjdwe} />;
    return asjdwe;
  }

  const okfdsd = (
    <>
      {/* <NameBaseo /> */}
      <ButtoTop />
      {/* <hr /> */}
      {/* <EmojiBas /> */}
      {/* <hr /> */}
      {/* <SpekaFind /> */}
      {/* <PlayaFinso /> */}
      {finObj}
      {/* <AddLists /> */}
    </>
  );

  function ShwChabe() {
    const ijsdwe = {
      obj: "Saving...",
      style: {
        fontSize: "40px",
        textAlign: "center",
      },
    };

    return <Divo {...ijsdwe} />;
  }

  const sdifje =
    //
    // okfdsd;
    loadSave ? <ShwChabe /> : okfdsd;

  function TitleO() {
    const gfgfkho =
      //
      // mediaObj;
      "<- " + mediaObj.name;

    const vbfod = {
      fontSize: "20px",
      margin: "20px",
      padding: "5px",
      // border: "2px solid red",
    };

    const ijsds = {
      obj: gfgfkho,
      className: "pointer wrapTrue",
      style: vbfod,
      // bediaTrue: true,
      // noBackground: true,
      onClick: () => history.push(pageMedia(mediaObj)),
    };

    const ndfigjd = (
      //
      <Divo {...ijsds} />
      // <MediaList {...ijsds} />
      // <ConfirmButton {...ijsds} />
    );

    return ndfigjd;
  }

  const fdjgirt = (
    <>
      {/* <TitleO /> */}
      {sdifje}
    </>
  );

  const dsfsrw = {
    //
    marginTop: "-30px",
  };

  const njhgfd = {
    obj: fdjgirt,
    style: dsfsrw,
  };

  args = {
    obj: <Divo {...njhgfd} />,
    ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
