import React, {
  //
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router";
import Divo from "../../components/Divo";

// import * as gens from "../consts/GenStyled";
import * as logs from "../../consts/logFuncs";
// import Divo from "../components/Divo";

import { ScriptContext } from "../../contexts/ScriptContext";
// // import aaaa from "./components/aaaa";
import {
  pageMedia,
  //
} from "../../functions/GlobalFunctions";
import MediaList from "../MediaList";

export default function HighRightCreate({
  //
  typevar,
  cancelLink,
  ...args
}) {
  const {
    changeTimePlace,
    currMedia,
    editTrue,
    currHighlight,
    orderTrue,
    playCurrentHighlight,
  } = useContext(ScriptContext);

  //   1const
  let history = useHistory();

  function quitCancel() {
    // set
    // setlistChapters()
    // changeTimePlace();
    // setcurrHighlight();
    // focusHighlight()

    history.push(pageMedia(currMedia));
  }

  function getBasePo(sdofkwa) {
    let asdaew = "";
    switch (sdofkwa) {
      // 1download
      case "play":
        asdaew = {
          obj: "Play from start",
          bediaTrue: true,
          // background: "darkred",
          onClick: playCurrentHighlight,
        };
        break;

      case "save":
        asdaew = {
          obj: "Save",
          //   onClick:
          type: "submit",
          //   logTrue: true,
          form: "highaltID",
        };

        break;
      case "cancel":
        asdaew = {
          background: "darkred",
          obj: "Cancel",
          onClick: quitCancel,
        };
        break;
      // case "name":
      //     asdaew = {
      //         obj
      //     }
      //     break;
      //     case "name":
      //         break;
      //         case "name":
      //             break;
    }
    return asdaew;
  }

  let endlist = "";
  function gettoType() {
    switch (typevar) {
      case "chapters":
        endlist = ["save", "cancel"];
        break;
    }

    return endlist;
  }

  function InfoBott() {
    //
    function HighCurro() {
      const ushwqe = {
        obj: currHighlight.name,
        style: {
          textAlign: "left",
          margin: "10px 0",
        },
      };

      return <Divo {...ushwqe} />;
    }

    function FromMeddo() {
      function backMediaHigh() {
        const aisjdwe = "/m/" + currMedia.slugURL;
        logs.logga("___ asjdw ___", aisjdwe);
        history.push(aisjdwe);
      }

      const sdijfwqe = {
        obj: currMedia,
        thumbnailSize: 40,
        // className: gens.butClass + " pointer",
        nameConfig: { style: { fontSize: "20px" } },
        wrapName: true,
        onClick: backMediaHigh,
      };
      const dsfare = <MediaList {...sdijfwqe} />;

      return dsfare;
    }

    const jsaew = (
      <>
        {currHighlight && <HighCurro />}
        {currMedia && <FromMeddo />}
      </>
    );

    const ijsadw = {
      obj: jsaew,
      style: {
        fontSize: "30px",
      },
    };

    return <Divo {...ijsadw} />;
  }

  const asdew =
    //
    editTrue ? ["save", "cancel"] : [{ mainObj: <InfoBott /> }];
  //   [
  //     //
  //     // "sdpfewr.",

  //     // { mainObj: <BaseerNm /> },
  //     // { mainObj:  },
  //     ...endlist,
  //   ];

  args = {
    dictFunc: getBasePo,
    listvar: asdew,
    ...args,
  };

  // 1HTML
  return args;
}
