import React, {
  //
  useState,
  useContext,
  useEffect,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import HighlightRight from "./HighlightRight";

import { useHistory } from "react-router";
import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  DivideMain,
  getLength,
  getMediaSeries,
  joinString,
  getArrayIndex,
  setGlobalSource,
  timeDecim,
  mapChapterToText,
  trimDownload,
  segReport,
  CenterObj,
  getMedRef,
  AskObj,
  getLocal,
  ImageTextList,
  setLocal,
  checkEmptyArray,
  stringTime,
  Mappo,
  mapChapters,
  createHighlight,
  //
} from "../functions/GlobalFunctions";
import SideFixObj from "../components/SideFixObj";
import OrderObject from "../containers/OrderObject";
import ListMain from "../components/ListMain";
import FloatMainAll from "../components/FloatMainAll";
import OrderHighlight from "./OrderHighlight";
import PlayerBase from "./PlayerBase";
import FormMain from "../components/FormMain";
import ConfirmButton from "../components/ConfirmButton";
import AlterHighlight from "./AlterHighlight";
import HighListMain from "./HighListMain";
import TabMain from "../components/TabMain";
import { isEmpty } from "lodash";
import VideoOver from "./VideoOver";
import HighlightMain from "./highlights/HighlightMain";

export default function HighlightList({
  //
  mediaObj,
  noNameAdd,
  highlightSelect,
  ...args
}) {
  // 1scriptcontext
  const {
    getCurrentTime,
    changePlayerTime,
    currHighlight,
    setcurrMedia,
    editTrue,
    seriesOver,
    listChapters,
    setlistChapters,
    currMedia,
    createcurrHighlight,
  } = useContext(ScriptContext);

  highlightSelect = highlightSelect ? highlightSelect : currHighlight;
  mediaObj = mediaObj ? mediaObj : highlightSelect?.media;
  const [dsmfeor, setdsmfeor] = useState(mediaObj);

  const nameoSer = getMediaSeries(mediaObj)?.name;
  const namerBase = nameoSer && nameoSer;
  const ijsadwe =
    //
    // namerBase + ": '";
    namerBase + " on ";

  const dyaew = [
    //
    "(",
    mediaObj.name,
    ")",
  ];

  const afterini =
    //
    // " after the end";
    joinString(dyaew);

  const befBase =
    //
    "";
  // !noNameAdd && ijsadwe + " ";

  const aftBase =
    //
    "";
  // !noNameAdd && " " + afterini;

  const listBase = listChapters;

  const showExamples = getLocal(gens.exampShown);
  const initalEmpty = isEmpty(listBase);
  const fdkgjire = initalEmpty && seriesOver && !showExamples;
  const [exampChaps, setexampChaps] = useState();

  useEffect(() => {
    setLocal(gens.exampShown, "y");
  }, []);

  const saudwesae = !fdkgjire
    ? listBase
    : [
        createHighlight({
          index: 1,
          name: "Intro",
          keyQuote: "Testing, testing...",
          description: "The good stuff starts soon...",
          startTime: 0,
          // endTime: ,
        }),
        createHighlight({
          index: 2,
          name: "Show begins",
          keyQuote: "Let's get this show on the road!",
          description: "The first topic/guest is introduced.",
          startTime: 30,
          // endTime: ,
        }),
      ];

  const [listFig, setltchapterListBase] = useState(saudwesae);

  // 1const
  const [starPlayo, setStarPlayo] = useState("");
  const [beforeEO, setbeforeEO] = useState(befBase);
  const [afterEo, setafterEo] = useState(aftBase);
  const downit =
    //
    // listChapters;
    // saudwesae;
    listFig;

  const chapterListBase = mapChapters(mediaObj, downit);

  //
  // 1console
  const jfireew = {
    chapterListBase: chapterListBase,
  };
  logs.logga("___ jfireew ___", jfireew);

  const [selListo, setselListo] = useState();

  const noHighsYet = checkEmptyArray(chapterListBase);

  const baseArgs = {
    //
    obj: highlightSelect,
    mediaObj: mediaObj,
    seriesObj: getMediaSeries(mediaObj),
  };

  let history = useHistory();

  function addNewHighs(siqwe) {
    //

    const asdoewe = {
      startTime: siqwe["endTime"],
    };
    const linkEndo = createcurrHighlight(asdoewe);

    logs.logga("___ siqwe ___", siqwe);

    // const figdao =
    //   //
    //   // "";
    //   getArrayIndex(chapterListBase, siqwe["id"], "id");
    // //   chapterListBase.index(siqwe);

    // const sdfije =
    //   //
    //   // [];
    //   chapterListBase.splice(figdao, 0, asdoewe);

    // const sdfdwe = {
    //   oldList: chapterListBase,
    //   INDEX: figdao,
    //   newHigh: asdoewe,
    //   oldLst: getLength(figdao),
    //   nwLst: getLength(sdfije),
    // };

    // logs.logga("___ sdfdwe ___", sdfdwe);

    // //   setNewHighs(sdfije);
    // setltchapterListBase(sdfije);
    // currHighlight(siqwe);

    //
    history.push(linkEndo);
  }

  function removeOrd(orea) {
    // const chapterListBase
    // setltchapterListBase( )
  }

  function chooseOHi(higso) {
    logs.logga("___ higso ___", higso);
    logs.logga("___ mediaObj ___", mediaObj);

    // focusHighlight(mediaObj);
  }

  function Askios({ ...dawe }) {
    const dsfwe = [
      "Subtitles",
      "Headings",
      "Social links",
      // to share on social media.
    ];

    const ifjvsw = {
      //
    };

    const oasjdwe = {
      obj: dsfwe,
      beforeObj: "- ",
      style: ifjvsw,
    };

    const elMaps = <Mappo {...oasjdwe} />;

    const sajew = (
      <>
        🙌 Highlights will let you download each episode chapter as an
        individual short video, including:
        {elMaps}
      </>
    );

    const isawe = {
      textAlign: "center",
      // color: "red",
      fontSize: "34px",
    };

    const uaswe = {
      obj: sajew,
      checkLocal: "highListFeat",
      typevar: "highlights",
      clickMessage: "WANTS FEATURE - HIGHLIGHTS",
      submitItems: { sdqwe: "aodkwe" },
      style: isawe,
    };

    const fdsfds =
      //
      "";
    // <AskObj {...uaswe} />;

    return fdsfds;
  }

  function ChapoList() {
    const awygewe = {
      //   height: "100px",
      fontSize: "26px",
      marginBottom: "20px",
    };

    function asdwe(bvosdf) {
      //
      logs.logga("___ EDIT-HIGHLIGHT ___", bvosdf);
      // setltchapterListBase(listnew)
    }

    function sadowe(objo, listnew) {
      //

      logs.logga("___ REMOVE-HIGHLIGHT ___", listnew);

      const ijfds = {
        ...currMedia,
        chaperList: listnew,
      };
      setlistChapters(listnew, currMedia);
    }

    const ijasew = {
      clickFunc: (higso) => changePlayerTime(higso.startTime),
    };

    const aisjwe = {
      style: awygewe,
      //   ...siqwe,
      mediaObj: mediaObj,
      removeFunc: sadowe,
      clickTop: (higso) => changePlayerTime(higso.startTime),
      // topConfig: ijasew,
      // playFunc: ,
      listOver: chapterListBase,
      beforeText: beforeEO,
      afterText: afterEo,
    };

    const asidjwe =
      //
      "";

    const [khos, setkhos] = useState();
    function saidjwe(sjew) {
      logs.logga("___ sjew ___", sjew);
      setkhos(sjew);
      // setselectHighs(sjew);
    }

    const osrewfjr = {
      selectFunc: saidjwe,
    };

    //
    // 1listvar
    const lstCOnsias = {
      ...baseArgs,
      suggestTrue: fdkgjire,
      listvar: chapterListBase,
      highlightConfig: aisjwe,
      listSelect: selListo,
      // itemConfig:
      // selectableTrue: true,
      // selectableConfig: osrewfjr,
      addFunc: (siqwe) => addNewHighs(siqwe),
    };

    logs.logga("___ lstCOnsias ___", lstCOnsias);

    const hfuee =
      //
      "e";
    // noHighsYet

    const sdjewq = hfuee && (
      <>
        <Askios />
      </>
    );

    // function

    const sidjaewq = (
      <>
        {/*  */}
        {sdjewq}
        <HighListMain {...lstCOnsias} />
      </>
    );

    return sidjaewq;
  }

  function EdMina() {
    const xvdsfw = {
      ...baseArgs,
      // linkReturn:
      // submitFunc:
      // submitLink:
    };

    logs.logga("___ xvdsfw ___", xvdsfw);

    const ijadae = editTrue ? (
      <AlterHighlight {...xvdsfw} />
    ) : (
      <HighlightMain {...xvdsfw} />
    );

    return ijadae;
  }

  function LeftConfo() {
    const ijsaew = highlightSelect ? <EdMina /> : <ChapoList />;

    const aidjwe = (
      <>
        {/*  */}
        {ijsaew}
        {/*  */}
      </>
    );

    return aidjwe;
  }

  function AddTestos() {
    const isajdw = [
      //
      "firstText",
      "afterText",
    ];

    function sauhew(values) {
      logs.logga("___ Text Before ___", values);

      setbeforeEO(values);
    }

    function vcxbfd(values) {
      logs.logga("___ Text AFTER ___", values);

      //   setafterEo(values);
    }

    function adokwe(sdoew) {
      logs.logga("___ SAVEFORM ___", sdoew);

      //
    }

    const sdfuer = {
      firstText: {
        // onChange: sauhew,
        titlevar: "Text Before",
        initialValue: beforeEO,
      },
      afterText: {
        // onChange: vcxbfd,
        titlevar: "Text After",
        initialValue: afterEo,
      },
    };

    const ijasdwe = {
      padding: "2% 10%",
      fontSize: "30px",
    };

    const aasew = {
      listvar: isajdw,
      dictvar: sdfuer,
      onSubmit: adokwe,
      style: ijasdwe,
      inputConfig: {
        style: {
          fontSize: "24px",
        },
      },
      noButton: true,
      buttonConfig: {
        obj: "Save",
      },
    };

    const zcxvds = <FormMain {...aasew} />;

    return zcxvds;
  }

  const isajdwe = highlightSelect ? <EdMina /> : <ChapoList />;

  const uijerwe = {
    obj: isajdwe,
    mediaObj: mediaObj,
    chapterList: chapterListBase,
    highlightSelect: highlightSelect,
  };

  const okfdsd = <VideoOver {...uijerwe} />;

  function poajewe() {
    // trimDownload(mediaObj);
  }

  args = {
    obj: okfdsd,
    onClick: poajewe,
    ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
