import React, {
  //
  useState,
  useContext,
} from "react";
import ConfirmButton from "../components/ConfirmButton";
import MediaList from "./MediaList";
import ModalMain from "../components/ListMain";
import * as gens from "../consts/GenStyled";

import Divo from "../components/Divo";

import * as logs from "../consts/logFuncs";

import { ScriptContext } from "../contexts/ScriptContext";
import { WhatOfferList } from "../functions/GlobalFunctions";
import whatyouget from "../consts/FeatureConst";
import FloatMainAll from "../components/FloatMainAll";
// import aaaa from "./components/aaaa";

export default function AboutTypes({ typevar = "card", ...asdfokwe }) {
  // prettier-ignore
  // const { aaaaaa } = useContext(ScriptContext);

  logs.logga("___ asdfokwe ___", asdfokwe)

  // 1const
  const [type, setType] = useState("");
  const [bsfsdgd, setmodalTyp] = useState();

const nbfgihjf = 
// 
"transcript"
// bsfsdgd

const modalTyp = whatyouget(nbfgihjf)

  function Modlaos() {
    logs.logga("___ malTyp ___", modalTyp);

    // const sakwedsok =

    const oawe = {
      // style: { textAlign: "center" },
    };
    const ijaew = (
      <>
        {/*  */}
        Examples - {modalTyp["title"]}
      </>
    );

    const phy2go = {
      mainSeries: {
        name: "Psych2Go",
      },
      name: "10 Behaviors that Destroy Relationships",
      mediaFileLink: "https://www.youtube.com/watch?v=zWb6sO2vodY",
      mediaDuration: 355.0,
      // imageAttr
      videoThumbnail: "https://i.ytimg.com/vi/zWb6sO2vodY/maxresdefault.jpg",
    };

    const vsdre = {
      obj: phy2go,
      linkBase: true,
      linkTrue: true,
    };

    const fldkgs =
      //
      // "40vh";
      "45vh";

    const rojhore = {
      // border: "2px solid red",
      // margin: "20px 0",
      padding: "20px",
      height: fldkgs,
      overflow: "scroll",
      fontSize: "16px",
      // ...,
    };

    const sdkmds = {
      obj: modalTyp["obj"],
      style: rojhore,
      className: gens.butClass,
    };

    const xvcj = {
      padding: "15px 10px",
      fontSize: "18px",
    };

    const ofkczx = {
      obj: modalTyp["subtitle"],
      style: xvcj,
      // className: "bold",
    };

    const okaswe = (
      <>
        <MediaList {...vsdre} />
        <Divo {...ofkczx} />
        <Divo {...sdkmds} />
      </>
    );

    const iakwew = {
      obj: okaswe,
    };

    const uahsewe = <Divo {...iakwew} />;

    const shargs = {
      //
      titleVar: ijaew,
      titleConfig: oawe,
      // footer: null,
      // fullTrue:
      cancelFunc: () => setmodalTyp(),
      // visible: shareSelect,
      // cancelFunc: closeModRequest,
      obj: uahsewe,
      style: {
        padding: "0 20%",
      },
    };

    const vlsdcs = (<>
      <ModalMain
        {...shargs}
      />
{/* asodkwqeq */}
      </>
    );

    //
    return vlsdcs;
  }

  function ButtonMod({ type, ...adwe }) {
    const okasdew = {
      // className: gens.buttonMain,
      obj: "Example",
      bediaTrue: true,
      style: {
        marginTop: "60px",
        // height: "10px",
        fontSize: "30px",
      },
      // divTrue: true,
      onClick: () => setmodalTyp(type),
    };

    const xmpButto = (
      //
      // <Divo spanTrue {...okasdew} />
      // <ImageTextDiv {...okasdew} />
      <ConfirmButton {...okasdew} />
    );

    return xmpButto;
  }

  function simpleList({ emoji, title, usePlace, ...asodkwqe }) {
    const sadplwe = emoji + " " + usePlace;

    const ikjasd = {
      ...asodkwqe,
    };

    const ijasdew = {
      // leftObj: sadplwe,
      centerObj: sadplwe,
      rightObj: <ButtonMod {...ikjasd} />,
    };

    const ijasdwe =
      //
      sadplwe;
    // <FloatMainAll {...ijasdew} />;

    return ijasdwe;
  }

  function asdwe({ emoji, title, textBeside, ...sdfwer }) {
    const gfhokwer = emoji + " " + title;

    //
    function Tilos() {
      //
      const qeytqw = {
        fontWeight: "bold",
        fontSize: "24px",
      };

      const okawe = {
        obj: gfhokwer,
        style: qeytqw,
        // className:
      };

      const kasew = <Divo {...okawe} />;

      const okaleww = {
        centerConfig: { style: { width: "220px" } },
        centerObj: kasew,
        // rightObj: xmpButto,
      };

      const tileso = (
        <>
          {kasew}
          {/* <FloatMainAll {...okaleww} /> */}
          {/* <Divo spanTrue {...okawe} /> */}
        </>
      );
      return tileso;
    }

    const cvijdfg = !gens.mobileView && { height: "100px" };

    const ujawe = {
      padding: "10px 0",
      ...cvijdfg,
    };

    const fasasds = {
      obj: textBeside,
      style: ujawe,
    };

    const sdfge = {
      ...sdfwer,
    };

    const sdhwe = <ButtonMod {...sdfge} />;

    const okasew = (
      <>
        <Tilos />
        <Divo {...fasasds} />
        {/* {sdhwe} */}
        {/* <br /> */}
        {/* {gfhokwer} */}
        {/* <MarkdownMain obj={okasew} /> */}
        {/* {exmaptxt} */}
      </>
    );

    const okaew = {
      fontSize: "22px",
      // text
      margin: "0px 20px",
      padding: "30px 10px",
      // border: "2px solid grey",
    };

    const ijawe = {
      obj: okasew,
      // className: gens.butClassHover,
      style: okaew,
    };

    return <Divo {...ijawe} />;
  }

  //
  function ofdwer(okwer) {
    logs.logga("___ okwer ___", okwer);
  }

  const sdafowq =
    //
    // "";
    // "shadowHover";
    gens.butClass;

  const dngbsfd = {
    className: sdafowq,
    onClick: ofdwer,
  };

  const tasweasew =
    //
    "";
  // "shadowHover";
  // gens.butClass;

  const qygeqsa = {
    //
    style: {
      textAlign: "center",
      margin: "20px",
    },
    className: tasweasew,
  };

  const ijawasdw = gens.mobview(false, true);

  // const oaksdwe =
  //   //
  //   simpleList;

  // const kasdse = {
  //   horizTrue: true,
  //   obj:
  // };

  const kasde = {
    basic: {
      obj: simpleList,
    },
    card: {
      horizTrue: ijawasdw,
      noSlider: ijawasdw,
      obj: asdwe,
    },
  };

  const okawe = {
    // dictvar:
    // listvar: listo,
    genConfig: qygeqsa,
    // lineNumber: 2,
    // rowNumber: 2,
    itemConfig: dngbsfd,
    // obj: bidsewsad,
    // ...kasde["card"],
    ...kasde[typevar],
    ...asdfokwe,
  };

  // const asdijwe = 

  const aspdlwe = (
    <>
      <WhatOfferList {...okawe} />
      <Modlaos />
      {modalTyp && <Modlaos />}
    </>
  );

  return aspdlwe;
}
