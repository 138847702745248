import React, {
  //
  useState,
  useContext,
} from "react";

import ConfirmButton from "../components/ConfirmButton";
import ListMain from "../components/ListMain";
import FormMain from "../components/FormMain";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";

import { ScriptContext } from "../contexts/ScriptContext";
import {
  getSHortSet,
  checkFullArray,
  getErrorLinks,
  mergeArrays,
  segReport,
  Sersyt,
  addLinkArr,
  postJSON,
  GraphArgo,
  MainPreview,
  // saveModel,
  saveModels,
} from "../functions/GlobalFunctions";
import { isEmpty } from "lodash";
// import aaaa from "./components/aaaa";

export default function TextFindMedia({
  //
  //   dataImport,
  buttonConfig,
  onSubmit,
  initialLinks,
  noFunction,
  loadCheck,
  singleTrue,
  linkFunction,
  titleConfig,
  aboveObj,
  initialValue,
  longTrue,
  genConfig,
  ...args
}) {
  const { showYotubeAdd, setsetAddd, requestSeries } = useContext(
    ScriptContext
  );

  const [goodStore, setgoodStore] = useState([]);
  const [orderao, setOrderDict] = useState();

  const asdhwew =
    //
    // orderao;
    // initialValue;
    initialValue ? initialValue : orderao;

  const { badLinks, badLinkString } = getErrorLinks(asdhwew);
  const osdfkwerwe = checkFullArray(badLinks);
  const [erroFindo, seterrTros] = useState(osdfkwerwe);
  // const [arrGrapLoad, setarrGrapLoad] = useState();
  const [listLinks, setordList] = useState(initialLinks);

  const ellvao =
    //
    // errTros;
    erroFindo ? badLinkString : asdhwew;

  const asokwe = {
    asdhwew: asdhwew,
    osdfkwerwe: osdfkwerwe,
    ellvao: ellvao,
  };

  // 1link

  function fethao(medalsad) {
    logs.logga("___ TEXFINDMEDIA - FETCH MEDIA ___", medalsad);
    onSubmit(medalsad);
  }

  function submitto(asdfewrw) {
    logs.logga("___ submitto ___", asdfewrw);

    if (!isEmpty(asdfewrw)) {
      setordList(asdfewrw);
    }
    
    // linkFunction()
    // setarrGrapLoad(true);
    // saveModels("media", gfohkfd, fethao);
  }

  const gfohkfd = {
    mediaLink: listLinks,
    createTrue: "y",
    baseTrue: "y",
  };

  const oadkwe = {
    messVar: "asdkweqwASDw",
    boolVar: listLinks,
    boolTrue: true,
    fetchFunction: fethao,
    // logTrue: true,
    singleTrue: singleTrue,
  };

  // 1graph
  const { dataImport: xvcbmdoaa, loadtrue: arrGrapLoad, arrGrapErr } =
    //
    // "";
    GraphArgo("media", gfohkfd, oadkwe);
  // GraphAttr("media", "mediaLink", listLinks);
  // GraphPage("media", listLinks);
  // const graphLinks = showGrap && xvcbmdoaa;

  // 1console
  logs.logga("___ asokwe ___", asokwe);

  const errStyle = erroFindo && {
    border: "2px solid red",
    color: "red",
  };

  const sdufhqwe = {
    height: "80px",
    textAlign: "center",
    ...errStyle,
  };

  const requirenon =
    //
    true;
  // !approVDone ? "Required" : "";

  logs.logga("___ requirenon ___", requirenon);

  const hvzewe =
    //
    !singleTrue;
  // "";
  // true;

  const sdifjsd =
    //
    5;
    // longTrue ? 7 : 3;

  const textFingo = hvzewe && {
    typevar: "textarea",
    rows: sdifjsd,
    style: sdufhqwe,
  };

  const linkDicto = {
    required: true,
    name: "medialink",
    textvar: ellvao,
       // onChange: saodkw,
    // onInput: saodkw,
    ...textFingo,
  };

  const socDict = {
    main: linkDicto,
  };

  // 1link

  function setOrBase(valueso) {
    //
    const originalText =
      //
      // valueso
      valueso["medialink"];

    const { goodLinks, nogoodLinks, badLinkString, badGoodBoth, ...sada } =
      //
      getErrorLinks(originalText);

    //
    //
    const stringSet =
      //
      badLinkString;
    // originalText;
    // badLinkString ? badLinkString : originalText;

    const doeRRS =
      //
      // "";
      // nogoodLinks;
      badLinkString;

    const uhqwew =
      //
      mergeArrays(goodLinks, goodStore);

    const ijasdw = {
      doeRRS: doeRRS,
      uhqwew: uhqwew,
      //
      goodLinks: goodLinks,
      nogoodLinks: nogoodLinks,
      badLinkString: badLinkString,
      badGoodBoth: badGoodBoth,
      stringSet: stringSet,
      //   originalText: valueso,
      //   originalText: originalText,
      //   splitText: splitText,
      ...sada,
    };

    segReport("Searched for Links", ijasdw);

    logs.logga("___ ijasdw ___", ijasdw);

    if (doeRRS) {
      setOrderDict(stringSet);
      seterrTros(true);
      setgoodStore(uhqwew);
    } else {
      seterrTros(false);
      submitto(uhqwew);
    }

    // if (asdkwe) {

    //   // setOrderDict(stringSet);
    //   setOrderDict(stringSet);
    // }

    // logs.logga("___ onSubmit ___", onSubmit);
  }

  function asdofwer(valos) {
    // setOrBseO()
  }

  const asodos = [
    // currSoc,
    "main",
    // socDict[currSoc],
    // emDicto,
  ];

  const uhasdw = {
    fontSize: "34px",
    height: "50px",
  };

  const ijwqeqw = {
    iconvar: "tick",
    // disableTrue: gens.testDev,
    onlyText: "Add Links",
    // textvar: "Add Links",
    // style: uhasdw,
  };

  const linputConfig = {
    // // ...listToppo,
    style: {
      padding: "0 10%",
    },
    noEmptyFunc: true,
    onSubmit: setOrBase,
    // titleConfig: {
    //   textAlign: "center",
    //   fontSize: "14px",
    // },
    // topObj: <TopTol />,
    buttonConfig: ijwqeqw,
    listvar: asodos,
    dictvar: socDict,
    ...args,
  };

  function getChanno() {
    const ijadsw = {
      // a
    };
  }

  // const linputConfig =
  //   //
  //   // "";
  //   getChanno();
  // // kaswe;
  // // wqe ?

  function LoadMed() {
    //

    const ijsadwe = {
      fontSize: "24px",
      paddingTop: "20px",
      // background: "blue",
      // color: "red",
    };
    const lasokwe = {
      typevar: "findMedia",
      loadtrue: true,
      style: ijsadwe,
      // quoteTrue: true,
      // className: "buttonHover",
    };

    const jwq3as = (
      <>
        {/*  */}
        <MainPreview {...lasokwe} />
      </>
    );

    const ijaewqasd = {
      //
    };

    // const

    const ijwae = {
      obj: jwq3as,
      style: ijaewqasd,
    };

    return <Divo {...ijwae} />;
  }
  //
  logs.logga("___ linputConfig ___", linputConfig);

  const formos = (
    <FormMain
      //
      // noButton
      noTitle
      // addTrue
      {...linputConfig}
    />
  );

  function ButConfo() {
    //
    const ksda = {
      type: "submit",
      textvar: "Submit",
    };

    const ijqwe = [ksda];

    const iasd = {
      listvar: ijqwe,
      obj: ConfirmButton,
      ...buttonConfig,
    };

    logs.logga("___ iasd ___", iasd);

    const retoas = (
      <ListMain
        //
        // objFuncTrue
        horizTrue
        {...iasd}
      />
    );

    return retoas;
  }

  function TopTol() {
    //
    //
    const aoskdwqe =
      //
      // "Copy the link to your video/podcast."
      "Add the links to your episodes below.";

    const quwhw =
      //
      "Make sure each one is on a new line!";
    //  so we can tell them apart!";
    // "Take a new line for each link.";

    const rrtPOO =
      //
      "Oops! looks like we couldn't find some episodes.";

    const errMesso =
      //
      "Please check these links are correct and try again:";
    // "Please check the links at the top are correct and try again:";

    const linkTieal = {
      //
      iconvar: "link",
      textvar: aoskdwqe,
    };

    const asidwqe = (
      <>
        {erroFindo ? rrtPOO : aoskdwqe}
        <br />
        {erroFindo ? errMesso : quwhw}
      </>
    );

    const nisjewa = asidwqe;

    const asidjwe = {
      fontWeight: "bold",
      color: "red",
    };

    const ajsdas = erroFindo && asidjwe;

    const asd7awe = loadCheck ? "Loading...." : nisjewa;

    const jaswe = {
      obj: asd7awe,
      style: {
        fontSize: "20px",
        ...ajsdas,
      },
      ...titleConfig,
    };

    const iasjdqwe = <Divo {...jaswe} />;

    const siadjae = {
      ...ijwqeqw,
    };

    const confirmo = <ConfirmButton {...siadjae} />;
    const aisjdwe = {
      centerObj: iasjdqwe,
      rightObj: confirmo,
      listTrue: true,
    };

    const qu8weqwawe = (
      //
      <>
        {/*  */}
        {/* <FloatMainAll {...aisjdwe} /> */}
        {iasjdqwe}
      </>
    );

    const asuidwq = {
      textAlign: "center",
      fontSize: "20px",
      color: erroFindo ? "red" : "black",
      // height: "60px",
    };

    const uhqwwe = {
      obj: qu8weqwawe,
      style: asuidwq,
    };

    return <Divo {...uhqwwe} />;
  }

  function GoChanno() {
    //

    const quweqw = {
      background: "darkred",
    };

    function osdkwqe(params) {
      setsetAddd(false);
    }

    const okaswe = {
      obj: "Open your channel",
      style: quweqw,
      onClick: osdkwqe,
      genConfig: Sersyt(requestSeries),
    };

    // const kewea =  <>{getSHortSet(requestSeries)} ,
    //   you can add videos<br>
    //   directly from Youtube!</>

    const xcijvsd = (
      <>
        {/* {kewea} */}
        {getSHortSet(requestSeries)} , you can add episodes
        <br />
        directly from Youtube!
        <ConfirmButton {...okaswe} />
      </>
    );

    const kbsdfaa = {
      obj: xcijvsd,
      fadeTrue: true,
      className: "shadowHover",
      style: {
        // textAlign: "left",
        padding: "25px",
        margin: "25px",
        fontSize: "25px",
      },
    };

    const showAddoo = showYotubeAdd && requestSeries;
    const kzcxmv = showAddoo && <Divo {...kbsdfaa} />;

    const xkvadfa = (
      <>
        {/*  */}
        {kzcxmv}
      </>
    );
    return xkvadfa;
  }

  // const ijsde = singleTrue ? ""

  const okfdsd = (
    <>
      {/* <GoChanno /> */}
      <TopTol />
      {/* <br /> */}
      <br />
      {formos}
      {/* <ButConfo /> */}
    </>
  );

  const jfdsrwe = arrGrapLoad ? <LoadMed /> : okfdsd;

  const aqweas = {
    obj: jfdsrwe,
    ...genConfig,
  };

  // 1HTML
  return <Divo {...aqweas} />;
}
