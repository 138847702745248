import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";
import { Media } from "reactstrap";
import * as gens from "../consts/GenStyled";
import { Avatar, Image, AvatarBadge, AvatarGroup } from "@chakra-ui/core";
// import { Avatar } from "antd";

import Divo from "../components/Divo";

import * as logs from "../consts/logFuncs";

// IMPORT OTHER COMPONENTS HERE
// import aaaa from "./components/aaaa";

export default function ImageCheckReplace({
  //
  imageSize,
  styleVar,
  onClick,
  imagevar,
  imageLink,
  buttonTrue,
  replaceUnknown,
  circleTrue,
  backColor,
  linkConfig,
  borderCol,
  replaceImage,
  shapeVar,
  linkvar,
  overlayObj,
  loadtrue,
  noAntd,
  noAvatar,
  padVar,
  mainObject,
  widthVar,
  borderTrue,
  ...imgos
}) {
  // 1const

  // logs.logga("------speakPicUnknown: ", gens.speakPicUnknown);
  // logs.logga("------BediaLogoWhite: ", gens.BediaLogoWhite);

  const unkASOW =
    //
    "";
  // gens.speakPicUnknown;
  // gens.BediaMagBlueST;

  //
  replaceImage = replaceUnknown ? unkASOW : replaceImage;
  imageLink = imagevar || imageLink;
  imageLink = imageLink ? imageLink : replaceImage;
  // const pishBorder = gens.appMainLight()
  const pishBorder = gens.appDarkFunc(0.1);
  borderCol = borderCol ? borderCol : borderTrue ? pishBorder : "";
  const backFull = backColor ? backColor : borderTrue ? borderCol : "";
  const existIt = replaceImage || imageLink;

  const imgSto = {
    // border: "3px solid green",
    // width: widthVar,
    border: borderTrue ? "3px solid " + borderCol : "",
    background: backFull,
  };

  const bsImgs = {
    src: imageLink,
    size: imageSize,
  };

  const hfokte = {
    padding: 0,
    display: "block",
    height: imageSize,
    width: imageSize,
    borderRadius: "100%",
    borderRadius: circleTrue ? "50%" : "",
    ...imgSto,
    ...styleVar,
  };

  const antConfo = {
    ...bsImgs,
    // onClick: onClick,
    // size: imageSize,
    size: 20,
  };

  const imgclik = {
    onClick: onClick,
    // style: hfokte,
    // className: "borderHover",
    // borderRadius: circleTrue ? "50%" : "",
    height: imageSize,
    width: imageSize,
    // shape: shapeVar,
    ...imgos,
  };

  const bmfdgs = {
    ...bsImgs,
    ...imgclik,
    // verticalAlign: "middle",
    // width: "50px",
    // height: "50px",
    // borderRadius: "50%",
  };

  // 1console
  // logs.logga("borderCol-eeee: ", borderCol, "qwygebd982");
  logs.logga("imageCheckReplace-eeee: ", bmfdgs, "qwygebd982");

  noAntd = true;
  // noAntd = widthVar || noAntd;

  function MainObjImg() {
    const kxvfssa = loadtrue ? (
      ""
    ) : // <LoadSkel avatar />
    noAvatar ? (
      <>
        {/* <gens.StyledImage {...imgclik} /> */}
        <img {...bmfdgs} />
        {/* <Image {...imgclik} /> */}
      </>
    ) : (
      <>
        <Avatar {...bmfdgs} />
        {/* <Avatar {...antConfo} /> */}
        {/* <Media {...bmfdgs} /> */}
        {/* <gens.ImageAvatar {...imgclik} /> */}
      </>
    );

    return kxvfssa;
  }

  // 1ImageCheckReplace
  // return <ImagEx />;

  const fjdfs = (
    <>
      {/* // <img {...bmfdgs} /> */}
      {/* <img {...imgclik} />; */}
      <MainObjImg />
      {/* <Image {...bmfdgs} /> */}
    </>
  );

  const xvbmdfsf = {
    borderRadius: "50%",
  };

  const ijasewa = {
    obj: fjdfs,
    // style: xvbmdfsf,
    // ...imgclik,
  };

  const xcvomfs = (
    //
    // fjdfs;
    <Divo
      //
      noDiv
      {...ijasewa}
    />
  );

  return xcvomfs;
}
