import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import { getSHortSet } from "./GlobalFunctions";

export function checkProcess({
  attrvas,
  mediaList,
  typePrivacy,
  orderDetails,
  requestSeries,
  userSearched,
  typeProcess,
  scribeObj,
  approvePay,
  typeOrder = "both",
  ...asdf
}) {
  //
  let valasDIo = "";
  let titleOss = "";
  let botsome = "";
  let rightMesso = "";

  titleOss =
    //
    // "Order";
    "The fastest, easiest way";

  const askwe = {
    transcript: "transcripts",
    subtitle: "subtitles",
    both: "transcripts and subtitles",
  };
  const asowjke = askwe[typeOrder] + ".";

  botsome =
    //
    // "Order"
    "to get " + asowjke;
  switch (typeProcess) {
    //
    case "options":
      titleOss =
        //
        // "Order"
        "You're almost there!";

      botsome =
        //
        // "Order"
        "Choose what you'd like us to send over.";

      break;

    //
    case "details":
      //   titleOss =
      //     //
      //     // "Order"
      //     "Great! One last thing...";

      //   botsome =
      //     //
      //     // "Order"
      //     "Add your details below";

      break;

    // case "final":
    // //
    //   break;
  }
  const orderNams = requestSeries
    ? getSHortSet(requestSeries)
    : orderDetails?.name;

  logs.logga("___ orderDetails ___", orderDetails);

  logs.logga("___ requestSeries ___", requestSeries);
  logs.logga("___ orderNams ___", orderNams);

  const titors =
    //
    // "Order"
    // "You're all set, " + orderNams + "!";
    "You're almost there, " + orderNams + "!";

  const { deliveryBase, deliveryDays: iajsdwe } =
    //
    "";

  const qweasw = "After you pay, we'll send the work by " + iajsdwe + ".";

  const rttew0 =
    // "";
    // "Order"
    qweasw;

  const iokae = scribeObj && (
    <>
      {/* <ScribeRate scribeObj={scribeObj} />  */}
      {/* {scribeObj.name}'s standing by to  */}
      {scribeObj.name} will start work as soon as you pay.
      {/* ng on your files. */}
      {/* {scribeObj.name} can't wait to get started. */}
    </>
  );

  const titMo =
    //
    titors;

  const sfdgsdf =
    //
    iokae;
  // rttew0;

  // botsome = scribeObj
  //   ? //
  //     iokae
  //   : rttew0;

  titleOss = approvePay ? titMo : titleOss;
  botsome = approvePay ? sfdgsdf : botsome;

  const aoakdw = {
    titleTop: titleOss,
    titleBottom: botsome,
    rightMeta: rightMesso,
    ...valasDIo,
  };

  logs.logga("___ aoakdw ___", aoakdw);

  const uqhwe = aoakdw[attrvas];
  return uqhwe;
}
