import React, {
  //
  useState,
  useContext,
} from "react";
import {
  borderMain,
  //
  GraphFuncBase,
  MainPreview,
  PageTitle,
} from "../functions/GlobalFunctions";
import * as logs from "../consts/logFuncs";
import ConfirmButton from "../components/ConfirmButton";
import Divo from "../components/Divo";
import FormMain from "../components/FormMain";
import ListMain from "../components/ListMain";

// import ListMain from "../components/ListMain";
// import aaaa from "./components/aaaa";

export default function HomeChannel({
  //
  // subtitleConfig,
  submitFunc,
  ...obj
}) {
  //
  const asdpwq =
    //
    // "";
    "https://www.spreaker.com/show/2577555/episodes/feed";
  // "https://feeds.buzzsprout.com/877747.rss";
  // "https://www.youtube.com/c/SecularTalk";
  // "https://www.youtube.com/user/ScottishParl";
  // "https://www.omnycontent.com/d/playlist/4b5f9d6d-9214-48cb-8455-a73200038129/32ad3f7a-9469-4d73-911f-a789006d3042/d7ec49ab-84e9-4c00-87b8-a789006d3050/podcast.rss";
  // "https://www.youtube.com/channel/UCKMFiRDl9tp7E19PwXvCbNw";

  const argAddo = {
    userCreated: "true",
    createTrue: "true",
    // podcast: asdpwq,
    // slugURL: "bedia",
    // slugURL: "4db29f490a",
    // youtube: asdpwq,
    // email: "andrew@scriptate.com",
  };

  const truCahnno =
    //
    "";
  // true;

  const argind =
    //
    // "";
    argAddo;

  // 1createtrue
  const [fetchArgs, setfetchArgs] = useState(argind);
  const [loadFetch, setloadFetch] = useState();
  const [channCreate, setchannCreate] = useState(truCahnno);
  const [currSoc, setcurrSoc] = useState("youtube");

  const vcxbosd =
    //
    channCreate && fetchArgs;

  const aweojwe = {
    typevar: "series",
    args: vcxbosd,
    messvar: "87tgk",
  };

  const {
    dataImport: seriesObj,
    loadtrue: loadChann,
    importErr,
  } = GraphFuncBase(aweojwe);
  logs.logga("seriesObj-zz", seriesObj);

  const loasdiw =
    //

    channCreate && loadChann;
  // true;

  function linkConn(sadwqea) {
    const asdins =
      //
      "The link to your " + sadwqea;
    // "Connect your " + sadwqea + " for free";

    return asdins;
  }

  const emDicto = {
    iconvar: "email",
    titlevar: "Your email",
    name: "email",
    noTitle: false,
  };

  const ytConfo = {
    // pattern: {
    //   value: /^youtube.com/i,
    //   message: "Please enter a valid Youtube link",
    // },
    iconvar: "youtube",
    titlevar: "Youtube channel",
    name: "youtube",
    color: "red",
  };

  const fbCOnoas = {
    // pattern: {
    //   value: /^facebook.com/i,
    //   message: "Please enter a valid Facebook link",
    // },
    iconvar: "facebook",
    titlevar: "Facebook playlist",
    name: "facebook",
    color: "blue",
  };

  const twitco = {
    iconvar: "twitch",
    titlevar: "Twitch channel",
    name: "twitch",
    color: "purple",
  };

  const pocdoso = {
    iconvar: "podcast",
    titlevar: "podcast feed",
    name: "podcast",
    color: "orange",
  };

  // rss {
  //   iconvar: "home",
  //   textvar: "Website",
  //   name: "website",
  // }

  // titlevar, ...
  function foksas({ titlevar, ...sofk }) {
    const okasd = {
      ...sofk,
      required: true,
      titlevar: linkConn(titlevar),
    };

    return okasd;
  }

  const socDict = {
    email: emDicto,
    youtube: foksas(ytConfo),
    facebook: foksas(fbCOnoas),
    twitch: foksas(twitco),
    podcast: foksas(pocdoso),
    // rss:
  };

  const showDictup = [
    //
    "youtube",
    "podcast",
    "facebook",
    "twitch",
  ];

  const asodos = [
    currSoc,
    "email",
    // socDict[currSoc],
    // emDicto,
  ];

  function subSocial(vales) {
    //
    const alArgos = {
      ...argAddo,
      ...vales,
    };
    logs.logga("vales", vales);

    setfetchArgs(alArgos);
    setchannCreate(true);

    if (submitFunc) {
      // submitFunc()
    }
  }

  const lcxsda = {
    style: {
      // fontSize: "24px",
      // width: "80%",
      width: "100%",
    },
    // width: "400px",
    // height: "500px",
    // placeholder: "fetchLink",
    // placeholder: fetchLink,
    // textvar: fetchLink,
    // textvar: "qsad9jqwoeqwqw",
    // textvar: <Divo spanTrue obj="qe9qwie21312" />,
  };

  const oksfad = {
    // className: "buttonHover",
    onlyText: "Connect Channel",
    style: {
      margin: "20px 40px",
      fontSize: "20px",
      // background: "darkred",
      // color: "white",
    },
  };

  const linputConfig = {
    onSubmit: subSocial,
    titleConfig: {
      textAlign: "center",
      fontSize: "14px",
    },
    inputConfig: lcxsda,
    buttonConfig: oksfad,
    listvar: asodos,
    dictvar: socDict,
  };

  function handFin(obj) {
    logs.logga("hadno", obj);
    setcurrSoc(obj.iconvar);
  }

  function ObjF({ color, style, ...obj }) {
    logs.logga("OJASA", obj);

    const zxcvasd = {
      ...obj,
      style: {
        color: color,
        ...style,
      },
    };

    const jnsdcsa = "";
    // (
    //   <ImageTextDiv
    //     //
    //     // linkTextTrue
    //     // popTextTrue
    //     popTrue
    //     // baseStyle
    //     {...zxcvasd}
    //   />
    // );

    return "asdqweqw";
    // return jnsdcsa
  }

  const currChoseo = socDict[currSoc];
  const linkFindo = [
    //
    "email",
    "twitter",
    "facebook",
    "linkedin",
  ];

  function SuccChan() {
    //
    const objSucces = (
      <>
        <Divo
          style={{
            fontSize: "30px",
          }}
          obj={<>{seriesObj.name},</>}
        />
        <Divo obj="We've created your channel!" />
        <Divo
          obj={
            <>
              {/*  */}
              We'll email you with some next steps. In the meantime...
            </>
          }
        />
        <ConfirmButton
          iconvar="share"
          textvar="Share your channel"
          style={{
            color: "darkblue",
            fontSize: "22px",
          }}
        />
        {/* <ListSocial
      noSlider
      popTrue
      //
      //
      messvar="what.transcript.channels----"
      listvar={linkFindo}
      iconConfig={{
        className: "buttonHover expandHover",
      }}
      linkConfig={{
        outsideTrue: true,
        linkvar: gens.linkModel("series", seriesObj),
        textvar:
          "Just joined Bedia to make our content accessible! Which episodes should we do first? " +
          gens.linkModel("series", seriesObj),
        titlevar:
          "We're getting subtitles and transcripts! (joining Bedia)",
      }}
    /> */}
        <ConfirmButton
          iconvar="link"
          textvar="Visit your channel"
          linkvar={"/series/" + seriesObj.slugURL}
          style={{
            fontSize: "22px",
          }}
        />
        {/* <ConfirmButton
      iconvar="info"
      textvar="How Bedia Works"
      linkvar="/how-bedia-works"
      style={{
        fontSize: "22px",
      }}
      
    /> */}
        {/* <Divo linkvar={"upload"} obj="Add transcripts" />
    <Divo obj="Add your team" />
    <Divo obj="Invite guests" /> */}
      </>
    );

    //
    return objSucces;
  }

  const choso = {
    className: "pointer",
    style: {
      color: currChoseo["color"],
      ...borderMain("black", "5px"),
      background: "white",
      height: "60px",
      width: "80px",
      // padding: "20px",
      // ...gens.selectGrey,
    },
  };

  const oksdaa =
    //
    "shadeTrue buttonHover expandHover pointer";

  const oskdas = {
    // padding: "20px",
    // color: "red",
    // padding: "30px",
    fontSize: "44px",
  };

  const ofkvds = {
    className: oksdaa,
    style: oskdas,
  };

  const listIconConfig = {
    // styleList: {
    //   // border: "2px solid blue",
    // },
    dictvar: socDict,
    listvar: showDictup,
    chosenItem: currSoc,
    onClick: handFin,
    itemConfig: ofkvds,
    chosenConfig: choso,
    obj: ObjF,
  };

  const problemConn = !seriesObj || importErr;
  const channErr = channCreate && problemConn;
  const chanSucc = channCreate && seriesObj;
  const asodaw = (
    <Divo
      obj={
        <>We couldn't create your channel. Try again or tweet us at @bediatv</>
      }
    />
  );

  const loadIt = {
    seriesObj: seriesObj,
    topObj: <>Connecting your channel...</>,
    loadtrue: loasdiw,
    errorTrue: problemConn,
    errorObj: asodaw,
    objCheck: seriesObj,
    // obj: "asdw",
    obj: seriesObj && <SuccChan />,
  };

  logs.logga("loadChan---", loadIt);

  function LoadChan() {
    //

    // return "as";
    return <MainPreview {...loadIt} />;
  }

  function AddChan() {
    //
    const asidjwe =
      //
      loasdiw
        ? "Connecting your channel..."
        : channErr
        ? asodaw
        : "Join The Waitlist";

    // obj="First hour's on us."

    const okxcsad = {
      // color: channErr ? "red" : "black",
      fontSize: channErr ? "20px" : "46px",
      paddingTop: "5%",
      // padding: "0 0 16px",
    };

    const okaxcvzczw = {
      style: okxcsad,
      obj: asidjwe,
    };

    const okasd = (
      <>
        <Divo {...okaxcvzczw} />
        <ListMain horizTrue {...listIconConfig} />
        <FormMain noTitle {...linputConfig} />
      </>
    );

    return okasd;
  }

  const oxkasawe = chanSucc ? <SuccChan /> : <AddChan />;
  // <LoadChan />

  const qetwqw = "Connect Your Bedia";

  const wiejqwe = {
    titlevar: qetwqw,
  };

  const vsdalkw = {
    topText: "Join the waitlist",
    bottomText: "Connect your channel below",
  };

  const eobjas = (
    <>
      <PageTitle {...vsdalkw} />
      {/*  */}
      {oxkasawe}
    </>
  );

  const cbokxvsdadf = {
    obj: eobjas,
    // helmetConfig: wiejqwe,
    // titleConfig: { obj: qetwqw },
    // noTitle: !succTrue,
  };

  const oksada = (
    <Divo
      //
      {...cbokxvsdadf}
    />
  );

  return oksada;
}
