import * as logs from "../consts/logFuncs";
// import "firebase/firestore";
// import * as auth from "firebase/auth";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { get } from "react-hook-form";
import useFirestoreQuery from "./firestoreFuncs";

var firebaseConfig = {
  apiKey: "AIzaSyCTNAVPnCcrNJV1wEzObL-Ky6UGvUeuZMU",
  authDomain: "test-bediab.firebaseapp.com",
  projectId: "test-bediab",
  storageBucket: "test-bediab.appspot.com",
  messagingSenderId: "883595134259",
  appId: "1:883595134259:web:ec88d15925b0f818b56a46",
  measurementId: "G-C0D7TGGYM2",
};

firebase.initializeApp(firebaseConfig);
let autho =
  //
  firebase.auth();

logs.logga("___ firebase ___", firebase);

function getAuth() {
  let adwe = firebase.auth();

  logs.logga("___ getAuth ___", adwe);

  return adwe;
}

export function loginPop() {
  let dsofkes =
    //
    "";
  // firebase.auth().GoogleAuthProvider();
  // .signInWithPopup(dsofkes)
}

// "andrew.maguire2011@gmail.com", "password"
export function fireCreate(user) {
  // get user data from the auth trigger
  const userUid = user.uid; // The UID of the user.
  const email = user.email; // The email of the user.
  const displayName = user.displayName; // The display name of the user.

  // set account  doc
  const account = {
    useruid: userUid,
    calendarEvents: [],
  };
  firebase.firestore().collection("users").doc(userUid).set(account);
}

export function errCreate(params) {
  //
}

export function fireLogin(email, password) {
  autho
    .signInWithEmailAndPassword(email, password)
    .then(fireCreate)
    .catch(errCreate);
}

export function fireConnect(typeVar) {
  //   getAuth();

  let dsofkes = "";
  switch (typeVar) {
    case "google":
      dsofkes = new firebase.auth.GoogleAuthProvider();
      break;
    case "facebook":
      dsofkes = new firebase.auth.FacebookAuthProvider();
      break;
    case "twitter":
      dsofkes = new firebase.auth.TwitterAuthProvider();
      break;
    // case "instagram":
    //     dsofkes = new firebase.auth.TwitterAuthProvider();
    //     break;
    // }
    default:
  }

  autho.signInWithPopup(dsofkes).then(fireCreate).catch(errCreate);
}

// db.settings({
//   timestampsInSnapshots: true,
// });
// export function get

export function addHighlight(user, data) {
  //   db.collection("highlights")
  //     // .doc(user)
  //     // .collection("booksList")
  //     .doc(myBookId)
  //     .set();
}

export function FiltModel(typevar, fdhrt) {
  const db = firebase.firestore();
  let refMod = db.collection(typevar);
  let serijer = fdhrt ? fdhrt(refMod) : refMod;

  return useFirestoreQuery(serijer);

  //   return serijer
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         console.log(doc.id, " => ", doc.data());
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });

  //   return serijer;
}
