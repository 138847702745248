import React, {
  lazy,
  Suspense,
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
  Component,
  createContext,
} from "react";
import {
  goThroughArray,
  CheckAndFetch,
  removeEmptyDict,
  getFirstArr,
  checkEmptyArray,
} from "../../functions/GlobalFunctions";
import * as gens from "../../consts/GenStyled";
import * as logs from "../../consts/logFuncs";

import { useQuery } from "@apollo/react-hooks";
// import * as gens from "../../consts/GenStyled";

export default function GraphFunction({
  typevar,
  argsVar,
  fetchFunction,
  queryParse,
  messVar,
  boolTrue,
  boolVar,
  logTrue,
  messsVar,
  fieldType,
  mutateTrue,
  singleTrue,
}) {
  //
  argsVar = removeEmptyDict(argsVar);

  boolVar =
    //
    // "3";
    // !boo;
    boolTrue ? boolVar : true;

  const asidjw = boolVar && {
    query: queryParse,
    variables: argsVar,
  };

  const asdlmq = {
    queryParse: queryParse,
    asidjw: asidjw,
  };

  // // logs.logga("graphFunction---argsVar-zz", argsVar);
  logs.logga("GRAPH-FUNCTIONS-AAAAAA", asdlmq, "asd980usdw");

  // const { importTest, loadtrue, importErr } = "";
  const { data: importTest, loading: loadtrue, error: importErr } = useQuery(
    //
    queryParse,
    asidjw
  );

  // const asdiqwheq = gens.modelConst[typevar];
  const gejr9we2 =
    //
    typevar;
  // asdiqwheq.graphType ? asdiqwheq.graphType : typevar;

  const dataOney = importTest ? importTest[gejr9we2] : "";
  // singleTrue = "y";

  const singeo = singleTrue || checkEmptyArray(dataOney);
  const adsfwer = singeo ? getFirstArr(dataOney) : dataOney;
  const dataVary = adsfwer;

  const sadmqwe = {
    typevar: typevar,
    boolVar: boolVar,
    argsVar: argsVar,
    queryParse: queryParse,
    importTest: importTest,
    gejr9we2: gejr9we2,
    dataOney: dataOney,
    dataVary: dataVary,
    loadtrue: loadtrue,
    singleTrue: singleTrue,
    importErr: importErr,
  };
  //
  // 1console
  const ksaeadw =
    //
    // logTrue;
    // true;
    // ""
    typevar == "order";

  function lgso(asdfwer, dsafewr) {
    if (ksaeadw) {
      logs.logga(asdfwer, dsafewr);
      // logs.logga("GRAPHI--FUNC", asdfwer, dsafewr);
    }
  }

  lgso(messVar + "--GRAPH-FUNCTIONS-BBBBB", sadmqwe, "asd980usdw");

  const ijdsae = boolVar
    ? {
        dataVary,
        loadtrue,
        importErr,
      }
    : "";

  lgso("___ ijdsae ___", ijdsae);

  if (loadtrue) {
    lgso("___ loadtrue ___", loadtrue);

    return ijdsae;
  } else {
    // logs.logga("___ importTest ___", importTest);
    // logs.logga("___ fetchFunction ___", fetchFunction);

    if (importErr) {
      lgso("___ importErr ___", importErr);
    }

    const aodkwe =
      //
      fetchFunction && boolVar;

    if (aodkwe) {
      lgso("___ fetchFunction ___", importTest);

      fetchFunction(dataVary);
    }

    return ijdsae;
  }
  // importTest, loading: loadtrue, error: importErr

  // return
}
