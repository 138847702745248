import React, { useState, useContext } from "react";
import { ScriptContext } from "../contexts/ScriptContext";
import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import PageBase from "../components/PageBase";
import PageBasey from "../components/PageBase";
import {
  getParamVar,
  GraphFuncBase,
  GraphPage,
  stringJSON,
  resetOrder,
  paramOrLocal,
  PageTitle,
  setLocal,
  getLocal,
  changePage,
  mergeArrays,
  getSHortSet,
  getLength,
  joinString,
  //
} from "../functions/GlobalFunctions";
// import GraphFunctions from "../config/graphql/GraphFunctions";
import OrderContainer from "../containers/OrderContainer";
import OrderConfirm from "../containers/OrderConfirm";
import RequestIntro from "../containers/RequestIntro";
import OrderStep from "../containers/OrderStep";

export default function OrderPage({
  match: {
    params: { slugURL, stagevar },
  },
  // id,
}) {
  //
  // const gfohkfd = {
  //   slugURL: listLinks,
  //   createTrue: "trie"
  // };
  const {
    passRequest,
    graphRequest,
    typeProcess,
    reqLoado,
    seriesOver,
    requestMedia,
    checkItAll,
    currBasketMedia,
    requestHigh,
    loadPageit,
    orderDetails,
    setBasket,
    loadBasket,
    requestSaw,
    setrequestSaw,
    currentOrder,
    setcurrentOrder,
    setorderDetails,
  } = useContext(ScriptContext);

  const orderTypes = "typ";
  const getParm = getParamVar(orderTypes);  

  const xvcbomwe = currentOrder?.paidTrue;
  // || ioaew;
  const succTrue =
    //
    xvcbomwe 
    // && !paidSeen;
  // id || ioaew;
  // currentOrder || xvcbomwe;
  // currentOrder;

  const iajdwe = {
    text: "Transcripts",
    sub: "Subtitles",
  };

  const aidwe = getParm ? iajdwe[getParm] : "";

  function Loadit() {
    const okasd = "";
    return okasd;
  }

  function ReqIntreo() {
    //
    function ovkdsf() {
      passRequest();
      changePage();
    }

    const vcobfr = {
      //
      // ...baseArgs,
      confirmFunc: ovkdsf,
    };

    const dogkrer = <RequestIntro {...vcobfr} />;

    return dogkrer;
  }

  const reqHomer =
    //
    // "";
    // true;
    requestHigh && !requestSaw;

  function OrdConto() {
    return <OrderContainer />;
  }

  const oskdwe = (
    //
    // "";
    <OrderContainer />
  );
  // reqHomer ? graphRequest ? <ReqIntreo /> : <OrdConto /> : <OrdConto />;

// orderDat, loadtrue: arrLinkLoad
  const loadAny = 
  // 
  loadPageit
  // arrLinkLoad || loadPageit;
  
  const asdokwe =
    //
    // succTrue ? <OrderConfirm /> : oskdwe;
    loadAny ? <Loadit /> : succTrue ? <OrderConfirm /> : oskdwe;

  const ksdfa = {
    paidTrue: succTrue,
  };
  const asdkwe = <OrderStep {...ksdfa} />;
  const ksdfas = {
    stepTrue: true,
    // centerObj: asdkwe,p
    // centerObj: "asdkwe aodkas oaewqoke qokw oe k",
  };

  const subbto = {
    // checkItAll("titleTop"),
    // bottomText: checkItAll("titleBottom"),
  };

  // getSHortSet(seriesOver)

  function ordhHLM() {
    const audhwe = joinString([
      seriesOver.name,
      "'s order (",
      getLength(currBasketMedia),
      " episodes)",
    ]);

    return audhwe;
  }

  function transRet() {
    const ijasew = "Transcript Request: '" + requestMedia.name + "'";
    return ijasew;
  }

  const dfghrte = succTrue
    ? "Thanks for your order!"
    : // : currentOrder
    // ? ordhHLM()
    requestMedia
    ? transRet()
    : "Order";

  const wiejqwe = {
    titlevar: dfghrte,
  };

  const dsijfer =
    //
    // "asdokwe"
    asdokwe;

  const aokeqwe = {
    // titleConfig: { obj: qetwqw },
    noTitle: true,
    obj: dsijfer,
    // subtitleConfig: subbto,
    fastestvar: !succTrue && "to get transcripts and subtitles.",
    // noSubtitle: succTrue,
    helmetConfig: wiejqwe,
    headerConfig: ksdfas,
    stepObj: asdkwe,
    // fadeTrue: true,
  };

  logs.logga("___ aokeqwe ___", aokeqwe);

  const uehwqe = {
    succTrue: succTrue,
    ...aokeqwe,
  };

  // 1console
  logs.logga("___ currentOrder ___", currentOrder);
  logs.logga("___ ORDERPAGE ___", uehwqe);

  const ijaksa = (
    <>
      {/*  */}
      {/* sdfokwf */}
      {/* {asdkwe} */}
      <PageBase {...aokeqwe} />
    </>
  );

  return ijaksa;
}
