import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { HttpLink, createHttpLink } from "apollo-link-http";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import * as tsts from "./consts/testConst";

const ikdsfad = process.env.REACT_APP_SERVER_URL;
const endLing =
  //
  // ikdsfad + "/graphql";
  tsts.testBackend ? "http://127.0.0.1:8000/graphql" : ikdsfad + "/graphql";

const cache = new InMemoryCache();
const link = new createHttpLink({
  uri: endLing,
  credentials: "include",
});

const client = new ApolloClient({
  link,
  cache,
  onError: ({ networkError, graphQLErrors }) => {
    // logs.logga("graphQLErrors", graphQLErrors);
    // logs.logga("networkError", networkError);
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logs.logga))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
