import React, {
  //
  useState,
  useContext,
} from "react";
import { ImageTextList } from "../functions/GlobalFunctions";
import * as gens from "../consts/GenStyled";
import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";

export default function AddInfoMedia({
  //
  dataImport,
  ...args
}) {
  // // 1context
  // 1scriptcontext
  // prettier-ignore
  const { aaaaaa } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  function AddTrnsMss() {
    const iasjdqwe = "<Te";

    return iasjdqwe;
  }

  function asoaskwq(vbomws) {
    //
    // logs.logga("___ uhasdwe ___", uhasdwe);
    let sdfijer = {
      transcriptMess: <AddTrnsMss />,
    };

    // setbeloas()
    const kcxvs = sdfijer[vbomws];

    // setbeloas(kcxvs);
  }

  const adhwe =
    //
    "clock";
  // "file-text"

  const tsnMsssCon = {
    iconvar: adhwe,
    textvar: "Add Transcript",
    onClick: () => asoaskwq("transcriptMess"),
  };

  const asudhqwe =
    //
    "clock";
  // "sticky-note-o"

  const rdMssCon = {
    iconvar: asudhqwe,
    textvar: "Add Message",
    // onClick: () => setbeloas("list"),
  };

  const vkdfsfa = {
    addTranscript: tsnMsssCon,
    orderMessage: rdMssCon,
  };

  const iajdw = [
    //
    "addSpeaker",
    "orderMessage",
    "addTranscript",
  ];

  const belConfo = {
    dictvar: vkdfsfa,
    listvar: iajdw,
    // popTrue: false,
  };

  const asygew = (
    <ImageTextList
      //
      horizTrue
      {...belConfo}
    />
  );

  const okfdsd = (
    <>
      {/*  */}
      {/* {sssss} */}
      {asygew}
    </>
  );

  args = {
    obj: okfdsd,
    ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
