import gql from "fraql";
// import * as mainAttrs from "./mainAttr";
// import * as mediaAttrs from "./mediaAttr";
// import * as speakAttrs from "./speakAttr";
// import * as prodAttrs from "./prodAttr";
// // import * as seriesAttr from "./seriesAttr";

// ---------------
// ---------------
// ---------------
// ---------------
// 1base

export const userBase = gql`
  fragment _ on UserType {
    user {
      id
      name
      password
      username
      dateJoined
    }
  }
`;

// # lineSpeaker {
//   #   ${speakerBase}
//   # }

export const highBaseSpeak = gql`
  fragment _ on HighlightType {
    id
    # slugURL
    # lineNumber
    # text
    name
    description
    startTime
    endTime
    duration
    tags
  }
`;

export const highBase = gql`
  fragment _ on HighlightType {
    ${highBaseSpeak}
  }
`;

export const seriesSmall = gql`
  fragment _ on SeriesType {
    id
    slugURL
    name
  }
`;

export const mediaBase = gql`
  fragment _ on MediaType {
    id
    slugURL
    name
    mediaDuration
    videoThumbnail
    videoReleasedate
    videoDescription
    videoFileLink
    audioFileLink
    textFileLink
    # baseFileLink
    chapterList {
      ${highBase}
    }
    # chapterJSON
    #
    hasSubtitles
    transcribedTrue

    mediaFileLink
    imageAttr
    videoSeries {
      ${seriesSmall}
    }
    #
  }
`;

// text
// requestTrue
// publicTrue
// created
// modified

// videoSpeakers {
//   name
// }

// 1speaker
export const speakerBase = gql`
  fragment _ on SpeakerType {
    name
    imageAttr
    #
    id
    slugURL
    meta
    description
    firstname
    lastname
    twitter
    speakerUnknown
  }
`;

// recentWeek
//     recentMonth
// SeriesType

// numberMeta
export const seriesBase = gql`
  fragment _ on SeriesType {
    id
    slugURL
    imageAttr
    name
    podcast
    MainSeries
    mainEmail
    youtube
    meta
    description
    transcribedTrue
    twitter
    website
    wikipedia
    facebook
    country
    #
    shortNameAttr
    shortName
  }
`;
// imageAttr

export const seriesRequest = gql`
  fragment _ on SeriesType {
    ${seriesBase}
    subTrue
    requestMessage
    # requestEmail 
    perMinuteCost
    costPerMinute
  }
`;

export const orderBase = gql`
  fragment _ on OrderType {
    id
    slugURL
    email
    name
    completeTrue
    deliveryTime
    deliveryPlusHours
    paidTrue
    noteJSON
    noteTrue
    media {
      ${mediaBase}
    }
    orderSeries {
      ${seriesRequest}
    }
  }
`;

export const commentBase = gql`
  fragment _ on CommentType {
    id
    slugURL
    text
    created
    modified
    inviteTrue
    requestTrue
    publicTrue
    user {
      ${userBase}
    }
  }
`;

// series {
//   ${seriesBase}
// }
// media {
//   ${mediaBase}
// }
// // #

// ${mediaBase}
//     videoSpeakers {
//       ${speakerBase}
//     }
//     videoSeries {
//       ${seriesBase}
//     }
export const mediaSero = gql`
  fragment _ on MediaType {
    name
  }
`;

export const lineBaseSpeak = gql`
  fragment _ on LineType {
    id
    slugURL
    lineNumber
    startTime
    endTime
    text
    lineSpeaker {
      ${speakerBase}
    }
  }
`;

// 1line
export const lineBase = gql`
  fragment _ on LineType {
    ${lineBaseSpeak}
    lineMedia {
      id
      slugURL
      name
      mediaDuration
      mediaFileLink
      videoSeries {
        ${seriesBase}
      }
    }
  }
`;

// singleSpeaker {
//   ${speakerBase}
// }
// firstLine {
//   ${lineBase}
// }
// getTransLines
// jsonTranscript

export const mediaFull = gql`
  fragment _ on MediaType {
    ${mediaBase}    
    # requestDone
    # requestPrice
    # goodDeedOrder
    # jsonTranscript
    # # speakerDictID
    # passwordHex
    # passwordEnabled
  }
`;
// # firstLineTime

// ${mediaBase}
// ${mediaFull}

export const mediaMain = gql`
  fragment _ on MediaType {
    ${mediaFull}
    videoSeries {
      ${seriesBase}
    }
    videoSpeakers {
      ${speakerBase}
    }
  }
`;

// highBase
// # ${lineBaseSpeak}
// ${lineBase}

// lineMedia {
//   id
//   slugURL
//   name
//   mediaDuration
//   mediaFileLink
//   videoSeries {
//     ${seriesBase}
//   }
// }

// # ${highBase}

export const highFromMedia = gql`
  fragment _ on HighlightType {
    
    ${highBaseSpeak}
  }
`;

// traceSeries {
//   ${seriesBase}
// }
// connectSpeakers {
//   ${speakerBase}
// }
// latestSpeech {
//   ${lineBase}
// }
//

// 1speaker
export const speakPrime = gql`
  fragment _ on SpeakerType {
    ${speakerBase}

  }
`;

export const seriesAllSpeakers = gql`
  fragment _ on SeriesType {
    speakerappearSeries {
      ${speakerBase}
    } 
    MainSpeakers {
      ${speakerBase}
    }    
    PrimarySpeaker {
      ${speakerBase}
    }
    featSpeakers {
        ${speakerBase}
      }   
  }
`;

export const seriesLastMedia = gql`
  fragment _ on SeriesType {
    lastMedia {
      ${mediaBase}
    } 
  }
`;

// ${seriesBase}
// // seriesOth
// primeSeries {
//   ${seriesSmall}
// }

// 1seriesprime
export const seriesPrime = gql`
  fragment _ on SeriesType {
    ${seriesBase}  
    ${seriesAllSpeakers}
    lastMedia {
      ${mediaBase}
    }
  }
`;

export const mediaPrime = gql`
  fragment _ on MediaType {
    ${mediaFull}
    videoSeries {
      ${seriesBase}
    }
    videoSpeakers {
      ${speakerBase}
    }
  }
`;

//  # id
// ${lineBase}
// 1line
export const linePrime = gql`
  fragment _ on LineType {
    ${lineBase}
  }
`;

export const prodBase = gql`
  fragment _ on ProducerType {
    id
    name
  }
`;

// ---------------
// ---------------
// ---------------
// ---------------
// 1other

// ---------------
// ---------------
// ---------------
// ---------------

export const highMain = gql`
  fragment _ on HighlightType {
    ${highBase}    
    highlightSpeakers {
      ${speakerBase}
    }
    speakerSet {
      ${speakerBase}
    }
    highlightVideoid {
      ${mediaBase}    
    }
  }
`;
//

export const speakMain = gql`
  fragment _ on SpeakerType {
    ${speakerBase}    
    videoSet {
      ${mediaBase}
    }
    highlightsSet {
      ${highMain}
    }
  }
`;

export const seriesMain = gql`
  fragment _ on SeriesType {
    ${seriesBase}    
    producer {
      ${prodBase}
    }
    MainSpeakers {
      ${speakMain}
    }
  }
`;

export const prodMain = gql`
  fragment _ on ProducerType {
    ${prodBase} 
                 
  }
`;

// 1media

export const mediaComment = gql`
  fragment _ on MediaType {
    commentmediaComment{
      ${commentBase}
    } 
  }
`;

export const transPage = gql`
  fragment _ on MediaType {
    ${mediaMain}
  }
`;

// ${mediaPrime}
// 1page

// export const userPage = gql`
//   fragment _ on UserType {
//     ${userBase}
//     produceruserProducer {
//       ${prodBase}
//       SeriesProducer{
//         ${seriesPrime}
//       }
//     }
//   }
// `;

// ${mediaFull}
// ${mediaBase}

// highlightsLineMedia {
//   ${highFromMedia}
// }

// # ${seriesBase}

// # videoSpeakers {
//   #   ${speakerBase}
//   # }

export const mediaPage = gql`
  fragment _ on MediaType {
    # requestMessage
    # emailRequestArgs
    # orderNote
    ${mediaFull}
    videoSeries {
      ${seriesRequest}
    }
  }
`;

export const mediaUnclear = gql`
  fragment _ on MediaType {
    ${mediaFull}
    lineLineMedia
  }
`;

// SeriesProducers {
//   ${seriesBase}
// }
export const producerPage = gql`
  fragment _ on ProducerType {
    ${prodBase}
  }
`;

//  VideoSeries {
//      HighlightVideo {
//      ${highMain}
//      }
//    }

// connectSpeakers {
//   ${speakerBase}
// }
// connectSpeakers {
//   ${speakerBase}
// }
// ${seriesBase}
// ${seriesPrime}
// seriesAttr {
//   ${seriesSmall}
// }

// 1series
export const seriesPage = gql`
  fragment _ on SeriesType {
    ${seriesPrime}
  }
`;

export const studioRequest = gql`
  fragment _ on SeriesType {  
    ${seriesPrime}      
    producers {
      ${prodBase} 
    } 
  }
`;

//
//
//
//

//
//
//
//
// highlightsSet {
//   ${highMain}
// }
// videoSet {
//   ${mediaWithSpeaks}
// }
//

export const speakerPage = gql`
  fragment _ on SpeakerType {
    ${speakPrime}
  }
`;

// ---------------
// ---------------
// ---------------
// ---------------
// 1all 1full 1base

export const argsMain = gql`
  input Main {
    name: String
    id: ID
    id_In: [ID]
    slugURL_In: String
    saveArgs: String
  }
`;

export const commentMain = gql`
  input Main {
    media: String
    series: String
    publicTrue: Bool
    requestTrue: Bool
    text: String
  }
`;

// $media: String,
// $series: String,
// $publicTrue: Bool,
// $requestTrue: Bool,
// $text: String

// mainAttrs.argsMain;
// export const argsMedia = gql`
//   ${argsMain}
// `;

// export const argsSeries = gql`
//   ${argsMain}
// `;
