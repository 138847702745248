import EditContainer from "../containers/EditContainer";
import { downloadChaps } from "../functions/GlobalFunctions";

export default function EditPage({
  //
  match: { params: paramsSo },
}) {
  const isdjwe = {
    ...paramsSo,
  };

  return <EditContainer editTrue {...isdjwe} />;
}
