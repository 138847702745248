import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";
import SideFixObj from "../components/SideFixObj";
import HighlightRight from "./HighlightRight";

export default function VideoOver({
  //
  obj,
  sideConfig,
  ...args
}) {
  // 1scriptcontext
  const { aaaaaa } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  function RightCurr() {
    //

    const hudsfr = {
      // typevar: typevar
      ...args,
    };

    const gfijwe = <HighlightRight {...hudsfr} />;
    return gfijwe;
  }

  const uhvsd =
    //
    // 220;
    "35vw";
  // "30vw";

  const qwerwsd = {
    obj: <RightCurr />,
    bg: "transparent",
    style: {
      width: uhvsd,
      padding: "10px",
    },
  };

  const uijerwe = {
    topvar: 80,
    widthvar: uhvsd,
    // mainObj: <LeftConfo />,
    mainObj: obj,
    // sideObj: <RightCurr />,
    sideConfig: qwerwsd,
    noColorSide: true,
    // alignType: "left",
    // style: { border: "2px solid red", textAlign: "center" },
  };

  const okfdsd = (
    //
    // "asdpwewq";
    <SideFixObj {...uijerwe} />
  );

  const jdaew = {
    obj: okfdsd,
    // ...genConfig,
  };

  // 1HTML
  return <Divo {...jdaew} />;
}
