import React, {
  //
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { TiCancel, TiEdit, TiTick } from "react-icons/ti";
import { GiEdit, GiCancel } from "react-icons/gi";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  historyPush,
  ImageTextList,
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";
import ListMain from "../components/ListMain";
import HistoryFunc from "../functions/HistoryFunc";

export default function OrderObject({
  //
  obj,
  topObj,
  clickTop,
  topConfig,
  // topClick,
  belowObj,
  editLink,
  noButton,
  overObj,
  index,
  // style,
  saveTrue,
  starConfig,
  editBefore = "",
  editAfter = "",
  listOver,
  buttonConfig,
  //
  //
  playFunc,
  editFunc,
  removeFunc,
  ...args
}) {
  // 1scriptcontext
  // const { aaaaaa } = useContext(ScriptContext);

  logs.logga("___ index ___", index);

  let history = useHistory();

  // 1const
  const [type, setType] = useState("");

  function Buttias({ dictvar, ...sdwa }) {
    const iasew =
      //
      "";
    // "expandHover";
    // "buttonHover shadowHover pointer";
    // gens.butClass;
    // "buttonHover expandHover";

    const asidwq = {
      textAlign: "center",
      fontSize: "26px",
      // width: "200px",
      
      // color: "white",
      // border: "2px solid black",
      // padding: "0px 20px",
      // ...style,
    };

    const asudhqwe = {
      // popTrue
      // popTextTrue
      className: iasew,
      bediaTrue: true,
      divTrue: true,
      // confirmTrue: true,
      style: asidwq,
    };

    const asydgwe =
      //
      // "down"
      "edit";

    const xvcbfda = (
      //
      <TiEdit />
      // <GiEdit />
    );
    // "❓";
    // "❗";
    // "❌"

    const ijfs = (
      //
      // "✔️"
      <TiTick />
    );

    const okawe = saveTrue ? ijfs : xvcbfda;
    const nfkmfd = (
      //
      // okawe;
      // "Edit";
      <>{okawe} Edit</>
    );

    function isajde() {
      // addEd
      // + obj.slugURL ? obj.slugURL : "";
      editFunc(obj);
      const ijaew =
        //
        editLink;
      // editBefore + "/edit/" + editAfter;

      logs.logga("___ ijaew ___", ijaew);

      if (ijaew) {
        history.push(ijaew);
        // HistoryFunc(ijaew);
      }
    }

    const editConfo = {
      onClick: isajde,
      textvar: nfkmfd,
    };

    const playia = {
      onClick: () => playFunc(obj),
      textvar: "Play",
    };

    function removo() {
      const newList = !listOver
        ? ""
        : listOver.filter((item) => item.id !== obj.id);

      const dijfre = {
        obj: obj,
        listOver: listOver,
        newList: newList,
      };

      logs.logga("___ removo ORDEROBJECT ___", dijfre);

      removeFunc(obj, newList);
    }

    const jfdret = (
      <>
        {/* <TiCancel /> */}
        <GiCancel />
        {/* Remove */}
      </>
    );

    const cancelConfig = {
      onClick: removo,
      style: { background: "darkred" },
      textvar: jfdret,
    };

    const ijawsa = {
      // play: playCOd,
      edit: editConfo,
      remove: cancelConfig,
      play: playia,
      ...dictvar,
    };

    const asidjw = {
      // style: { marginLeft: "50px" },
      itemConfig: asudhqwe,
      dictvar: ijawsa,
      // ...buttonConfig,
      ...sdwa,
    };

    logs.logga("___ ORDER OBJECT -BUTTON ___", asidjw);

    const sohwButs =
      //
      // "";
      !noButton && (
        <ImageTextList
          //
          {...asidjw}
          horizTrue
          noSlider
        />
      );

    const ijasdwe = (
      <>
        <hr />
        {sohwButs}
        {belowObj}
      </>
    );

    return ijasdwe;
  }

  const fdgfr = !noButton || belowObj;

  const adjwewe = fdgfr && <Buttias {...buttonConfig} />;

  const ijadae = {
    width: "10px",
  };

  function StarObj() {
    const osdwe = {
      obj: 6,
      //   className: ssaweq,
      style: ijadae,
      ...starConfig,
    };

    const sgrees = <Divo spanTrue {...osdwe} />;

    const isadae = [
      //
      sgrees,
      topObj,
    ];

    const ijsade = {
      listvar: isadae,
      returnTrue: true,
      horizTrue: true,
      noSlider: true,
    };
    const sadwa = <ListMain {...ijsade} />;

    return sadwa;
  }

  function sdfgjirt(){
    
    if (clickTop){
      clickTop(obj)
    }
  }

  const sijae = {
    onClick: sdfgjirt,
    obj: topObj,
    ...topConfig,
  };

  const djcxvfs = <Divo {...sijae} />;

  const itemRifg = (
    <>
      {/* <StarObj /> */}
      {djcxvfs}
      {adjwewe}
    </>
  );

  const ijsaew =
    //
    //   expandHover
    "shadowHover pointer";

  const sadawq = {
    obj: itemRifg,
    className: ijsaew,
    ...args,
  };

  // 1HTML
  return <Divo {...sadawq} />;
}
