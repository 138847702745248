import React, {
  //
  useState,
  useContext,
} from "react";
import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  BackForwardObj,
  //
} from "../functions/GlobalFunctions";
import ImageTextDiv from "../components/ImageTextDiv";
import MediaList from "./MediaList";
import ConfirmButton from "../components/ConfirmButton";
import FormMain from "../components/FormMain";

export default function MediaAlter({
  //
  mediaObj,
  saveFunc,
  backFunc,
  noteJSON,
  topObj,
  scribeObj,
  ...asokwe
}) {
  // // 1context
  // 1scriptcontext
  // prettier-ignore
  // const { aaaaaa } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");
  // const chapList = getMediaChapters(mediaObj);

  const kxvsd = (
    <>
      The more information about your episode Scribes get,
      {/* <br /> */} the faster they work!
    </>
  );

  function submitAll(values) {
    const mergiot = {
      ...mediaObj,
      orderNote: values,
      // ...values,
    };

    logs.logga("___ mergiot ___", mergiot);

    if (saveFunc) {
      saveFunc(mergiot);
    }
  }

  function TitlMed() {
    //
    const iasew = {
      // obj:
      type: "submit",
    };

    const sodkfyt = {
      iconvar: "save",
      textvar: "Save",
    };

    const okzsd = {
      save: sodkfyt,
    };

    const isakjdw = {
      dictvar: okzsd,
      listvar: "save",
    };

    function sofdkwe(medobj) {
      const uahew =
        //
        "EDIT | " +
        // "EDIT EPISODE - " +
        //
        medobj.name;

      const ijkaew = {
        obj: uahew,
        swapItems: true,
        noIcon: true,
        divTrue: true,
        // iconvar: "edit",
        className: "bold wrapTrue",
      };

      const blnmfd = (
        //
        <Divo {...ijkaew} />
      );

      return blnmfd;
    }

    const auhdw = {
      obj: kxvsd,
      style: { padding: "0 3%" },
    };

    const ijawe = <Divo {...auhdw} />;

    const okasew = {
      obj: mediaObj,
      bottomObj: ijawe,
      nameFunc: sofdkwe,
      linkBase: true,
      // noClass: true,
      thumbnailSize: 120,
      // rightIconConfig: isakjdw,
      // floatConfig: { rightObj: ijawe },
    };

    const asidjwe = {
      iconvar: "",
      centerObj: <MediaList {...okasew} />,
      //   rightObj: ijawe,
      //   style: {
      //   }
    };

    const iakew = {
      style: {
        fontSize: "30px",
      },
    };

    const okawew = {
      // forwardFunc: () => submitAll(),
      // backFunc: () => submitAll(),
      hideForward: true,
      buttonConfig: iakew,
      obj: <MediaList {...okasew} />,
    };

    const ijkasdsa = (
      <>
        {/*  */}
        <MediaList {...okasew} />

        {/* <BackForwardObj {...okawew} /> */}
        {/* <MediaList {...okasew} /> */}
        {/* <FloatMainAll {...asidjwe} /> */}
      </>
    );

    const xlvcombe = {
      obj: ijkasdsa,
      style: { marginBottom: "30px" },
    };

    return <Divo {...xlvcombe} />;
  }

  function Formo() {
    //
    //   1people
    const iajswe = (
      //
      <>
        Write down the names of everyone in the episode.
        <br />
        Please take a new line for each person.
        {/* <br /> */}
        {/* If you're only speaker, leave this blank. We'll figure it out 😉. */}
      </>
    );

    const ijaesd =
      //
      "";
    // "John Smith (JS): Interview \n asd \n sadasd\n AOEKWE";

    const poplConf = {
      tabTitle: "People",
      titlevar: "Who's in this episode?",
      subtitlevar: iajswe,
      typevar: "textarea",
      textvar: ijaesd,
      iconvar: "users",
      name: "peopleJSON",
      style: {
        height: "120px",
        textAlign: "center",
      },
    };

    const uaje2qwe = <>What chapters should we timestamp?</>;

    const okad = {
      linkConfig: {
        linkvar: "https://www.youtube.com/watch?v=HZTStHzWRxM",
        outsideTrue: true,
      },
      className: "bold",
      obj: "(You can find out more about timestamped chapters here.)",
    };

    const ayhsdwae = (
      <>
        {/* If you don't have them already,  */}
        {/* Please  */}
        Write down all the sections you would like to be chapters in your
        Youtube description and their approximate start and end times.
        {/* <br />
        We'll add each one to the transcript as a head */}
        <br />
        <Divo {...okad} />
        {/*
         */}
      </>
    );

    // CHAPTER
    const chplConf = {
      tabTitle: "Highlights",
      titlevar: uaje2qwe,
      subtitlevar: ayhsdwae,
      typevar: "textarea",
      textvar: ijaesd,
      iconvar: "users",
      name: "chapterJSON",
      style: {
        height: "120px",
        textAlign: "center",
      },
    };

    // TRANSCRIPT
    const sdfjire =
      //
      "Do you already have a transcript?";

    const uheidfw = (
      //
      <>
        If so, please copy and paste it here and our Scribes can use it as a
        draft rather than starting from scratch.
        {/*  */}
      </>
    );

    const trnsConf = {
      tabTitle: "✍️ Draft",
      titlevar: sdfjire,
      subtitlevar: uheidfw,
      typevar: "textarea",
      name: "userTranscript",
    };

    //
    //
    // DESCRITPTION
    const dscTetitl =
      //
      "What's this episode about?";

    const descSubbo = (
      //
      <>
        {/*  */}
        Please add any information which could help our Scribes.
        {/* The more they know, the faster they work! */}
        {/*  */}
        <br />
        (You don't need to add Youtube descriptions.)
      </>
    );

    function ChapInput() {
      const ijaew = (
        <>
          {/*  */}
          Make a new chapter
        </>
      );

      return ijaew;
    }

    const chaptEvor = {
      textvar: <ChapInput />,
      // titlevar: dscTetitl,
      // subtitlevar: descSubbo,
      typevar: "checkbox",
      name: "chapterPerMinute",
      // obj: sadokwe,
    };

    const desConf = {
      tabTitle: "🛈 General",
      titlevar: dscTetitl,
      subtitlevar: descSubbo,
      typevar: "textarea",
      name: "userDescription",
    };

    const ijawesasd = {
      people: poplConf,
      transcript: trnsConf,
      description: desConf,
      chapter: chplConf,
      chapterEvery: chaptEvor,
    };

    // 1listvar
    const kaew = [
      //
      "description",
      // "chapter",
      "people",
      "transcript",
    ];

    const butoasndi = {
      // height: "60px",
      // fontSize: "40px",
      onlyText: "Save",
    };

    const sdokaas = {
      style: {
        height: "90px",
      },
    };

    const okdae = {
      // textAlign:
    };

    function BackGoFor() {
      const iakew = {
        className: gens.butClass,
        style: {
          fontSize: "30px",
          // position: "fixed",
          // top: 80,
        },
      };

      const okzs = {
        //
        type: "submit",
        obj: "Save",
      };

      logs.logga("___altermedia mediaObj ___", mediaObj);

      const asudhwe =
        //
        mediaObj.mediaFileLink;
      // "sdofkraawe";

      const okdfsa =
        //
        // "📺 Youtube Link"
        "Link";
      // "📺 Link ";

      const XCVBDAF = {
        genConfig: {
          linkConfig: {
            outsideTrue: true,
            linkvar: asudhwe,
          },
        },
        // disableTrue: true,
        style: {
          height: "60px",
          background: "red",
        },
        obj: okdfsa,
        onClick: "",
        noSubmit: true,
      };

      logs.logga("___ XCVBDAF ___", XCVBDAF);

      const oklawe = (
        //
        // !gens.mobileView &&
        <ConfirmButton {...XCVBDAF} />
      );

      const iawke = {
        // hideForward: true,
        buttonConfig: iakew,
        // betweenObj: kxvsd,
        // betweenObj: oklawe,
        // obj: ijawe,
        forwardConfig: okzs,
        // betweenObj: "--EDIT EPISODE--",
        textTrue: true,
        genConfig: {
          style: {
            height: "100px",
          },
        },
      };

      const fhjtyuet = (
        //
        // <MediaAlter {...ijase} />
        <BackForwardObj {...iawke} />
      );

      return fhjtyuet;
    }

    const oksaw = (
      <>
        <TitlMed />
        {/* {aushwe} */}
        {/* <BackGoFor /> */}
        {/* {topObj} */}
      </>
    );
    const gflhkd = {
      textAlign: "center",
    };

    const asokew = {
      styleTab: {
        //
        fontSize: "30px",
        margin: "10px",
        // height: "20px",
      },
    };

    const ijasdwe = {
      // chapter: [
      //   //
      //   "chapterEvery",
      //   "chapter",
      // ],
    };

    const kaedfgokew = {
      dictvar: ijawesasd,
      listvar: kaew,
      onSubmit: submitAll,
      buttonConfig: butoasndi,
      inputConfig: sdokaas,
      overObj: noteJSON,
      listDict: ijasdwe,
      titleConfig: gflhkd,
      // subtitleConfig:
      topObj: oksaw,
      //
      // topButtonTrue: true,
      tabConfig: asokew,
      // tabConfig: asokew,
      //
      ...asokwe,
    };

    const zxkofwe = <FormMain {...kaedfgokew} />;
    return zxkofwe;
  }

  const ikaew = (
    <>
      {/*  */}
      <Formo />
    </>
  );

  const ijase = {
    obj: ikaew,
    // ...genC,
  };

  return <Divo {...ijase} />;
}
