import * as logs from "../consts/logFuncs";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Center,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
} from "@chakra-ui/react";

import {
  SiFacebook,
  SiGoogle,
  SiLinkedin,
  SiMessenger,
  SiTwitter,
} from "react-icons/si";
import FormMain from "../components/FormMain";
import ListMain from "../components/ListMain";
import { fireConnect } from "../functions/firebaseFuncs";

export default function SignBase({
  //
  ...sdse
}) {
  const fdsogkret = {
    google: {
      textvar: "Google",
      iconvar: <SiGoogle />,
      typevar: "google",
    },
    facebook: {
      textvar: "Facebook",
      iconvar: <SiFacebook />,
      typevar: "facebook",
    },
    twitter: {
      textvar: "Twitter",
      iconvar: <SiTwitter />,
      typevar: "twitter",
    },
    //
  };

  function Bsaeosa({ typevar, iconvar, textvar, ...swe }) {
    function osadew() {
      fireConnect(typevar);
    }

    const dfid = {
      // fontSize: "20px",
      w: "full",
      onClick: osadew,
      leftIcon: iconvar,
      ...swe,
      // color: "black",
      // background: "white",
      // colorScheme: "white",
    };

    const ijdsf = (
      <>
        <Button {...dfid}>
          <Center>
            <Text>
              {/* {iconvar} */}
              {/* Continue with */}
              {textvar}
            </Text>
          </Center>
        </Button>
        {/* <hr /> */}
      </>
    );

    return ijdsf;
  }

  const igfder = {
    listvar: [
      //
      "google",
      "twitter",
      "facebook",
    ],
    dictvar: fdsogkret,
    obj: Bsaeosa,
  };

  function spfdewr({ email, password }) {
    //

    logs.logga("___ email ___", email);
    logs.logga("___ password ___", password);
  }

  function sofer(dfodsr) {
    logs.logga("___ dfodsr ___", dfodsr);
  }

  const dsfijd = <ListMain {...igfder} />;

  const dfijd = [
    //
    "email",
    "password",
  ];

  const dfgre = {
    listvar: dfijd,
    // dictvar:
    buttonText: "Register",
    buttonSize: "15px",
    inputConfig: { style: { fontSize: "20px" } },
    style: { margin: "30px" },
    onSubmit:
      //
      sofer,
    // spfdewr,
    // noButton: true,
    ...sdse,
  };

  const forso = (
    <>
      Continue with social
      <FormMain {...dfgre} />;
    </>
  );

  const endsoiawe = (
    <>
      <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Sign in to your account</Heading>
            <Text fontSize={"lg"} color={"gray.600"}>
              to enjoy all of our cool <Link color={"blue.400"}>features</Link>{" "}
              ✌️
            </Text>
          </Stack>
          <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              {forso}
              {dsfijd}
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );

  const jdtre = (
    <>
      {dsfijd}
      {/* {forso} */}
    </>
  );

  return jdtre;
}

// <Stack spacing={10}>
// <Stack
//   direction={{ base: "column", sm: "row" }}
//   align={"start"}
//   justify={"space-between"}
// >
//   <Checkbox>Remember me</Checkbox>
//   <Link color={"blue.400"}>Forgot password?</Link>
// </Stack>
// <Button
//   bg={"blue.400"}
//   color={"white"}
//   _hover={{
//     bg: "blue.500",
//   }}
// >
//   Sign in
// </Button>
// </Stack>
