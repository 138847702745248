import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import BasePriceMod from "./BasePriceMod";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  CurrencyMain,
  openCrisp,
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";
import ListMain from "../components/ListMain";
import { useHistory } from "react-router-dom";

export default function PriceCards({
  onChange,
  perMinute,
  totalTime,
  timevar,
  obj,
  titlevar,
  genConfig,
  noClick,
  ...asd
}) {
  //
  function changeOrdTYp({ cost, typevar, ...asdoe }) {
    logs.logga("___ changeOrdTYp ___", typevar);

    setpriceType(typevar);
    changePerMin(cost);
    //
  }

  const {
    orderDetails,
    orderCountry,
    changePerMin,
    hourDuration,
    perMinTimes,
    orderLength,
    seriesOver,
    priceType,
    setpriceType,
  } = useContext(ScriptContext);

  let history = useHistory();

  //   timevar
  //   const asdwe = {
  //       week:
  //       month: 4
  //   }

  logs.logga("___ hourDuration ___", hourDuration);

  totalTime =
    //
    // 100;
    totalTime ? totalTime : hourDuration;

  function sadfojewr(numlow, numhigh, reqqo) {
    function asodw() {
      const ifdjeqwe = numhigh ? totalTime < numhigh : true;
      const nsije = totalTime >= numlow && ifdjeqwe;
      return nsije;
    }

    const reuos = !totalTime ? reqqo : asodw();
    return reuos;
  }

  const recFinno =
    //
    function doCHange(typd) {
      const perMDuct = {
        basic: 0.1,
        curated: 1,
      };
      const ifdjgero = perMDuct[typd];

      logs.logga("___ ifdjgero ___", ifdjgero);

      // onChange(ifdjgero)
    };

  const basYes = [
    //
    "Transcripts",
    "Subtitles",
    // ""
  ];

  const isjdsr = {
    typevar: "basic",
    titlevar: "💬 Basic",
    referTrue: sadfojewr(0.5, 4),
    cost: 0.5,
    baseType: "AI Speech-to-text",
    textvar: ["An AI-produced transcript", "with sentence timestamps."],
    accuracy: 80,
    timeSave: totalTime * 3 + 3,
    includeYes: [
      //
      "80% accuracy",
      "Transcripts",
      "Subtitles",
    ],
    includeNo: [
      //
    ],
  };

  const sdiwase = [
    //
    "100% accuracy",
    "Basic features",
    "Youtube integration",
    "Speaker Markings",
    "Chapters",
  ];

  const basiew =
    //
    // perMinute
    1;

  const plusCurTime = totalTime * 5 + 8;

  const curioso = {
    cost: basiew,
    referTrue: sadfojewr(4, 10, true),
    typevar: "curated",
    titlevar: "✍️ Curated",
    baseType: "Proofread by Scribes",
    textvar: [
      //
      "Proofread by our Scribes,",
      "who mark out sections as they go.",
    ],
    accuracy: 100,
    timeSave: plusCurTime,
    // accuracy: "97-98",
    includeYes: sdiwase,
    includeNo: [
      //
    ],
  };

  function djsifgoret() {
    setpriceType("curated");
    openCrisp("");
  }

  const fnfdk =
    //
    1;
  // perMinTimes
  // 1.5;

  const assissowa = {
    cost: fnfdk,
    referTrue: sadfojewr(10),
    scheduleType: "month",
    cost: fnfdk,
    plusCost: 50,
    typevar: "assistant",
    titlevar: "🙋 Assistant",
    // baseType: "Everything covered",
    textvar: ["A dedicated Scribe to", "manage all your content."],
    timeSave: plusCurTime + 4,
    chooseFunc: djsifgoret,
    accuracy: 100,
    includeYes: [
      //
      "Curated features",
      "Website integration",
      // "Youtube integration",
      "Highlight videos",
      // "Chat/video calls",
      "Unlimited revisions",
    ],
    includeNo: [
      //
    ],
  };

  const sdfae = {
    basic: isjdsr,
    curated: curioso,
    assistant: assissowa,
  };

  const chkDct = [
    //
    // sdfae["basic"],
    // sdfae["curate"],
    "basic",
    "curated",
    "assistant",
  ];

  function ifdjsrsR() {
    return "asdwe";
  }

  function asjdwe({ ...sae }) {
    const sdokwe = {
      totalTime: totalTime,
      ...sae,
    };

    logs.logga("___ sae ___", sae);
    logs.logga("BasePriceMod-zzz", sdokwe);

    const ijsdsa = (
      //
      // "asdojkwoe"
      <BasePriceMod noAccuracy {...sdokwe} />
    );

    return ijsdsa;
  }

  const ijsd =
    //
    priceType;
  // "basic";

  const dfijer = {
    totalTime: totalTime,
  };

  const sdjfew = {
    // titlevar: "Order Type",
    listvar: chkDct,
    dictvar: sdfae,
    addDict: dfijer,
    chosenItem: ijsd,
    // checkItems: chkDct,
    // onChange: doCHange,
    horizTrue: true,
    onClick: !noClick && changeOrdTYp,
    obj: obj ? obj : asjdwe,
    // obj: ifdjsrsR,

    ...asd,
  };

  const zxcmsd = (
    //
    <ListMain {...sdjfew} />
  );
  // <DropDownMain {...sdjfew} />

  // 1console
  const kmxvs = {
    obj: zxcmsd,
    ...genConfig,
  };

  logs.logga("___ PRICE CARDS dfijer ___", dfijer);

  return <Divo {...kmxvs} />;
}
