import React, {
  lazy,
  Suspense,
  useState,
  useReducer,
  Fragment,
  createRef,
  useContext,
  useRef,
  Component,
  createContext,
} from "react";
import OrderStep from "./OrderStep";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import * as gens from "../consts/GenStyled";
import SideFixObj from "../components/SideFixObj";
import TextFindMedia from "./TextFindMedia";
import OrderOptions from "./OrderOptions";
import OrderDetails from "./OrderDetails";
import ScribeMessage from "./ScribeMessage";
import ListMain from "../components/ListMain";
import OrderRight from "./OrderRight";
import OrderList from "./OrderList";
import MediaAlter from "./MediaAlter";
import {
  filterDuplicates,
  mapArrAttr,
  Sersyt,
  AddMedTypes,
  AddSeriesOpen,
  PageTitle,
  openCrisp,
  checkEmptyArray,
  MainPreview,
  setLocal,
  setParamVar,
  getLocal,
  turnarray,
  mapDictDict,
  mergeArrays,
  checkParamVar,
  ScribeRate,
  filesToMedia,
  matchModelArr,
  durationAtts,
  EmailMessage,
  WrapQuotes,
  getParamVar,
  GraphPage,
  GraphArgo,
  checkFullArray,
  getFirstArr,
  GraphAttr,
  padhorz,
  segReport,
  changePage,
  getSHortSet,
} from "../functions/GlobalFunctions";
import MediaRequest from "./MediaRequest";
import { useHistory } from "react-router-dom";

import ConfirmButton from "../components/ConfirmButton";
import RequestIntro from "./RequestIntro";
// import FinalOrderPage from "./FinalOrderPhage";
import { ScriptContext } from "../contexts/ScriptContext";
import { GlobalFooter } from "../components/GlobalFooter";

export default function OrderContainery({
  mainImage,
  orderObj,
  clientExists,
  dealTrue,
  helmetTrue,
  checkoutTrue,
  seriesSlug,
  paraLinko,
  dataImport,
  loadtrue,
  // loadtrue,
  pageType,
  checkoutConfig,
  // ...checkoutConfig
}) {
  //
  const {
    requestSeries,
    orderStepChange,
    currentOrder,
    typeProcess,
    perMinTimes,
    eitherOrdReq,
    orderDetails,
    alterMeddAdd,
    setorderDetails,
    currentScribe,
    currBasketMedia,
    orderConfig,
    setBasket,
    seriesOver,
    setTypeProcess,
    mediaLinkLoad,
    setmediaLinkLoad,
    currMedia,
  } = useContext(ScriptContext);

  const plsda = {
    generalComment: "",
    optionsMedia: {},
  };

  const fdtresad = {};
  const jsdwae = seriesOver && <ScribeMessage {...fdtresad} />;

  const [morechooseTrue, setmorechooseTrue] = useState();
  const [messageClose, setmessageClose] = useState();
  // 1para
  // 1private
  const ygewq = checkParamVar("prv");
  const publicorpriv =
    //
    // "private";
    // "public"
    ygewq ? "private" : "public";

  const noreqqo = getLocal(gens.storeNoRequestBool);
  const [noRequest, setnoRequest] = useState(noreqqo);

  const sfdgrewt = getLocal("skipRequest");
  const [requestSaw, setrequestSaw] = useState(sfdgrewt);

  const cvbkmfd = {
    noreqqo: noreqqo,
    noRequest: noRequest,
  };

  logs.logga("___ NO-REQUEST- SET ___", cvbkmfd);

  const rekkio =
    //
    // "";
    getParamVar("rq");

  const kcvmdsf = getLocal(gens.storeRequestString);

  const requestHigh = rekkio ? rekkio : kcvmdsf;

  logs.logga("___ rekkio ___", rekkio);
  logs.logga("___ kcvmdsf ___", kcvmdsf);
  logs.logga("___ requestHigh ___", requestHigh);

  const { dataImport: graphRequest, loadtrue: reqLoado } = GraphPage(
    "media",
    requestHigh
  );

  logs.logga("___ graphRequest ___", graphRequest);
  logs.logga("___ requestHigh ___", requestHigh);

  const mediaHigh = getParamVar("md");
  const corporat = getParamVar("crp");

  const scribeTrue =
    //
    // true;
    getParamVar("scrb");

  const scribeObj =
    //
    gens.ScribeAndy;
  // scribeTrue && gens.ScribeAndy;

  // 1const START

  // 1tabs
  const ijaew =
    //
    "link";
  const [shoTab, setshoTab] = useState(ijaew);

  const [baseErr, setbaseErr] = useState();
  const errorTrue =
    //
    baseErr;
  // true;
  // false

  // 1style 1height

  const heightMidBox =
    //
    //
    "175px";
  // "275px";
  // gens.mobileView("320px", "280px")

  const heightTabso =
    //
    "150px";
  // "30vh";
  // "45vh";
  // "55vh";
  // "460px";

  const heigWaws = 300;

  const mainBoxo = {
    minHeight: heightTabso,
    // border: "2px solid orange",
  };

  // https:www.youtube.com/watch?v=TYcLIyrGyzY
  // https:www.youtube.com/watch?v=TYcLIyrGyzY
  // https:www.youtube.com/watch?v=zh8iGntPpjU

  // https:www.youtube.com/watch?v=HE3Grhn1SrI&t=233s
  // https:www.youtube.com/watch?v=TYcLIyrGyzY
  // https:www.youtube.com/watch?v=_L3gNaAVjQ4

  //
  //
  // // 1links 1medialink
  // listLinks = gens.orderMeta;

  //
  const uaswee = [
    // "https://www.youtube.com/watch?v=TYcLIyrGyzY",
    // "https://www.youtube.com/watch?v=zh8iGntPpjU",
    // "https://www.youtube.com/watch?v=HE3Grhn1SrI&t=233s",
    // "https://www.youtube.com/watch?v=TYcLIyrGyzY",
    // "https://www.youtube.com/watch?v=_L3gNaAVjQ4",
  ];

  const medSello =
    //
    uaswee;
  // paraLinko
  // getParamArr(gens.mediaIDLinkString);

  // 1media

  const asudhwq = "https://www.youtube.com/watch?v=";

  const ytExit = getParamVar("YT");
  const uhsadqe = !ytExit ? [] : turnarray(asudhwq + ytExit);

  const [baseText, setbaseText] = useState();
  const [rdLiso, setordList] = useState(medSello);
  const listLinks =
    //
    // uaswee;
    rdLiso;

  logs.logga("___ listLinks ___", listLinks);

  const [confirmConnect, setConfirmConnect] = useState();
  const [userSearched, setuserSearched] = useState();

  const grapfetchMed =
    //
    !noRequest && graphRequest;

  logs.logga("___ grapfetchMed ___", grapfetchMed);

  // const osdfre = getBaseInfo(gfohkfd);
  // const okassdfer = mergeArrays(uashdwqe, grapfetchMed);
  // const okassdfer = mergeArrays(uashdwqe, grapfetchMed);
  // const dogkere = matchModelArr(currBasketMedia, okassdfer);
  const FINALMED =
    //
    currBasketMedia;
  // filterDuplicates(dogkere, "id");
  // removeArrayArray(dogkere, removeArro);
  // const newList = FINALMED.filter((item) => item.id !== ofkad.id);

  // 1media 1arrGrapLink
  const arrGrapLink =
    // "";
    // currBasketMedia;
    // osdfre;
    gens.FINALTRUE ? gens.testMediarr : FINALMED;
  // uashdwqe;
  // gens.testMediarr;
  // asdjw3 ? asdjw3 :
  // arrGrapData;

  const proceFind =
    //
    typeProcess;
  // !fullVid ? "add" : typeProcess;
  const findMedTrue = proceFind == "media";

  function ovkdsf() {
    logs.logga("asdwewqeqw ___ requestSeries ___", requestSeries);
    logs.logga("___ setrequestSaw ___", "");

    setrequestSaw(true);
    setLocal("skipRequest", "y");
  }

  // 1useeffect
  // window.setTimeout(localBases, 100);

  const noMedia = !arrGrapLink;
  const ordFields = orderDetails;

  // 1minorder
  const minordertime =
    //
    50;

  const okaswe = {
    ...orderConfig,
    orderObj: orderObj,
    mediaFound: confirmConnect && arrGrapLink,
    mediaList: arrGrapLink,
    seriesObj: seriesOver,
    proceFind: proceFind,
    orderMin: minordertime,
  };

  const { overHour, discountVar } = durationAtts(okaswe);

  const shwoitMedia =
    //
    checkFullArray(arrGrapLink);

  // 1price
  const priceitos = {
    ...okaswe,
  };

  logs.logga("___ priceitos ___", priceitos);

  const sdfer = ordFields;
  const fndSeasad = findMedTrue && userSearched;

  const requestBase = {
    typeProcess: proceFind,
    requestMedia: graphRequest,
    requestSeries: seriesOver,
    currentScribe: scribeObj,
    scribeObj: scribeObj,
  };

  // 1base
  const baseArgs = {
    ...okaswe,
    ...requestBase,
    searchMediaFind: fndSeasad,
    userSearched: userSearched,
  };

  //
  // function scribeFind()
  // {scribeFind("our Scribes")}

  const sideshower =
    //
    // false;
    // true;
    !gens.mobileView;

  // 1const

  // 1alter

  function sadjwe(obj) {
    //
    const auyweqwe = {
      //
      textAlign: "center",
      padding: "0 10%",
      // border: "2px solid green",
    };
    const aewedwe = {
      style: auyweqwe,
      obj: obj,
      // obj: <AltMeddo />,
    };

    // const aret0o = aygdwe ? <Divo {...aewedwe} /> : obj;

    // const ghtre = {
    //   obj: aret0o,
    //   fadeTrue: true,
    // };

    // const ijsaedf = (
    //   //
    //   <Divo {...ghtre} />
    // );

    return obj;
  }

  function RightoHead() {
    const sdfawer = {
      onClick: orderStepChange,
      ...baseArgs,
    };

    // const ijsdfd = {
    //   //
    //   minHeight: heightos
    // };

    const yaew = {
      showBottom: true,
      // showBottom: sideshower,
      buttonConfig: sdfawer,
      ...baseArgs,
    };

    logs.logga("___OrderRight -- baseArgs ___", baseArgs);

    const ioasew = (
      <>
        {/* asds */}
        <OrderRight {...yaew} />
      </>
    );

    const pcokbd = {
      // style: { border: "2px solid purple" },
      obj: ioasew,
    };

    return <Divo {...pcokbd} />;
  }

  // findmedia medilist orderlist
  function MediKist({ ...saok }) {
    //
    //

    const qweuyqw = {
      fontSize: "40px",
      // colo
    };

    const adios =
      //
      // true;
      morechooseTrue;

    const isjew0 = {
      ...baseArgs,
      style: qweuyqw,
      className: "",
      noAddMore: adios,
      addFunc: () => setmorechooseTrue(true),
      // saveMediaFunc:
      // topConfig
      noTitle: true,
      ...saok,
    };

    logs.logga("___ isjew0 ___", isjew0);

    const ogfhmrt = (
      <>
        {/*  */}
        <OrderList {...isjew0} />
      </>
    );

    const ijsadawe = {
      obj: ogfhmrt,
      // ...asdhwqe,
    };

    const iasda = <Divo {...ijsadawe} />;

    return iasda;
  }

  function MeddorFound({ noneObj = "", ...saok }) {
    logs.logga("___ arrGrapLink ___", arrGrapLink);
    const showOrders = userSearched || requestHigh;

    const ijae =
      //
      checkFullArray(arrGrapLink);
    // && showOrders;

    const okawesa =
      //
      ijae ? <MediKist {...saok} /> : noneObj;

    return okawesa;
  }

  function HourDiss() {
    //
    const aisjdwqe = (
      <>
        <Divo
          spanTrue
          className="bold"
          obj="You've unclocked the Hour Discount!"
        />
        Bedia's per-minute rate goes down by 5 cents for every hour.
      </>
    );

    const vijsddfwe = {
      obj: aisjdwqe,
    };

    return <Divo {...vijsddfwe} />;
  }

  const hourObjFo =
    //
    "";
  // overHour && <HourDiss />;

  function UplBut() {
    //

    const isadwqe = {
      //
    };
    const iajsdw = "<UploadButton {...isadwqe} />;";

    return iajsdw;
  }

  // 1text findtext textfind
  function TextEnter() {
    //
    const ijasd = confirmConnect && arrGrapLink;
    const classbutso = ijasd ? "" : "disabled";
    const iconso = ijasd ? "tick" : "clock";
    const buttFalse = ijasd ? "green" : "red";
    const baskStl = {
      // ...backgroundMain(buttFalse),
      color: "white",
    };

    const bkfdigsd = {
      style: baskStl,
      iconvar: iconso,
      className: classbutso,
      textvar: "Checkout",
      // obj: <BelowFindo />,
      // oncClick: () => setPriceShow(true),
      // iconConfig: {
      //   numVar: lenOrdero,
      //   // numVar: 0,
      //   showZero: true,
      // },
      // textvar: "Orders",
    };

    function sdewqe(medOrdios) {
      logs.logga("___ ordercontainer - tefindmedia ___", medOrdios);
      setBasket(medOrdios);
      setmorechooseTrue(false);
    }

    const fdseeq = {
      initialValue: baseText,
      onSubmit: sdewqe,
      longTrue: noMedia,
      genConfig: {
        style: {
          ...mainBoxo,
          // background: "green",
          // border: "2px solid red",
        },
      },
    };

    const asduwe = (
      <>
        {/*  */}
        <TextFindMedia {...fdseeq} />
      </>
    );

    return asduwe;
  }

  function sdofksre() {
    segReport("Add Links Button clicked");
    setmorechooseTrue(true);
  }

  function AdddMore() {
    const uiasjdw = {
      onClick: sdofksre,
      ...okaswe,
    };

    const asokwe = (
      <>
        <AddSeriesOpen {...uiasjdw} />
      </>
    );

    return asokwe;
  }

  function FoundFile() {
    //
    //

    const locxbkvs = (
      //
      <>
        Please add any details about each one that could help our Scribes below.
      </>
    );

    const lgbkdf = {
      obj: locxbkvs,
      style: {
        fontSize: "0.8em",
      },
    };

    const xvkbmdff = {
      className: "bold",
      obj: "We found your episodes!",
    };

    const topMess = (
      // //
      <>
        <Divo {...xvkbmdff} />
        {/* <br /> */}
        {/* <br /> */}
        {/* <br /> */}
        {/* <br /> */}
        {/* <hr /> */}
        <Divo {...lgbkdf} />
        {/* // ""; */}
        {/* // Hmmm, looks like we couldn't find informatio for some links. */}
      </>
    );

    const xvkbmdfsg = {
      textAlign: "left",
    };

    const xcvbmdf = {
      obj: topMess,
      style: xvkbmdfsg,
    };

    const ijadwe =
      //
      "";
    // <HourDiss />

    const iajsdwe = (
      <>
        <Divo {...xcvbmdf} />
        {/* {ijadwe} */}
        <hr />
      </>
    );

    const qeqw = {
      fontSize: "24px",
      padding: "0 10%",
      // ...mainBoxo
    };

    const iajswqe = {
      obj: iajsdwe,
      style: qeqw,
    };

    //
    return <Divo {...iajswqe} />;
  }

  // 1tab
  function TypeTab() {
    //
    // <TextEnter />
    // <UplBut />

    const TbDict = {
      link: <TextEnter />,
      file: <UplBut />,
    };

    function hirj93w(tyepos) {
      setshoTab(TbDict[tyepos]);
    }

    const iujsaewe = {
      onClick: hirj93w,
    };
    const uhxcvxfs = <AddMedTypes {...iujsaewe} />;

    const scurrTab = shoTab && TbDict[shoTab]["objo"];
    const qaqwea = {
      obj: scurrTab,
      style: { height: "" },
    };
    const tabsod = <Divo {...qaqwea} />;

    logs.logga("___ scurrTab ___", scurrTab);

    // const asidkjwe = (
    //   <>
    //     {/* {uhxcvxfs} */}
    //     <TextEnter />
    //     {/* asdwep */}
    //     {/* {tabsod} */}
    //   </>
    // );

    const aueww =
      //
      morechooseTrue || checkEmptyArray(arrGrapLink);
    // !morechooseTrue && shwoitMedia;

    const showMore =
      //
      // shwoitMedia
      aueww;
    // true;
    // !confirmConnect && aueww;

    const fdlmbdfg = {
      morechooseTrue: morechooseTrue,
      confirmConnect: confirmConnect,
      showMore: showMore,
      shwoitMedia: shwoitMedia,
    };

    logs.logga("___ fdlmbdfg ___", fdlmbdfg);

    // <FoundFile />
    const ijswe =
      //
      // "";
      showMore && <TextEnter />;

    const ijwewq = {
      // 1height
      // height: heightTabso,
      obj: ijswe,
    };

    const sidkwe = (
      //
      // "adsf9jwerw";
      <Divo {...ijwewq} />
    );

    //
    return sidkwe;
  }

  // function ShowRequest() {}

  // function

  // inbetween
  function InBwtweens() {
    const loadItObj =
      //
      // true;
      // false;
      // arrGrapLoad;
      mediaLinkLoad;
    // confirmConnect && arrGrapLoad;

    logs.logga("___ loadItObj ___", loadItObj);

    const ijasdw = {
      confirmConnect: confirmConnect,
      arrGrapLink: arrGrapLink,
      shwoitMedia: shwoitMedia,
      loadItObj: loadItObj,
    };

    logs.logga("___ ijasdw ___", ijasdw);

    // const orderShow = orderObj

    const showFindy =
      //
      true;
    // !orderObj;

    const foundor = showFindy && (
      <>
        <TypeTab />
        {jsdwae}
        {/* <UplBut /> */}
        {/* <TextEnter /> */}
      </>
    );

    const kdmvdsa =
      //
      // shwoitMedia
      // true;
      // mediaHigh;
      // scribeTrue && mediaHigh
      !confirmConnect && checkFullArray(graphRequest);

    const cvobnbf =
      //
      graphRequest;
    // || requestHigh;

    const shwScribos =
      //
      // false
      (kdmvdsa || cvobnbf) &&
      !userSearched &&
      !morechooseTrue &&
      checkFullArray(arrGrapLink);

    logs.logga("___ kdmvdsa ___", kdmvdsa);
    logs.logga("___ cvobnbf ___", cvobnbf);
    logs.logga("___ shwScribos ___", shwScribos);

    const iasdaw =
      //
      // foundor;
      // abouit;
      // morechooseTrue && foundor
      shwScribos ? "" : foundor;

    const xcvaswa = {
      obj: iasdaw,
    };

    const asuhwe = {
      style: {
        fontSize: "14px",
      },
    };

    const zxcvasx = {
      // noneObj: <QuoteFromGuy {...asuhwe} />,
    };

    const we8hasd = (
      <>
        {/* zfgojwer9 */}
        {/* {foundor} */}
        <Divo fadeTrue {...xcvaswa} />
        <MeddorFound {...zxcvasx} />
      </>
    );

    const uqweqqwe =
      //
      // "";
      // "asdokjaweqw";
      loadItObj ? " <LoadMed /> " : we8hasd;

    const kdzfasda = {
      // border: "2px solid brown",
    };

    const kcmvsd = {
      obj: uqweqqwe,
      style: kdzfasda,
    };

    const oksdfds = (
      <>
        {/* asdfokarwep */}
        <Divo {...kcmvsd} />
      </>
    );

    return oksdfds;
  }

  // typevar,
  function sidjewqwe({ name, email, typevar, ...arsd }) {
    //
    //

    const qehas = {
      ...arsd,
      ...ordFields["noteJSON"],
    };

    const iksadw = {
      name: name,
      email: email,
      noteJSON: qehas,
    };

    setorderDetails(iksadw);

    switch (typevar) {
      // case "media":
      //   ikeqwe =
      //     //
      //     // "details";
      //     "options";
      //   break;
      case "options":
        orderStepChange(typevar);
        break;
      case "details":
        orderStepChange(typevar);
        break;
    }
  }

  const isajwe = {
    style: {
      fontSize: "20px",
      // padding: "0 15%",
    },
  };

  const sdfime = {
    height: "60px",
    fontSize: "35px",
  };

  const iajsdwe = {
    marginBottom: "30px",
  };

  const ijqwe = {
    style: iajsdwe,
  };

  const sdfh9qwe = {
    ...baseArgs,
    inputConfig: ijqwe,
    // ...ijqwe,
    genConfig: isajwe,
    onSubmit: sidjewqwe,
    buttonConfig: sdfime,
    // noForm: true,
    // noButton: true,
  };

  function FinaCheck() {
    const vfobmgfo0rt = (
      <>
        {/*  */}
        {/* <ScribeRate matchTrue bottomTrue /> */}
      </>
    );

    const ascnaworw = (
      <>
        asdokwe
        {/*  */}
        {/* Hi {seriesOver?.name}! I'm a Verified Scribe at Bedia;{" "}
        {seriesOver?.requestMessage} */}
      </>
    );

    const okasd = {
      obj: ascnaworw,
      // style: {
      //   paddingTop: "20px",
      //   fontSize: "0.8em",
      // },
      // className: "italics",
      // ...personConfig,
    };
    const blcnf = <WrapQuotes {...okasd} />;

    const cvlbkds = {
      // margin: "5px 40px",
      // maxWidth: "300px",
      // padding: "5px 40",
      // border:""
      marginBottom: "30px",
      textAlign: "center",
      fontSize: "24px",
    };

    const cvxvsdf = [
      //
      vfobmgfo0rt,
      // blcnf
    ];
    const fgokdff = {
      listvar: cvxvsdf,
      returnTrue: true,
      horizTrue: !gens.mobileView,
    };

    const ijawe = (
      //
      // ijawe
      <ListMain {...fgokdff} />
    );

    const auhsdwe = {
      obj: ijawe,
      style: cvlbkds,
    };

    const xkicmcasd = [
      "currBasketMediaLink",
      // "people",
      "edit",
      "remove",
    ];

    const plxsad = {
      rightIconsConfig: {
        // listvar: xkicmcasd,
      },
    };

    const kewasd = (
      <>
        {/* <Divo {...auhsdwe} /> */}
        <MeddorFound {...plxsad} />
        {/* <FinalOrderPage {...sdfh9qwe} /> */}
      </>
    );

    return kewasd;
  }

  function typeFin() {
    let endValue = "";

    logs.logga("___ proceFind ___", proceFind);

    // 1typeprocess
    switch (proceFind) {
      // case "add":
      //   endValue = <TypeTab />;
      //   break;
      case "media":
        endValue = <InBwtweens />;
        break;
      case "details":
        // endValue = "asdw";
        // endValue = <DeetCheck />;
        endValue = <OrderDetails {...sdfh9qwe} />;
        break;
      // 1final

      case "final":
        // endValue = "asd9wqe";
        endValue = <FinaCheck />;
        break;
      // default:
      //   endValue = <InBwtweens />;
    }

    const ijsa = {
      obj: endValue,
      fadeTrue: true,
      // asjwe[typeo]
      // fadeTrue: true
    };

    return <Divo {...ijsa} />;
  }

  //
  function FinDeets() {
    //
    //
    const ijasad = typeFin("endValue");

    // 1padding
    const cvbndff = padhorz("3%", "13%");
    const ijasew = {
      ...cvbndff,
      // height: heightTabso,
      textAlign: "center",
    };

    const gfxgewr = {
      obj: ijasad,
      fadeTrue: true,
      style: ijasew,
    };

    const okawe = <Divo {...gfxgewr} />;

    const MAINBASOS =
      //
      okawe;
    // sadjwe(okawe);

    // 1step
    const xxkvsf = {
      ...baseArgs,
      genConfig: {
        style: {
          margin: "0 5% 50px",
        },
      },
    };
    const xcvqlm = <OrderStep {...xxkvsf} />;

    const hpryork = (
      <>
        {xcvqlm}
        {MAINBASOS}
        {/* asdowkewq */}
        {/* {hourObjFo} */}
        {/* wqeokwqe */}
        {/* <FootoSooi /> */}
        {/*  */}
        {/*  */}
        {/* <FormSeach OLD/> */}
        {/* <FormStripeRight /> */}
        {/* <CrossFadeMain
        // bool={endShow}
        // bool={true}
        trueObj={<FormStripeRight />}
        falseObj={<FormPiece />}
      /> */}
        {/* {endShow ? <FormStripeRight /> : <FormPiece />} */}
      </>
    );

    const asydwe = {
      // border: "2px solid red",
      // padding: "0 20%",
      // paddingLeft: 100,
    };
    const okzsdew = {
      obj: hpryork,
      style: asydwe,
    };

    const ijawasd = (
      //
      <Divo {...okzsdew} />
    );

    return ijawasd;
  }

  function Sdeoks() {
    //
    //
    const rghtPad =
      //
      "28vw";

    const uijerwe = {
      widthvar: rghtPad,
      mainObj: <FinDeets />,
      sideObj: <RightoHead />,
    };

    const dsofkere = (
      //
      // "";
      <SideFixObj {...uijerwe} />
      // <FinDeets />
    );

    return dsofkere;
  }

  function ReqIntreo() {
    //

    const vcobfr = {
      //
      ...baseArgs,
      confirmFunc: ovkdsf,
    };

    const dogkrer = <RequestIntro {...vcobfr} />;

    return dogkrer;
  }

  function ADOkaew() {
    //

    const noShow = getParamVar("noIntro");
    logs.logga("___ currentScribe ___", currentScribe);

    const reqll = eitherOrdReq || currentScribe;
    const reqHomer =
      //
      false;
    // true;
    // reqll && !requestSaw & !noShow;

    logs.logga("___ requestSaw ___", requestSaw);

    const pladewq = reqHomer ? <ReqIntreo /> : <Sdeoks />;

    const asdoawe = {
      obj: pladewq,
      // obj:
    };

    const sdfawe = <Divo fadeTrue {...asdoawe} />;

    return sdfawe;
  }

  const okawe = requestHigh && reqLoado;
  const vbokdff =
    //
    // "asdpkqwew";
    // !okawe && <Sdeoks />;
    !okawe && <ADOkaew />;

  const showDevos = !requestHigh && !gens.mobileView;

  const jtyur = (
    <>
      {/* <form> */}
      {/* {jsdwae} */}
      {vbokdff}
      {/* {showDevos && <DevShow />} */}
      {/* <PriceHeader /> */}
      {/* {hpryork} */}
      {/* </form> */}
    </>
  );

  const fgotmkr = {
    obj: jtyur,
    // subtitleConfig:
    // noTitle: true,
    // style: {
    //   paddingTop: "0px",
    // },
  };

  // const okasew = "";
  const okasew = (
    //
    <Divo {...fgotmkr} />
  );

  return okasew;
}
