import React, {
  //
  useState,
  useContext,
} from "react";
import {
  durationAtts,
  MetaRightAr,
  getSHortSet,
  PriGoigna,
  ImageTextList,
  ScribeRate,
  SecurePay,
  CurrencyMain,
  Mappo,
} from "../functions/GlobalFunctions";
import PriceMedList from "./PriceMedList";
import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";
import OrderButton from "./OrderButton";
import MediaRequest from "./MediaRequest";
import { ScriptContext } from "../contexts/ScriptContext";

export default function OrderRight({
  //
  showBottom,
  buttonConfig,
  underHalf,
  orderMin,
  genConfig,
  typePrivacy,
  ...asdqw
}) {
  //
  const {
    typeProcess,
    mediaList,
    currBasketMedia,
    perMinTimes,
    seriesOver,
    requestMedia,
    currHighlight,
    seriesOrder,
    orderConfig,
    orderDetails,
    deliveryDateDay,
    deliveryHours,
    showDiscount,
    mediaFound,
  } = useContext(ScriptContext);

  const baseRight = {
    //
    ...asdqw,
    orderConfig: orderConfig,
    mediaList: mediaList,
    typeProcess: typeProcess,
  };

  const askew = {
    ...baseRight,
    attrvas: "rightMeta",
  };
  //

  const { underTime } = durationAtts(askew);

  const iajewwe = mediaList && underTime;

  logs.logga("___ iajewwe ___", iajewwe);

  const showTime =
    //
    true;
  // seriesOrder?.perMinuteCost;

  let listos = [
    //
    // "episodes",
    "time",
    // showTime && "perminute",
    // "episodes",
    // "highlights",
    "cost",
    // "discount",
    // showDiscount && "discount",
    "delivery",
  ];

  logs.logga("___orderRIGHT typeProcess ___", typeProcess);

  function MathcObjo() {
    const cldfkf = (
      //
      // <MediaRequest {...asdwq} />
      <ScribeRate matchTrue bottomTrue />
    );

    return cldfkf;
  }

  function Metbasoaw() {
    //
    const oiaew =
      //
      // true;
      seriesOver;

    let asodwe =
      //
      oiaew ? <MathcObjo /> : "";
    // "";

    // switch (typePrivacy) {
    //   case "private":
    //     asodwe = <PriGoigna />;
    //     break;
    //   case "public":
    //     // true;

    //     // <MetaRightAr />;
    //     asodwe = oiaew ? <MathcObjo /> : "";
    //     // asodwe = <MetaRightAr />;

    //     // listos = ["episodes", "time"];
    //     // asodwe = iajewwe ? <ErrorTime /> : <MetaRightAr />;
    //     // asodwe = "asodkwew";
    //     break;
    // }
    // switch (typeProcess) {
    //   //

    //   case "options":
    //     asodwe = <SecurePay />;
    //     break;
    //   case "details":
    //     // asodwe = "asdokqw";
    //     asodwe = <SecurePay />;

    //     // asodwe = requestSeries ? <Alredos /> : <SecurePay />;

    //     // asodwe = <SecurePay />;
    //     break;
    //   case "final":
    //     // asodwe = vokfdg;

    //     asodwe = (
    //       <>
    //         {/*  */}
    //         {/* <SecurePay /> */}
    //         {/* <hr /> */}
    //         <MathcObjo />
    //       </>
    //     );
    //     break;
    // }

    function ErrorTime() {
      const zcxbns = (
        <>
          The minimum order time is {orderMin} minutes. Please add more
          episodes.
        </>
      );

      const okawe = {
        className: "error",
        obj: zcxbns,
      };

      return <Divo {...okawe} />;
    }
    //
    //

    const heightos = "80px";

    const ujaew = {
      padding: "10px 10%",
      fontSize: "22px",
      minHeight: heightos,
    };

    const xvobksaf = (
      <>
        {/* <hr /> */}
        {asodwe}
      </>
    );

    const okdqw = {
      style: ujaew,
      obj: xvobksaf,
    };

    const asdwewq = <Divo {...okdqw} />;

    //
    return asdwewq;
  }

  function BelInfo() {
    const sfdoke = {
      email: {
        textvar: orderDetails["email"],
        iconvar: "mail",
      },
      // name:
    };

    const xkcvfs = {
      listvar: ["email", ""],
      dictvar: sfdoke,
    };

    const dffdsokrew = <ImageTextList {...xkcvfs} />;

    return dffdsokrew;
  }

  function PriceHeader() {
    //
    const duhwew = {
      style: {
        // background: "green",
        // ...gens.
        textAlign: "center",
        fontSize: "64px",
        // paddingBottom: "20px",
      },
    };

    const heightPrice =
      //
      // "30vh"
      "80px";

    const oxvcdsf = {
      //
      // height: heightPrice,
    };

    const asdfer = seriesOver?.country;

    const aijsdwe = {
      ...orderConfig,
      // loadtrue: loadtrue,
      // perMinute={costPero}
      countryVar: asdfer,
      style: oxvcdsf,
      itemConfig: duhwew,
      listvar: listos,
    };

    logs.logga("___ orderRight---PriceMedList ___", aijsdwe);

    const cxuhasd = (
      <>
        <PriceMedList
          //
          // horizTrue
          {...aijsdwe}
        />
      </>
    );

    const asudhwe = {
      // height: "80px",
      padding: "10px",
      textAlign: "center",
      // border: "2px solid red",
    };

    const osojwe = {
      obj: cxuhasd,
      // className: gens.butClass,
      style: asudhwe,
    };

    return <Divo {...osojwe} />;
  }

  function ButOrder() {
    //

    const qyegqw = {
      // textvar:
      ...baseRight,
      ...buttonConfig,
    };

    logs.logga("___ qyegqw ___", qyegqw);

    const sdfkwe = <OrderButton {...qyegqw} />;

    return sdfkwe;
  }

  //
  function Steppos() {
    //

    function awe(sdfkwer) {
      const ijasdwe = sdfkwer == typeProcess;

      const aswsad = ijasdwe ? "finish" : "";
      // "waiting"

      return aswsad;
    }

    const mdSttp = {
      status: awe("media"),
      title: "Episodes",
      icon: "video",
    };

    const ddMeStep = {
      status: awe("details"),
      title: "Details",
      icon: "verification",
    };

    const kaewsa = {
      // status: "finish",
      title: "logig",
      icon: "edit",
    };

    const okaswe = [
      //
      mdSttp,
      ddMeStep,
      //   {title: "Add" },
      //   {title: "Add" },
      //   "details",
      //   kaewsa,
      //   kaewsa,
    ];
    const ijsadw = {
      listvar: okaswe,
    };
    const ijasde = "";
    // const ijasde = (
    //   <StepMain
    //     //
    //     {...ijsadw}
    //   />
    // );

    return ijasde;
  }

  logs.logga("___ seriesOver ___", seriesOver);
  const asidjwe =
    //
    seriesOver;
  // showBottom || oiaew;

  // 1meta
  const iajew =
    //
    // "";
    asidjwe && <Metbasoaw />;

  function Retuo() {
    const saidjwe = (
      <>
        Delivery
        <br />
        {deliveryDateDay}
        <br />({deliveryHours})
      </>
    );

    const osadae = {
      marginTop: "10px",
      fontSize: "24px",
    };

    const jsdewe = {
      // className: "bold",
      obj: saidjwe,
      style: osadae,
    };

    const isjde = <Divo {...jsdewe} />;

    return isjde;
  }

  // {/* <Retuo /> */}
  // {/* <Steppos /> */}
  const huadew = !gens.testDev && iajew;

  const trhfdss = [<PriceHeader />, <ButOrder />, huadew];

  const hfdg = {
    obj: trhfdss,
    style: { margin: "10px 0" },
  };

  const ioasew = <Mappo {...hfdg} />;

  const cvbmdfd = {
    padding: "10px",
  };

  const ijawew = {
    obj: ioasew,
    style: cvbmdfd,
  };

  const aushdwe = <Divo {...ijawew} />;

  return aushdwe;
}
