import React, {
  //
  useState,
  useContext,
} from "react";
// import { Flex, Spacer } from "@chakra-ui/core";
import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import { isEmpty } from "lodash";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  checkFullArray,
  checkLenArray,
  createHighlight,
  DivideMain,
  suggestTrue,
  findHighString,
  getLength,
  getSHortSet,
  joinString,
  LocalShow,
  mapArrIndex,
  removeEmptyArray,
  ScribeRate,
  setGlobalSource,
  TitleMain,
  Mappo,
  mapText,
  //
} from "../functions/GlobalFunctions";
import OrderHighlight from "./OrderHighlight";
import ConfirmButton from "../components/ConfirmButton";
import ListMain from "../components/ListMain";
import TabMain from "../components/TabMain";

export default function HighListMain({
  //
  mediaObj,
  seriesObj,
  suggestTrue,
  addFunc,
  listType = "official",
  listvar,
  highlightConfig,
  ...qweasw
}) {
  // 1scriptcontext
  // const { seriesOver } = useContext(ScriptContext);

  function getAllLis(listvar) {
    //
    //
    function checkFino(higho) {
      const duroase = higho.duration;
      const truBetween = duroase >= 30 && duroase <= 180;
      // const truBetween

      const finalTp =
        //
        truBetween;

      return finalTp;
    }

    const zijsda = listvar.filter(checkFino);

    return zijsda;
  }

  const fullList = checkFullArray(listvar);
  listvar =
    //
    mapArrIndex(listvar);
  const listFiltere = fullList && getAllLis(listvar);

  const sfijw = {
    users: {
      textvar: "Suggested - " + getLength(listFiltere),
      listo: listFiltere,
    },
    full: {
      textvar: "Full - " + getLength(listvar),
      listo: listvar,
    },
    official: {
      textvar: "Official - " + getLength(listvar),
      listo: listvar,
    },
  };

  const numOver = checkLenArray(listFiltere, 2);
  const suggTrue =
    //
    "";
  // true;
  // numOver;

  // 1const
  const sdij =
    //
    // 0;
    // "suggested";
    suggTrue ? "users" : "official";

  // const [listvar, setlistvar] = useState();
  const [type, setType] = useState("");
  const [fdgsfd, setcurrType] = useState(sdij);
  const currType =
    //
    fdgsfd;
  // listvar[fdgsfd];
  // const mapDct;

  const mainTruasa = currType == "official";

  const currViewo = sfijw[currType];
  const listFinal =
    //
    // listvar;
    currViewo["listo"];

  logs.logga("___ currViewo ___", currViewo);

  //
  function Aokemas() {
    //
    const aijwe = seriesObj && getSHortSet(seriesObj);
    const saidjwae = (
      <>
        {/*  */}
        Hi {aijwe}! Here's some of your Youtube chapters I think would work as
        short videos on social media.
        <br />
        {/* They're the right time () */}
        If you want to look through all of your chapters, just choose the Full
        option above. Thanks!
      </>
    );

    const isawe = {
      fontSize: "24px",
      // textAlign: "center",
    };

    const jaewsaw = {
      bottomObj: saidjwae,
      ratingTrue: true,
      style: isawe,
    };

    const asijwe = <ScribeRate {...jaewsaw} />;
    return asijwe;
  }

  const nisje = {
    padding: "10px",
    margin: "10px 0 100px",
  };

  const ijsae = {
    localvar: "noScribeMessage",
    style: nisje,
    obj: <Aokemas />,
  };

  const noMesso = suggTrue && <LocalShow {...ijsae} />;

  const lsitose = removeEmptyArray([
    //
    // "users",
    suggTrue && "users",
    "official",
  ]);

  const sdffdig = {
    listvar: listvar,
    listFiltere: listFiltere,
    //
    //
    currType: currType,
    currViewo: currViewo,
    //
    //
    numOver: numOver,
    suggTrue: suggTrue,
  };

  logs.logga("___ TABS HILIGHTS ___", sdffdig);

  function ChkTab() {
    const ijew = {
      margin: "20px",
    };

    const dfijgter = {
      fontSize: "28px",
    };

    function ijads(roie) {
      // roie = listvar[roie];
      logs.logga("___ roie ___", roie);
      setcurrType(roie);
    }

    const asodwe = {
      onChange: ijads,
      style: ijew,
      styleTab: dfijgter,
      dictvar: sfijw,
      listvar: lsitose,
      chosenItem: currType,
    };

    return <TabMain {...asodwe} />;
  }

  //

  function udsfew({ ...siqwe }) {
    // const index = "sokd";
    logs.logga("___HIGHLIGHT AFTER siqwe ___", siqwe);

    // const
    siqwe["media"] = mediaObj;

    // function asoke(){

    // }

    const uhsdaew = {
      obj: siqwe,
      // nameFunc: fdsaojer,
      ...highlightConfig,
    };

    logs.logga("___ uhsdaew ___", uhsdaew);

    function Aasodw() {
      const trfgde = {
        fontSize: "30px",
        padding: "0 20px",
      };

      const saijdwe = {
        obj: "+",
        bediaTrue: true,
        onClick: () => addFunc(siqwe),
        style: trfgde,
      };

      const addNewFiseo = <ConfirmButton {...saijdwe} />;

      const sadeewa = {
        obj: addNewFiseo,
      };

      const saweq = <DivideMain {...sadeewa} />;

      return saweq;
    }

    const sudiwew = addFunc && <Aasodw />;
    const ordeos = <OrderHighlight {...uhsdaew} />;

    const aygwe = (
      <>
        {/* k\oksa */}
        {ordeos}
        {/* <hr /> */}
        {/* {sudiwew} */}
      </>
    );

    const sokfer = {
      fontSize: "30px",
      //   display: "flex",
    };

    const ashdwe = {
      obj: aygwe,
      //   flexTrue: true,
      style: sokfer,
    };
    const ijasde = <Divo {...ashdwe} />;

    return ijasde;
  }

  function sdfewr({ ...asd }) {
    logs.logga("___ asd ___", asd);

    const jsder = "sadwe";
    return jsder;
  }

  const sdifjr = {
    style: {
      background: "red",
      padding: "20px",
      margin: "20px",
    },
  };

  function Finsiow() {
    const nawwe =
      //
      "Convert from text.";

    const sdjfwew = {
      obj: nawwe,
    };

    const timesaa = <ConfirmButton {...sdjfwew} />;

    const ijsdwe = mapText([
      "Looks like you don't have any highlights yet.",
      "We've suggested a couple you can change the times for and go from there!",
    ]);

    const dfijre = "emptyChapters" + "-" + mediaObj.slugURL;
    const ijsae = {
      localvar: dfijre,
      // style: nisje,
      obj: ijsdwe,
    };
    const iajswe = <LocalShow {...ijsae} />;

    const ijvdwqe = (
      <>
        {iajswe}
        {/* {timesaa} */}
      </>
    );

    const deovaase = {
      obj: ijvdwqe,
      style: { textAlign: "center", fontSize: "24px" },
    };

    return <Divo {...deovaase} />;
  }

  const ijawe = {
    // onClick:
    // objFuncTrue: true,
    ...qweasw,
    obj: udsfew,
    // titleVar: currViewo["aboveObj"],
    itemConfig: sdifjr,
    objFuncTrue: true,
    listvar: listFinal,
    // indexPlus: 1,
    // obj: sdfewr,
  };

  logs.logga("___ listFinal ___", listFinal);
  logs.logga("___ ijawe ___", ijawe);

  function MainListo() {
    // const jdae
  }

  function TopProffer() {
    // const ijaesae = (result = words.filter((word) => word.length > 6));

    const asidjew = "Potential #shorts";
    const oewrje = "You can learn more about Youtube short";

    const ijertr = {
      linkvar: "asdwe",
      obj: oewrje,
    };

    const ijaewd = {
      obj: asidjew,
      subtitleConfig: ijertr,
    };

    const ijwawe = (
      <>
        <TitleMain {...ijaewd} />
      </>
    );

    return ijwawe;
  }

  const fadreo =
    //
    mainTruasa;

  const jfscxv = {
    fadeBool: fadreo,
    obj: <ListMain {...ijawe} />,
  };

  const tabboTru = suggTrue;
  const sdijer = tabboTru && <ChkTab />;

  const uhgdse =
    //
    // "";
    // true;
    suggestTrue;
  // suggestTrue && !fullList;

  const showSugg = uhgdse && <Finsiow />;

  const asijew = findHighString(mediaObj, listvar);

  const saidje = {
    obj: asijew,
    className: "wrapTrue bold",
  };

  const sadwe = <TitleMain {...saidje} />;

  const iwewe = (
    <>
      {sadwe}
      <hr />

      {/* <TopProffer /> */}
      {/* {sdijer} */}
      {noMesso}
      {showSugg}
      {/* {currViewo["aboveObj"]} */}
      <Divo {...jfscxv} />
    </>
  );

  return iwewe;
}
