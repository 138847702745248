import React, {
  //
  useState,
  useContext,
} from "react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import {
  Heading,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Link,
  HStack,
  IconButton,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
// import { FcGoogle } from "react-icons/fc";
// import { FaFacebook } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import { SiLinkedin, SiInstagram, SiMessenger } from "react-icons/si";
import { loadStripe } from "@stripe/stripe-js";
import * as moment from "moment";
import ReactLoading from "react-loading";
import * as soco from "../consts/socialConst";
import * as tsts from "../consts/testConst";
import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import * as grapphy from "../config/graphql/GraphQueries.js";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// 1currency
import html2canvas from "html2canvas";
import CurrencyConverter from "react-currency-conv";
import {
  convertCurrency,
  getCurrencyRate,
  getCurrencyRateList,
} from "currencies-exchange-rates";
import { SelectableGroup } from "react-selectable-fast";
import { Flex, Spacer } from "@chakra-ui/core";
import HistoryFunc from "./HistoryFunc";
import ReactFlagsSelect from "react-flags-select";
import ReactMarkdown from "react-markdown";
import ImageTextDiv from "../components/ImageTextDiv";
import ListMain from "../components/ListMain";
import Divo from "../components/Divo";
import GraphFunctions from "../config/graphql/GraphFunctions";
import ReactDOMServer from "react-dom/server";
import OrderStripeBase from "../containers/OrderStripeBase";
import ConfirmButton from "../components/ConfirmButton";
import FloatMainAll from "../components/FloatMainAll";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { Divider, Avatar } from "antd";

import whatyouget from "../consts/FeatureConst";
import FormMain from "../components/FormMain";
import ImageCheckReplace from "../components/ImageCheckReplace";
import { isEmpty } from "lodash";

var _ = require("lodash");

export function DivideMain({ obj, ...saeaw }) {
  const aijqeq = <Divider {...saeaw}>{obj}</Divider>;

  return aijqeq;
}
// 1moment

// 1moment 1duration
export function momentFormat(releaseformat, agoAfter) {
  return releaseformat ? moment(releaseformat).format(agoAfter) : "";
}

export function momentDuration(releaseformat, agoAfter, formatoo) {
  const asijw = releaseformat ? moment.duration(releaseformat, agoAfter) : "";
  const xckvid = formatoo && asijw ? asijw.format(formatoo) : asijw;

  return xckvid;
}

const iajew = "h:mm:ss";
const asdokass = "mm m ss s";
export function timeDecim(minuteva) {
  // let endinqqqq = durationParser(minuteva, iajew);

  function goins() {
    const inneioa = minuteva < 1;
    const hjrdfft = inneioa ? "0:00" : durationParser(minuteva, iajew);

    return hjrdfft;
  }

  let endinqqqq = minuteva > 0 ? goins() : "0:00";

  return endinqqqq;
}

export function sectoHours(releaseformat) {
  const endinqqqq = durationParser(releaseformat, "hm");
  return endinqqqq;
}

export function mapChapters(mediaObj, omxver) {
  //
  //
  const sjfer = mediaObj.mediaDuration;

  function sadwet({ startTime, indexVar, ...aswe }) {
    const endito = omxver[indexVar + 1];
    const idjre = startTime ? startTime : 0;
    const endTimeo = endito ? endito["startTime"] : sjfer;

    const dfsdwe = {
      ...aswe,
      startTime: idjre,
      endTime: endTimeo,
      duration: endTimeo - idjre,
    };

    const sodfere = {
      omxver: omxver,
      indexVar: indexVar,
    };

    // logs.logga("___ dfsdwe ___", dfsdwe);
    logs.logga("___ sodfere ___", sodfere);

    return dfsdwe;
  }

  const ijadw = mapFunc(omxver, sadwet);

  return ijadw;
}

export function mapChapterToText(asojdwe) {
  //
  //
  function sadwet({ startTime, endTime, name, ...sad }) {
    const ijsaew = timeDecim(startTime);
    const nediFin = joinString([ijsaew, name], " ");

    logs.logga("___ nediFin ___", nediFin);

    return nediFin;
  }

  const ijadw = mapFunc(asojdwe, sadwet);
  const ggftt = joinString(ijadw, "\n");

  const gjdfss = {
    ijadw: ijadw,
    ggftt: ggftt,
  };

  logs.logga("___ gjdfss ___", gjdfss);
  // logs.logga("___ ggftt ___", ggftt);

  return ggftt;
}

export function durationParser(inputTime, formatType = "h[h] m[m]") {
  // finalForm = moment.duration(inputTime).format("h[hrs] m[min] s[sec]");
  var momentDurationFormatSetup = require("moment-duration-format");

  const ijge = {
    inputTime: inputTime,
    formatType: formatType,
  };

  logs.logga("___ ijge ___", ijge);

  const timeDicto = {
    hms: "h:*mm:ss",
    sfp: "s",
    hm: "h[h] m[m]",
    // decimal: "mm m ss s",
    hms: "h:*mm:ss",
  };

  const bvnfdgd =
    //
    // "";
    moment.duration(inputTime, "seconds");

  const formios =
    //
    formatType ? timeDicto[formatType] : formatType;
  // timeDicto[formatType]

  const finalForm =
    //
    // "";
    bvnfdgd.format(formios);

  const cvlmbfddsf = {
    bvnfdgd: bvnfdgd,
    finalForm: finalForm,
  };

  logs.logga("___ cvlmbfddsf ___", cvlmbfddsf);

  return finalForm;
}

export function toFrontArray(arrayMain, stringVar, firstVar) {
  // eeeeee
  logs.logga("zz-stringVar: ", stringVar, "a");
  logs.logga("zz-arrayMain: ", arrayMain, "a");
  logs.logga("zz-firstVar: ", firstVar, "a");

  arrayMain.sort(function (x, y) {
    return x[stringVar] == firstVar ? -1 : y[stringVar] == firstVar ? 1 : 0;
  });

  return arrayMain;
}

// 1time
export function filterFormat(fullArr, formatWatch) {
  logs.logga("zz-fullArr: ", fullArr, "2222qa");
  logs.logga("zz-formatWatch: ", formatWatch, "2222qa");

  fullArr = fullArr ? toFrontArray(fullArr, "link", formatWatch) : "";
  // fullArr = fullArr.filter((typeItem) => typeItem.trueNow);

  return fullArr;
}

// 1exist
export function existTrue(checkVar, repVar) {
  checkVar = checkVar ? checkVar : repVar;

  return checkVar;
}

export function priceEnd(obj, addFirst = "") {
  const endoasa = addFirst + obj.toFixed(2);

  return endoasa;
}

export function priceMedarray(obj, multVar = 2) {
  // return obj.requestPrice;
  const pushFurro = (obj * multVar) / 60;
  const endoasa = pushFurro.toFixed(2);

  logs.logga("pushFurro-zzz", pushFurro, "asdow");
  logs.logga("endoasa-zzz", endoasa, "asdow");

  return endoasa;
}

export function priceMed(obj = 0, multVar = 1) {
  // return obj.requestPrice;
  const pushFurro = (obj * multVar) / 60;
  const endoasa =
    //
    pushFurro;

  logs.logga("pushFurro-zzz", pushFurro, "asdow");
  logs.logga("endoasa-zzz", endoasa, "asdow");

  return endoasa;
}

export function gethourDeliver(duaroef) {
  //
  const ijasd = duaroef * gens.delivMultiple;
  let houro = ijasd / 60;
  houro = houro;

  const kbnfd = {
    duaroef: duaroef,
    ijasd: ijasd,
    houro: houro,
  };

  logs.logga("___ kbnfd ___", kbnfd);

  return houro;
}

export function getreturndate(medDura) {
  //
  const houro =
    //
    medDura;
  // gethourDeliver(medDura);

  const dayio = houro / 24;

  const dateNow = new Date();

  const dateMino = dateNow.getDate() + dayio;
  dateNow.setDate(dateMino);

  const momentomin = moment().add(houro, "hours");
  const estDatao =
    //
    momentomin;
  // new Date(dateNow);

  // d.setMonth(d.getMonth() + 1 - 0);

  const endios = estDatao;

  const okawe = {
    houro: houro,
    dayio: dayio,
    dateNow: dateNow,
    dateMino: dateMino,
    momentomin: momentomin,
    estDatao: estDatao,
  };

  logs.logga("___ getreturndate ___", okawe);

  //
  return endios;
}

export function durationAtts({
  //
  mediaList,
  orderMin = gens.minUnderTime,
  perTimeDiscount,
}) {
  const asdf9jwere = durationMedArray(mediaList);
  const minuteDuration =
    //
    asdf9jwere + 24;
  const overHour = minuteDuration > 3600;
  const underTime = minuteDuration < orderMin * 60;

  //
  const okawe = {
    underTime,
    overHour,
  };

  return okawe;
}

const xfobkdf =
  //
  "dddd D MMM, ha";
// ""

// timeformat
export function formatDate(fadsa, formatvar = xfobkdf) {
  // moment("2014-02-27T10:00:00").
  const ksdszdsf = moment(fadsa).format(formatvar);

  return ksdszdsf;
}

// export function dateBetweenStrings(dateone, datetwo = moment()) {

// }

export function dateFromNow(houro) {
  //
  const cvkbmdf = moment().add(houro, "hours");
  const ksmdsa =
    //
    // formatDate(cvkbmdf, "HH");
    cvkbmdf.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

  //
  return ksmdsa;
}

export function dateFromString(dateone, datetwo = moment()) {
  const dateunder = 24;

  const cxkfdg = {
    dateone: dateone,
    datetwo: datetwo,
  };

  logs.logga("___ cxkfdg ___", cxkfdg);

  const bkfdsf = moment(dateone).diff(datetwo);
  // const houBetwe = dateone.subtract(datetwo);
  const duroBaso = moment.duration(bkfdsf);

  const houBetwe = duroBaso.asHours();
  // const heourd =
  const horSoon =
    //
    // true;
    houBetwe < dateunder;

  const byTime =
    //
    " in " + parseInt(houBetwe) + " hours";
  // "by " + dateFromNow(houBetwe) + " today.";

  const koadodw = horSoon ? byTime : "by " + formatDate(dateone);

  const hlfkf = {
    dateone: dateone,
    datetwo: datetwo,
    houBetwe: houBetwe,
    koadodw: koadodw,
  };

  logs.logga("___ hlfkf ___", hlfkf);

  return koadodw;
}

export function delivMedia(mediaList) {
  const { deliveryBase } = mediaDeliver(mediaList);

  return deliveryBase;
}

export function formatDeliver(deliveryBase) {
  //
}

export function baseModel(baseModel) {
  const jfdgirt = {
    id: baseModel.id,
    name: baseModel.name,
  };

  return jfdgirt;
}

export function SlideNumber({ thumbStyle, value, ...dwer }) {
  // flex="1" focusThumbOnChange={false} value={value} onChange={handleChange}

  const aisjdwe = (
    <>
      {/* asfdoejrwe */}
      <Slider value={value} {...dwer}>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize="32px" children={value} {...thumbStyle} />
      </Slider>
    </>
  );

  return aisjdwe;
}

export function InputNumber({ ...sdfer }) {
  //  defaultValue={15} min={10} max={20}>
  const numbero = (
    <NumberInput display="inline" mx={3} size="sm" maxW={50} {...sdfer}>
      <NumberInputField />
      {/* <NumberInputStepper>
    <NumberIncrementStepper />
    <NumberDecrementStepper />
  </NumberInputStepper> */}
    </NumberInput>
  );

  return numbero;
}

export function parseHourString(sdfger) {
  const overHor = sdfger > 1;
  const tsafdw = overHor
    ? parseInt(sdfger) + " hours"
    : parseInt(60 * sdfger) + " minutes";

  const dskfjier = {
    overHor: overHor,
    tsafdw: tsafdw,
  };

  logs.logga("parseHourString-zzz", dskfjier);

  return tsafdw;
}

export function mediaDeliver(mediaList, hourPlus, messio) {
  //

  hourPlus = hourPlus ? hourPlus : gens.plusHours;

  const okaew =
    //
    "dddd D MMM, ha";
  // "dddd D MMM, hh:mm a";
  // "dddd Do MMMM, YYYY";

  const sfsapython =
    //
    "YYYY-MM-DD HH:mm";

  var currTime = new Date(); // for now
  // d.getMinutes(); // =>  30
  // d.getSeconds(); // => 51

  const fullDuration = durationMedArray(mediaList);
  const noTime = fullDuration == 0;
  const minuteDuration = fullDuration / 60;
  const hourDuration = minuteDuration / 60;
  const hourDurationString = parseHourString(hourDuration);
  const inHoursTime = gethourDeliver(minuteDuration) + hourPlus;

  const fromEndo = 24 - currTime.getHours();
  const delivCurr = inHoursTime < fromEndo;
  const deliveryBase = getreturndate(inHoursTime);

  function parseTimeo(qwease) {
    const formos = noTime ? "NA" : qwease;

    return formos;
  }

  const adiwe = delivCurr
    ? "Today " + deliveryBase.format("ha")
    : deliveryBase.format("dddd ha");

  const deliveryDays = deliveryBase.format(okaew);
  const deliveryDateDay = parseTimeo(adiwe);
  const deliveryPython = deliveryBase.format(sfsapython);
  const deliveryHours = parseHourString(inHoursTime);

  const asodkew = {
    fromEndo: fromEndo,
    minuteDuration: minuteDuration,
    hourDuration: hourDuration,
    deliveryBase: deliveryBase,
    deliveryHours: deliveryHours,
    deliveryDays: deliveryDays,
    deliveryDateDay: deliveryDateDay,
    deliveryPython: deliveryPython,
    inHoursTime: inHoursTime,
    hourPlus: hourPlus,
  };

  logs.logga(messio + "___ mediaDeliver ___", asodkew);

  const dfdsafds = {
    minuteDuration,
    hourDuration,
    hourDurationString,
    deliveryBase,
    deliveryHours,
    deliveryDays,
    deliveryDateDay,
    deliveryPython,
    inHoursTime,
  };

  return dfdsafds;
}

export function normalText(cxokvs, asdijwe = "left", sokad) {
  const zxaasd = padhorz("2%", "15%");

  const ofgdf = {
    ...zxaasd,
    margin: "25px",
    // padding: "10px",
    fontSize: "22px",
    textAlign: asdijwe,
    ...sokad,
  };
  const oawe = {
    style: ofgdf,
    // obj: cxokvs,
  };

  return <div {...oawe}>{cxokvs}</div>;
}

export function MarkdownMain({ obj, ...asads }) {
  const aywew = (
    //
    <ReactMarkdown source={obj} />
  );
  // <ReactMarkdown>{obj}</ReactMarkdown>;
  return aywew;
}

export function timeShort(timString) {
  // logs.logga("sswww timString: ", timString);
  // logs.logga("dddddw timString: ", timString);

  // logs.logga("zxsadw: " + moment(releaseformat));
  // logs.logga("date: " + date);
  // logs.logga("now: " + now);

  var matches = timString.match(/\b(\w[\d+]?)/g); // ['J','S','O','N']
  var acronym = matches.join("").replace(/^a/, "1").replace("a", "");
  // .replace("a", "1y"); // JSON

  //   function isFutureDate(value) {
  //     d_now = new Date();
  //     d_inp = new Date(value)
  //     return d_now.getTime() <= d_inp.getTime();
  // }

  // var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  // var acronym = matches.join(""); // JSON

  // return timString;
  return acronym;
}

export function DateTimeAgo({ dateVar, shortTrue, loadtrue }) {
  const timmy = timeDisplay(dateVar);
  const timeTrue = shortTrue ? timeShort(timmy) : timmy;
  const centObj = loadtrue ? "" : timeTrue ? timeTrue : "No Date";

  return centObj;
}

export function timeDisplay(releaseformat) {
  const alwqeo = moment(releaseformat).fromNow();
  return alwqeo;
}

export function setParamVar(paramVar, stringVar, linkMain) {
  // const linkvar = paramLink(linkMain);
  // let url = new URL("https://example.com?foo=1&bar=2");
  let linkvar = new URLSearchParams(document.location.search);

  const ijsdaw = {
    stringVar: stringVar,
    linkvar: linkvar,
    paramVar: paramVar,
  };
  logs.logga("setParamVar:---", ijsdaw);

  // const params = new URLSearchParams(location.search);
  // params.set('version', 2.0);

  // URL: https://example.com?version=2.0

  linkvar.set(paramVar, stringVar);
  window.history.replaceState(
    {},
    "",
    `${document.location.pathname}?${linkvar}`
  );

  // return checkIt;
}

export function paramLink(linkvar) {
  // const docURL = document.URL;
  const docURL = document.location;

  linkvar = linkvar ? linkvar : docURL;

  const urlFind = new URL(linkvar);
  const linkMatch = new URLSearchParams(urlFind.search);

  // linkMatch = linkMatch ? linkMatch.toString() : "nomatch";

  return linkMatch;
}

export function getParamVar(stringVar, linkMain) {
  const linkvar = paramLink(linkMain);
  const checkIt = linkvar ? linkvar.get(stringVar) : "";

  const ijaeweq = {
    linkvar: linkvar,
    stringVar: stringVar,
    checkIt: checkIt,
  };

  logs.logga("___ getParamVar ___", ijaeweq);

  return checkIt;
}

// 1array functions
export function removeEmptyDict(obj) {
  obj = JSON.parse(JSON.stringify(obj));
  // listArray = _.pickBy(listArray, _.identity);

  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }

  return obj;
}

export function turnarray(arrVary, extraPush) {
  const checkArr = Array.isArray(arrVary);
  const emptArr = [];
  emptArr.push(arrVary);

  arrVary = arrVary ? (checkArr ? arrVary : emptArr) : [];

  // logga("arrVary-vvvv", arrVary, "vjjjjjvv");
  if (extraPush) {
    arrVary.push(extraPush);
  }

  //   logs.logga("arrVary-zzzzz", arrVary, "vjjjjjvv");
  //   logs.logga("checkArr", checkArr, "vjjjjjvv");

  // arrVary = removeEmptyArray(arrVary);

  return arrVary;
}

// movearray
export function moveItemArray(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function checkLenArray(dataVar, numVar = 0, endNum) {
  dataVar = getLength(dataVar) == numVar;
  return dataVar;
}

export function checkEmptyArray(arr) {
  arr = turnarray(arr).length < 1;

  return arr;
}

export function checkFullArray(arr, repVar = "") {
  arr = removeEmptyArray(arr).length > 0;

  return arr;
}

export function removeEmptyArray(arr) {
  const sofksd = turnarray(arr).filter((item) => item);

  return sofksd;
}

export function addArray(obj, array) {
  array.push(obj);
  return array;
}

export function addArrayNumber(array) {
  const endNum = array.reduce((a, b) => a + b, 0);
  return endNum;
}

export function getLength(arr) {
  arr = turnarray(arr).length;

  return arr;
}

// 1mapdict

// for (const [key, value] of turnDict(test)) {
//   logs.logga(key, value);
// }

export function turnDict(dictvar) {
  return Object.entries(dictvar);
}

export function mapDictAttr(obj, dictvar) {
  for (const [key, value] of Object.entries(dictvar)) {
    obj[key] = value;
  }

  return obj;
}

// getshortname
export function getSHortSet(serobj) {
  const cvbdfs = serobj.shortNameAttr;
  const eqQo = cvbdfs == "false";
  const trueShort = cvbdfs && !eqQo;

  const endo = trueShort ? cvbdfs : serobj.name;

  return endo;
}

export function minusZero(numeOne, numeTwo) {
  const ijsda = numeOne - numeTwo;
  const uhasdwe = ijsda < 0 ? 0 : ijsda;

  return uhasdwe;
}

export function addDictInners(metarArr, innerVar) {
  // const constoi = addArrayNumber(mapDictInners(metarArr, innerVar))
  const ijawe = mapArrAttr(metarArr, innerVar);
  const okawe = removeEmptyArray(ijawe);
  const constoi = addArrayNumber(okawe);

  return constoi;
}

export function addDictInside(dictvar, attrVar) {
  for (const [key, value] of Object.entries(dictvar)) {
    dictvar[key][attrVar] = key;
  }

  return dictvar;
}

export function addDictAttr(arrVar, keyVar, attrVar) {
  arrVar.forEach(function (lineMain, indexVar) {
    lineMain[keyVar] = attrVar;
  });

  return arrVar;
}

export function addDictAttrArr(dictvar, arrVar, attVar) {
  arrVar.forEach(function (lineMain, indexVar) {
    const endPwaoew = dictvar[lineMain];

    logs.logga("endPwaoew-zzzzz", endPwaoew, "qwe1ni2wsr");
    logs.logga("attVar-zzzzz", attVar, "qwe1ni2wsr");
    logs.logga("lineMain-zzzzz", lineMain, "qwe1ni2wsr");

    endPwaoew[attVar] = lineMain;
  });

  return arrVar;
}

export function mapArrIndex(arrVar) {
  function saodae(linearray, indexa) {
    logs.logga("___ indexa ___", indexa);

    linearray["index"] = indexa + 1;
    const oasdwe = linearray;
    return oasdwe;
  }

  const aarrl = arrVar.map(saodae);
  logs.logga("AAAAA-arrVar", arrVar, "asdnw9");
  logs.logga("AAAAA-aarrl", aarrl, "asdnw9");

  return aarrl;
}

export function mapArrAttr(arrVar, attrVar = "id") {
  const aarrl = mapFunc(arrVar, (linearray) => linearray[attrVar]);
  logs.logga("AAAAA-aarrl", aarrl, "asdnw9");
  logs.logga("AAAAA-arrVar", arrVar, "asdnw9");

  return aarrl;
}

export function mapDictInners(metarArr, innerVar) {
  const constoi = Object.keys(metarArr).map(
    (linearray) => metarArr[linearray][innerVar]
  );

  return constoi;
}

export function mapDictKeys(dictvar) {
  return Object.keys(dictvar);
}

// maparrnewDict mapBase basemodel
export function baseDict(typevar, objso, arrvar) {
  const bjifdgr = {};

  // const iasdw = [
  //   "media",
  //   "indexVar",
  //   "index",
  //   "endTime",
  //   "duration",
  //   "__typename",
  // ];

  const idjfaw = [
    //
    "id",
    "startTime",
    "name",
    "description",
    "keyQuote",
    "tags",
  ];

  const sdier = {
    highlight: idjfaw,
  };

  arrvar = arrvar ? arrvar : sdier[typevar];
  arrvar.map((attr) => (bjifdgr[attr] = objso[attr]));

  logs.logga("___ bjifdgr ___", bjifdgr);

  return bjifdgr;
}

export function mapArrDictAttr(metarArr, attr = "id") {
  const endDict = {};

  // function fdjdsr(linearray){

  // }

  metarArr.map((linearray) => (endDict[linearray[attr]] = linearray));

  return endDict;
}

export function mapArrDict(metarArr) {
  const constoi = Object.keys(metarArr).map((linearray) => metarArr[linearray]);

  logs.logga("ww-: constoi", constoi, "12398ywh12");
  logs.logga("ww-: metarArr", metarArr, "12398ywh12");

  return constoi;
}

export function addDictKey(metarArr) {
  metarArr = addDictInside(metarArr, "key");

  logs.logga("ww-: metarArr", metarArr, "e90u2n");

  return metarArr;
}
export function mapDictArrs(dictvar, arrVar) {
  const aarrl = mapFunc(arrVar, (linearray) => dictvar[linearray]);
  return aarrl;
}

export function mapDictArrAttr(arrVar, attrVar) {
  const dictMtrew = {};
  const aarrl = mapFunc(
    arrVar,
    (linearray) => (dictMtrew[linearray[attrVar]] = linearray)
  );
  return dictMtrew;
}

export function mapDictDict(dictvar, arrVar) {
  const dictMtrew = {};
  const aarrl = mapFunc(
    arrVar,
    (linearray) => (dictMtrew[linearray] = dictvar[linearray])
  );
  return aarrl;
}

export function addRemoveArray(pushArr, obj, attrVar) {
  //   pushArr = turnarray(pushArr);
  //   const dicto = findArrayTrue(pushArr, obj, attrVar);
  //   // const dicto = findArrayAttr(pushArr, obj, attrVar);

  //   logs.logga("pushArr-zzz", pushArr, "2193e1h2");
  //   logs.logga("dicto-zzz", dicto, "2193e1h2");

  //   if (dicto > -1) {
  //     _.remove(pushArr, function (el) {
  //       return attrVar ? el[attrVar] === obj : el === obj;
  //     });
  //     // pushArr = removeArray(obj, pushArr, attrVar);
  //   } else {
  //     pushArr.push(obj);
  //   }

  return pushArr;
}

export function removeItemDict(arrVar, obja) {
  function asidjwe(sadl) {
    delete obja[sadl];

    const sdijs = {
      obja: obja,
      sadl: sadl,
    };

    logs.logga("___ sdijs ___", sdijs);

    // return obja;
  }

  arrVar.map(asidjwe);

  return obja;
}

export function addRemoveDict(arrVar, attrVar, obja) {
  const truFindoo = attrVar in arrVar;

  if (truFindoo) {
    delete arrVar[attrVar];
  } else {
    arrVar[attrVar] = obja;
  }

  return arrVar;
}

// 1logo
export function BediaLogo({
  homeLink,
  magTrue,
  blueTrue,
  avaTrue,
  backColor,
  expandTrue,
  heightVar,
  widthVar,
  ...args
}) {
  // const heightVary = "40px";

  // logs.logga("heightVar", heightVar);
  // logs.logga("linkvar-xxxx", linkvar);

  const noLink =
    //
    "";
  // !homeLink && !linkvar;

  const aisjdw =
    //
    gens.BediaLogoBlue;

  // // const ijadwe = {
  //   "blue":
  //   "white"
  // }

  const asidnw = magTrue
    ? blueTrue
      ? gens.BediaMagBlue
      : gens.BediaMagWhite
    : gens.BediaLogoWhite;

  const asdfewqrew =
    //
    // asidnw
    aisjdw;

  const ijaew =
    //
    20;
  // 600;
  // heightVar ? heightVar : "25px"

  const iutouee = {
    ...args,
    height: ijaew,
    src: asdfewqrew,
  };

  logs.logga("___ iutouee ___", iutouee);

  const mainOjbo = <img {...iutouee} />;
  const linkooss =
    //
    "2iwq";
  // noLink ? "" : gens.homeLinkBedia;

  //
  // const aujdwqe = {
  //   linkvar: linkooss,
  //   obj: <MainBedo />,
  // };

  // return <Divo {...aujdwqe} />;

  const iajwew =
    //
    // "Bedia"
    "Bedia.";

  const oksdf =
    //
    // hdSzo
    gens.mobview("30px", "40px");

  const ijasdwe = {
    // imageSize: "sm",
    imageSize: oksdf,
    imagevar: gens.BediaMagBlack,
    textvar: iajwew,
    // fontSize: "44px",
  };

  const dfkgr = <ImageTextDiv {...ijasdwe} />;

  const fvidjfsa = {
    obj: dfkgr,
    ...args,
  };

  // return "sdfewr";
  // return <ImageCheckReplacey {...ijasdwe} />;
  return <Divo {...fvidjfsa} />;
}

export function historyPush(dictvar) {
  HistoryFunc(dictvar);
}

// 1length 1array 1first getfirstarra
export function getFirstArr(dataVar) {
  dataVar = turnarray(dataVar)[0];

  return dataVar;
}

export function BlankWrap({ heightVar }) {
  return (
    <footer
      style={{
        minHeight: heightVar ? heightVar : "120px",
      }}
    />
  );
}

export function getIdVar() {
  const nisjwae = 100000 + Math.floor(Math.random() * 900000);

  return nisjwae;
}

export function getPlayerElement(playerRef) {
  const trtier = geFDrEF(playerRef);
  const isdjfer = trtier && trtier.getInternalPlayer();

  const ijsaaew = {
    trtier: trtier,
    isdjfer: isdjfer,
  };

  logs.logga("___ getPlayerElement ___", ijsaaew);

  return isdjfer;
}
export function screenShotDiv(refCurr, funcvar) {
  // let div = document.getElementById(id);

  logs.logga("___ refCurr ___", refCurr);

  html2canvas(refCurr).then(function (canvas) {
    const cnviIMage = canvas
      .toDataURL("image/jpeg")
      .replace("image/jpeg", "image/octet-stream");

    logs.logga("___ canvas ___", canvas);
    logs.logga("___ cnviIMage ___", cnviIMage);

    // var a = document.createElement("a");
    // // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
    // a.href = cnviIMage;
    // a.download = "somefilename.jpg";
    // a.click();

    // funcvar(canva  s);
    // document
    // .getElementById('output')
    // .appendChild(canvas);
  });
}

export function getObject(sadwe, adsfe) {
  const nisaje = {
    id: getIdVar(),
    // name:
    // description:
    ...adsfe,
  };

  return nisaje;
}

export function createHighlight(adsfe) {
  const nisaje = {
    id: getIdVar(),
    // name:
    // description:
    ...adsfe,
  };

  return nisaje;
}

export function bediaLinePush(stringVar, plusVar) {
  const plusplus = plusVar ? " " + plusVar : "";
  const endPlis = stringVar + " | Bedia" + plusplus;

  return endPlis;
}

export function helmetSearch(dataImport, titlevar, connectSear) {
  const nameVar = titlevar;
  //   const shareTrue = checkParamVar(gens.shareLinkString);
  //   const quoteTrue = checkParamVar(gens.shareLinkString);

  const searchVar =
    //
    "";
  //   getSearchParam();

  const titleFull = nameVar + spaceSurround(connectSear) + searchVar;
  const titEnd = titleFull ? bediaLinePush(titleFull, "") : "";
  // const showSeach = searchVar && shareTrue
  const showSeach = searchVar;
  const truEnd = showSeach ? titEnd : "";

  const oksdf = {
    titleFull: titleFull,
    titEnd: titEnd,
    truEnd: truEnd,
    nameVar: nameVar,
    searchVar: searchVar,
  };

  logs.logga("oksdf-zzz", oksdf);

  return truEnd;
}

export function spaceSurround(stringVar) {
  // eeeeee
  return " " + stringVar + " ";
}

// 1border
export function borderMain(colorVar = "grey", radiusVar = "5px") {
  const asdwq = {
    border: "2px solid " + colorVar,
    borderRadius: radiusVar,
  };

  return asdwq;
}

export function backgroundMain(colorVar = "grey", colorBord) {
  colorBord = colorBord ? colorBord : colorVar;
  const asdwq = {
    background: colorVar,
    ...borderMain(colorBord),
  };

  return asdwq;
}

export function ChooseOpt(itemo) {
  //
  //

  const ydvew =
    //
    gens.appMainDark;
  // "black"

  const iajswe = {
    // margin: "0 20px",
    background: ydvew,
    color: "white",
    borderRadius: "5px",
  };

  const ijewq = (
    <>
      Order {/*  */}
      {/* {xvbpdf} */}
      {/*  */}
    </>
  );

  function LinkBoo() {
    const subbos = {
      iconvar: "clock",
      textvar: "Transcripts",
      linkvar: "",
    };

    const transoie = {
      iconvar: "clock",
      textvar: "Transcripts",
      linkvar: "",
    };

    const bthsoakjdwe = {
      iconvar: "clock",
      textvar: "Transcripts",
      linkvar: "",
    };

    const iajwe = {
      subtitle: subbos,
      transcript: transoie,
      both: bthsoakjdwe,
    };

    const iaskd = [
      //
      "transcript",
      "subtitle",
      "both",
    ];

    const mvytu = {
      //
    };

    const wqyetwe = {
      dictvar: iajwe,
      listvar: iaskd,
      itemConfig: mvytu,
    };

    const ajsdweo0 = (
      <ImageTextList
        //
        {...wqyetwe}
      />
    );

    return ajsdweo0;
  }

  const okase = {
    // style: { color: "white" },
  };

  const dfogkewrew = {
    iconvar: "info",
    // popContent: "How Bedia Works",
    textvar: "About",
    // textvar: "How Bedia Works",
    // style: {
    //   minWidth: "700px",
    //   maxWidth: "700px",
    //   border: "2px solid red",
    //   // background: "green",
    // },
    // textvar: "About",
    linkvar: "/about",
  };

  const rqeBaseo = {
    textvar: "Request",
    linkvar: "request",
  };

  const linkoss =
    //
    "/order";
  // "/upload";
  // "/upload?typevar=transcript"

  const okawesdf = {
    style: {
      ...iajswe,
    },
  };

  const orderBaseo = {
    ...okawesdf,
    textvar: ijewq,
    linkvar: linkoss,
    // popContent: ChooseOpt,
  };

  const iconmqo = {
    video: {
      iconvar: "video",
      textvar: "Videos",
    },
    audio: {
      iconvar: "video",
      toolVar: "Upload",
      textvar: "Tra",
      // linkvar: "upload?typevar=transcript",
    },
    search: {
      iconvar: "search",
      toolVar: "Search",
      // onClick:
    },
    subtitle: {
      iconvar: "subtitle",
      // textvar: "Subtitles",
      toolVar: "Upload",
      linkvar: "/upload?typevar=subtitle",
    },
    user: {
      icon: "user",
      textvar: "Sign Up",
      // obj: "asdihnqw",
      // obj: (
      //   <Divo
      //     //
      //     popContent={<AvaPop />}
      //     obj={<UserAva />}
      //   />
      // ),
    },
    transcript: {
      // iconvar: "transcript",
      // textvar: "Transcript",
      iconvar: "transcript",
      // textvar: "Transcript",
      toolVar: "Order Trancripts",
      linkvar: "/upload?typevar=transcript",
    },
    info: dfogkewrew,
    price: {
      iconvar: "info",
      // popContent: "How Bedia Works",
      textvar: "Pricing",
      linkvar: "/pricing",
    },
    order: orderBaseo,
    request: rqeBaseo,
  };

  // margin: "0 20px",

  const xkcvsdf = itemo ? iconmqo[itemo] : iconmqo;
  // const xkcvsdf = itemo ? <ImageTextDiv {...iconmqo[itemo]} /> : iconmqo;

  // 1ChooseOpt
  return xkcvsdf;
}

export function ImageTextList({ bediaTrue, ...configVars }) {
  //   const obo = bediaTrue ? BediaTextDiv : ImageTextDiv;

  // gens.messCheck({
  //   messVar: "sakmdqe",
  //   messVar: "ImageTextList---configVars-zz", configVars
  // })

  function vxbmfg({ bediaTrue, style, ...obj }) {
    // const xcvmbdf = "sdvoksdfs";

    logs.logga("___ vxbmfg ___", obj);

    const iawesad = bediaTrue && gens.bediaMainStyle;
    const xkfs = {
      textAlign: "center",
      // fontSize: "10px",
      padding: "0 20px",
      ...iawesad,
      ...style,
    };

    function asdowqe() {
      logs.logga("___ ImageTextList ___", "asdowqe");
    }

    const asdije = {
      style: xkfs,
      ...obj,
      // onClick: asdowqe,
    };

    logs.logga("___ asdije ___", asdije);

    const saijwe = (
      //
      // "asdaew";
      // <Divo {...asdije} />
      <ConfirmButton {...asdije} />
    );

    return saijwe;
  }

  function osdkfewr({ mainObj, ...vncgf }) {
    logs.logga("___ ImageTextList AAA ___", vncgf);

    // mainObj
    const ivcobkd = mainObj ? mainObj(vncgf) : vxbmfg(vncgf);

    return ivcobkd;
  }

  const asijdwe = {
    obj: osdkfewr,
    ...configVars,
  };

  logs.logga("___ asijdwe ___", asijdwe);

  return (
    <ListMain
      //
      objFuncTrue
      // objReturnTrue
      {...asijdwe}
    />
  );
}

export function WhatOfferList({ ...zxcvkmds }) {
  const okasew = [
    //
    "transcript",
    "timestamp",
    //
    "subtitle",
    "chapter",
    //
    "unclear",
    "translation",
    //
  ];
  //
  const kawe = {
    dictvar: whatyouget(),
    listvar: okasew,
    ...zxcvkmds,
  };

  const ijawe = (
    <ListMain
      //
      // objReturnTrue
      {...kawe}
    />
  );

  return ijawe;
}

export function downloadChaps(dkfwr, mediaObj) {
  const strineo = mapChapterToText(dkfwr);
  const fjiifrew = joinString(
    [
      //
      "CHAPTERS -",
      mediaObj.name,
      ".txt",
    ],
    " "
  );

  const sidjwe = {
    TEXT: strineo,
    NAME: fjiifrew,
  };

  logs.logga("___ downloadChaps ___", sidjwe);

  dowloadFile(strineo, fjiifrew);
}

export function dowloadFile(texto, name) {
  const element = document.createElement("a");
  const strineo = texto;
  const file = new Blob([strineo], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = name;

  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

// quotebyguy
export function QuoteFromGuy({ quoteConfig, personConfig, ...wea }) {
  //
  const oakwe =
    "You get a transcript for your blog, subtitles for Youtube, and you get both for cheaper than one anywhere else. Easiest decision I've ever made.";
  // > Blockquotes are very handy in email to emulate reply text.
  // > This line is part of the same quote.

  // Quote break.

  const oxkcbsdf = {
    obj: oakwe,
    // style:
    quoteSize: "1.25em",
    ...quoteConfig,
  };

  const sdfgfsafs = {
    obj: "- James Daniels, Upgrowth Podcast",
    style: {
      paddingTop: "20px",
      fontSize: "0.8em",
    },
    className: "italics",
    ...personConfig,
  };

  const dsweq = (
    <>
      {/* // */}
      {/* // oakwe */}
      <WrapQuotes {...oxkcbsdf} />
      {/*  */}
      <br />
      <Divo {...sdfgfsafs} />
    </>
  );

  const ijaweasd = {
    obj: dsweq,
    ...wea,
  };
  const okawe = <Divo {...ijaweasd} />;

  return okawe;
}

export function padhorz(asdkew = "0%", asdoke = "15%") {
  const okawds = gens.mobview(asdkew, asdoke);
  const ijawd = "0 " + okawds;
  const sokfgdds = {
    padding: ijawd,
    // border: "2px solid green",
  };

  return sokfgdds;
}

export function removeLocal(sdawe) {
  localStorage.removeItem(sdawe);
}

export function resetOrder() {
  removeLocal(gens.stroeOrdId);
  removeLocal(gens.storeBasketMedia);
  removeLocal(gens.storeOrderConf);
  removeLocal(gens.storeBasketHighlight);
  removeLocal(gens.detialGot);
}

export function WrapQuotes({
  disVar,
  noPadHorz,
  quoteSize = "0.65em",
  obj,
  ...args
}) {
  const odkwqe = {
    style: {
      className: "wrapQuotes",
      padding: noPadHorz && "0px 5px",
    },
  };

  const ijfgdr = {
    style: { margin: "0 5px", fontSize: quoteSize },
  };

  const sfjiew = <span {...ijfgdr}>❝</span>;
  const fgdstre = <span {...ijfgdr}>❞</span>;

  return (
    <span {...odkwqe}>
      {sfjiew}
      <span {...args}>
        {/*  */}
        {/* sadojwen isadwqe */}
        {obj}
      </span>
      {fgdstre}
    </span>
  );
}

export function mapDictMain(listo, dicto) {
  const asokew = mapFunc();
}

export function mapInnerJoin(arrVar, attrVar, connectVar) {
  var prevSpeak = "";
  connectVar = connectVar ? connectVar : " ";

  return mapFunc(arrVar, (linearray) => linearray[attrVar]).join(connectVar);
}

export function mapInnerAttr(arrVar, attrVar, connectVar) {
  const aijdwq = [];
  const enaso = mapFunc(arrVar, (linearray) => linearray[attrVar]);
  // aijdwq.push(linearray[attrVar]));

  const aodjw = {
    arrVar: arrVar,
    attrVar: attrVar,
    aijdwq: aijdwq,
    enaso: enaso,
  };
  // logs.logga("mapInnerAttr-zzz", aodjw);

  return enaso;
}

export function durationMedArray(vidarray) {
  const firsty = vidarray ? addDictInners(vidarray, "mediaDuration") : "";

  return firsty;
}

export function timeMarker(valNum) {
  logs.logga("zzz-valNum", valNum, 4);
  // const trueVar = numOne < valVar && valVar < numTwo;

  return valNum;
}

export function getMediaDuration(vidarray) {
  const firsty = vidarray
    ? timeMarker(durationParser(vidarray.mediaDuration, "h:*mm:ss")).toString()
    : "";
  // logs.logga("xxxxxx-SpObj: ", SpObj);
  // logs.logga("EEEEEEE-getFirstName: ", firsty);

  return firsty;
}

export function mapFunc(arrVar, funcVar, dictvar) {
  arrVar = turnarray(arrVar);

  function hereo(obj, indexVar) {
    const obrar =
      //
      obj;
    //  dictvar ? dictvar[obj] : obj;

    const ijadw = {
      indexVar: indexVar,
      ...obrar,
    };

    logs.logga("___ ijadw ___", ijadw);

    const vkbmdfs =
      //
      // "opkcwqe";
      funcVar(ijadw);

    return vkbmdfs;
  }
  //

  const asudjwe =
    //
    // "asodkaewqe";
    arrVar.map(hereo);

  return asudjwe;
}

export function WrapMain({
  elipsisTrue,
  obj,
  pushTrue,
  disVar,
  className,
  lineCount,
  dropLine,
  ...argos
}) {
  //
  const xvobkvds = lineCount ? "wrapTwo" : "wrapMain";
  const okxcvzx = xvobkvds;
  const asdwem = xvobkvds + " " + okxcvzx;
  const lokgos = {
    className: asdwem + " " + className,
    ...argos,
  };

  const pksdf = <div {...lokgos}>{obj}</div>;
  return pksdf;

  //
}

export function mapText(objs, aodew) {
  const nsajew = {
    style: { padding: "10px 0" },
    obj: objs,
    ...objs,
  };

  return <Mappo {...nsajew} />;
}

export function Mappo({
  obj,
  bulletTrue,
  beforeObj,
  arrVar,
  listvar,
  // dictvar,
  newLineTrue,
  ...asdiw
}) {
  //
  function asdokw(aaa, indexVar, lastVar) {
    // const newLineTrue = newLineTrue && !lastVar;

    // const gfbjtrr  = bulletTrue? ""
    const ikjwe = (
      <>
        {beforeObj}
        {aaa}
      </>
    );

    const oksdad = {
      ...asdiw,
      obj: ikjwe,
      // newLineTrue: newLineTrue,
    };

    logs.logga("___ oksdad ___", oksdad);

    return <Divo {...oksdad} />;
  }

  obj = obj || arrVar || listvar;

  return obj.map(asdokw);
}

export function PluralFunc(stringVar, lengAfter, noNumber) {
  var pluralize = require("pluralize");

  var plT = pluralize(stringVar, lengAfter);
  var finalSt = noNumber ? plT : lengAfter + " " + plT;

  return finalSt;
}

export function ImageAlign({
  //
  mainObj,
  smallObj,
  imageConfig,
  ...argo
}) {
  //
  //
  argo = {
    mainObj: <ImageCheckReplace noAvatar {...imageConfig} />,
    smallObj: smallObj,
    // styleVar={percStyle}
    // sizeVar={sizeVar}
    // mainVar={mainVar}
    // smallVar={smallVar},
    ...argo,
  };

  logs.logga("argo-zzz", argo, "asd09js");

  //
  return <AlignMain {...argo} />;
}

export function AlignMain({
  styleBig,
  styleSmall,
  circleButton,
  styleMain,
  styleVar,
  bottomTrue,
  mainVar,
  mainConfig,
  smallConfig,
  disVar,
  mainObj,
  smallObj,
  ...allConfig
}) {
  //
  //

  // 1align
  const dictAlign = {
    bottom: {
      bottom: 0,
    },
    bottomRight: {
      bottom: 0,
      right: 0,
    },
    bottomLeft: {
      bottom: 0,
      left: 0,
    },
    top: {
      top: 0,
    },
    topLeft: {
      top: 0,
      left: 0,
    },
    topRight: {
      top: 0,
      right: 0,
    },
    center: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function aliow({
    //
    alignvar = "center",
    obj,
    ...asofdkew
  }) {
    //

    const smallStyle = {
      position: "absolute",
      ...dictAlign[alignvar],
      ...styleSmall,
    };

    logs.logga("___ smallStyle ___", smallStyle);

    const oadwqesa = {
      style: smallStyle,
      obj: obj,
      ...asofdkew,
    };

    const ofvkbder = (
      //
      <Divo spanTrue {...oadwqesa} />
    );

    return ofvkbder;
  }

  const radVar = circleButton ? "25px" : "";

  const gonmfd = {
    position: "relative",
    textAlign: "center",
    ...styleMain,
  };
  const asdowqwp = {
    style: gonmfd,
  };

  mainConfig = {
    ...asdowqwp,
    ...mainConfig,
  };

  smallConfig = {
    listvar: smallObj,
    obj: aliow,
    ...smallConfig,
  };

  logs.logga("___smallConfig ___", smallConfig);

  // const asokdw =

  const asodkwq = (
    <>
      {/* <Divo {...mainConfig} /> */}
      <div {...mainConfig}>
        {mainObj}
        <ListMain objFuncTrue {...smallConfig} />
      </div>
    </>
  );

  allConfig = {
    obj: asodkwq,
    ...allConfig,
  };

  const wiwqasd = (
    //
    // asodkwq;
    //  disVar ? wokwqe : <Divo {...allConfig} />;
    <Divo {...allConfig} />
  );

  return wiwqasd;
}

// const queryParse = gens.modelConst["line"]["queryType"];

// const { data: obj, loading: loadtrue, error: importErr } = useQuery(
//   queryParse,
//   {
//     query: queryParse,
//     variables: {
//       slugURL: slugURL,
//       lineMedia: lineMedia,
//       lineNumber: lineNumber,
//     },
//   }
// );
// const {
//   dataVars,
//   loadtrue,
//   importErr,
// } = GraphComplex("series", argVars)
export function GraphComplex(typevar, fetchArgs) {
  //
  const { dataVary, loadtrue, importErr } = GraphFuncBase({
    typevar: typevar,
    messsVar: "23423ewsdavd",
    argsVar: fetchArgs,
  });
  const dataImport = dataVary;
  // ? modelFind(typevar, dataVary) : "";

  // const fullloo = dataImport;
  return {
    dataImport,
    loadtrue,

    importErr,
  };
}

export function GraphMutateFind(typevar, fieldType) {
  // const finalType = gens.modelConst[typevar];
  // const queryParse = finalType.mutateBase;
  // // const queryParse = fieldType ? finalType[fieldType] : finalType.queryBase;
  // // const queryRend = finalType.mutateBase;

  return typevar;
}

export function setCrispData(seriesReqo) {
  // window.$crisp = [];

  // // function setCrisp() {
  // window.CRISP_WEBSITE_ID =
  //   //
  //   "e475db7a-82c0-4166-9cba-16f641be7dc9";
  // // process.env.REACT_APP_CRISP_WEBID;

  // // window.$crisp.push(["set", "session:data", cripsos]);

  // var d = document;
  // var s = d.createElement("script");

  // s.src = "https://client.crisp.chat/l.js";
  // s.async = 1;
  // d.getElementsByTagName("head")[0].appendChild(s);

  // // window.$crisp.push(["set", "user:nickname", [nameo]]);
  // // window.$crisp.push(["set", "user:name", [nameo]]);

  //
  //
  // }
  const kxmcvd = {
    id: seriesReqo.id,
    // media: seriesReqo?.email,
    name: seriesReqo.name,
    nickname: seriesReqo.name,
    avatar: seriesReqo.imageAttr,
    email: seriesReqo.mainEmail,
    youtube: seriesReqo.youtube,
    // seriesRequest: seriesReqo,
  };

  for (const [key, value] of turnDict(kxmcvd)) {
    const dsfdfe = "user:" + key;
    const valso = [value];
    const kcvms = ["set", dsfdfe, valso];

    logs.logga("___ crisp ___", kcvms);

    // window.$crisp.push(kcvms);
    window.$crisp.push(kcvms);
  }
}

export function checkTimeBefore(dateObj) {
  const dateNow = new Date();

  logs.logga("___ dateObj ___", dateObj);
  logs.logga("___ dateNow ___", dateNow);

  const jdfawew = dateObj < dateNow;

  logs.logga("___ jdfawew ___", jdfawew);

  return jdfawew;
}

// export function openCrisp(datavar) {
//   // setCrispData(datavar);
//   // window.$crisp.push(["do", "chat:open"]);
// }

export function openCrisp(datavar) {
  // setCrispData(datavar);
  window.$crisp.push(["do", "chat:open"]);
}

export function ScribeChat({ scribeObj, ...asw }) {
  const sijwewq =
    "💬 Chat with " +
    //
    "Andrew";
  // currentScribe.name;

  const sadidswe = {
    bediaTrue: true,
    obj: sijwewq,
    onClick: openCrisp,
    ...asw,
  };

  const iajw = <ConfirmButton {...sadidswe} />;

  return iajw;
}

export function GraphTypeFind(typevar, fieldType = "base") {
  const xcvdszx = {
    // series: {},
    series: {
      queryPage: grapphy.SERIES_FETCH_BY_IDENT,
      base: grapphy.SERIES_FETCH_BY_IDENT,
    },
    media: {
      queryPage: grapphy.MEDIA_FETCH_BY_SLUG,
      base: grapphy.MEDIA_BASE_BY_IDENT,
    },
    order: {
      queryPage: grapphy.orderBaseFetch,
      base: grapphy.orderBaseFetch,
    },
    //
  };

  logs.logga("___ typevar ___", typevar);

  const finalType = xcvdszx[typevar];
  // const xsfdg = grapphy.orderBaseFetch;
  const xsfdg = fieldType ? finalType[fieldType] : finalType.queryBase;
  // logs.logga("zzzzz-queryParse", queryParse, "23409112");

  return xsfdg;
}

// // 1graph

export function GraphIdent(typevar, fetchIdent, saoj = "id_In") {
  const argMaino = { [saoj]: turnarray(fetchIdent) };

  const ijadqw = {
    typevar: typevar,
    args: argMaino,
    listTrue: true,
  };
  logs.logga("___ argMaino ___", argMaino);

  const { dataImport, loadtrue, importErr } = GraphAris(typevar, ijadqw);
  return { dataImport, loadtrue, importErr };
}

export function GraphAttr(typevar, attrVar, fetchIdent, typeFind, messvar) {
  const argMaino = { [attrVar]: fetchIdent };
  const { dataImport, loadtrue, importErr } = GraphArgo(
    typevar,
    argMaino,
    typeFind
  );

  logs.logga("argMaino-zz", argMaino, "dataImport-zzz", dataImport);

  return { dataImport, loadtrue, importErr };
}

export function GraphPage(typevar, identVar, typeFind) {
  const iaskdqwe = {
    queryParse: "queryPage",
    ...typeFind,
  };

  const covbvf =
    //
    // GraphSlug(typevar, identVar, typeFind);
    GraphSlug(typevar, identVar, iaskdqwe, "GraphPage");

  return covbvf;
}

// export function GraphMutate(typevar, typeFind) {
//   const queryParse = GraphMutateFind(typevar, typeFind);
//   // const queryParse = GraphMutateFind(typevar, typeFind);

//   const [addTodo, { dataMutate, loadMutate, errorMutate }] = GraphMutatey(
//     queryParse
//   );

//   //
//   return [addTodo, { dataMutate, loadMutate, errorMutate }];
// }

export function paramOrLocal(paramo, loceo) {
  const sdfke = getParamVar(paramo);
  const asdw = sdfke ? sdfke : getLocal(loceo);

  setLocal(loceo, asdw);

  return asdw;
}

export function GraphMedLink(gedass) {
  const gfohkfd = {
    mediaLink: gedass,
    createTrue: "y",
  };

  // const { dataImport: graphLinks, loadtrue: arrGrapLoad, arrGrapErr } =
  //   //
  //   GraphArgo("media", gfohkfd);
  // // GraphAttr("media", "mediaLink", mediaSelect);
  // // GraphPage("media", mediaSelect);

  const cvlobds = GraphArgo("media", gfohkfd);

  return cvlobds;
}

export function fetchBase(urvcxdsl, argMaino, funco) {
  const sijdwe = addLinkArr(urvcxdsl);
  const stripeos = postJSON(argMaino);

  const ijsvcbfdgfdad = {
    sijdwe: sijdwe,
    ARGS: argMaino,
    // POST: stripeos,
  };

  logs.logga("___ fetchBase ___", ijsvcbfdgfdad);

  fetch(sijdwe, stripeos)
    .then(function (response) {
      logs.logga("___ response ___", response);

      return response.json();
    })
    .then(function (session) {
      logs.logga("___ session ___", session);

      if (funco) {
        funco(session);
      }
      // return asuhdwe;
    })
    .catch(function (error) {
      logs.logga("___ error ___", error);
      console.error("Error:", error);
    });
}

// 1editmodel 1savemodel 1model
export function saveModel(typevar, argMaino, funco) {
  const argFnd = {
    typevar: typevar,
    singleTrue: true,
    returnBase: true,
    ...argMaino,
    // ...sdfokww,
  };
  fetchBase("edit_model", argFnd, funco);
}

export function saveModels(typevar, argMaino, funco) {
  const argFnd = {
    typevar: typevar,
    ...argMaino,
    // ...sdfokww,
  };
  fetchBase("edit_model", argFnd, funco);
}

// parsejson 1jsonparse 1json
export function filterChapter(chapList, oksdas, addTrue) {
  //
  function funsid(eacho) {
    const statios =
      //
      // "";
      eacho["startTime"] < oksdas["startTime"] &&
      eacho["endTime"] > oksdas["startTime"];

    const asijwe = {
      eacho: eacho,
      statios: statios,
      // fnfhgsd: fnfhgsd,
    };

    logs.logga("___ asijwe ___", asijwe);

    return statios;
  }

  function aisjwe({ startTime }) {}

  const newChap = isEmpty(chapList)
    ? [oksdas]
    : arrayReplace(chapList, oksdas, funsid);

  return newChap;
}

export function pageMedia(mediaObj) {
  return "/m/" + mediaObj.slugURL + "/highlights";
}

export function saveChapters(mediaObj, chapo, funco) {
  //
  //
  const ChpdoDIct = {};

  function iasjde(asidjaew) {
    const sdfrew = baseDict("highlight", asidjaew);
    ChpdoDIct[sdfrew.id] = sdfrew;
  }

  mapFunc(chapo, iasjde);

  logs.logga("___ ChpdoDIct ___", ChpdoDIct);

  const asudhwqe = {
    //
    // ...medi,
    chapterJSON: ChpdoDIct,
  };

  const ijdse = {
    saveArgs: asudhwqe,
    // returnAttr: "chapterList",
    slugURL: mediaObj.slugURL,
  };

  logs.logga("___ savhapters ___", ijdse);

  saveModel("media", ijdse, funco);
}

export function GraphArgo(typevar, argMaino, sdfokww) {
  const argFind = {
    typevar: typevar,
    args: argMaino,
    ...sdfokww,
  };

  // logs.logga("GraphArgo---zzzz", argFind, "asd9jq");

  logs.logga("___ argFind ___", argFind);

  const { dataImport, loadtrue, importErr } = GraphFuncBase(argFind);
  return { dataImport, loadtrue, importErr };
}

// 1graphql
export function GraphSlug(typevar, fetchIdent, typeFind, messvar) {
  const fetchit = { slugURL: getFirstArr(fetchIdent) };
  // fetchIdent = { slugURL__In: gens.turnarray(fetchIdent) };

  const sfijae = {
    ...typeFind?.args,
    ...fetchit,
  };

  const qwueqw = {
    ...typeFind,
    typevar: typevar,
    boolVar: fetchIdent,
    boolTrue: true,
    singleTrue: true,
    args: sfijae,
  };

  const trueLog = typevar == "order";

  if (trueLog) {
    logs.logga("___ GraphSlug ___", qwueqw);
  }

  const okawe = GraphFuncBase(qwueqw);

  return okawe;
}

export function GraphFind(typevar, objo, aatto) {
  const fetchIdent = { [aatto]: objo };
  // fetchIdent = { slugURL: fetchIdent };

  const { dataImport, loadtrue, importErr } = GraphArgo(
    typevar,
    fetchIdent
    // typeFind,

    // messvar
  );
  return { dataImport, loadtrue, importErr };
}

// addobj
export function AskObj({
  obj,
  typevar,
  checkLocal,
  buttonConfig,
  clickMessage,
  submitItems,
  ...sdfe
}) {
  // const sijewsd = {
  //   o
  // }

  const getoo = getLocal(checkLocal);

  const [wantSet, setwantSet] = useState(getoo);
  const [submitReason, setsubmitReason] = useState();

  function aieqw(reaonsi) {
    //

    const ijasew = joinString([clickMessage, "-- REASON WHY"], " ");

    const ojasew = {
      ...submitItems,
      ...reaonsi,
    };

    logs.logga(ijasew, ojasew);
    segReport(ijasew, ojasew);
    // reaonsi;
    // submitReason, setsubmitReason
  }

  function oskaee() {
    segReport(clickMessage, submitItems);
    setwantSet(true);
    setLocal(checkLocal, "qwe");
  }

  const asijwe = {
    // 🙌
    obj: "I want this!",
    onClick: oskaee,
    genConfig: { style: { margin: 20 } },
    ...buttonConfig,
  };

  const wanteBtye = (
    <>
      {obj}
      <ConfirmButton {...asijwe} />
      <hr />
    </>
  );

  const subboto = {
    name: "reason",
    type: "textvar",
    noTitle: true,
  };

  const ijsade = {
    typevar: typevar,
    listvar: [subboto],
    onSubmit: aieqw,
    buttonText: "Submit",
  };

  const awuwqe = (
    <>
      {/*  */}
      Thanks! Any reasons why you'd like this?
      <FormMain {...ijsade} />
    </>
  );

  const asidwe = (
    <>
      {/*  */}
      {/* We'll get right on it :) thanks for your interest! */}
    </>
  );

  const dgrte =
    //
    wantSet;
  // "w";

  const saidjew =
    //
    wantSet ? asidwe : wanteBtye;
  // submitReason ? asidwe : dgrte ? awuwqe : wanteBtye;

  const dsfewder = {
    obj: saidjew,
    ...sdfe,
  };
  const adwedsf = <Divo {...dsfewder} />;

  return adwedsf;
}

export function CenterObj({ disvar, ...sdfe }) {
  const isaeew = <Divo {...sdfe} />;

  const isewq = {
    // className: "col-6 mx-auto",
  };

  const idjfsrsd = disvar ? (
    isaeew
  ) : (
    <div class="container h-100">
      <div class="row align-items-center h-100">
        <div {...isewq}>
          {isaeew}
          {/* sfdgfdsok */}
          {/* <div class="jumbotron">I'm vertically centered</div> */}
        </div>
      </div>
    </div>
  );

  return idjfsrsd;
}

export function GraphAris(typevar, args) {
  const argFind = {
    typevar: typevar,
    ...args,
  };

  // logs.logga("GraphArgo---zzzz", argFind, "asd9jq");

  const { dataImport, loadtrue, importErr } = GraphFuncBase(argFind);
  return { dataImport, loadtrue, importErr };
}

export function GraphFetch(typevar, fetchIdent, typeFind) {
  const { dataImport, loadtrue, importErr } = GraphSlug(
    typevar,
    fetchIdent,
    typeFind
  );

  logs.logga("dataImport-cccccc", dataImport, "asd9jq");

  return dataImport;
}

// 1segment
export function segIdent(serObj) {
  logs.logga("___ segIdent ___", serObj);

  window.analytics.identify(serObj.id, {
    name: serObj.name,
    email: serObj.mainEmail,
    youtube: serObj.youtube,
  });
}

export function segReport(eokwqe, sdfijsadw) {
  window.analytics.track(eokwqe, sdfijsadw);

  logs.logga("___ window.analytics ___", window.analytics);
}

// 1graphfunction
// queryPage
export function GraphFuncBase({
  typevar,
  args,
  queryParse = "base",
  messvar,
  ...ijwesart
}) {
  //
  // queryParse =
  //
  // "base";
  // queryParse ? queryParse : "queryPage";

  logs.logga("___ queryParse ___", queryParse);
  logs.logga("___GraphFuncBase typevar ___", typevar);

  const art = {
    typevar: typevar,
    queryParse__Orig: queryParse,
    queryParse: GraphTypeFind(typevar),
    argsVar: args,
    ...ijwesart,
  };

  logs.logga("G/aphFctiony---zzzz" + typevar, art);

  const { dataVary: dataImport, loadtrue, importErr } =
    //
    args ? GraphFunctions(art) : "";
  // const dataImport = dataVary ? modelFind(typevar, dataVary) : "";

  logs.logga("GraphFtions-GLOBAL--", art, "23409112");
  // logs.logga("ddddddd-dataVary", dataVary, "23409112");
  logs.logga("ddddddd-dataImport", dataImport, "23409112");

  return {
    dataImport,
    loadtrue,
    importErr,
  };
}

export function getPaidOrder(slugURL, fdigjer) {
  const ioaew = getParamVar("success");
  const okdfa = "{'paidTrue': 'y'}";
  const FDSSDWSTS = ioaew && { saveArgs: okdfa };
  const orderID = slugURL ? slugURL : paramOrLocal("id", gens.stroeOrdId);
  const jhvxfd = {
    slugURL: orderID,
    ...FDSSDWSTS,
  };
  const sdjser = {
    args: FDSSDWSTS,
    fetchFunction: fdigjer,
  };

  logs.logga("___ orderId ___", orderID);

  //
  // "";
  // getPaidOrder(orderId);
  return GraphPage("order", orderID, sdjser);
  // return GraphArgo("order", FDSSDWSTS, sdjser);

  // GraphFunctions(argFind);
}

export function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export function WrapEmailRequest({}) {}

// messagediv
export function EmailMessage({
  scribeConfig,
  titlevar,
  textConfig,
  wrapTrue,
  obj,
  textvar,
  ...assad
}) {
  const qetqw = {
    starConfig: {
      starDimension: "20px",
      starSpacing: "10px",
    },
    ...scribeConfig,
    // ...assad,
    // ...baseArgs,
  };

  const ikaewa = <ScribeRate bottomTrue {...qetqw} />;

  // <Mappo
  //         // spanTrue
  //         newLineTrue
  //         style={{
  //           margin: "5px 10px 0",
  //           // color: "rned",
  //           // fontStyle: "bold",
  //         }}
  //         arrVar={obj}
  //       />

  const saodkwe = {
    ...textConfig,
  };

  const sjwew = obj && (
    <div {...saodkwe}>
      {/*  */}
      <hr />
      {obj}
    </div>
  );

  const uahweas = (
    <>
      {ikaewa}
      {sjwew}
    </>
  );

  const wrPfIN =
    wrapTrue &&
    {
      // whiteSpace: "nowrap",
      // overflow: "hidden",
      // textOverflow: "ellipsis",
    };

  const kadw = {
    ...gens.borderMessage,
  };

  const okawe = {
    style: kadw,
    // obj: uahweas,
    ...assad,
  };

  const aywew = <div {...okawe}>{uahweas}</div>;

  return aywew;
}

export function splitnewlines(uahwas, iajasdsd) {
  if (uahwas) {
    const aywew = uahwas.split("\n");
    const ysdge = {
      // margin: "30px",
      ...iajasdsd,
    };

    function asdijwqe(aslde) {
      const soewe = (
        <>
          <div {...ysdge}>{aslde}</div>
          {/* sadaks */}
          <br />
        </>
      );

      return soewe;
    }

    uahwas = mapFunc(aywew, asdijwqe);
  }

  return uahwas;
}

// parsehtml
export function convertHTML(stringo) {
  const htmlString = ReactDOMServer.renderToStaticMarkup(stringo);
  // logs.logga("12398y12312-xxx");
  // logs.logga("htmlString-xxx", htmlString);

  return htmlString;
}

// parsejson 1jsonparse 1json
export function tranSet(jsonVar) {
  const jsonPut =
    //
    jsonVar ? JSON.parse(jsonVar) : "";

  const jsonFin =
    //
    // jsonPut;
    jsonPut ? JSON.parse(jsonPut) : [];
  // []

  // const jsonFin = jsonVar ? JSON.parse(JSON.parse(jsonVar)) : [];
  // const jsonFin = jsonPut;

  const soadkqw = {
    jsonVar: jsonVar,
    jsonPut: jsonPut,
    jsonFin: jsonFin,
  };
  // logs.logga("tranSet-xxxxx", soadkqw, "12389u12j2");

  return jsonFin;
}

export function getListText(arVar) {
  const asijdwqe = splitArrayBy(arVar, "\n");
  const romvo = removeEmptyArray(asijdwqe);
  const asijqwe = filterDuplicates(romvo);
  // const asijqwe = filterDuplicates(asijdwqe);

  const asuhdqwe = {
    arVar: arVar,
    asijdwqe: asijdwqe,
    romvo: romvo,
    asijqwe: asijqwe,
  };

  logs.logga("___ getListText ___", asuhdqwe);

  return asijqwe;
}

//
// export function goThroughArray(arVar, arEl, arMatch, numTrue) {
//   // logs.logga("arVar-zzz", arVar, "234qwe213");

//   for (var i = 0; i < arVar.length; i++) {
//     const arraPoit = arVar[i];
//     const vary = arraPoit[arEl];

//     if (arMatch === vary) {
//       const retFull = numTrue ? [arraPoit, i] : arraPoit;
//       return retFull;
//     }
//   }
// }

export function goThroughArray(arVar, arEl, arMatch, numTrue) {
  // logs.logga("arVar-zzz", arVar, "234qwe213");

  for (var i = 0; i < arVar.length; i++) {
    const arraPoit = arVar[i];
    const vary = arraPoit[arEl];

    if (arMatch === vary) {
      const retFull = numTrue ? [arraPoit, i] : arraPoit;
      return retFull;
    }
  }
}

export function getErrorLinks(originalText) {
  const splitText = getListText(originalText);
  const goodLinks = stringYesListSplit(gens.chckLinkos, splitText);
  const badLinks = stringNotListSplit(gens.chckLinkos, splitText);
  const nogoodLinks = checkEmptyArray(goodLinks);

  const goodLinkString = joinString(goodLinks, "\n");
  const badLinkString = joinString(badLinks, "\n");
  const badGoodBoth = badLinkString + "\n\n\n\n" + goodLinkString;

  const nmgfgdfg = {
    splitText: splitText,
    goodLinks: goodLinks,
    badLinks: badLinks,
    nogoodLinks: nogoodLinks,
    goodLinkString: goodLinkString,
    badLinkString: badLinkString,
    badGoodBoth: badGoodBoth,
  };

  logs.logga("___ nmgfgdfg ___", nmgfgdfg);

  const osakdwe = {
    splitText,
    originalText,
    goodLinks,
    badLinks,
    nogoodLinks,
    badLinkString,
    goodLinkString,
    badGoodBoth,
  };

  // const iasjdew = {
  //   // lengthLink: lengthLink,
  //   findLinks: findLinks,
  //   errrLinks: errrLinks,
  //   // erroFindo: erroFindo,
  // };

  return osakdwe;
}

export function stringJSON(attrvar) {
  return JSON.stringify(attrvar);
}

export function postJSON(attrVar, ...aswa) {
  const aoijqw = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attrVar),
    // body: attrVar,
    ...aswa,
  };

  return aoijqw;
}

export function mergeAttrInner(attrVar, stringAttr, configVars) {
  const figgo = configVars ? configVars[stringAttr] : "";
  const gioo =
    //
    "";
  // figgo ?(attrVar, figgo) : attrVar;

  return gioo;
}

export function joinClass(arrVar) {
  const acronym = turnarray(arrVar).join(" ");
  return acronym;
}

export function mergeArrays(arrOne, arrTwo) {
  arrOne = turnarray(arrOne);
  arrTwo = turnarray(arrTwo);
  const arrFull = [...arrOne, ...arrTwo];
  const vbkmdf = filterDuplicates(arrFull);

  return vbkmdf;
}

export function mergeArrayArr(arrVar) {
  function plusSpace(obj, indexVar) {
    // logs.logga("stringVar-eeee: ", stringVar, "qwygebd982");
    // logs.logga("obj-eeee: ", obj, "qwygebd982");
    obj = turnarray(obj);
    obj = obj.concat(obj);
    return obj;
    // const
  }

  const wert = mapFunc(arrVar, plusSpace);
  return wert;
}

export function combineArrays(arrOne, arrTwo) {
  arrOne = turnarray(arrOne);
  arrTwo = turnarray(arrTwo);
  const arrFull = arrOne.concat(arrTwo);
  return arrFull;
}

// // 1duration
// function getDuration
//   const { getVideoDurationInSeconds } = require('get-video-duration')
//   const { getAudioDurationInSeconds } = require('get-audio-duration');

// // From a local path...
// getVideoDurationInSeconds('video.mov').then((duration) => {
//   logs.logga(duration)
// })

// getAudioDurationInSeconds('audio.flac').then((duration) => {
//   logs.logga(duration);
// });

const aosdkwe = gens.ScribeAndy;

export function ScribeRate({
  scribeObj = aosdkwe,
  messageClick,
  bottomTrue,
  matchTrue,
  noOpenChat,
  noBorder,
  socialTrue,
  messageTrue,
  topObj,
  bottomObj,
  imageTrue,
  imageSize,
  ratingTrue,
  imageConfig,
  starConfig,
  ...ars
}) {
  // rating={this.state.rating}
  // widgetRatedColors="blue"
  // changeRating={this.changeRating}

  const starro = scribeObj.rating;
  const starConfl = {
    rating: starro,
    // rating: 2.403,
    starDimension: "20px",
    starSpacing: "5px",
    // color: "yellow",
    // color: "blue",
    // ...starConfig,
  };

  const cvffds =
    //
    // "2xs"
    imageSize ? imageSize : 100;

  const sauhwe = {
    size: cvffds,
    // boxSize: "150x",
    name: scribeObj.name,
    src: scribeObj.imageAttr,
    ...imageConfig,
  };

  // imageSize =
  // imageTrue = "t";
  const mthchis =
    //
    imageSize ? <Avatar {...sauhwe} /> : scribeObj.emoji;

  const qwyewq = (
    <>
      {/* // */}
      {/* Referred Scribe:  */}
      {/* Silly Scribe*/}
      {mthchis} {scribeObj.name} ({scribeObj.scribeName} Scribe)
      {/* // "Refererred Scribe: Andrew"; */}
    </>
  );

  const asuhwe = (
    <div
      style={{
        color: "black",
        // fontWeight: "bold",
        // fontSize: "10%",
        // margin: "5px 5px 20px",
      }}
    >
      {qwyewq}
    </div>
  );

  const oiasjdwe = {
    divTrue: true,
    obj: qwyewq,
    // textvar: qwyewq,
    iconvar: "user",
    style: {
      fontWeight: "bold",
    },
  };

  function ASQesadw() {
    const ksdfa = {
      obj: "Matched Scribe",
      className: "bold",
      style: {
        fontSize: "1.3em",
      },
    };

    return <Divo {...ksdfa} />;
  }

  function RateObj() {
    const ijsdsad = (
      <>
        ✔️ {scribeObj.rating}% accuracy, {scribeObj.jobs} jobs
      </>
    );

    const qygew = {
      obj: ijsdsad,
      style: {
        fontSize: "0.8em",
      },
    };

    return <Divo {...qygew} />;
  }

  function MessButo() {
    const iksdwe = {
      bediaTrue: true,
      onClick: messageClick,
      obj: "Message " + scribeObj.name,

      style: {
        margin: "20px 0",
        fontSize: "30px",
      },
    };

    const kmbdfs = <ConfirmButton {...iksdwe} />;

    return kmbdfs;
  }

  function SocialGo() {
    const asjew = {};

    const xbjgdw = (
      <>
        <Center p={8}>
          <Stack spacing={2} align={"center"} maxW={"md"} w={"full"}>
            <Button
              w={"full"}
              colorScheme={"messenger"}
              leftIcon={<SiLinkedin />}
            >
              <Center>
                <Text>Send to Messenger</Text>
              </Center>
            </Button>
            <SiLinkedin
              //
              color="blue"
              className="shadowHover"
              size="40px"
            />
            <IconButton
              //
              size
              colorScheme="blue"
              variant={"outline"}
              aria-label="Search database"
              icon={<SiLinkedin />}
            />
            <IconButton
              //
              colorScheme="blue"
              variant={"outline"}
              aria-label="Search database"
              icon={<SiInstagram />}
            />

            {/* <SiInstagram /> */}
          </Stack>
        </Center>
      </>
    );

    return xbjgdw;
  }

  // socialTrue = "rt";
  const kxvssd = matchTrue && <ASQesadw />;
  // messageTrue = true;
  const ijqweqw = (
    <>
      <div style={{ textAlign: "center" }}>
        {topObj}
        {kxvssd}
        {asuhwe}

        {/* <ImageTextDiv {...oiasjdwe} /> */}
        {/* eddd */}
        {/* <StarRater {...starConfl} /> */}
        {ratingTrue && <RateObj />}
      </div>
      <hr />
      {messageTrue && <MessButo />}
      {socialTrue && <SocialGo />}
      {bottomObj}
    </>
  );

  const sdiaeqw =
    //
    // gens.butClass;
    // "";
    "shadowHover pointer";
  // !noBorder && gens.butClass;

  const zijcsad = {
    obj: ijqweqw,
    // className: sdiaeqw,
    // style: ijasdwe,
    // onClick: openCrisp,
    ...ars,
  };
  const vcxbsfd = <Divo {...zijcsad} />;

  return vcxbsfd;
}

// 1cohere
export function cohereTest(id, usrInfo) {
  //   Cohere.identify(
  //   "<user_id>", // Required: can be any unique ID
  //   {
  //     displayName: "<user_display_name>", // Optional
  //     email: "<user_email>", // Optional
  //   }
  // );
}

// 1check
export function mapHighsMed(mediArray, highList) {
  //   def dsjdaew(idvar){
  // saodw e=
  //   }
  //   mapFunc(highList, dsjdaew)
}

// 1check
export function checkTick(zxcfa) {
  const trueVar = zxcfa ? "✔️" : "❓";
  return trueVar;
}

export function betweeNum(valVar, numOne, numTwo) {
  // eeeeee
  const trueVar = numOne < valVar && valVar < numTwo;

  return trueVar;
}

// dictinarray
export function itemInArray(obj, a, innerVar) {
  logs.logga("a", a, "vjjjjjvv");
  logs.logga("obj", obj, "vjjjjjvv");

  function innerIt(itemVar) {
    const finealCheck = innerVar ? itemVar[innerVar] : itemVar;
    return finealCheck;
  }

  for (var i = 0; i < a.length; i++) {
    const aoneMe = a[i];
    const ideOne = aoneMe.id;
    const ideTwo = obj.id;

    const equIt = ideOne === ideTwo;

    // const equIt = innerIt(aoneMe) === innerIt(obj)

    logs.logga("aoneMe", aoneMe, "vjjjjjvv");
    logs.logga("ideOne", ideOne, "vjjjjjvv");
    logs.logga("ideTwo", ideTwo, "vjjjjjvv");
    logs.logga("equIt", equIt, "vjjjjjvv");

    if (equIt) {
      return true;
    }
  }
  return false;
}

export function includo(stringOne, stringTwo) {
  const checkFree = stringOne.toLowerCase().includes(stringTwo.toLowerCase());
  const checkTwo = stringTwo.toLowerCase().includes(stringOne.toLowerCase());
  const endo = checkFree || checkTwo;
  return endo;
}

export function stringInString(stringOne, stringTwo) {
  const checkFree = stringOne && stringTwo ? includo(stringOne, stringTwo) : "";
  return checkFree;
}

export function stringInArraySplit(stringVar, arrayVar, notOpp) {
  //
  function filsoa(v) {
    const qwew = regexPresent(stringVar, v);

    //
    const aisdw = {
      stringVar: stringVar,
      v: v,
      qwew: qwew,
    };

    logs.logga("stringArraySplit-FUNCTION", aisdw);

    return notOpp ? !qwew : qwew;
  }

  const truFull = checkFullArray(arrayVar);
  const trueMe = truFull ? arrayVar.filter(filsoa) : "";

  const bvmnfgh = {
    stringVar: stringVar,
    arrayVar: arrayVar,
    truFull: truFull,
    trueMe: trueMe,
  };

  logs.logga("___ strinnArraySplit ___", bvmnfgh);

  return trueMe;
}

// checkjsonfull
export function checkjsonfull(obj) {
  for (var key in obj) {
    const trueIt = obj[key] !== null && obj[key] != "";
    // const falseIt = obj[key] !== null && obj[key] != ""
    if (trueIt) return true;
  }
  return false;
}

export function stringNotListSplit(stringVar, arrayVar) {
  return stringInArraySplit(stringVar, arrayVar, "no");
}

export function stringYesListSplit(stringVar, arrayVar) {
  return stringInArraySplit(stringVar, arrayVar);
}

// export function setParamOrd(objvar, arrayVar) {
//   return stringInArraySplit(stringVar, arrayVar);
// }

export function linkCheckString(stringVar) {
  const asokawe = stringInString(gens.chckLinkos, stringVar);

  logs.logga("___ linkCheckString ___", asokawe);

  return asokawe;
}

export function stringInArray(stringVar, arrayVar, messvar) {
  const sdqweq = {
    arrayVar: arrayVar,
    stringVar: stringVar,
    messvar: messvar,
  };
  // logs.logga("stringInArray-aaaaa", sdqweq);

  const trueMe = stringInArraySplit(stringVar, arrayVar);
  const checko = checkFullArray(trueMe);

  const aweas = {
    trueMe: trueMe,
    checko: checko,

    ...sdqweq,
  };
  logs.logga("1stringInArray", messvar, aweas);

  return checko;
}

// 1case 1switch
// let endValue = "";
//   switch (attrVar) {
//     case "a":
//       endValue = ""
//       break;

//     default:
//       endValue = "";
//   }

export function checkTrueOther(
  //
  ewrfsd,
  asdf
) {
  ewrfsd = ewrfsd ? ewrfsd : asdf;

  return ewrfsd;
}

// linkmed medlink getmed medlink
export function getLinkPlay(
  //
  mediaLink,
  startTime,
  endTime,
  loopConf
) {
  const audTrue = stringInString(".mp3", mediaLink);
  const loopNo = "&loop=" + checkTrueOther(loopConf, "0");
  // startTime = checkTrueOther(startTime, timeFind);
  // endTime = checkTrueOther(endTime, endTimeFind);

  const mainStarto = "&t=" + startTime;
  const vidExp =
    // ""
    mainStarto + "#t=" + startTime + "&start=" + startTime;

  const mediaStarto =
    //
    mainStarto;
  // audTrue ? mainStarto : vidExp;

  const medEndio = audTrue ? "," + endTime : "&end=" + endTime;

  const loopeo = endTime ? mediaStarto + medEndio : mediaStarto;
  const asdjs =
    //
    joinString([
      //
      mediaLink,
      mediaStarto,
      // loopeo,
      // loopNo,
    ]);
  // mediaStarto
  // mediaLink + loopeo + loopNo;
  // mediaLink + "#t=120";
  // mediaLink + "#t=22s?autoplay=1"
  // mediaLink + "#t=1m2s"
  // https://www.youtube.com/watch?v=W3CDMcWlPyM#t=736

  const asdnqwi = {
    mediaLink: mediaLink,
    startTime: startTime,
    endTime: endTime,
    // loopConf,
  };

  logs.logga("getLinkPlay-zzz", asdjs);

  return asdjs;
}

export function getLinkPrep(dataImport, timeVar, endTime) {
  const mediaFind = dataImport && dataImport.mediaFileLink;
  const showTimeeo = dataImport && dataImport.firstLineTime;
  // timeVar = timeVar ? timeVar : timeFind ? timeFind : showTimeeo;
  const asdnw = dataImport ? getLinkPlay(mediaFind, timeVar, endTime) : "";

  return asdnw;
}

export function matchModelArr(asdfwer, dfsgre, attrVar = "id") {
  const dfksdfsd = mergeArrays(asdfwer, dfsgre);
  const okawew = filterDuplicates(dfksdfsd, attrVar);
  return okawew;
}

export function arrayIndex(stringVar, arrayVar, attrr = "") {
  return arrayVar.indexOf(stringVar);
}

export function arrayInString(stringVar, arrayVar) {
  const trueMe = arrayIndex(stringVar, arrayVar) > -1;
  return trueMe;
}

// export function getArrayIndex(stringVar, arrayVar, attrr = "") {
//   return arrayVar.indexOf(stringVar);
// }

export function arrayReplace(arrVar, newObj, failFunc) {
  logs.logga("___ arrayReplace AAA ___", arrVar);

  const overLength = getLength(arrVar);
  const dictFir = arrVar.findIndex((each) => {
    const equTreo = each["id"] == newObj["id"];
    return equTreo;
  });

  let failIndex = "";
  const ijds = dictFir > -1;

  if (ijds) {
    arrVar[dictFir] = newObj;
  } else {
    failIndex = arrVar.findIndex(failFunc);
    const truInner = failIndex > -1 ? failIndex + 1 : overLength - 1;
    arrVar.splice(truInner, 0, newObj);
  }

  const ijdf = {
    arrVar: arrVar,
    dictFir: dictFir,
    failIndex: failIndex,
  };

  logs.logga("___ arrayReplace bbbb ___", ijdf);

  return arrVar;
}

// getindexarray arrayindex
export function getArrayIndex(arrVar, obj, attrVar) {
  // arrVar = removeEmptyArray(arrVar);
  const dicto = arrVar.findIndex((each) => {
    const ijaew = attrVar ? each[attrVar] : each;
    const equTreo = ijaew == obj;

    const isjwe = {
      each: each,
      ijaew: ijaew,
      obj: obj,
      equTreo: equTreo,
    };

    logs.logga("___ isjwe ___", isjwe);

    return equTreo;
    // return attrVar ? each[attrVar] == obj[attrVar] : each == obj;
  });

  const truea = dicto;
  // const truea = dicto > -1;
  return truea;
}

// removeduplicate 1duplicate
export function filterDuplicates(arrVar, attrVar) {
  var uniqBy = require("lodash.uniqby");

  // const serMain = new Set(arrVar);
  // Array.from(serMain);

  // attrVar ? uniqBy(array, attrVar)

  // function onlyUnique(value, index, self) {
  //   const ckbmdsfsa = self.indexOf(value) === index;

  //   const ijsdsa = {
  //     value: value,
  //     index: index,
  //     self: self,
  //     ckbmdsfsa: ckbmdsfsa,
  //   };

  //   logs.logga("___ ijsdsa ___", ijsdsa);

  //   return ckbmdsfsa;
  // }

  // var serObjs = arrVar.filter(onlyUnique);

  const serObjs = !arrVar
    ? []
    : attrVar
    ? uniqBy(arrVar, attrVar)
    : uniqBy(arrVar);

  // logs.logga("xxxw---serMain", serMain, "eeee");
  // logs.logga("xxxw---serObjs", serObjs, "eeee");

  return serObjs;
}

export function splitArrayBy(stringVar, splitVar) {
  splitVar = splitVar ? splitVar : ",";
  const checkStr = stringVar ? !checkEmptyArray(stringVar) : "";
  const splitMe = checkStr ? stringVar.split(splitVar) : "";

  logs.logga("splitMe--xxx", splitMe, "1239812io");
  logs.logga("stringVar--xxx", stringVar, "1239812io");
  logs.logga("checkStr--xxx", checkStr, "1239812io");

  return splitMe;
}

export function getBasePath(linkWindow, linkAdd) {
  linkWindow = linkWindow ? linkWindow : window;
  const splito = linkWindow.location.pathname;
  const splitMe = splito.split("/");
  const finSplit = splitMe[1];

  logs.logga("----finSplit", finSplit);

  return finSplit;
}

export function linkLocation(linkWindow, linkAdd) {
  // logs.logga("----linkWindow", linkWindow);

  return linkWindow.location.pathname.replace(/\/+$/, "") + "?" + linkAdd;
}

export function mapSpace(arrayo, asdijwe = " ", dictvar) {
  function asdda(ojs) {
    return (
      <>
        {ojs}
        {asdijwe}
      </>
    );
  }

  const okasd = arrayo ? mapFunc(arrayo, asdda, dictvar) : "";
  // asdda
  return okasd;
}

export function joinString(arrayo, strngo = "", first = "") {
  arrayo = turnarray(arrayo);
  const okasd = arrayo ? arrayo.join(strngo) : "";

  return okasd;
}

function rgPrep(stringVar) {
  //

  stringVar = stringVar.replace("?", "/?");
  stringVar = stringVar.replace(")", "/)");

  return stringVar;
}

export function turnRegex(stringVar, globalTrue) {
  // const globVar = globalTrue ? "gi" : "";

  stringVar = stringVar && rgPrep(stringVar);

  logs.logga("___ stringVar ___", stringVar);
  logs.logga("___ globalTrue ___", globalTrue);

  const newR = new RegExp(stringVar, globalTrue);

  return newR;
}

export function regexPresent(regVar, stringVar) {
  regVar = regVar ? turnRegex(regVar, "gi") : "";
  const asdni = stringVar && regVar ? regVar.test(stringVar) : "";

  const asjdw = {
    regVar: regVar,
    asdni: asdni,
    stringVar: stringVar,
  };
  logs.logga("regexPresent", asjdw);

  return asdni;
}

export function regexStringEnd(regVar, stringVar) {
  const fullReg = turnRegex(regVar + "$");

  logs.logga("fullReg---", fullReg, "5555");
  return stringVar.match(fullReg);
}

// 1regex
export function regexReplace(stringVar, regVar, repVar) {
  // logs.logga("stringVar---", stringVar, "55455");
  // logs.logga("regVar---", regVar, "55455");

  regVar = turnRegex(regVar, "gi");
  repVar = repVar ? repVar : "";

  const endO = stringVar ? stringVar.replace(regVar, repVar) : stringVar;

  return endO;
}

export function removeStartEnd(stringVar) {
  stringVar = stringVar ? stringVar.slice(1, -1) : "";
  return stringVar;
}

// 1delete 1remove
export function removeString(stringVar, charVar) {
  stringVar = regexReplace(stringVar, charVar);

  return stringVar;
}

export function getSearchParam(stringVar) {
  stringVar = stringVar ? stringVar : getParamVar(gens.searchLinkString);
  const ksdfe = getParamVar(stringVar);
  const okasd =
    //
    // removeString(ksdfe, '"')
    removeStartEnd(ksdfe);

  //
  return okasd;
}

// addmediabutton
export function AddMedTypes({ ...asdwq }) {
  //
  const uplConf = {
    iconvar: "upload",
    textvar: "Upload Files",
    colorScheme: "blue",
    // style: {
    //   color: "white",
    //   background: "blue",
    // },
  };

  const lnConf = {
    iconvar: "link",
    textvar: "Add Links",
    colorScheme: "red",
    // style: {
    //   color: "white",
    //   background: "red",
    // },
  };

  const confirmCnfo = {
    iconvar: "tick",
    textvar: "Confirm",
    colorScheme: "green",
    // onClick: () => setshoTab("link"),
    // style: {
    //   color: "white",
    //   background: "red",
    // },
  };

  const TbDict = {
    link: lnConf,
    file: uplConf,
  };
  const jasew = [
    //
    "link",
    "file",
  ];

  const idsjawe = {
    size: "xl",
    className: gens.butClass,
  };

  const isjdwq = {
    dictvar: TbDict,
    listvar: jasew,
    itemConfig: idsjawe,
    ...asdwq,
  };

  const ijasdw = (
    <ImageTextList
      //
      horizTrue
      {...isjdwq}
    />
  );

  //
  return ijasdw;
}

export function stringTime(adsfer, numvar = 2) {
  return adsfer && adsfer.toFixed(numvar);
}
// return <MainPreview {...loadIt} />;

// 1loading
export function MainPreview({
  obj,
  typevar,
  floatConfig,
  errorObj,
  loadtrue,
  objLoad,
  quoteTrue,
  quoteConfig,
  mainConfig,
  noObj,
  objCheck = true,
  errorTrue,
  ...asdw
}) {
  // eeeeee
  errorTrue = !objCheck || errorTrue;

  const channls =
    //
    "Hang tight. We're finding your channel.";

  const ffmNedia = (
    //
    <>
      Finding your links...
      {/* We're finding your links online */}
      <br />
      {/* This shouldn't take too long... */}
      {/* // "Loading media...."; */}
    </>
  );

  const loadConfa = {
    findEpisodes: channls,
    findMedia: ffmNedia,
  };

  //
  function MainPrev() {
    //
    const qyegwq =
      //
      // "asidjweqwsd";
      loadConfa[typevar];

    const ovkfodsd = {
      // type={type}
      // color={color}
      // height={667}
      // width={375}
    };

    const asijdwe = (
      <ReactLoading
        //
        {...ovkfodsd}
      />
    );

    const asdnwqa = {
      leftObj: asijdwe,
      centerObj: qyegwq,
      // topObj: objLoad,
      ...mainConfig,
    };

    const dsgte = {
      loadingText: qyegwq,
      // textvar: qyegwq
      ...mainConfig,
    };

    const ijawe = (
      <>
        {qyegwq}
        {/* {asijdwe} */}
        {/* <ShowObjit /> */}
        {/* <FloatMainAll {...asdnwqa} /> */}
        {/* <ImageTextDiv isLoading {...dsgte} /> */}
      </>
    );

    const odsmfasdw = {
      obj: ijawe,
      style: { textAlign: "center" },
    };

    return <Divo {...odsmfasdw} />;
  }

  const qureoa = quoteTrue && <ShowObjit />;
  const ijasdwe = loadtrue ? <MainPrev /> : errorTrue ? errorObj : obj;

  // gens.StyledPreview
  const oakew = (
    <>
      {ijasdwe}
      {qureoa}
    </>
  );

  const ijweq = {
    obj: oakew,
    ...asdw,
  };

  return <Divo {...ijweq} />;
}

export function ShowObjit({ ...ase }) {
  //
  // // const allASO = objCheck ? obj : "";
  // logs.logga("-asdsad ", allASO);
  const ikasdw =
    //
    "";
  // gens.lineDict["main"];

  const ijasdw = {
    // iajsdsad
    lineObj: ikasdw,
    ...ase,
  };

  const aushew =
    //
    "";
  // <HighlightBoxy {...ijasdw} />;

  return aushew;
}

// 1channel
export function getyoutubelink(ideos, typeos) {
  const oakwe = "https://www.youtube.com/channel/";
  const ijawew = oakwe + ideos + "/" + typeos;

  return ijawew;
}

// seriesyoutubefetch
export function Sersyt(seriesReqo, aosew = "videos") {
  const ytlnkO =
    //
    "https://studio.youtube.com/channel/videos/";

  const lnkpen =
    //
    seriesReqo ? getyoutubelink(seriesReqo.youtube, aosew) : ytlnkO;

  const btLink = {
    linkConfig: {
      linkvar: lnkpen,
      outsideTrue: true,
    },
  };

  return btLink;
}

export function getRndInteger(min, max) {
  var zdasd = Math.random() * (max - min);
  zdasd += min;
  return zdasd;
}

export function checkParamVar(stringVar, linkMain) {
  const linkvar = paramLink(linkMain);
  const checkIt = linkvar ? linkvar.has(stringVar) : "";

  return checkIt;
}

export function getDuration(endo, starto) {
  let isade = endo - starto;
  return isade;
}

export function getMediaChapter(mediaObj, idObj) {
  const sdsuhe = mediaObj["chapterList"];
  const iasjw = mapArrDictAttr(sdsuhe);
  const jsfsa = iasjw && iasjw[idObj];

  logs.logga("___ sdsuhe ___", sdsuhe);
  logs.logga("___ getMediaChapters ___", iasjw);
  logs.logga("___ idObj ___", idObj);

  return jsfsa;
}

// 1local
export function getLocal(identVar) {
  // const date2 = new Date(localStorage.getItem("date"));
  const trueProd = localStorage.getItem(identVar);
  const trueAll = trueProd ? JSON.parse(trueProd) : "";
  const sadokawe = trueAll?.data;

  const gfjhtrrt = {
    // identVar:
    trueProd: trueProd,
    trueAll: trueAll,
    sadokawe: sadokawe,
  };

  const sdfije =
    //
    // true
    identVar == "bediaBasket_LOC___";

  if (sdfije) {
    logs.logga(identVar + "___ getLocal ___", gfjhtrrt);
  }

  return sadokawe;
}

export function setLocal(stringVar = "", identVar) {
  const dateFirst = new Date();
  // const dateo = dateFirst.getTime();

  const oobo = {
    date: dateFirst,
    data: identVar,
  };

  const obVar = JSON.stringify(oobo);
  // const obVar = {
  //   [stringVar]: oobo
  // };

  const asdijfgfrwe = {
    stringVar: stringVar,
    obVar: obVar,
  };

  const sdfije =
    //
    // true
    identVar == "bediaBasket_LOC___";

  if (sdfije) {
    logs.logga(stringVar + "__setLocal---", asdijfgfrwe, "1231209u12");
  }

  if (stringVar) {
    localStorage.setItem(stringVar, obVar);
  }
}

// 1selecton
export function selectOn() {
  const sello =
    //
    gens.appLightBack;
  // "lightgrey";
  // "grey"
  // "red";

  const lgkfbd =
    //
    // "buttonHover"
    // "shadowHover";
    "shadowMain shadowHover";
  // "borderHover shadowMain";

  const okvbdsf = {
    style: {
      // ...gens.borderMain("black"),
      ...backgroundMain(sello),
      //
      // fontSize: "20px",
      // width: "330px",
      // minWidth: "220px",
      // maxWidth: "280px",
    },
    textConfig: {
      className: "wrapTrue",
    },
    className: lgkfbd,
    // ...kssdf,
  };

  logs.logga("___ okvbdsf ___", okvbdsf);

  return okvbdsf;
}

export const HorizFeature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export function BackForwardObj({
  obj,
  backFunc,
  disVar,
  arrVar,
  textTrue,
  forwardFunc,
  disBack,
  betweenObj,
  disForward,
  hideBack,
  hideForward,
  genConfig,
  backConfig,
  forwardConfig,
  buttonConfig = {},
  ...argssdf
}) {
  //
  //
  const endLeftfsa = {
    iconvar: "left",
    obj: textTrue ? " Back" : "⬅",
    onClick: backFunc,
    bediaTrue: true,
    // disVar: disBack,
    ...backConfig,
  };

  const endRighto = {
    iconvar: "right",
    obj: textTrue ? "Forward" : "",
    onClick: forwardFunc,
    // disVar: disForward,
    ...forwardConfig,
  };

  function saaoekwq(oskwe) {
    const ijawe = {
      className: gens.butClass,
    };

    const zdsdar = {
      ...oskwe,
      ...ijawe,
      ...buttonConfig,
    };

    const uajwe = (
      //
      <ConfirmButton {...zdsdar} />
      // <ImageTextDiv {...endRighto} />;
      // <Divo {...zdsdar} />
    );

    return uajwe;
  }

  const xcijsdf =
    //
    // "okasdwqe";
    !hideBack && saaoekwq(endLeftfsa);

  const dfgkmre =
    //
    // "sadokqwe";
    !hideForward && saaoekwq(endRighto);
  // {<Divo {...centerConfig} obj={obj} />}

  const uawhe = {
    leftObj: xcijsdf,
    centerObj: betweenObj,
    rightObj: dfgkmre,
    ...argssdf,
  };

  const ijwqeq = (
    <>
      <FloatMainAll
        //
        noVertAlign
        nowap
        listTrue
        {...uawhe}
      />
      {obj}
    </>
  );

  const dfbire = {
    obj: ijwqeq,
    ...genConfig,
  };

  return <Divo {...dfbire} />;
}

export function PriGoigna() {
  const asokwe =
    // transcribe new episodes
    "Bedia can also transcribe new episodes as soon as they're uploaded.";
  const iajwe =
    //
    "To connect your podcast /\n Youtube channel.";
  // "for a discount."

  const auhwxcvsdr = {
    obj: "Get in touch",
    style: { ...gens.linkStyle, fontSize: "1.3em", paddingTop: "10px" },
    linkConfig: gens.gettouchconf,
  };

  return "asdokwe";
  // const oksadwe = (
  //   <>
  //     {asokwe}
  //     <br />
  //     {/* <ConfirmButton {...auhwxcvsdr} /> */}
  //     <Divo {...auhwxcvsdr} />
  //     {iajwe}
  //   </>
  // );

  // return oksadwe;
}

export function MetaRightAr() {
  const asokwe =
    // transcribe new episodes
    "Bedia can also transcribe new episodes as soon as they're uploaded.";
  const iajwe =
    //
    "To connect your channel.";
  // "To connect your podcast /\n Youtube channel.";
  // "for a discount."

  const okdas = { ...gens.linkStyle, fontSize: "1.3em", paddingTop: "10px" };

  const cvokdsasc =
    //
    // "Connect your channel";
    "Get in touch";

  const auhwxcvsdr = {
    obj: cvokdsasc,
    style: okdas,
    linkConfig: gens.gettouchconf,
  };

  const oksadwe = (
    <>
      {asokwe}
      <br />
      {/* <ConfirmButton {...auhwxcvsdr} /> */}
      <Divo {...auhwxcvsdr} />
      {iajwe}
    </>
  );

  return oksadwe;
}

export function SecurePay() {
  const okxda = {
    className: "bold",
    linkConfig: {
      outsideTrue: true,
      linkvar: "https://stripe.com/",
    },
    obj: "Stripe",
  };

  const cvijsads = <Divo spanTrue {...okxda} />;

  const aisjdwsa = (
    <>
      {/*  */}
      All payment details safely secured through {cvijsads}.
    </>
  );

  return aisjdwsa;
}

// getstripeargs 1stripe
export function makeStripeArgs({ mediaList, currencyVar }) {
  //
  function asdijwe(meddo) {
    logs.logga("___ meddo ___", meddo);

    const qwhew = {
      transcript: "TRANSCRIPT",
      subtitle: "SUBTITLE",
      both: "TRANSCRIPT & SUBTITLE",
    };

    const sdpdfre = [meddo.imageAttr];

    const iajwew = qwhew["both"];
    const Findito = iajwew + ": '" + meddo.name + "'";
    const ijswe = {
      name: Findito,
      images: sdpdfre,
    };

    const asjwe =
      //
      Math.ceil(meddo.priceFloat * 100);

    const ijaew = {
      price_data: {
        currency: currencyVar,
        product_data: ijswe,
        unit_amount: asjwe,
      },
      quantity: 1,
    };

    return ijaew;
  }

  function genIdas(sowdsf) {
    const ijawew = sowdsf.id;

    return ijawew;
  }

  const medPriMap = mapFunc(mediaList, asdijwe);
  const mdIsas = mapFunc(mediaList, genIdas);
  const ijew = {
    stripeData: medPriMap,
    mediaList: mdIsas,
  };

  return ijew;
}

// 1stripe
export function OrderFormStripe({ ...arg }) {
  // return "12389hu12i312";

  const stripeLive = process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY;
  const stripeTest = process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY;

  const stripPubKeyConts =
    //
    // stripeTest;
    stripeLive;
  // gens.DEPLOYTRUE ? stripeLive : stripeTest;

  const stripePromise = loadStripe(stripPubKeyConts);

  return (
    <>
      <Elements
        //
        stripe={stripePromise}
      >
        {/* <StripeHookProvider> */}
        <OrderStripeBase {...arg} />
        {/* <OrderFormStripey {...arg} /> */}
        {/* </StripeHookProvider> */}
      </Elements>
    </>
  );
}

export function addLinkArr(arrVar) {
  const arro = [tsts.urlLINk, arrVar];
  const assaw = arro.join("/");
  return assaw;
}

export function TitleMain({
  subtitleConfig,
  belowObj,
  sizevar = "34px",
  subtitlevar,
  ...args
}) {
  const aijsdwe =
    //
    // gens.butClass;
    "bold";

  const asyhdwe = {
    fontSize: sizevar,
    // padding: "0 2% 20px",
  };

  //
  const titleConfig = {
    style: asyhdwe,
    className: aijsdwe,
    ...args,
  };

  const sijwesae = {
    style: { fontSize: "28px" },
    obj: subtitlevar,
    ...subtitleConfig,
  };

  // BediaTextDiv
  const oksd = (
    <>
      {/* asdokw */}
      <Divo {...titleConfig} />
      <Divo {...sijwesae} />
      {belowObj}
      {/* <ImageTextDiv noIcon noImage {...titleConfig} /> */}
    </>
  );

  const iasjwe = {
    obj: oksd,
  };

  return oksd;
}

// 1title
export function PageTitle({
  topText,
  bottomText,
  topConfig,
  bottomConfig,
  ...asdwe
}) {
  const topSize = gens.mobview("24px", 34);
  const bttmtSize = gens.mobview("20px", 30);

  const iadqweok = {
    obj: topText,
    style: { fontWeight: "bold" },
  };

  const yeqwe = {
    style: { fontSize: bttmtSize },
    obj: bottomText,
  };

  const gfigerw = (
    <>
      <Divo {...iadqweok} />
      <Divo {...yeqwe} />
    </>
  );

  const heigTitle =
    //#
    // "5px";
    gens.mobview("40px", "60px");

  const fghktrt = {
    verticalAlign: "text-bottom",
    fontSize: topSize,
    marginBottom: heigTitle,
    // background: "blue",
    // color: "black",
    textAlign: "left",
    // padding
  };

  const iewq = {
    obj: gfigerw,
    style: fghktrt,
    ...asdwe,
  };

  const oawe = <Divo {...iewq} />;

  return oawe;
}

export function padline(hgito) {
  const skdfsa = {
    height: hgito,
  };

  const zxcmsd = (
    <>
      {/* EXAMPLE TEXT  */}
      {/* Hello! */}
    </>
  );

  const kmxvs = {
    obj: zxcmsd,
    style: skdfsa,
  };

  return <Divo {...kmxvs} />;
}

export function changePage(path, localas) {
  window.scrollTo(0, 0);

  // const location = {
  //   pathname: "/" + path,
  //   state: localas,
  // };

  // history.push(location);
}

export function requestSeriesMessage(ijawe) {
  const aywasew =
    //
    " your video was requested.";
  // " your video was requested by one of our transcribers.";
  // " one of our transcribers requested your video.";
  // " one of our transcribers wants to work on your videos.";

  const uehrwa = ijawe && getSHortSet(ijawe) + "," + aywasew;
  const zasdwe = (
    <>
      {/*  */}
      {uehrwa}
      {/* {"requested your video"} */}
    </>
  );

  return zasdwe;
}

// export openSerYt(seriesObj){

// }

export function AddSeriesOpen({
  seriesObj,
  style,
  textvar = "Add More",
  ...asd
}) {
  logs.logga("___ seriesObj ___", seriesObj);

  const oksadae = {
    genConfig: Sersyt(seriesObj),
  };
  const mainos =
    //
    // oksadae;
    seriesObj && oksadae;

  const uiasjdw = {
    onlyText: textvar,
    className: gens.butClass,
    style: {
      fontSize: "36px",
      background: "green",
    },
    ...mainos,
    ...asd,
  };

  logs.logga("___ AddSeriesOpen ___", uiasjdw);
  logs.logga("___ seriesObj ___", seriesObj);

  const asokwe = <ConfirmButton bediaTrue {...uiasjdw} />;

  return asokwe;
}

export function getLocalMedia(graphRequest) {}

export function getMediaSeries(graphRequest) {
  const skdfsa = graphRequest && getFirstArr(graphRequest.videoSeries);

  return skdfsa;
}

export function CurrencyConvo(count, amounto) {
  var ccvar = require("iso-country-currency");

  const kawe = ccvar.getAllInfoByISO(count).currency;
  const obkdfgds = convertCurrency("USD", kawe, amounto);

  return obkdfgds;
}

// 1currency
export function CurrencyMain(count = "US", amount, ijfwew) {
  const flotAm = parseFloat(amount);
  const showTrue = flotAm > 0;

  function adojwer() {
    var ccvar = require("iso-country-currency");

    const kawe = ccvar.getAllInfoByISO(count);
    const ijadwe = kawe.currency;

    const dkhdt = {
      from: "USD",
      to: ijadwe,
      value: flotAm,
    };

    logs.logga("___ flotAm ___", flotAm);
    logs.logga("___ dkhdt ___", dkhdt);

    const amono =
      //
      // "ERRR";
      showTrue ? <CurrencyConverter {...dkhdt} /> : "0.00";

    const ijasdw = (
      <>
        <Flex>
          {ijfwew}
          {kawe.symbol}
          {amono}
        </Flex>
      </>
    );

    return ijasdw;
  }

  const usTrue = count == "US";
  const countBase = showTrue ? flotAm.toFixed(2) : "0.00";
  const dasjwee = usTrue ? "$" + countBase : adojwer();

  return dasjwee;
}

export function CurrencyChoose({ obj, amount, chooseFunc, ...sfdw }) {
  const skdfsa = {
    // height: "",
  };

  const slctmani = [
    //
    "US",
    "GB",
    // "FR",
    // "DE",
    // "IT",
  ];

  function erijtew(count) {
    // const rwer;

    const dkhdt = {
      from: "USD",
      to: count,
      value: amount,
    };
    const srfesdr = {
      USD: "$",
      GB: "£",
    };

    const ijasdw = (
      <>
        {/* {srfesdr[count]} */}
        <CurrencyConverter {...dkhdt} />
      </>
    );

    const ser = {};
    // const iwqeqw = <WarpM

    // const ijasdw = "";

    return ijasdw;
  }

  const oskfawe = {
    US: { primary: erijtew("USD") },
    GB: { primary: erijtew("GBD") },
    // FR: "FR",
  };

  const badaew = {
    countries: slctmani,
    selected: obj,
    onSelect: chooseFunc,
    // customLabels: oskfawe,
    ...sfdw,
  };

  const zxcmsd = (
    <>
      {/* EXAMPLE TEXT  */}
      {/* Hello! */}
      <ReactFlagsSelect {...badaew} />
      {/* <CurrencyConverter {...dkhdt} /> */}
    </>
  );

  const kmxvs = {
    obj: zxcmsd,
    style: skdfsa,
  };

  return <Divo {...kmxvs} />;
}

export function boldTitle(asdfome) {
  const okfdas = {
    className: "titleMain",
    style: {
      //
      margin: "35px 10px 25px",
      textAlign: "center",
      fontSize: "28px",
    },
    obj: asdfome,
  };

  return <Divo {...okfdas} />;
}

export function trimDownload(videos) {
  const ijsadw =
    //
    // "https://storage.googleapis.com/veed-docs/sample-video.mp4"
    videos.baseFileLink;

  const uhaew = "key_test_64WI2udBeKBeEIamolldDULv";

  var veed = require("@veedstudio/veed-node")(uhaew);
  let url = "https://api.veed.io/api/assets";

  const sadwae = {
    type: "video",
    params: {
      source: {
        url: ijsadw,
      },
      trim: {
        from: 2.0,
        to: 5.0,
      },
    },
  };

  const trimmos = "";
  // {
  //   type: "text",
  //   params: {
  //     value: "My First Render!",
  //     position: {
  //       x: "center",
  //       y: "top",
  //     },
  //   },
  // },

  const endiro = [
    //
    sadwae,
  ];

  const ijsaes = {
    // method: "GET",
    headers: { Authorization: "key_test_64WI2udBeKBeEIamolldDULv" },
    elements: endiro,
  };

  logs.logga("___ url ___", url);
  logs.logga("___ sadwae ___", sadwae);

  fetch(url, postJSON(ijsaes))
    .then((res) =>
      //
      res.json()
    )
    .then((json) => logs.logga(json))
    .catch((err) => console.error("error:" + err));

  return;
}

export function FlexMain({ obj, ...asdwe }) {
  const skdfsa = {
    // height: "",
  };

  const zxcmsd = (
    <Flex>
      {/* EXAMPLE TEXT  */}
      {obj}
    </Flex>
  );

  const kmxvs = {
    obj: zxcmsd,
    style: skdfsa,
  };

  return <Divo {...kmxvs} />;
}

export function exampleHighs() {
  //
  const ijfsdr = [
    createHighlight({
      index: 1,
      name: "Intro",
      keyQuote: "Testing, testing...",
      description: "The good stuff starts soon...",
      startTime: 0,
      // endTime: ,
    }),
    createHighlight({
      index: 2,
      name: "Show begins",
      keyQuote: "Let's get this show on the road!",
      description: "The first topic/guest is introduced.",
      startTime: 30,
      // endTime: ,
    }),
  ];

  return ijfsdr;
}

export function SelectArray({ disVar, obj, selectFunc, ...selectConfig }) {
  //
  function innerVaroas(k) {
    const isa = k.props.listarr;
    return isa;
  }
  function saonw(selectingItems) {
    let updatedItems = mapFunc(selectingItems, innerVaroas);

    return selectFunc(updatedItems);
  }

  selectConfig = {
    className: "main",
    clickClassName: "tick",
    tolerance: 0,
    onSelectionFinish: saonw,
    ...selectConfig,
  };

  const ajsdiw = {
    selectFunc: selectFunc,
    ...selectConfig,
  };

  logs.logga("SelectArray-zz", ajsdiw);

  const ijvsaw = disVar ? (
    obj
  ) : (
    <SelectableGroup
      //
      // globalMouse
      // disabled
      enableDeselect
      {...selectConfig}
    >
      {obj}
    </SelectableGroup>
  );

  return ijvsaw;
}

export function geFDrEF(sadlkw) {
  const odkfs = sadlkw?.current;
  // && sadlkw.current.getCurrentTime();
  return odkfs;
}

export function getMedRef(playMediaRef) {
  const playRefCurrent = playMediaRef?.current;
  const playedTimeFloat = playRefCurrent ? playRefCurrent.getCurrentTime() : "";

  const fghuretr = {
    playRefCurrent: playRefCurrent,
    playedTimeFloat: playedTimeFloat,
  };

  logs.logga("___ getMedRef ___", fghuretr);

  return playedTimeFloat;
}

export function SliderObj(dataVar) {
  const skdfsa = {
    height: "",
  };

  const saijdwe = {
    // max={1152}
    // currentTime={this.state.currentTime}
    // progress={400}
    // onChange={(time)=>{
    //     this.setState({
    //         currentTime:time
    //     } as State);
    // }}
    // offset={0}
    // secondsPrefix="00:00:"
    // minutesPrefix="00:"
  };

  const zxcmsd =
    //
    "";
  // <VideoSeekSlider />;

  const kmxvs = {
    obj: zxcmsd,
    style: skdfsa,
  };

  return <Divo {...kmxvs} />;
}

export function floatMeddo(timvar, numvar = 2) {
  const kosad = timvar && timvar.playedSeconds;
  return kosad;
}

export function linkOrdse({
  //
  typevar,
  textvar,
  linkvar,
  linkBase,
  linkConnect,
  textConnect,
}) {
  linkvar = encodeURIComponent(linkvar);
  const twitTure = typevar == "twitter";
  const noLink = twitTure;
  const showLink =
    //
    !noLink && linkConnect;

  const tnstFinal =
    //
    twitTure ? textvar + " " + linkvar : textvar;

  const bsaeio = showLink && joinString([linkConnect, linkvar], "=");
  const txtooeio = textConnect && joinString([textConnect, tnstFinal], "=");

  const ihjtt = [
    //

    txtooeio,
    bsaeio,
  ];
  const linksiw = joinString(ihjtt, "&");
  const sadije = joinString([
    //
    linkBase,
    "?",
    linksiw,
  ]);

  const didsfe =
    //
    sadije;
  // encodeURIComponent(sadije);

  return didsfe;
}

export function getLinkSocial(twtias, dfgrt, sdfwre) {
  //
  const cxvdg = {
    typevar: twtias,
    ...soco.socialListDict[twtias],
    linkvar: dfgrt,
    textvar: sdfwre,
  };

  const ijse = linkOrdse(cxvdg);
  const nkmfidgs = {
    //
    linkvar: ijse,
    outsideTrue: true,
  };

  const ijwe = {
    linkConfig: nkmfidgs,
  };

  logs.logga("___ cxvdg ___", cxvdg);
  logs.logga("___ ijse ___", ijse);

  return ijwe;
}

// obj, localvar, closeVar
export function LocalShow({ localvar, ...asdew }) {
  const noMesso = getLocal(localvar);
  const [lcoalO, setlcoalO] = useState(noMesso);

  function sajdwe() {
    setlcoalO(true);
    setLocal(localvar, "aosd");
  }

  const nisje = {
    padding: "10px",
    margin: "20px",
    border: "2px solid red",
  };

  const ijaswe = {
    onClick: sajdwe,
    className: "shadowHover pointer",
    style: nisje,
    ...asdew,
  };

  const asdwe = lcoalO ? "" : <Divo {...ijaswe} />;

  return asdwe;
}

export function CollapseMain({ obj, buttonConfig, collapseConfig, ...asdwe }) {
  const [showFul, setshowFul] = useState();

  const collapseOt = !showFul && {
    style: {
      // overflow: "hidden",
      // height: "300px",
      padding: "10px",
      ...collapseConfig,
    },
    // className: "shadowHover pointer",
  };

  const isjdesoj = {
    obj: showFul ? "Collapse" : "Expand",
    onClick: () => setshowFul(!showFul),
    genConfig: { style: { margin: "20px 0", textAlign: "center" } },
    ...buttonConfig,
  };

  const idsjwe = <ConfirmButton {...isjdesoj} />;

  const zxcmsd = (
    <>
      {obj}
      {idsjwe}
    </>
  );

  const isjdwe = {
    ...asdwe,
    ...collapseOt,
    obj: zxcmsd,
  };

  const kmxvs = {
    obj: <Divo {...isjdwe} />,
    // style: isajdwe,
  };

  return <Divo {...kmxvs} />;
}

// 1higlights

export function highEditLink(sdferw, edita) {
  const medaos = sdferw.media;
  const stringAdd = !medaos ? "" : "/m/" + medaos.slugURL;
  const eidao = joinString([
    //
    stringAdd,
    "/highlights/",
    sdferw.id,
  ]);

  return eidao;
}

export function findHighString(endiai, chapterList) {
  const isjde = _.join(
    [
      //
      // "12",
      _.size(chapterList),
      "Highlights -",
      "'" + endiai.name + "'",
    ],
    " "
  );

  return isjde;
}

export function DropDownMain({
  titlevar,
  listvar,
  onChange,
  dictvar,
  typevar,
  triggerVar,
  overlayVar,
  obj,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function dropObj({ key, obj, ...value }) {
    logs.logga("___ key ___", key);
    logs.logga("___ value ___", value);

    // logs.logga("___ hey, value ___", hey, value)

    function jdsew() {
      onChange(key);
    }

    const cbvngf = {
      ...value,
      onClick: jdsew,
    };

    const ijdsser =
      //
      obj;
    // "asodkwqeq";

    return <DropdownItem {...cbvngf}>{ijdsser}</DropdownItem>;
  }

  const endias = listvar.map(dropObj);

  const ciraodw = dictvar[typevar];
  const ewra = ciraodw["obj"];
  const ndsjfs = {
    obj: <AiFillCaretDown />,
    style: { marginRight: "5px" },
  };

  const itemsoa = (
    <Flex>
      <Divo {...ndsjfs} />
      {ewra}
    </Flex>
  );

  const rendMenuOpt = (
    <>
      {titlevar}
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>{itemsoa}</DropdownToggle>
        <DropdownMenu>{endias}</DropdownMenu>
      </Dropdown>
    </>
  );

  return rendMenuOpt;
}

// 1function

export function Exmapl(dataVar) {
  const skdfsa = {
    height: "",
  };

  const zxcmsd = (
    <>
      {/* EXAMPLE TEXT  */}
      Hello!
    </>
  );

  const kmxvs = {
    obj: zxcmsd,
    style: skdfsa,
  };

  return <Divo {...kmxvs} />;
}
