import React, {
  //
  useState,
  useContext,
} from "react";

import {
  //
  PageTitle,
  BlankWrap,
  TitleMain,
  segReport,
  joinString,
} from "../functions/GlobalFunctions";
import HelmetInfo from "../functions/HelmetInfo";
import HeaderContent from "./HeaderContent";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

export default function PageBase({
  //
  helmetConfig,
  noHelmet,
  noTitle,
  titleConfig,
  headerConfig,
  noSubtitle,
  subtitleConfig,
  style,
  mainConfig,
  fastestvar,
  pageInfo,
  stepObj,
  loadObj,
  obj,
  noHeader,
  fadeTrue,
  loadTrue,
  // sideObj,
  noPadBottom,
  infoTrue,
  // dataImport,
  ...orga
}) {
  // 1const
  const [vidstype, setType] = useState(true);

  const okfdsd = (
    <>
      {/*  */}
      sssss
    </>
  );

  //   args = {
  //     obj: okfdsd,
  //     // ...args,
  //   };

  //   const asdokqwe =
  //     //
  //     obj;
  // ijasdwq;
  // "asdkaewqe";

  const oawewq = {
    // ...mainsos,
    // style: {
    //   // ...gens.borderMain("red"),
    //   // background: "blue",
    //   // padding: "0 200px",
    //   //   padding: "10px " + padFin,
    //   ...style,
    // },
    // obj: asdokqwe,
    ...orga,
  };

  //   helmetConfig

  function TitFinso({ ...ase }) {
    logs.logga("___ PAGEBASE TITLE ___", ase);

    return <TitleMain {...ase} />;
  }

  function SubFinso({ ...asdwe }) {
    const topSize = gens.mobview("24px", 34);
    const bttmtSize = gens.mobview("20px", 30);

    const iadqweok = {
      obj: "The fastest, easiest way",
      style: { fontWeight: "bold" },
    };

    const yeqwe = {
      style: { fontSize: bttmtSize },
      obj: fastestvar,
    };

    const sdfrasw = {
      obj: <Divo {...iadqweok} />,
      subtitlevar: <Divo {...yeqwe} />,
    };

    const gfigerw = (
      <>
        <Divo {...iadqweok} />
        <Divo {...yeqwe} />
      </>
    );

    const heigTitle =
      //#
      // "5px";
      gens.mobview("40px", "60px");

    const fghktrt = {
      verticalAlign: "text-bottom",
      fontSize: topSize,
      marginBottom: heigTitle,
      // background: "blue",
      // color: "black",
      textAlign: "left",
      // padding
    };

    const iewq = {
      obj: gfigerw,
      style: fghktrt,
      // ...asdwe,
    };

    const oawe = (
      //
      // <TitleMain {...iewq} />;
      <Divo {...iewq} />
    );

    return oawe;
  }

  function HelmBao() {
    //
    const jdrwewq = joinString(
      [
        //
        "The fastest, easiest way",
        fastestvar,
      ],
      " "
    );

    helmetConfig = {
      // dataImport: dataPage,
      // searchObject: dataPage,
      // titlevar: dataPage ? dataPage.name : "",
      //   ...mainsos,
      //   ...helmetFunc,
      metavar: jdrwewq,
      ...helmetConfig,
    };

    logs.logga("___ helmetConfig ___", helmetConfig);

    const aodkwaw = <HelmetInfo {...helmetConfig} />;

    return aodkwaw;
  }

  function asuwe() {
    const iawe = gens.mobview("2%", "10%");
    const uahe = {
      padding: "0px " + iawe,
    };

    return uahe;
  }

  const isakew = infoTrue && asuwe();

  const slsow = {
    textAlign: "center",
    // padding: "40px " + gens.mobileView ? "15%" : "20%" + " 0",
    padding: "0px 6% 30px",
    // paddingTop: "60px",
    // border: "2px solid red",
    ...isakew,
    ...style,
  };

  const baseItHelm = !noHelmet && <HelmBao />;
  const basTItle = !noTitle && <TitFinso {...titleConfig} />;

  logs.logga("___ subtleConfig ___", subtitleConfig);

  const kjsadwqe =
    //
    // "";
    (!noSubtitle && subtitleConfig) || fastestvar;
  // !noSubtitle || subtitleConfig;

  logs.logga("___ kjsadwqe ___", kjsadwqe);

  const titleSmal = kjsadwqe && <SubFinso {...subtitleConfig} />;

  const vcbkdf =
    //
    // obj;
    loadTrue ? loadObj : obj;
  // vidstype && obj;

  const ijaw = (
    <>
      {/* sfdplwer */}
      {basTItle}
      {titleSmal}
      {vcbkdf}
    </>
  );

  function oaskdwe() {
    logs.logga("___ CLICK SEG ___");

    segReport("clickedPage", { andrewTrue: true });
  }

  const cvbdf =
    //
    // fadeTrue
    true;

  const okad = {
    style: slsow,
    obj: ijaw,
    // onClick: oaskdwe,
    fadeTrue: cvbdf,
    ...mainConfig,
    // ...qwejqwoe,
  };

  const mainObjit = (
    //
    // "sdfwads";
    // ijaw;
    <Divo {...okad} />
  );

  const asowke =
    //
    true;
  // noPadBottom

  const iskdwqwe =
    //
    asowke && <BlankWrap heightVar={"100px"} />;

  logs.logga("___ headerConfig ___", headerConfig);

  function Headdos() {
    const xcvixf = {
      ...headerConfig,
      centerObj: stepObj,
      // centerObj: <>doasdoakewqeqeqw </>,
    };

    logs.logga("___ headerConfig ___", headerConfig);

    const sdfgtea = (
      //
      <HeaderContent {...xcvixf} />
      // <HeaderChak />
    );

    const askwe = {
      height: "120px",
    };

    const ovkcsdd = {
      obj: sdfgtea,
      style: askwe,
    };

    // const fsdokgs =
    //   //
    //   // "splfwfewr"
    //   pslda;

    return <Divo {...ovkcsdd} />;
  }

  const vcokbdsf = !noHeader && <Headdos />;

  const aijewqe = (
    <>
      {baseItHelm}
      {vcokbdsf}
      {/* {pageInfo} */}
      {mainObjit}
      {iskdwqwe}
      {/* <div {...okad}>{okad}</div> */}
      {/* {fodgkfer} */}
      {/* <Route404Main {...oawewq} /> */}
      {/* {baseItHelm}
        <Divo {...oawewq} /> */}
    </>
  );

  return aijewqe;
}
