import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Divo from "../components/Divo";
import HorizChoiceBar from "./HorizChoiceBar";
import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import { useDrag } from "react-dnd";

// IMPORT OTHER COMPONENTS HERE
import { ScriptContext } from "../contexts/ScriptContext";
import {
  removeEmptyArray,
  SelectArray,
  turnarray,
} from "../functions/GlobalFunctions";
// import SelectComp from "./general/SelectComp";

export default function ListMain({
  obj,
  formTrue,
  footerVar,
  returnTrue,
  indexPlus = "",
  removeEmpty,
  listvar,
  classvar,
  aboveButton,
  dragTrue,
  dictFunc,
  arrowTrue,
  buttonTrue,
  messvar,
  buttonConfig,
  noButton,
  sliderTrue,
  noListObj,
  addTrue,
  addDict = {},
  selectList,
  indexString,
  noRemove,
  scrollTrue,
  objBetTrue,
  textTrue,
  selectTrue,
  selectConfig,
  chosenItem,
  selectObj,
  chosenConfig = {},
  chooseBedia,
  borderTrue,
  genConfig,
  errorobj,
  listMess,
  selectableTrue,
  selectableConfig,
  inputTrue,
  objReturnTrue,
  objInnerTrue,
  changeSubmit,
  submitFunc,
  styleVar,
  belowButton,
  fixTitle,
  innerObjTrue,
  activeVar,
  inputConfig,
  keyTrue,
  noOptionObj,
  styleList,
  loadtrue,
  loadNumber = 10,
  listNumber,
  onClick,
  clickSingle,
  chooseFunc,
  itemConfig = {},
  horizTrue,
  titleVar,
  beforeVar,
  dictTrue,
  dictvar,
  bulletTrue,
  classItem,
  lineTitle,
  hoverFunc,
  lineBetween,
  constantTrue,
  objFuncTrue,
  collapseTrue,
  listConfig,
  ...listArgs
}) {
  // scriptcontext
  // prettier-ignore
  // const { selectableContext } = useContext(ScriptContext);

  // const { register } = useForm({
  //   defaultValues: {
  //     firstName: "bill",
  //     lastName: "luo",
  //     email: "bluebill1049@hotmail.com",
  //     isDeveloper: true
  //   }
  // })

  onClick = onClick ? onClick : itemConfig["onClick"];

  listvar = listvar ? listvar : listArgs["options"];

  logs.logga("___ listvar AAA___", listvar);

  listvar = turnarray(listvar);

  logs.logga("___ listvar BBB___", listvar);

  listvar = noRemove ? listvar : removeEmptyArray(listvar);

  logs.logga("___ listvar CCC___", listvar);

  // 1style
  styleList = {
    overflowY: scrollTrue ? "scroll" : "",
    // overflowY: "scroll",
    ...styleList,
  };
  listvar = listNumber ? listvar.slice(0, listNumber) : listvar;
  inputTrue = formTrue || inputTrue;
  const showIt = noOptionObj || listvar;
  const fullVar =
    //
    true;
  // checkFullArray(listvar) || loadtrue;

  // const trueI = itemHeight && itemPad;
  // const heightme = trueI ? (itemHeight + itemPad * 2) * displayNumb + "px" : "";

  const showTit = titleVar && !lineTitle && !collapseTrue;
  beforeVar = beforeVar ? beforeVar : bulletTrue ? " - " : "";

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const asodwsdfe =
    //
    // "hoverExpand";
    "hoverExpand buttonHover";

  //
  function EndListA(coimswe, indexSort) {
    const asdwe = itemConfig;
    const origText = coimswe;

    const cvokbsdf = selectObj || chosenItem;
    dictTrue = dictTrue || dictvar || dictFunc;
    selectTrue = coimswe == cvokbsdf;

    const basChso = !chooseBedia
      ? {}
      : {
          chooseBedia: chooseBedia,
          style: {
            background: gens.appMainDark,
            color: "white",
          },
        };

    const xcbokfd = {
      ...basChso,
      ...chosenConfig,
    };

    const baConsa = !chooseBedia
      ? {}
      : {
          className: asodwsdfe,
          style: {
            padding: "10px",
          },
        };

    const choseAll = selectTrue ? xcbokfd : {};
    const dgste = dictFunc
      ? dictFunc(coimswe)
      : dictTrue
      ? dictvar[coimswe]
      : coimswe;

    const listarr = dictTrue
      ? //
        {
          ...dgste,
          ...addDict,
        }
      : dgste;

    // 1select
    function selectFin(ijretre) {
      const oaew = selectList && selectList[coimswe];
      const ijaew = {
        obj: ijretre,
        bediaTrue: oaew,
      };

      const wuehqe = <Divo {...ijaew} />;
      return wuehqe;
    }

    function adojaqw(theoasd) {
      //

      logs.logga("___ CHOOSING ITEM " + theoasd + ":", olaqwefs);

      const iasda = {
        // ...listarr[theoasd],
        ...asdwe[theoasd],
        ...baConsa[theoasd],
        ...choseAll[theoasd],
        ...listarr[theoasd],
      };
      return iasda;
    }

    const dplsd = objInnerTrue ? { obj: listarr } : listarr;

    const olaqwefs = {
      asdwe: asdwe,
      baConsa: baConsa,
      choseAll: choseAll,
      listarr: listarr,
    };

    const indxo = indexPlus ? indexSort + indexPlus : indexSort;

    const endRet = {
      // listType: coimswe,
      index: indxo,
      ...asdwe,
      ...baConsa,
      ...choseAll,
      ...dplsd,
      style: adojaqw("style"),
      // className: adojaqw("className"),
    };

    logs.logga("___ COMPARING ITEMS :", olaqwefs);
    logs.logga("___ endRet ___", endRet);

    const boksf = {
      endRet: endRet,
      listarr: listarr,
      asdwe: asdwe,
      baConsa: baConsa,
      choseAll: choseAll,
    };

    // logs.logga("___ boksf ___", boksf);

    objFuncTrue = objFuncTrue || dictTrue;
    const eitherRend = returnTrue || constantTrue;
    const ikasd = endRet.obj;
    const okwq =
      //
      // ""
      // ikasd;
      objBetTrue && ikasd;
    const findoobj = okwq ? ikasd : obj;

    function clickList() {
      // gens.ifFunc(onClick, listarr),
      // logs.logga("listarr-onClick--zz", listarr, "asds");

      // 1onClick
      if (onClick) {
        const asd0okw =
          //
          clickSingle;
        //  clickKey

        const okgs = asd0okw ? origText : listarr;

        onClick(okgs);
      }
    }

    const asjds = {
      // selectTrue: selectTrue,
      // itemConfig: asdwe,
      listarr: listarr,

      // dictvar: dictvar,
      // dictTrue: dictTrue,
      // choseAll: choseAll,
      endRet: endRet,
      eitherRend: eitherRend,
      constantTrue: constantTrue,
      objReturnTrue: objReturnTrue,
      objFuncTrue: objFuncTrue,
    };

    // 1console
    messvar = "sdfwer";
    logs.logga(messvar + "---listMain---", asjds);
    // gens.messCheck("daspokqweq", messvar, "---listMain---", asjds);
    logs.logga("___ findoobj ___", findoobj);

    // const ijsdas = <Divo obj={listarr} {...itemConfig} />

    // const dfgoker =
    const asdpkwe = eitherRend ? (
      <Divo {...itemConfig} obj={listarr} />
    ) : constantTrue ? (
      listarr ? (
        listarr.props.children
      ) : (
        ""
      )
    ) : // : showObjit ? endRet.obj
    objReturnTrue ? (
      endRet
    ) : objFuncTrue ? (
      findoobj(endRet)
    ) : (
      //
      // ? obj({ listarr })
      // "sdasdww"
      findoobj(listarr, indexSort)
    );

    function DragBase() {
      //     function goeDrago(){
      //     }
      //   const sockewe =  <Draggable key={item.id} draggableId={item.id} index={index}>
      //   {(provided, snapshot) => (
      //     <div
      //       ref={provided.innerRef}
      //       {...provided.draggableProps}
      //       {...provided.dragHandleProps}
      //       style={getItemStyle(
      //         snapshot.isDragging,
      //         provided.draggableProps.style
      //       )}
      //     >
      //       {item.content}
      //     </div>
      //   )}
      // </Draggable>
      // return sockewe,
    }

    const isjdew =
      //
      dragTrue ? <DragBase /> : asdpkwe;

    const redObj = (
      <>
        {/* eeeeeeee */}
        {/* {listarr["name"]} */}
        {isjdew}
        {/* {afterVar} */}
      </>
    );

    const filleoo = {
      onClick: clickList,
      onMouseEnter: hoverFunc,
      obj: redObj,
    };

    function LoadList() {
      //
      const akweas =
        //
        "loadtrue";

      return akweas;
    }

    // selectableTrue = false;
    // logs.logga("filleoo-aaaaa", filleoo, "sssssa");

    const auewasdash = loadtrue ? <LoadList /> : <Divo {...filleoo} />;
    // selectableTrue ? (
    //   <SelectComp {...filleoo} />
    // ) : (
    //   <Divo {...filleoo} />
    // );

    const ijsew =
      //
      "";
    // lineBetween && <gens.DividerObj />;

    const asidjwe = (
      <>
        {auewasdash}
        {ijsew}
      </>
    );

    return asidjwe;
  }

  const collList = {
    className: classvar,
    footer: footerVar,
    bordered: borderTrue,
    // listNumber: loadtrue ? loadNumber : listvar.length,
    // dataSource: listvar,
    obj: EndListA,
    renderItem: EndListA,
    // display: "flex",
    // itemLayout: "horizontal",
    itemLayout: horizTrue ? "horizontal" : "vertical",
  };

  function TitleFix() {
    // return titleVar;
    // return "asdhwew";

    const iasjew = "";

    // (
    //   <FixedMain
    //     disVar
    //     // disVar={!fixTitle}
    //     // obj="titleVarasdqw"
    //     obj={
    //       <>
    //         <Divo obj={titleVar} />
    //         {/* <gens.DividerObj disVar={!lineTitle} obj={titleVar} /> */}
    //       </>
    //     }
    //   />
    // );

    return iasjew;
  }

  listMess = "sd9jqwxas";
  const oaskdwq = "";

  const slideo =
    //
    // sliderTrue
    horizTrue;

  function mapoBap(saokae, indexvr) {
    logs.logga("___ saokae ___", saokae);

    const asidjwe = saokae["mainObj"];

    const jcvbfd =
      //
      asidjwe ? asidjwe : EndListA(saokae, indexvr);

    return jcvbfd;
  }

  // 1horiz
  function HozBar() {
    const endoa = {
      messvar: messvar,
      ...collList,
      ...listArgs,
      ...listConfig,
      listvar: listvar,
      dictvar: dictvar,
      dictFunc: dictFunc,
      selectableTrue: selectableTrue,
      retObj: mapoBap,
    };

    // 1console
    // logs.logga("xxx-LIST-MAIN--args", messvar, listvar, "asd90u12321");
    logs.logga("xxx-LIST-MAIN--args", endoa, "asd90u12321");
    // gens.messCheck("sd9jqwxas", listMess, "ListMain --AAAA--", oaskdwq);

    const jisad = <HorizChoiceBar {...endoa} />;
    return jisad;
  }

  // const aisjwe = "";
  const aisjwe = !listvar ? "" : slideo ? <HozBar /> : listvar.map(mapoBap);
  // <gens.ListAll {...collList} />

  //
  //
  function Seletio() {
    const asikwe = {
      // ...selectableContext,
      obj: aisjwe,
      ...selectableConfig,
    };

    const as83asd = (
      //
      // "";
      <SelectArray {...asikwe} />
    );

    return as83asd;
  }

  // const sakdwease =   <> <DragDropContext onDragEnd={this.onDragEnd}>
  // <Droppable droppableId="droppable">
  //   {(provided, snapshot) => (
  //     <div
  //       {...provided.droppableProps}
  //       ref={provided.innerRef}
  //       style={getListStyle(snapshot.isDraggingOver)}
  //     >
  //       <Draggable key={item.id} draggableId={item.id} index={index}>
  //                 {(provided, snapshot) => (
  //                   <div
  //                     ref={provided.innerRef}
  //                     {...provided.draggableProps}
  //                     {...provided.dragHandleProps}
  //                     style={getItemStyle(
  //                       snapshot.isDragging,
  //                       provided.draggableProps.style
  //                     )}
  //                   >
  //                     {item.content}
  //                   </div>
  //                 )}
  //               </Draggable>
  //             ))}
  //             {provided.placeholder}
  //           </div>
  //         )}
  //       </Droppable>
  //     </DragDropContext>

  const titlo = showTit && <TitleFix />;
  const asudwe = fullVar ? selectableTrue ? <Seletio /> : aisjwe : noListObj;
  const saokwe = (
    <>
      {titlo}
      {asudwe}
    </>
  );

  //   const showBut =
  //   //
  //   // true;
  //   inputTrue || buttonTrue;

  // const osakd =   <>
  // {collapseTrue ? (
  //   <gens.CollapseMain
  //     defaultActiveKey={activeVar ? turnarray(activeVar) : ""}
  //   >
  //     {mapFunc(listvar, PanPot)}
  //   </gens.CollapseMain>
  // ) : (
  //   <ListRend />
  // )}
  // {/* <BoolDiv bool={showBut} obj={<ButFins />} /> */}
  // </>
  // }

  const baseLiaox = formTrue ? <form>{saokwe}</form> : saokwe;

  const saidje = {
    loadtrue: loadtrue,
    obj: baseLiaox,
    ...genConfig,
  };

  const qeqwyhqw = (
    <>
      {/* <MainPreview {...saidje} /> */}
      <Divo {...saidje} />
      {/* {baseLiaox} */}
    </>
  );

  return qeqwyhqw;
}
