import React, {
  //
  useState,
  useContext,
} from "react";

import { FaPlay } from "react-icons/fa";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  getDuration,
  timeDecim,
  //
} from "../functions/GlobalFunctions";

import ConfirmButton from "./ConfirmButton";
import ListMain from "./ListMain";
import ImageTextDiv from "./ImageTextDiv";

export default function TimeButtons({
  //
  showTime,
  bothFunc,
  startTime,
  endTime,
  startFunc,
  endFunc,
  buttonConfig,
  ...args
}) {
  // 1scriptcontext
  const { changePlayerTime, getCurrentTime } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  function getTimeo(aspecto, timeo) {
    const timeea = timeo ? timeo : getCurrentTime();
    switch (aspecto) {
      case "start":
        startFunc(timeea);
        break;
      case "end":
        endFunc(timeea);
        break;
    }

    if (bothFunc) {
      bothFunc(timeea);
    }

    const dsdfwer = {
      timeea: timeea,
      aspecto: aspecto,
      //   duraion: duraion,
    };

    logs.logga("___ dsdfwer ___", dsdfwer);
  }

  const topelCo = {
    attr: "start",
    typeText: "Start",
    // bediaTrue: true,
    // background: "blue",
    butName: "buttonStart",
    onClick: () => getTimeo("start"),
  };

  const backElCo = {
    attr: "end",
    typeText: "End",
    background: "darkred",
    butName: "buttonEnd",
    onClick: () => getTimeo("end"),
  };

  function PlayDurro() {
    const asidew = endTime && timeDecim(getDuration(endTime, startTime));

    const asidjew = (
      <>
        {/*  */}
        {/* asdokwqew */}
        <FaPlay /> {asidew}
      </>
    );

    const askdwse = {
      obj: asidjew,
      bediaTrue: true,
      butName: "playeButton",
      onClick: () => changePlayerTime(startTime),
    };

    return askdwse;
  }

  const ijasd = {
    start: topelCo,
    end: backElCo,
    // duration: duraoe,
    play: PlayDurro(),
  };

  function Asijwe({ divObj, typeText, attr, ...asdw }) {
    logs.logga("___ asdw ___", asdw);

    const sadebd = {
      minWidth: "160px",
      padding: "10px",
      // margin: "20px",
      fontSize: "25px",
    };

    let asjdwe = "";
    switch (attr) {
      case "start":
        asjdwe = startTime;
        break;
      case "end":
        asjdwe = endTime;
        break;
    }

    const fdhgur = asjdwe ? timeDecim(asjdwe) : "0:00";

    const sdifje = showTime ? (
      <>
        {typeText}
        {/*  */}
        {/*  */}: {fdhgur}
      </>
    ) : (
      typeText
    );

    const okasedwe = {
      // genConfig: kdsdqwe,
      obj: sdifje,
      ...asdw,
      style: sadebd,
      ...buttonConfig,
    };

    logs.logga("___ okasedwe ___", okasedwe);

    // attr = "endTime";
    const ksaesdwe = <ConfirmButton {...okasedwe} />;

    return ksaesdwe;
  }

  const ijdsf = {
    textAlign: "center",
    // padding: "0 10%",
    // margin: "30px",
  };

  const jasde = {
    dictvar: ijasd,
    // horizTrue: true,
    // itemConfig: sdofs,
    obj: Asijwe,
    genConfig: { style: ijdsf },
    ...args,
  };

  //   1console
  const sgkjerw = {
    startTime: startTime,
  };

  logs.logga("___ TimeButton BBB ___", sgkjerw);

  const ksaesdwe = (
    //

    <ListMain horizTrue {...jasde} />
    //   <ImageTextDiv horizTrue {...jasde} />;
    // <FloatMainAll {...asdnow8q} />
  );

  return ksaesdwe;
}
