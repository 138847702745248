import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Flex,
  Link,
  Badge,
  useColorModeValue,
  Spacer,
} from "@chakra-ui/react";
import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";

export default function FloatMainAll({
  //
  noVertTop,
  noPadding,
  alignTrue,
  //
  disvar,
  leftObj,
  leftConfig,
  centerObj,
  centerConfig,
  rightObj,
  rightConfig,
  noVertAlign,
  ...args
}) {
  // 1scriptcontext
  const { aaaaaa } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  const alignItemsFlex =
    //
    // ""
    // "flex-start";
    // "space-between";
    "center";
  // "stretch";
  // "space-around";
  // "space-evenly";
  // "flex-end";

  const alignContentFlex =
    //
    // "flex-start";
    // "space-between";
    // "center";
    // "stretch";
    // "space-around";
    "space-evenly";
  // "flex-end";

  const justifyContentFlex =
    //
    // "";
    "flex-start";
  // "space-between";
  // "center";
  // "stretch";
  // "space-around"
  // "space-evenly";
  // "flex-end";

  const vertAlign = {
    alignItems: alignItemsFlex,
    justifyContent: justifyContentFlex,
    // flexWrap: "wrap",
    // alignContent: alignContentFlex,
    /* vertical-align: middle; */
    /* width: 100%; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
  };

  const vertTop = {
    display: "flex",
    /* justify-content: center; */
    // alignItems: "top",
    // verticalAlign: "top",
  };

  const allPasow =
    //
    // {};
    vertTop;

  const trueClass =
    //
    // {};
    noVertAlign ? (noVertTop ? "" : allPasow) : vertAlign;

  const jndf = {
    // paddingLeft: noPadding
    p: "2",
  };

  const lefto = leftObj && (
    <>
      <Box {...jndf}>
        {/*  */}
        {leftObj}
      </Box>
      {!disvar && <Spacer />}
    </>
  );

  const centio = (
    <div {...centerConfig}>
      {/*  */}
      {/* asdowe */}
      {centerObj}
    </div>
  );

  const ijsad = {
    style: alignTrue && { textAlign: "right" },
  };

  const rightos = rightObj && (
    <>
      {!disvar && <Spacer />}
      <Box p="2">
        <div {...ijsad}>
          {/* sdokw */}
          {rightObj}
        </div>
      </Box>
    </>
  );

  const okfdsd = (
    <>
      {/*  */}
      {/* sdfwerew */}
      <Flex {...trueClass}>
        {lefto}
        {centio}
        {rightos}
      </Flex>
    </>
  );

  const sdifjw =
    //
    okfdsd;
  // oeqewq;

  args = {
    obj: sdifjw,
    ...args,
  };

  // 1HTML
  return <Divo {...args} />;
}
