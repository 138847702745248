import React, { useState, useContext } from "react";
// import { setLocal } from "../functions/GlobalFunctions";

export const testBackend =
  //
  //   !DEPLOYTRUE;
  "";
// true;

//  localhost
export const urlLINk =
  //
  // ""
  //
  testBackend ? "http://127.0.0.1:8000" : process.env.REACT_APP_SERVER_URL;
