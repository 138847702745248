import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/core";

// IMPORT OTHER COMPONENTS HERE
// import aaaa from "./components/aaaa";
import { ScriptContext } from "../contexts/ScriptContext";
// import Modal from "react-modal";

export default function ModalMain({
  obj,
  style,
  modalStyle,
  visible,
  disVar,
  noModal,
  titleVar,
  cancelFunc,
  noClose,
  header,
  footer,
  initialRef,
  finalRef,
  titleConfig,
  footConfig,
  fullTrue,
  bodyStyle,
  antTrue,
  headStyle,
  ...args
}) {
  //
  // visible = false;
  fullTrue =
    //
    // fullTrue;
    true;
  // fullTrue && gens.mobileView;

  // false;

  const fullDesk = fullTrue && !gens.mobileView;
  // 1const

  const modStyMain = {
    modal: {
      backgroundColor: "red",
      // backgroundColor: "transparent",
      boxShadow: "none",
      display: "flex",
      // overflow: "none",
      width: "100%",
      padding: "0",

      height: "100%",
      // minWidth: "100%",
      justifyContent: "center",
    },
    overlay: {
      // backgroundColor: "#1cccc",
      backgroundColor: gens.appMainBody,
      // padding: 0,
    },
    closeIcon: {
      fill: "darkblue",
    },
  };

  const styleMesho = {
    // top: "200px",
    // height: "100vh",
    // width: "100vw",
    background: gens.appMainBody,
    // zIndex: gens.indexz["searchModal"],
  };
  // const  outaso = "inside"
  const outaso = "outside";
  // const { isOpen, onOpen, onClose } = gens.useDisclosure();
  // const [scrollBehavior, setScrollBehavior] = React.useState(outaso);
  const [vizMody, setVizMod] = useState(visible);
  // const vizMod = vizMody
  const vizMod = true;

  function closeModo() {
    // logs.logga("cancelFunc-zzzz", cancelFunc, "asdihw");

    if (cancelFunc) {
      cancelFunc();
    }
    // setVizMod(false);
  }
  modalStyle = {
    ...modStyMain,
    ...modalStyle,
  };
  const styleMesh = {
    styleMesho,
    ...modalStyle,
  };
  const fullSty = fullTrue ? styleMesh : modalStyle;

  const topFind = {
    top: 10,
    // top: 100,
  };

  // modaltitle
  const modalHead = {
    style: {
      // marginTop: "30px",
      //
      // color: "green",
      // fontFamily: gens.bediaFontMain,
      fontSize: gens.mobileView ? "20px" : "30px",
      ...topFind,
      ...headStyle,
    },
    ...titleConfig,
  };

  const modalCont = {
    style: {
      // w: "100%",
      // width: "600px",
      minHeight: fullTrue ? "100vh" : "40vh",
      // padding: fullTrue ? "0 30%" : "0%",
      margin: fullDesk ? "0 20%" : "",
      // ...gens.borderMain("red"),
      // paddingTop: "44px",
    },
  };
  const widhthBod =
    //
    "";
  // "200px"
  // "40vw"
  const modalBody = {
    style: {
      // top: 350,
      // paddingTop: "30px",
      margin: gens.mobileView ? "" : "0 15%",
      // marginTop: 60,
      // height: "80vh",
      // width: widhthBod,
      ...bodyStyle,
    },
  };
  const modalFoot = {
    style: {
      // height: "100vh",
      // height: "60vh",
    },
  };

  const allsdomw = {
    // marginTop: 100,
    marginTop: 15,
    // fontSize: 160,
    // height: "150px",
    // width: "150px",
    ...gens.bediaMainStyle,
    ...topFind,
  };
  const asjdw = {
    ...topFind,
  };
  const modalClose = {
    style: fullTrue ? allsdomw : topFind,
  };

  const aisjds =
    //
    // "sm";
    // "lg";
    "xl";
  // "full";
  // "";

  const modalConfig = {
    size: fullTrue ? "full" : aisjds,
    // scrollBehavior: scrollBehavior,
    isOpen: vizMod,
    open: vizMod,
    visible: vizMod,
    //
    //     // style: fullSty,
    // style: fullSty,
    // styles: fullSty,
    animationDuration: 1000,
    focusTrapped: true,
    closeIconSize: 40,
    showCloseIcon: true,
    //
    //
    //

    //
    closable: !noClose,
    maskCloseable: true,
    footer: footer ? footer : "",
    //
    // onAfterOpen :
    //
    //
    //
    onRequestClose: closeModo,
    onCancel: closeModo,
    onClose: closeModo,
    //
    //
    contentLabel: titleVar,
    title: titleVar,
    // initialFocusRef: initialRef,
    // finalFocusRef: finalRef,
    ...args,
  };

  function Basegos() {
    const obkxcdg = <Modal {...modalConfig}>{obj}</Modal>;

    return obkxcdg;
  }

  function HeaDmaino() {
    const cxvkmdf = (
      <ModalHeader
        //
        {...modalHead}
      >
        {titleVar}
      </ModalHeader>
    );

    return cxvkmdf;
  }

  function Stylo() {
    // return <gens.AntModal {...modalConfig}>{obj}</gens.AntModal>;
  }

  // 1problem
  function Chakro() {
    const xcvksf = (
      <Modal isCentered {...modalConfig}>
        <ModalOverlay />
        <ModalContent {...modalCont}>
          <HeaDmaino />
          <ModalCloseButton {...modalClose} />
          <ModalBody {...modalBody}>{obj}</ModalBody>
          {/* <FooteoMo /> */}
        </ModalContent>
      </Modal>
    );

    return xcvksf;
  }

  const soifjwq = {
    modalCont: modalCont,
    modalClose: modalClose,
    modalBody: modalBody,
    fullTrue: fullTrue,
    modalConfig: modalConfig,
  };

  logs.logga("modalMain-zzzz", soifjwq);

  // return "bbbbbbbbssss";
  // return <Fullo />;
  // return <Stylo />;
  // return <Chakro />;
  // return <gens.Modal></gens.Modal>;

  // 1modalmain
  // return disVar ? obj : <Chakro />; Stylo

  const bxcjvids = disVar ? (
    obj
  ) : antTrue ? (
    <Stylo />
  ) : fullTrue ? (
    <>
      {/* <gens.SlideIn in={isOpen}>{(styles) => <Chakro />}</gens.SlideIn> */}
      <Chakro />
    </>
  ) : (
    <Chakro />
  );

  const cvklxsa = (
    //
    // "sidjqwewqewqe"
    <Chakro />
  );

  // const cvklxsa = <Basegos />;

  return cvklxsa;
}
