import Divo from "../components/Divo";
import speakPicUnknowny from "../config/images/speakPicUnknown.png";
import BediaMagBlacky from "../config/images/BediaMagBlack.png";
import BediaMagWhitey from "../config/images/BediaMagWhite.png";
import BediaMagBluey from "../config/images/BediaMagBlue.png";
import speakPicNoney from "../config/images/speakPicNone.png";
import BediaLogoBluey from "../config/images/BediaLogoBlue.png";
import BediaLogoWhitey from "../config/images/BediaLogoWhite.png";
import BediaMagQuestiony from "../config/images/BediaMagQuestion.png";
import lattop from "../config/images/laptopSmall.jpg";
// import lattop from "../config/images/laptop.jpg";
import pinkk from "../config/images/pink.jpg";

// 1image
export const speakPicNone = speakPicNoney;
export const BediaMagQuestion = BediaMagQuestiony;
export const speakPicUnknown = speakPicUnknowny;
export const BediaMagBlack = BediaMagBlacky;
export const BediaMagBlue = BediaMagBluey;
export const BediaMagWhite = BediaMagWhitey;
export const BediaLogoWhite = BediaLogoWhitey;
export const BediaLogoBlue = BediaLogoBluey;

export const baseEmailso = "sillyscribe95@gmail.com";

// 1const
// 1DEPLOYTRUE
export const DEPLOYTRUE =
  //
  "y";
// ""

export const testDev =
  //
  // "y";
  "";

// 1finaltrue
export const FINALTRUE =
  //
  // "";
  DEPLOYTRUE ? "" : true;
// true;

//
// 1silly
const ijaew =
  //
  // "asdi je9 ia=";
  "Alpha Scribe";

// 1order DETAILS 1silly
export const fdbmeiwree = {
  name: ijaew,
  email: baseEmailso,
  // noteJSON: {
  //   company: "Scriptate",
  // },
};

export const costeAar = [
  {
    price_data: {
      currency: "usd",
      product_data: { name: "ANN" },
      unit_amount: 100,
    },
    quantity: 1,
  },
];

export const baseStirp = {
  // mediaList: ["44c03bcf-3f08-4723-9758-9a160a8fdedb"],
  stripeData: costeAar,
};

// 1param 1match STRING

// export const maintypevar = "";
// export const backStyMatych = getParamVar(backColMatch);
// export const textStyMatych = getParamVar(textColMatch);
// export const searchSrs = getParamVar(serIDLinkString);
// export const searchFind = getParamVar(searchLinkString);
// export const lineFind = getParamVar(lineLinkString);
// export const timeFind = getParamVar(timeLinkString);
// export const endTimeFind = getParamVar(endTimeMatch);
// export const lineEndFind = getParamVar(lineEndMatch);
// export const viewFind = getParamVar(viewMatchString);
// export const searchSp = getParamVar(speakIDLinkString);
// // export const searchSp = "aaaaaa";

// 1localstorage 1storage
export const storeNoRequestBool = "noRequest";
export const storeRequestString = "requestMedia";
export const stroeOrdId = "xcvbdfsgteqradsewr";
export const storeBasketHighlight = "basketHighlight";
export const storeBasketMedia = "bediaBasket_LOC";
export const storeOrderConf = "orderConfig";
export const detialGot = "dssdfrqrw";
export const exampShown = "examokwe";

// ORDERS
export const orderTypes = "typ";

export const searchMainString = "cvkmvs";
export const searchTrnsTrue = "trns";
export const searchTypeString = "tpo";
export const searchLinkString = "srch";
export const textColMatch = "colSt";
// export const textColMatch = "colSt";
export const backColMatch = "backSt";
export const mediaIDLinkString = "md";
export const speakIDLinkString = "sp";
export const serIDLinkString = "srs";
export const timerLinkString = "timeVar";
export const shareLinkString = "share";
export const searchMetaString = "meta";
export const filterMatchString = "filter";
export const viewMatchString = "view";

// 1media
export const timeLinkString = "t";
export const lineLinkString = "l";
export const endTimeMatch = "e";
export const watchSlug = "v";
export const lineEndMatch = "lineEnd";
export const orderLinkString = "mdix";
export const speakLinkString = "sp";
export const chapLinkString = "chapter";
export const highLinkString = "h";
export const anchLinkString = "anchor";
export const pinLinkString = "pin";
export const quoteLinkString = "qu";
export const tutLinkString = "tutorial";
export const subSpeakerString = "subspeaker";

// 1order
// export const orderTreuaq = true;
// export const orderTreuaq = checkParamVar("12uh123");
// export const yComTrue = checkParamVar("dscyc");

const ikavbf = [
  //
  "📜 Transcripts (Website/SEO)",
  "💬 Subtitles (Youtube)",
  "📚 Chapters & Quotes (show notes)",
  // "📺 Short video highlights (social media)",
  // (.mp4 files)
];

// 1scribe
export const ScribeAndy = {
  name: "Andrew",
  imageAttr: lattop,
  scribeName: "Alpha",
  emoji: "🙋",
  rating: 100,
  jobs: 76,
  years: 3,
  skillList: ikavbf,
};

// 1test
export const testFrontend =
  //
  !DEPLOYTRUE;
// "";
// true;

export const homeFull = testFrontend
  ? "http://localhost:3000/"
  : "https://bedia.tv/";

export function appDarkFunc(opac) {
  // opac = opac ? opac : "1";
  opac = "1";
  // return mainDarkBlueCol;
  return addOpacity(mainDarkBlueCol, opac);
}

export function addOpacity(colorVar, opac) {
  opac = opac ? opac : "1";
  opac = "rgb(" + colorVar + ", " + opac + ")";

  // logga("xxx-opac", opac, "sdsi3");

  return opac;
}

export const appMainBodDark = "#d9d9d9";
export const mainSelectBack =
  //
  // "red";
  // "0,22,77";
  appMainBodDark;

export const appMainSelect = {
  // background: appMainBodDark,
};

export const mainDarkBlueCol =
  //
  // "#00164d"
  "0,22,77";

export const appMainColDark = "white";
export const appMainDark = appDarkFunc();
export const appDarkCol = "0,22,77, 1";
export const mainLightBlueCol = "105,129,255";
export const mainLightWhiteCol = "105,129,255";
export function appMainLight(opac) {
  opac = opac ? opac : "1";
  return addOpacity(mainLightBlueCol, opac);
}
export const bediaCircle = {
  background: appMainDark,
  borderRadius: "50%",
};
export const appLightGrey = "rgb(105,129,255, 1)";
export const blueBorder = {
  border: "2px solid " + mainLightBlueCol,
  // borderRadius: "15px",
};

// export const appMainBody = "red";
export const appMainBody =
  //

  // "red";
  // "white";
  "#F3F3F3";

// 1bedia 1style bediastyle stylebedia
export const bediaMainStyle = {
  background: appMainDark,
  color: appMainColDark,
  textAlign: "center",
  // fontFamily: "Times New",
  // padding: "10px 20px",
  // fontSize: "20px",
  ...blueBorder,
};

export const appLightBack =
  //
  "#EEEEEE";

// 1style CONST
export const fixCenter = {
  position: "absolute",
  top: 0,
  height: "100vh",
  width: "100vw",
};

// borderHover
const xclmvsd =
  //
  "borderHover";
// "borderHover shadowHover";
// "shadowHover";
// "borderMain";

export const butClass = xclmvsd;
export const butClassHover = xclmvsd;
export const buttonMain = "buttonHover shadowHover";

export const fullScreen = {
  position: "absolute",
  top: 0,
  height: "100vh",
  width: "100vw",
};

export const fixedPos = {
  position: "fixed",
};

export const centerAlign = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const confirmGreen = "green";

function linko(linkvar, textvar) {
  return <a href={linkvar}>{textvar}</a>;
}

export const copyIco = {
  // copyConfig
};

export const wwwCheck = "wwww.";
export const httpCheck = "https";
export const chckLinkos = httpCheck;

export const quickChat = "https://calendly.com/bedia/quick-chat";
export const emailMe = "mailto:andrew@bedia.tv";
export const emailTrans = "mailto:transcripts@bedia.tv";
export const getInTouch = (
  <>
    {linko(emailMe, "email us")} or {linko(quickChat, "book a time to chat")}
  </>
);

export const Colmain = "rgba(154,154,154,0.2)";
// export const Colmain = "rgba(240, 240, 240, 1)";
export const watchFootBackground =
  "radial-gradient(circle, rgba(255,255,255,1) 0%, " +
  Colmain +
  " 100%, rgba(255,255,255,1) 100%)";
// "red";

export const emojiBase = {
  people: "",
  writing: "",
  chapters: "",
};

export const size = {
  // viewL: "700px",
  viewL: "780px",
  custHeight: "650px",
  //
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

// percentQu: `${percentPlayed}`,
export const MediaQueries = {
  viewtype: "(min-width: 780px)",
  customHeight: "(min-height: 650px)",
};

export const widthVary = window.matchMedia(MediaQueries.viewtype);
export const heightVary = window.matchMedia(MediaQueries.customHeight);
export const mobileView = !widthVary.matches;
export const mobileNot = widthVary.matches;

// 1mobileview
export function mobview(one = true, two = false, dict) {
  // var mobby = useMediaQuery({ orientation: "portrait" });
  // logga("aaaaaaaa---MobView: ", mobby);
  const asdw = mobileView ? one : two;

  return asdw;
}

// messCheck("odqewqcoadqweqw", messvar, "asjds", asjds);
export function messCheck(messChecko, msosit, messVar, admwqeq) {
  const pldas = msosit == messChecko;
  const qwej2 = {
    messChecko: messChecko,
    msosit: msosit,
    pldas: pldas,
  };

  // logga("messCheck-zz", qwej2);

  // //
  // if (pldas) {
  //   logga(messVar, admwqeq);
  // }
}

export const minUnderTime =
  //
  30;

// 1delivery
export const delivMultiple =
  //
  // 10
  3;

// 1hour
export const plusHours =
  //
  // 8;
  4;
// 12;
// 4 * 24;

// 1badge
export const lightOpac = {
  background: "red",
  padding: "0px 10px",
  color: "white",
  borderRadius: "50%",
  // background: appMainLight,
  // opacity: "0.7",
};

// 1mailchimp
export const chimpGet =
  //
  "mailto:connect@bedia.tv";
// "https://gmail.us2.list-manage.com/subscribe?u=35edc997e8885e35bbc91c801&id=a769094d15";
// "https://gmail.us2.list-manage.com/subscribe/post?u=35edc997e8885e35bbc91c801&id=a769094d15"

export const gettouchconf = {
  linkvar: chimpGet,
  outsideTrue: true,
};

// 1link // 1homelink
export const homeLinkBedia = "/home";
export const orderLink = "/order";

export const mainFlexItem = {
  // border: "2px solid blue",
  // width: "300px",
  // width: "100%",
  // width: "100vw",
  flexGrow: 1,
  order: 2,
  flex: 1,
  // overflow: manOver,
  // textAlign: asods,
};

export const bediaMark = {
  fontFamily: "sans-serif",
  textDecoration: "none",
  background: "rgb(0, 22, 77)",
  color: "#ffffff",
};

const mainPara = {
  // fontSize: "18px",
  // lineHeight: "26px",
  padding: "20px 0",
};

export const emailButton = {
  ...mainPara,
  ...bediaMark,
  fontSize: "20px",
  lineHeight: "23px",
  minWidth: "20px",
  textDecoration: "none",
  padding: "15px 37px",
  margin: "10px 0px",
  textAlign: "center",
  borderRadius: "4px",
  color: "white",
};

export const borderMessage = {
  // fontSize: "500px",
  borderRadius: "5px",
  fontSize: "20px",
  padding: "20px 10px",
  margin: "20px 10px",
  textAlign: "center",
  border: "1px solid " + appMainDark,
  background: appMainBody,

  // fontFamily: "Times New",
};

export const emailMessage = {
  ...borderMessage,
  padding: "20px 5%",
  margin: "0 5%",
};

export const linkStyle = {
  color: appMainDark,
  textDecoration: "underline",
  fontWeight: "bold",
};

export const smallLink = {
  // color: "red",
  color: "grey",
  fontWeight: "italic",
  fontSyle: "underline",
};

const asodwsdfe =
  //
  // "hoverExpand";
  "hoverExpand";

export const chooseBediaComn = {
  className: asodwsdfe,
  style: {
    // padding: "10px",
    // borderRadius: "50%",
  },
};

const okasd =
  //
  "https://www.youtube.com/watch?v=AqB9vjYxbHM&t=2244s";
// 1media
export const testScrpMd = {
  id: "44c03bcf-3f08-4723-9758-9a160a8fdedb",
  imageAttr:
    "https://ssl-static.libsyn.com/p/assets/f/3/1/5/f3155d27cde48514/cover-300.png",
  mediaDuration: 3631,
  mediaFileLink: okasd,
  name:
    "459 - International Television csafsdijewar ciadsfa sdfwjeq asodasjqwe oasdqowkeqw ko",
  slugURL: "10db4bff3a",
  orderNote: {
    transSpeako: "asdpkweqwasd",
  },
  videoReleasedate: "2020-07-07",
  videoThumbnail:
    "https://ssl-static.libsyn.com/p/assets/f/3/1/5/f3155d27cde48514/cover-300.png",
};

function asdqe(ovaerw, nbdadw) {
  const uwhewq = {
    ...testScrpMd,
    id: ovaerw,
    name: nbdadw,
  };

  return uwhewq;
}
export const testrtRUMPpMd = {
  id: "sdfwerdfewr",
  imageAttr:
    "https://ssl-static.libsyn.com/p/assets/f/3/1/5/f3155d27cde48514/cover-300.png",
  mediaDuration: 3631,
  orderNote: {
    transSpeako: "asdpkweqwasd",
  },
  mediaFileLink:
    "https://traffic.libsyn.com/secure/scriptnotes/459.mp3?dest-id=143783",
  name: "459 - International Television ASDKEWO ASDIJaiJQWE IJQWEIJ9 jasdjw",
  slugURL: "10db4bff3a",
  videoReleasedate: "2020-07-07",
  videoThumbnail:
    "https://ssl-static.libsyn.com/p/assets/f/3/1/5/f3155d27cde48514/cover-300.png",
};

export const testMediarr = [
  //
  testScrpMd,
  testScrpMd,
  testScrpMd,
  testScrpMd,
  testScrpMd,
  testScrpMd,
  asdqe("2a99548c-a7bc-4d8e-901b-8033afe94c67", "TEST-TWO"),
  // testrtRUMPpMd,
  asdqe("73ee0d3b-3e4e-4a3f-bced-ec568e89e3a8", "TEST-FOUR"),
  // testrtRUMPpMd,
];
