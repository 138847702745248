import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";
import {
  mapFunc,
  getFirstArr,
  turnarray,
  checkEmptyArray,
  chunkArrayInGroups,
} from "../functions/GlobalFunctions";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";

export default function HorizChoiceBar({
  displayNumber,
  cutOffNum,
  lineBelow,
  leftObj,
  listNumber,
  flexTrue,
  arrowConfig,
  horizMess,
  titleConfig,
  linkvar,
  sliceTrue,
  sliceNum,
  singleNum,
  spaceTrue,
  rightObj,
  rowDivide,
  noCenter,
  shiftNumber,
  lineNumber,
  itemPad,
  listFigs,
  noChoice,
  noShift,
  spaceAround,
  alignvar,
  rowNumber = 1,
  embedTrue,
  noSlider,
  disVar,
  loadtrue,
  firstTrue,
  showBottom,
  titleVar,
  noTransition,
  hideBottom,
  topObj,
  bottomObj,
  noArrows,
  horizConfig,
  listvar,
  retObj,
  obj,
  messvar,
  ...horzArgs
}) {
  // listNumber = 1
  // displayNumber = 1;
  // rowDivide = "y";

  // 1const 1console
  logs.logga(messvar + "---horizChoicebar-AAAA", listvar, "12390u13");
  listvar = turnarray(listvar);

  sliceNum = sliceTrue ? 1 : sliceNum ? sliceNum : "";

  // const listvar = listvar;
  listvar = firstTrue ? getFirstArr(listvar) : listvar;
  const listLen = listvar.length;
  const checkLen = checkEmptyArray(listvar);
  const showWhole = !checkLen;

  displayNumber = displayNumber ? displayNumber : listLen / rowNumber;

  // 1row
  const shRowo =
    //
    displayNumber && listLen / displayNumber;
  // lineNumber && listLen / lineNumber;

  const rowDiv = shRowo ? shRowo : 1;
  const displNorm = listNumber ? listNumber : listLen;
  const trueit = leftObj || rightObj;

  // const showNum = 2;
  // const shiftNum = 1;
  // const endFinll = 3;
  const endFinll = listvar.length;
  const notLenno = displayNumber < endFinll;
  const showNum =
    //
    // 3;
    // lineNumber;
    notLenno ? displayNumber : endFinll;

  //
  const shiftNum = shiftNumber ? shiftNumber : 1;

  const diffIN = rowDivide ? rowDiv : rowNumber;
  const rowNum =
    //
    // 1;
    diffIN;
  // lineNumber ? lineNumber : diffIN
  // const funcShow = firstTrue && showNum < 2;

  const funcShow = firstTrue;

  const asidnw = {
    textAlign: "center",
    padding: "0 50px",
    // color: "blue",
  };

  function SampleArrow({
    //
    className,
    style,
    onClick,
  }) {
    return (
      <Divo
        className={className}
        style={{
          ...style,
          borderRadius: "50%",
          fontSize: "25px",
          display: "block",
          color: "black",
          background: "black",
        }}
        onClick={onClick}
      />
    );
  }

  const arrShwoas =
    //
    true;
  // false
  // !noArrows

  const steeok =
    //
    // "stretch";
    "center";
  // !noCenter && "center";

  const dirVar =
    //
    "row";

  const jasdow =
    //
    "space-between";

  const saokdwqe = {
    width: "100%",
    padding: "5px 0",
    // width: "220px",
    textAlign: "center",
    alignItem: "stretch",
    // border: "2px solid blue",
    // justify: jasdow,
  };

  horzArgs = {
    spacing: 8,
    direction: dirVar,
    align: steeok,
    // ...horzArgs,
    style: saokdwqe,
    // ...saokdwqe,
  };

  const omcdg = {
    slidesToShow: showNum,
    rows: rowNum,
    slidesToScroll: shiftNum,
  };

  const asdijqw = {
    dots: true,
    infinite: true,
    arrows: arrShwoas,
    className: "center",

    // slidesToShow: 2,
    // slidesToScroll: 2,
    // rows: 1,

    style: asidnw,
    nextArrow: <SampleArrow />,
    prevArrow: <SampleArrow />,
    ...omcdg,
    ...horzArgs,
    // ...omcdg,
  };

  const oaskdwq = {
    // displayNumber: displayNumber,
    // listNumber: listNumber,
    // listLen: listLen,

    ...asdijqw,
  };

  // messCheck
  // logga

  // 1console
  horizMess = "wqeasd";
  logs.logga("___ oaskdwq ___", oaskdwq);

  function as9dq3(serArr, indexSort) {
    const sliceHide = sliceNum ? indexSort + 1 > sliceNum : "";
    const classMn = sliceHide ? "hideSect" : "";

    logs.logga("listvar-aaaaaa", serArr, "12390u13");
    logs.logga("indexSort-aaaaaaa", indexSort, "12390u13");

    // 1padding
    const dksd =
      //
      //
      // "";
      // "10px 20px";
      itemPad ? itemPad : "5px 5px";

    const okdsfs = {
      padding: dksd,
    };

    const okds = obj(serArr, indexSort, loadtrue);
    const lkasd = {
      //
      obj: okds,
      key: indexSort,
      style: okdsfs,
      ...horizConfig,
    };

    // const askdwqe =
    const fglhkfgher = <Divo {...lkasd} />;

    return <>{fglhkfgher}</>;
  }

  function cbngfsh(serArr, indexSort) {
    const okvsxcvbsdg = as9dq3(serArr, indexSort);
    // const bobfdd = <div>asdomwqeqwe</div>;

    return okvsxcvbsdg;
  }

  function Slideor(objList) {
    // logs.logga("___XXX-listvar ___", listvar);

    const lofsda = objList.length;
    const omcdg = {
      slidesToShow: lofsda,
      rows: 1,
      slidesToScroll: lofsda,
    };

    const sadkas = {
      ...omcdg,
      ...asdijqw,
    };

    const ertire = retObj ? retObj : cbngfsh;

    logs.logga("___ Horiz Oobj ___", ertire);

    const sdfoksad = (
      <Slider {...asdijqw}>
        {/*  */}

        {objList.map(ertire)}
      </Slider>
    );

    return sdfoksad;
  }

  // function biffo(objList) {
  //   return (
  //     <gens.Stack
  //       //
  //       isInline
  //       {...horzArgs}
  //     >
  //       {mapFunc(objList, as9dq3)}
  //     </gens.Stack>
  //   );
  // }

  function Sdjwre() {
    // displayNumber = 2;
    const listSplit = chunkArrayInGroups(listvar, displayNumber);

    logs.logga("___ displayNumber ___", displayNumber);
    logs.logga("___ listvar ___", listvar);
    logs.logga("___ listSplit ___", listSplit);

    const oksdf =
      //
      // biffo
      Slideor;
    // noSlider ? biffo : Slideor;

    const lksdfd = listSplit.map(oksdf);

    return lksdfd;
  }

  // //
  noSlider =
    //
    noSlider;
  // "";
  // "y";

  logs.logga("___ noSlider ___", noSlider);

  const pksdf = (
    <>
      {/* sdokfaas */}
      {/* noSlider ? <Sdjwre /> : <Slideor />; */}
      <Sdjwre />
    </>
  );
  //

  const okfd = {
    textAlign: "center",
  };

  const oksdfsa = {
    obj: pksdf,
    style: okfd,
  };

  return <Divo {...oksdfsa} />;
  // return ;
}
