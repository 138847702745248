import CreatePage from "./CreatePage";
export default function YoutubePage({
  //
  match: { params: paramsSo },
}) {
  const isdjwe = {
    fastestvar: "to make Youtube chapters.",
    ...paramsSo,
  };

  return <CreatePage {...isdjwe} />;
}
