import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";

import Divo from "../components/Divo";

import * as logs from "../consts/logFuncs";

import { ScriptContext } from "../contexts/ScriptContext";
import { ImageAlign } from "../functions/GlobalFunctions";
// import aaaa from "./components/aaaa";

export default function ThumbnailMedia({
  obj,
  typevar = "media",
  styleMain,
  alignvar,
  imageSize,
  dictvar,
  smallItem,
  timeConfig,
  accessConfig,
  noReplaceBedia,
  imageConfig,
  listConfig,
  smallVar,
  ...args
}) {
  //

  const whonbl = {
    background: "black",
    color: "white",
  };
  const blonwh = {
    background: "white",
    color: "black",
  };
  const stlTh = {
    ...whonbl,
    // ...blonwh,
    fontSize: "0.9em",
    // padding: "5px",
  };

  smallItem = smallItem ? smallItem : "duration";
  // const mainImg = getPrimeSeriesImage(obj)
  //   ? getPrimeSeriesImage(obj)
  //   : obj.videoThumbnail;
  //

  const gods = {
    obj: obj,
  };

  timeConfig = {
    obj: obj.duration,
    style: stlTh,
    ...timeConfig,
  };

  accessConfig = {
    //
    ...gods,
    ...accessConfig,
  };

  logs.logga("___ accessConfig ___", accessConfig);

  // const gfobks = <CheckAccessIcon {...accessConfig} />;
  const ccssOjb =
    //
    "";
  // "fdgpkewr";
  // gfobks;
  //

  const alignDura =
    //
    // "bottomRight"
    "topLeft";

  const objDura = (
    //
    // "lds";
    <Divo spanTrue {...timeConfig} />
  );

  const asdokw = "vvv";

  const immo = {
    access: {
      alignvar: "topLeft",
      obj: ccssOjb,
    },
    duration: {
      alignvar: alignDura,
      obj: objDura,
    },
    link: {
      alignvar: "topRight",
      //   obj: "vvv",
    },
    link: {
      alignvar: "topRight",
      obj: asdokw,
    },
    number: {
      alignvar: "bottomRight",
      obj: "$",
    },
    ...dictvar,
  };

  // const kdsfoew =

  const lksdoe = [
    //
    "link",
    "duration",
    "access",
  ];

  const lstDoca = {
    listvar: lksdoe,
    ...listConfig,
    dictvar: immo,
  };

  logs.logga("___ obj ___", obj);

  const okvsea =
    //
    // gens.BediaLogoWhite;
    obj.videoThumbnail ? obj.videoThumbnail : gens.BediaMagBlack;

  const soadkaewa = {
    // noAntd: true,
    shapeVar: "square",
    imagevar: okvsea,
    imageSize: imageSize,
    ...imageConfig,
  };

  logs.logga("___ soadkaewa ___", soadkaewa);
  logs.logga("___ okvsea ___", okvsea);

  const asdinw = {
    imageConfig: soadkaewa,
    // smallConfig: lstDoca,
    // ...args,
  };

  const oaksdwq = (
    //
    <ImageAlign {...asdinw} />
  );

  return oaksdwq;
}
