import React, {
  //
  useState,
  useContext,
} from "react";

import Divo from "../components/Divo";
import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import { IconButton, Button, ButtonGroup } from "@chakra-ui/core";
import { TiCancel, TiEdit, TiTick } from "react-icons/ti";

export default function ConfirmButton({
  textConfig,
  genConfig,
  disableTrue,
  errorTrue,
  background,
  bediaTrue,
  noBackground,
  style,
  obj,
  textvar,
  fontSize,
  longTrue,
  downloadLink,

  extStyle,
  chakTrue,
  // isLoading,
  groupConfig,
  loadClick,
  linkvar,
  logTrue,
  noGreen,
  divConfig,
  onlyText,
  typevar = "confirm",
  ...arg
}) {
  // const asndiw = {
  //   order: {
  //     linkvar: "/order",
  //     iconvar: "transcript",
  //     textvar: "Order Transcripts",
  //   },
  //   confirm: {
  //     textvar: "Confirm",
  //   },
  // };

  // textConfig = textConfig ? textConfig : asndiw[typevar];

  // const asdijwe = {
  //   font-weight: bold;
  //   height: 45px;
  //   padding: 2px 15px;
  //   border-radius: 5px;
  //   outline: none;
  //   background: #09d3ac;
  //   color: white;
  //   font-size: 14px;
  //   border: none;
  //   box-sizing: border-box;
  // }

  // bediaTrue = "QIWE";
  const sdfoer = errorTrue && {
    background: "red",
    color: "white",
  };
  const sdkwe = bediaTrue && gens.bediaMainStyle;

  const longeos = longTrue && {
    width: "200px",
  };

  const baseoSt = {
    padding: "10px 10px",
    ...longeos,
    color: noBackground ? "black" : "white",
    // fontSize: "30px",
    // height: "50px",
    background: background ? background : !noBackground && gens.confirmGreen,
  };

  const baseStlas = {
    fontSize: fontSize,
    ...baseoSt,
    ...sdkwe,
    ...sdfoer,
    ...style,
    ...extStyle,
    // width: "500px",
    // fontSize: "50px",
  };

  // "";

  const sfasdokwe = {
    ...arg,
    style: baseStlas,
    className: gens.butClass,
    isDisabled: disableTrue,
    // ...uahwe,
  };

  // onlyText= "true"
  const asodkwe = obj || onlyText || textvar;
  const qy7eww = asodkwe ? asodkwe : "Confirm";

  const aisjqw = (
    <>
      {/* qy7eww */}
      {qy7eww}
      {/* {njgyret} */}
    </>
  );

  // qy7eww ? qy7eww : <ImageTextDiv {...asduhqwe} />;

  // chakTrue = chakTrue ? chakTrue : sfasdokwe["isLoading"];

  function GroupBase({ rightObj, leftObj, leftConfig, rightConfig, ...qwe }) {
    const nidsfjer = {
      // size: "sm",
      // isAttached: true,
      // variant: "outline",
      ...qwe,
    };

    const nsidjew = {
      style: { fontSize: "1.0em" },
    };

    const rghtFunnp = {
      ...sfasdokwe,
      ...rightConfig,
    };

    const nsdijs = rightObj && (
      <Button {...rghtFunnp}>
        <div {...nsidjew}>{rightObj}</div>{" "}
      </Button>
    );

    const isadjwe = (
      <ButtonGroup {...nidsfjer}>
        <Button {...sfasdokwe}>{aisjqw}</Button>
        {nsdijs}
        {/* <Button mr="-px">Save</Button> */}
        {/* <IconButton icon={<TiEdit />} /> */}
      </ButtonGroup>
    );

    return isadjwe;
  }

  const sfdsgwe = groupConfig ? (
    <GroupBase {...groupConfig} />
  ) : chakTrue ? (
    <Button {...sfasdokwe}>{aisjqw}</Button>
  ) : (
    <button {...sfasdokwe}>{aisjqw}</button>
  );

  const asdijew =
    //
    // "";
    linkvar || divConfig;

  const jawewe = {
    obj: sfdsgwe,
    linkvar: linkvar,
    ...divConfig,
  };

  logs.logga("___ jawewe ___", jawewe);

  const asdgvwe = asdijew ? <Divo {...jawewe} /> : sfdsgwe;

  const pdska = downloadLink ? (
    <a href={downloadLink} download>
      {asdgvwe}
    </a>
  ) : (
    asdgvwe
  );

  // const isjdfe =

  const dweasdd = {
    obj: pdska,
    ...genConfig,
  };

  logTrue = true;
  // 1console
  // logs.logga("___ sfasdokwe ___", sfasdokwe);
  if (logTrue) {
    logs.logga("___CONFIRM BUTTON " + aisjqw, sfasdokwe);
  }

  logs.logga("___ CONFIRMBUTTON  STYLE___", baseStlas);
  logs.logga("___ baseStlas ___", baseStlas);

  const asdhwqe = <Divo {...dweasdd} />;

  return asdhwqe;
}
