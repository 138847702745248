import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";
import Divo from "../components/Divo";
import {
  convertHTML,
  mapFunc,
  normalText,
  EmailMessage,
} from "../functions/GlobalFunctions";
import * as logs from "../consts/logFuncs";
// import ModelShowy from "../containers/Line";
// import { fullEmail } from "../consts/emailHTML";

export default function EmailConst({
  //
  // obj,
  obj,
  butLink,
  butText,
  topObj,
  bottomObj,
  scribeMessage,
  htmlTrue,
}) {
  // // 1context
  // scriptcontext
  // playcontext

  // 1const
  // 1message

  // const asdjwqe = <>
  // aaaaa
  // </>

  // logs.logga("___ obj ___", obj);

  // 1recipfu
  function recipFunc(asdfewr) {
    const finso =
      //
      // obj && obj[asdfewr];
      "{{ " + asdfewr + " }}";
    // "%recipient." + asdfewr + "%";

    return finso;
  }

  // 1function
  function padVero(cxokvs, texawe = "center") {
    const ofgdf = {
      margin: "25px 0 ",
      textAlign: texawe,
    };
    const oawe = {
      style: ofgdf,
      // obj: cxokvs,
    };

    return <div {...oawe}>{cxokvs}</div>;
  }

  //

  // 1links
  const sarc = linko(
    recipFunc("examplePageSearch"),
    "search across all your transcripts in one place"
  );

  const baseProp = (
    <>
      {/*  */}
      {/*  */}
      We make it easy to share your {recipFunc("broadType")} with a transcript
      and subtitles on any website, {/*  */}
      {/*  */}
      with just a link; no need to add anything yourself.
      {/* without having to add anything yourself. */}
      {/*  */}
      {/*  */}
      {/* We're a new app that lets you easily find quotes in your{" "}
    {recipFunc("typeobj")} though its transcript and share the
    right moment with subtitles on social media. */}
    </>
  );

  const sadjoewq = (
    <>
      If this sounds useful, it's easy to{" "}
      {linko(
        recipFunc("orderLink"),
        "connect your  " + recipFunc("channelType") + " channel to Bedia"
      )}{" "}
      and we'll make new episodes accessible as soon as they're uploaded. We
      also let you{" "}
      {linko(
        recipFunc("examplePageSearch"),
        "search across all your transcripts in one place"
      )}{" "}
      to find good quotes, plus detailed analytics about what your audience is
      searching and and sharing.
      {/* from previous episodes. */}
      {/* and letting you share each individual quote  */}
      {/* and lets you
  search  */}
    </>
  );

  const messo = (
    <>
      We've already done o; you can look at, correct potential transcript
      mistakes we didn't catch and share highlights to social media all from the
      same place.
    </>
  );

  const oiaesdf = (
    <>
      {/*  */}
      Bedia's the ea
    </>
  );

  const hithere = <></>;

  const talkSoon = <>.</>;
  const medNameBold = (
    <Divo
      spanTrue
      style={{
        fontWeight: "bold",
      }}
      obj={recipFunc("mediaName")}
    />
  );

  const watchArFor = [
    {
      iconvar: "🎥",
      linkvar: recipFunc("videoViewType"),
      firstVar: "Watch",
      colorVar: "lightgreen",
    },
    {
      iconvar: "👁",
      linkvar: recipFunc("transcriptViewType"),
      firstVar: "Read",
      colorVar: "lightblue",
    },
    {
      iconvar: "🎙",
      linkvar: recipFunc("audioViewType"),
      firstVar: "Listen",
      colorVar: "lightpink",
    },
  ];

  // {'videoViewDate': '2 months ago', 'videoViewName': 'First Minister Statement - 9 July 2020', 'videoViewLink': 'https://www.bedia.tv/video/92aa847cc2?l=1', 'videoViewImage': 'https://img.pngio.com/scottish-parliament-wikipedia-scottish-parliament-png-333_299.png', 'videoViewBio': "Ken Macintosh: 'Good afternoon, colleagues. Before we begin, I remind members, as always, to observe the social distancing rules that are in place throughout the building, particularly when entering or leaving the chamber.'"}

  const oneS =
    //
    // 🎥 +
    //
    recipFunc("exampleSerOne");
  const twoS =
    //
    // 🎙 +
    //
    recipFunc("exampleSerTwo");
  const threeS =
    //
    // 🎥 +
    //
    recipFunc("exampleSerThree");

  // 1trending  1channels
  const chaTrend = {
    one: {
      name: oneS,
      imageAttr: recipFunc("exampleImgOne"),
      linkvar: recipFunc("exampleLinkOne"),
    },
    two: {
      name: twoS,
      imageAttr: recipFunc("exampleImgTwo"),
      linkvar: recipFunc("exampleLinkTwo"),
    },
    three: {
      name: threeS,
      imageAttr: recipFunc("exampleImgThree"),
      linkvar: recipFunc("exampleLinkThree"),
    },
  };

  // 1readwatch
  const readWatchDict = {
    watch: {
      name: recipFunc("watchViewName"),
      imageAttr: recipFunc("watchViewImage"),
      bioVar: recipFunc("watchViewDescrip"),
      // linkvar: recipFunc("watchViewLink"),
      // //
      // name: "asd9jqpeqw",
      // imageAttr:
      //   "https://img.pngio.com/scottish-parliament-wikipedia-scottish-parliament-png-333_299.png",
      // bioVar: "ad9jqw qwjqwoe q0eqw odqwq",
      // linkvar: "qd9jqeqweqw",
    },
    read: {
      name: recipFunc("watchViewName"),
      imageAttr: recipFunc("watchViewImage"),
      bioVar: recipFunc("watchViewDescrip"),
      linkvar: recipFunc("watchViewLink"),
    },
    listen: {
      name: recipFunc("watchViewName"),
      imageAttr: recipFunc("watchViewImage"),
      bioVar: recipFunc("watchViewDescrip"),
      linkvar: recipFunc("watchViewLink"),
    },
  };

  function typeWatchArro(typevar = "speaker") {
    const textSeaoqwe = {
      speaker: "for quotes or people across your videos in one place.",
      series: "for quotes or people across your videos in one place.",
    };

    const arrFoe = [
      {
        iconvar: "🔍",
        linkvar: recipFunc("searchViewType"),
        firstVar: "Search",
        textvar: textSeaoqwe[typevar],
      },
      {
        iconvar: "☝",
        linkvar: recipFunc("shareViewType"),
        firstVar: "Share",
        textvar: (
          <span>
            your favourite moments in one click.
            {/* across social media  */}
            {/* from Twitter posts to WhatsApp messages. */}
          </span>
        ),
      },
      {
        iconvar: "💾",
        linkvar: recipFunc("saveViewType"),
        firstVar: "Save",
        textvar: (
          <span>
            {/*  */}
            highlights of clips or quotes to use later.
            {/* within a broadcast  */}
          </span>
        ),
      },
    ];

    return arrFoe;
  }

  // <gens.WrapQuotes
  //         //
  //         disVar
  //         // styleVar={{
  //         //
  //         //   // padding: "0 18px",
  //         //   margin: "0 10px",
  //         // }}
  //         obj={obj.textvar}
  //       />

  function IcoBqwa({ obj, className, ...are }) {
    const allbut = (
      <span>
        {/*  */}
        {obj.textvar ? obj.textvar : obj}
      </span>
    );

    // emailButton " +
    const allEms = (
      <a class="bedialink" href={obj.linkvar}>
        <div
          {...are}
          // style={{
          //   background: obj.colorVar,
          //   // fontSize: "1em",
          // }}
          class={className}
        >
          {obj.iconvar ? (
            <div>
              <span class="bedBeforeList">{obj.iconvar}</span>
              <span class="bedAfterList">{allbut}</span>
            </div>
          ) : (
            allbut
          )}
        </div>
      </a>
    );
    return allEms;
  }

  const bedMark = {
    // family: sans-serif,
    // text-decoration: none
    background: "rgb(0, 22, 77)",
    color: "#ffffff",
  };

  // 1button
  function buttDiv(obj) {
    const ksada = {
      fontSize: "26px",
      // lineHeight: "30px",
      minWidth: "20px",
      textDecoration: "none",
      padding: "15px 7px",
      margin: "10px 0px",
      textAlign: "center",
      borderRadius: "4px",
    };

    const oawe = {
      className: "bediaMark pointer",
      obj: obj,
      style: {
        ...bedMark,
        ...ksada,
      },
      // ...sdfwe,
    };

    const uhaewq = (
      <>
        {/* zdfpoksda */}
        <div {...oawe}>{obj}</div>
        {/* <Divo {...oawe} /> */}
      </>
    );

    //
    return uhaewq;
  }

  function watchbuttDiv(obj) {
    // const allEms = (
    //   <th
    //   //
    //   >
    //     <IcoBqwa
    //       style={gens.emailButton}
    //       className="emailButton bediaMark"
    //       obj={obj}
    //     />
    //   </th>
    // );
    // return allEms;
  }

  function htmlList(obj) {
    return (
      <div class="bedTabList">
        <IcoBqwa
          //
          obj={obj}
        />
      </div>
    );
  }

  // 1title 1heading 1intro
  function titleDiv(obj) {
    return <h1 class="bold emailTitle">{obj.textvar}</h1>;
  }

  function listDiv() {
    return (
      <div>
        <hr />
        {mapFunc(typeWatchArro, htmlList)}
      </div>
    );
  }

  function watchlistDiv() {
    const allEms = (
      <div class="emailCenter">
        <hr />
        <div
          //
          class="emailTabe"
        >
          {mapFunc(watchArFor, watchbuttDiv)}
        </div>
      </div>
    );
    return allEms;
  }

  const regardsDiv = (
    <div class="regardso">
      Regards,
      <br />
      The Bedia Team
      {/* <div>Regards,</div>
        <div>Andrew</div> */}
    </div>
  );

  function retto(obj) {
    return obj.obj ? obj.obj : obj.funcVar(obj);
  }
  // 1link
  function linko(linkvar, textvar) {
    return <a href={linkvar}>{textvar}</a>;
  }

  const vidoss = {
    className: "bold",
  };

  // 1request CONST
  const intrMess = (
    <span>
      Andrew
      {/* {recipFunc("requesterName")} */}
      {/*  */} requested a transcript for your {recipFunc("broadType")}{" "}
      <span {...vidoss}>{medNameBold}</span> through Bedia.
    </span>
  );

  const vbfdg = {
    // style: {
    //   padding: "2px 0",
    //   // color: "red",
    // },
  };
  const okawe =
    //
    "{{{ requestMessage }}}";
  // recipFunc("requestMessage");

  const sfdhetw = (
    <>
      {hithere}
      {/* // 1request 1message */}
      {okawe}
      {/* {splitnewlines(okawe, vbfdg)} */}
      {/* <br /> */}
      {/* // ijawe,
      // baseProp,
      // sadjoewq, */}
    </>
  );

  // 1email
  function emailBase(objssadf) {
    const cplgsdas = {
      padding: "0 5%",
      textAlign: "left",
    };
    const ijawe = {
      obj: objssadf,
      // no
      textConfig: { style: cplgsdas },
    };

    const madiwme = EmailMessage(ijawe);
    const messgeGeno =
      //
      madiwme;
    // linko(recipFunc("orderLink"), madiwme);

    return messgeGeno;
  }

  const adksadw =
    //
    //  "on our order page"
    "on the " + recipFunc("seriesName") + " homepage";

  const repsondvar = (
    <>
      You can respond to Andrew's request{" "}
      {linko(recipFunc("orderLink"), adksadw)}.{/*  */}
      {/* asdwe */}
      {/* (delivery in {recipFunc("mediaOrderTime")}) */}
      {/* adar */}
      {/* asdasd  */}
      <br />
      If you accept, we'll complete your files within{" "}
      {recipFunc("mediaOrderTime")}.{/*  */}
      {/* If you'd like Andrew to automatically send connect new episodes from the{" "}
      {recipFunc("mainSerName")} channel,   as soon as they're uploaded.
      If you'd like to do more episodes, just add the links to their video on
      Youtube and we'll automically send them to Andrew as well. */}
    </>
  );

  const ordeLink = recipFunc("orderLink");
  const orderWhchi = (
    <>
      {/* You can order which episodes you'd like transcribed from your homepage
    or and we'll send you a link for each one to share online, simple as
    that. You'll also get For example, this video would */}
      {/* connect your {recipFunc("typeChannelVar")} easy to connect your channel to Bedia directly and let us  */}
      {/* {repsonLink} */}
      {repsondvar}
      {/* Bedia takes care of ordering transcript */}
      {/* If you need make other  it's and
    want to add more quotes & {recipFunc("typeobj")}s{" "}
    ,{" "} */}
    </>
  );

  const waeasw =
    // recipFunc("broadTypeIcon") + " | " +
    // 😃 😊
    "😃 " + recipFunc("mainSerName");
  //
  // " asdkae aod kaewq okasdk qweo qwk aksd ";

  const asgwesadas = {
    obj: waeasw,
    className: "wrapTrue",
  };

  // 1onbedia
  const sdfeawras = (
    <>
      {/*  */}
      {/* <div {...asgwesadas}>{waeasw}</div> */}
      {/* Order On Bedia */}
      {/* {waeasw} on Bedia */}
      {/* ❓ See Request */}
      {/* ❓ How Bedia Works */}
      Request: {recipFunc("mediaNameCut")}
    </>
  );

  const mainButtLink = linko(recipFunc("orderLink"), buttDiv(sdfeawras));

  const uqwasew =
    //
    // 🔗
    // "🛈 | How Bedia Works"
    "❓ How Bedia Works ";

  const aboutLink = linko(recipFunc("aboutLink"), buttDiv(uqwasew));

  // {/* {channelsList} */}
  //   {/* // { funcVar: watchlistDiv }, */
  //   {/* // getInTouch, */}
  //
  //
  // 1request 1email 1series
  const requestScriptArr = (
    <>
      {intrMess}
      {emailBase(sfdhetw)}
      {mainButtLink}
      {padVero(orderWhchi)}
      {aboutLink}
      {/* {aboutLink} */}
    </>
  );

  const startFIdtVar = [
    { funcVar: titleDiv, textvar: recipFunc("shortNameMain") + "," },
  ];

  const endFIdtVar = [
    //
    // { funcVar: regardsDiv },
    { funcVar: listDiv },
  ];

  const typeFind = {
    // speaker: mainArrFin,
    series: requestScriptArr,
    // transcribed: transDoneArr,
    // happy: happpyDoneq,
  };

  // 1array 1template
  const endArrr =
    typeFind[
      //
      // "speaker"
      // "happy"
      "series"
    ];
  // const endArrr = requestScriptArr;

  // const asdojw = (
  //   //
  // )

  // 1base Main
  const sadokae = (
    <>
      {/*  */}
      TEXTMAIN
    </>
  );

  const linkMaino =
    //
    "MAINLINK";

  const buttTexto =
    //
    "BUTTON";

  const texosa = linko(linkMaino, buttDiv(buttTexto));

  const fvdrewas = {
    // className: "mainEmDiv",
    style: {
      padding: "100px 0 20px",
      fontSize: "20px",
    },
  };

  const BASEMAIN = (
    <>
      <div {...fvdrewas}>{sadokae}</div>
      {texosa}
    </>
  );

  const allTexto =
    //
    // "aosdeww";
    obj ? obj : recipFunc("baseText");

  logs.logga("___ allTexto ___", allTexto);

  //
  const xvbmoewr =
    //
    linko(butLink, buttDiv(butText));
  // linko(recipFunc("buttonLink"), buttDiv(recipFunc("buttonText")));

  const buttArrow = padVero(<>↓ ↓ ↓{xvbmoewr}↑ ↑ ↑</>);

  const scribeMain = scribeMessage && emailBase(scribeMessage);

  const basetxtArr = (
    <>
      <div {...fvdrewas}>{allTexto}</div>
      {scribeMain}
      {buttArrow}
      {bottomObj}
    </>
  );

  // # 1base

  const olhrsdf = {
    className: "mainEmDiv",
    style: {
      padding: "0 2%",
      fontSize: "20px",
    },
  };

  // 1latest 1main
  const timsStirngs = (
    <div>
      <div {...olhrsdf}>
        {/* {mapFunc(startFIdtVar, retto)} */}
        {/* {requestScriptArr} */}
        {basetxtArr}
        {/* {BASEMAIN} */}
        {/* {mapFunc(endArrr, retto)} */}
        {/* {mapFunc(latestArro, retto)} */}
        {/*  */}
        {padVero(regardsDiv, "left")}
      </div>
      <div class="mainEmFooter">
        {/* {mapFunc(endFIdtVar, retto)} */}
        {/*  */}
      </div>
    </div>
  );

  // htmlTrue = true;
  // 1HTML
  return (
    <>
      {/*  */}
      {/* asdojqweqw */}
      {htmlTrue ? convertHTML(timsStirngs) : timsStirngs}
    </>
  );
}

// {/* {fullEmail} */}
