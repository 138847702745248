import React, {
  useState,
  useReducer,
  Fragment,
  createRef,
  forwardRef,
  useContext,
  useRef,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function LinkGlobal({
  //
  outsideVar,
  blankTrue,
  outsideTrue,
  hrefTrue,
  spaceTrue,
  hashTrue,
  homeTrue,
  blackVar,
  styleMain,
  // styleVar,
  target,
  disabled,
  toVar,
  linkConfig,
  linkvar,
  linkTextTrue,
  textvar,
  obj,
  className,
  ...argso
}) {
  // scriptcontext
  // playcontext
  // prettier-ignore
  // const { colorTextMain, colorTextAll, darkSet} = useContext(ScriptContext);
  // prettier-ignore
  // {  //   const { pip, handleSetPlaybackRate, handleVolFull, handleVolMute, progress, handlePlayPause, playedSeconds, playedTimeDigital, playedTimeInt, playedTimeFloat, isPlaying, loop, playbackRate, LineTextContent, setLineTextContent, volume, seeking, muted, setfunc, subUpdate, handleSeekMouseUp, handleSeekMouseDown, handleSeekChange, handlePlay, handleEnablePIP, handleDisablePIP, handlePause, handleProgress, handleProgressEmbed, handleDuration, mediaDuration, percentPlayed, decimalPlayed, getCurrentTime, handleEnded, getDuration } = useContext(PlayContext);}

  let history = useHistory();

  textvar = textvar ? textvar : obj
  textvar = spaceTrue ? " " + textvar + " " : textvar;
  toVar = linkvar || toVar;
  toVar =
    //
    // ""
    linkTextTrue && textvar
      ? textvar.toLowerCase()
      : hashTrue
      ? "#" + toVar
      : homeTrue
      ? "/"
      : toVar;
  // toVar = "/home";

  // const outReg = gens.outsideReg;
  const outReg = /^(https?:\/\/)|(mailto:)/;
  const outBlank = outsideTrue || outsideVar || blankTrue;
  const noLink = disabled | !toVar;
  const outBase = hrefTrue || hashTrue || outReg.test(toVar);
  const outTrue =
    //
    true;
  // outBase;

  const styleFeo = styleMain ? "linkPlush" : "";
  const textPush = styleMain ? <Fragment> {textvar} </Fragment> : textvar;


  //   history.push(submitLink);

  const aisdwq = {
    // target: "_blank",
    target: outBlank ? "_blank" : "",
    href: toVar,
    to: toVar,
    style: {
      textDecoration: "none",
      color: "black",
      // color: colorTextMain,
    },
    className: styleFeo,
    ...argso,
  };

  // logs.logga("___ aisdwq ___", aisdwq);

  function Rlasdow() {
    return outTrue ? (
      <a {...aisdwq}>{textPush}</a>
    ) : (
      // <gens.StyledLink {...aisdwq}>{textPush}</gens.StyledLink>
      <Link {...aisdwq}>{textPush}</Link>
    );
  }

  // return "asd9jasndw";
  return noLink ? textPush : <Rlasdow />;
}
