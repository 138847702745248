// import gql from "graphql-tag";
import gql from "fraql";
import * as mainAttrs from "./mainAttr";

// export const RSS_FETCH_BY_LINK = gql`
// query RSS_FETCH_BY_LINK($graphurl: String) {
//   rss {
//     rss2Feed(url: $graphurl) {
//       category
//       description
//       pubDate
//       title
//       link
//       image {
//         link
//         title
//         description
//         width
//         height
//         uri
//       }
//       managingEditor
//       lastBuildDate
//       language
//       generator
//       docs
//       textInput {
//         __typename
//       }
//       items {
//         guid {
//           data
//           permalink
//         }
//         author
//         category {
//           data
//           domain
//         }
//         comments
//         content
//         contentUri
//         description
//         link
//         pubDate
//         title
//         enclosure {
//           url
//           mime
//           length
//         }
//         guid {
//           data
//           permalink
//         }
//         source {
//           url
//           data
//         }
//       }
//     }
//   }
// }
// `;

// export const YOUTUBE_FETCH_BY_VIDEO = gql`
// query YOUTUBE_FETCH_BY_VIDEO($graphurl: String) {
//   youTube {
//     __typename
//     playlist(id: $graphurl) {
//       id
//       status {
//         privacyStatus
//       }
//       items(first: 30) {
//         nodes {
//           id
//           status {
//             privacyStatus
//           }
//           contentDetails {
//             videoId
//             video {
//               ageGating {
//                 alcoholContent
//                 restricted
//               }
//               contentDetails {
//                 duration
//                 regionRestriction {
//                   allowed
//                   blocked
//                 }
//                 projection
//               }
//               snippet {
//                 title
//                 thumbnails {
//                   default {
//                     width
//                     url
//                     height
//                   }
//                 }
//                 tags
//                 publishedAt
//                 liveBroadcastContent
//                 description
//               }
//             }
//           }
//         }
//         pageInfo {
//           startCursor
//           endCursor
//         }
//       }
//     }
//   }
// }
// `;

// export const YOUTUBE_FETCH_BY_PLAYLIST = gql`
// # fragment videoFields on Video {
// #   name
// #   friendsConnection(first: $first) {
// #     totalCount
// #     edges {
// #       node {
// #         name
// #       }
// #     }
// #   }
// # }

// query YOUTUBE_FETCH_BY_PLAYLIST($graphurl: String) {
//   youTube {
//     __typename
//     playlist(id: $graphurl) {
//       id
//       status {
//         privacyStatus
//       }
//       items(first: 15) {
//         nodes {
//           id
//           status {
//             privacyStatus
//           }
//           contentDetails {
//             videoId
//             video {
//               ageGating {
//                 alcoholContent
//                 restricted
//               }
//               contentDetails {
//                 duration
//                 regionRestriction {
//                   allowed
//                   blocked
//                 }
//                 projection
//               }
//               snippet {
//                 title
//                 thumbnails {
//                   default {
//                     width
//                     url
//                     height
//                   }
//                 }
//                 tags
//                 publishedAt
//                 liveBroadcastContent
//                 description
//               }
//             }
//           }
//         }
//         pageInfo {
//           startCursor
//           endCursor
//         }
//       }
//     }
//   }
// }
// `;

// 1args MAIN 1base
//
//
// $id: String,
// $id_In: [String],
// $slugURL: String,
// $slugURL_In: [String],
// $createTrue: String,
// $saveArgs: String,
// ) {
// video(
//   id: $id,
//   id_In: $id_In,
//   slugURL: $slugURL,
//   slugURL_In: $slugURL_In,
//   createTrue: $createTrue,
// saveArgs: $saveArgs,
// ) {

//   # requestPrice: $requestPrice,
//   name: $name,
//   mediaLink: $mediaLink,
// $mediaLink: [String],
// $name: String,

// export const fetchNumber = 10;

// export const speakAttr = gql`
//   fragment _ on SpeakerType {
//     firstname
//     lastname
//     id
//     twitterID
//     WikipiediaLink
//     imageAttr
//     MainLink
//     videoSet {
//       ${mediaAttr}
//     }
//   }
// `;

//
//
// createTrue: $createTrue,
// 1series
export const SERIES_FETCH_BY_IDENT = gql`
  query SERIES_FETCH_BY_IDENT(
    $id: String,
    $id_In: [String],
    $slugURL: String,
    $slugURL_In: [String],
    $createTrue: String,
    # 
    $youtube: String,
    $podcast: String,
    $twitch: String,
    $facebook: String,
    $email: String,
    
  ) {
    series(
      id: $id,
      id_In: $id_In,
      slugURL: $slugURL,
      slugURL_In: $slugURL_In,
      createTrue: $createTrue,
      #  
      youtube: $youtube,   
      podcast: $podcast, 
      facebook: $facebook, 
      twitch: $twitch, 
      email: $email,       
    ) {
      ${mainAttrs.seriesPage}
    }
  }
`;

// $startTime: Float,
// startTime: $startTime,
// speaker: $speaker,
//

// type slugFetch {
//   $slugURL: String
// }

// //
// line(slugURL: $slugURL, lineMedia: $lineMedia, lineNumber: $lineNumber, ) {
// line(slugURL: $slugURL) {
// 1line

export const LINE_FETCH_BY_SLUG = gql`
  query LINE_FETCH_BY_SLUG(
    # 
    $slugURL: String,
    $lineMedia: String,
    $lineNumber: Float,
    $endLine: Float,
    $startTime: Float,
    $lineSpeaker: String,

) {
    line(
      # 
      slugURL: $slugURL,
      lineMedia: $lineMedia,
      lineNumber: $lineNumber,
      endLine: $endLine,
      startTime: $startTime,
      lineSpeaker: $lineSpeaker,
      ) {
      ${mainAttrs.linePrime}
    }    
  }
`;

// ${mainAttrs.mediaBase}
// ${mainAttrs.mediaPage}
// const asidjw = $slugURL: String

// $medialink:[String]
// 1media
// medialink: $medialink
// slugURL_In

// $createSeries: Bool,
// createSeries: $createSeries
export const MEDIA_FETCH_BY_SLUG = gql`
  query MEDIA_FETCH_BY_SLUG(
    $id: String,
    $id_In: [String],
    $slugURL: String,
    $slugURL_In: [String],
    $createTrue: String,
  # 
    $mediaLink: [String],
    ) {
    media(
      id: $id,
      id_In: $id_In,
      slugURL: $slugURL,
      slugURL_In: $slugURL_In,
      createTrue: $createTrue,
      # 
      mediaLink: $mediaLink,
    ) {
      ${mainAttrs.mediaPage}
    }
  }
`;

// # ${mainAttrs.mediaPage}
export const MEDIA_BASE_BY_IDENT = gql`
  query MEDIA_BASE_BY_IDENT(
    $id: String,
    $id_In: [String],
    $slugURL: String,
    $slugURL_In: [String],
    $createTrue: String,
    # 
    $mediaLink: [String],
    ) {
    media(
      id: $id,
      id_In: $id_In,
      slugURL: $slugURL,
      slugURL_In: $slugURL_In,
      createTrue: $createTrue,
      mediaLink: $mediaLink,
    ) {        
      ${mainAttrs.mediaBase}
    }
  }
`;

export const orderBaseFetch = gql`
  query (
    $id: String,
    $slugURL: String,
    $successURL: String,
    $paidTrue: String,
    $saveArgs: String,
  # 
    ) {
    order(
      id: $id,
      slugURL: $slugURL,
      successURL: $successURL,
      paidTrue: $paidTrue,
      saveArgs: $saveArgs,
    ) {
      ${mainAttrs.orderBase}
    }
  }
`;

// query SPEAK_FETCH_BY_IDENT($slugURL: String, $traceSpeaker: String) {
//   speaker(slugURL: $slugURL, traceSpeaker: $traceSpeaker) {

// 1speaker
export const SPEAK_FETCH_BY_IDENT = gql`
  query SPEAK_FETCH_BY_IDENT(
    $id: String,
    $id_In: [String],
    $slugURL: String,
    $slugURL_In: [String],
    $createTrue: String,
    # 
    ) {
    speaker(
      id: $id,
      id_In: $id_In,
      slugURL: $slugURL,
      slugURL_In: $slugURL_In,
      createTrue: $createTrue,
      # 
    ) {
      ${mainAttrs.speakerPage}
    }
  }
`;

// export const baseQuery = gql`
//   query baseQuery($slugURL: String, $id: String) {
//     object(slugURL: $slugURL, id: $id) {
//       name
//     }
//   }
// `;

// const queryInput = ($slugURL: String, $id: String)
// const queryArgument = (slugURL: $slugURL, id: $id)
// export const baseQuery = gql`
//   query baseQuery($queryInput) {
//     object($queryArgument) {
//       name
//     }
//   }
// `;

// mainAttrs.commentMain
// 1mutate
// ${mainAttrs.allComment}
// ${mainAttrs.commentBase}

// media: $media, series: $series, publicTrue: $publicTrue, requestTrue: $requestTrue,
// $media: String,
// $series: String,
// $publicTrue: Bool,
// $requestTrue: Bool,
// lineMedia: $lineMedia, lineSpeaker: $lineSpeaker, lineNumber: $lineNumber, startTime: $startTime) {
export const COMMENT_BASE_MUTATE = gql`
  mutation CreateComment(    
    $text: String  
    ) {
  createComment(text: $text) {
    ${mainAttrs.commentBase}
    }
  }
`;

export const SPEAKER_BASE_MUTATE = gql`
  mutation CreateComment(    
    $firstname: String  
    ) {
  createSpeaker(
    firstname: $firstname
    # 
    ) {
    ${mainAttrs.speakerBase}
    }
  }
`;
