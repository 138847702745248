import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import ModalMain from "../components/ListMain";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  changePage,
  getLocal,
  getSHortSet,
  ScribeRate,
  setGlobalSource,
  ScribeChat,
  setLocal,
  //
} from "../functions/GlobalFunctions";
import ConfirmButton from "../components/ConfirmButton";

export default function ScribeMessage({
  //
  dataImport,
  ...args
}) {
  // 1scriptcontext
  const { seriesOver, currentScribe, modalShow, eitherOrdReq } = useContext(
    ScriptContext
  );

  const aijwe = getLocal("noScribeMess");
  const [hideMssso, sethideMssso] = useState(aijwe);
  const showMess = eitherOrdReq && !hideMssso;

  // 1const
  const [type, setType] = useState("");

  const ijasdwe = {
    padding: "20px",
    fontSize: "20px",
  };

  const adiwe = {
    // scribeObj:
    style: {
      marginTop: "20px",
      fontSize: "30px",
    },
    genConfig: {
      textAlign: "center",
    },
  };
  const scriBasos = (
    //
    // "";
    <ScribeChat {...adiwe} />
  );

  const jaswqew =
    //
    "I've already looked over the content of these videos, so";
  //   "You don't need to add any information for these";

  const jsde = (
    <>
      {jaswqew} you don't need to add any info about them apart from general
      comments.
      <br />
      <br />
      If you have any questions, just click the blue chat button on the bottom
      right of this page. Thanks again!
      {/* {scriBasos} */}
    </>
  );

  const [fullTrue, setfullTrue] = useState();

  const ijawe = {
    obj: "Show Message",
    // onClick: () => setfullTrue(true),
    // background: ""
  };

  const tyurthg = (
    <>
      {/*  */}
      {jaswqew}...
      {/* <ConfirmButton {...ijawe} /> */}
    </>
  );

  const asdwe =
    //
    fullTrue;
  // hideMssso;
  // modalShow;

  const ioaswe = asdwe ? jsde : tyurthg;

  const asijdwe = (
    <>
      Hi {getSHortSet(seriesOver)}, thanks for checking out my message!
      <br />
      <br />
    </>
  );

  const sdeawe = (
    <>
      {/*  */}
      {asijdwe}
      {tyurthg}
    </>
  );

  const jsadwe = {
    obj: sdeawe,
    style: {
      margin: "20px 10%",
      textAlign: "left",
    },
  };

  function asdfaewr() {
    // sethideMssso(true)
    setfullTrue(true);
  }

  // const sdfer = <Divo {...jsadwe} />

  const isjdew = {
    style: ijasdwe,
    className: "shadowHover pointer",
  };

  const wersda = <Divo {...jsadwe} />;
  const xsadsawe = {
    bottomObj: wersda,
    ...isjdew,
  };

  const dsjeae = (
    <>
      {asijdwe}
      {jsde}
      {scriBasos}
    </>
  );

  const asijdew = {
    bottomObj: dsjeae,
    style: { fontSize: "22px" },
  };

  function asdijew() {
    setLocal("noScribeMess", "y");
    sethideMssso(true);
    changePage();
  }

  const retrd = <ScribeRate {...xsadsawe} />;
  const ijasawe = {
    obj: retrd,
    modalClick: true,
    modalContent: <ScribeRate {...asijdew} />,
    offModalFunc: asdijew,
  };

  const ijdad = <Divo {...ijasawe} />;
  //   const iajsdw = <ModalMain {...ijasew} />;

  const okfdsd = (
    <>
      {/*  */}
      {ijdad}
      {/* {iajsdw} */}
    </>
  );

  args = {
    obj: okfdsd,
    ...args,
  };

  // 1HTML
  return showMess ? <Divo {...args} /> : "";
}
