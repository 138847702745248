export function logga(messageIt, valY) {
  // loggo(messageIt, valY);
}

export function loggo(messageIt, valY) {
  console.log("loggo--", messageIt, valY);
  // logga('logga:', logga)
}

export function logge(messageIt, valY) {
  loggo(messageIt, valY);
}
