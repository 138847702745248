import React, { useEffect, useRef, useState, createContext } from "react";

import {
  GraphPage,
  GraphArgo,
  FullArray,
  getFirstArr,
  paramOrLocal,
  getLocal,
  segIdent,
  highEditLink,
  resetOrder,
  saveChapters,
  setLocal,
  getParamVar,
  // checkPriceFunc,
  getRndInteger,
  GraphFuncBase,
  stringJSON,
  mapArrAttr,
  matchModelArr,
  turnDict,
  // initUser,
  setCrispData,
  getPaidOrder,
  getMediaSeries,
  mergeArrays,
  checkFullArray,
  getPlayerElement,
  mediaDeliver,
  segReport,
  changePage,
  baseModel,
  getLength,
  getMedRef,
  geFDrEF,
  screenShotDiv,
  createHighlight,
  filterChapter,
  joinString,
} from "../functions/GlobalFunctions";
import * as logs from "../consts/logFuncs";
import * as gens from "../consts/GenStyled";
import { checkProcess } from "../functions/checkProcess";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

export const ScriptContext = createContext();

const ScriptContextProvider = (props) => {
  let history = useHistory();

  function setHistory(poksadwqe) {
    const hguere = {
      LINK: poksadwqe,
      history: history,
    };
    logs.logga("___ setHistory ___", hguere);

    history.push("/" + poksadwqe);
  }

  const [showYotubeAdd, setsetAddd] = useState(true);

  // 1history

  // 1crisp
  window.$crisp = [];

  window.CRISP_WEBSITE_ID =
    //
    "e475db7a-82c0-4166-9cba-16f641be7dc9";
  // process.env.REACT_APP_CRISP_WEBID;

  // window.$crisp.push(["set", "session:data", cripsos]);

  var d = document;
  var s = d.createElement("script");

  s.src = "https://client.crisp.chat/l.js";
  s.async = 1;

  d.getElementsByTagName("head")[0].appendChild(s);

  function initUser(mediao) {
    //
    const sersiso = getMediaSeries(mediao);
    const zhbxcas = {
      mediao: mediao,
      sersiso: sersiso,
    };

    logs.logga("___ initUser ___", zhbxcas);

    // const
    if (sersiso) {
      const serData = sersiso;

      setCrispData(serData);
      segIdent(serData);
    }
  }

  // 1local
  const typCurr = "currentPage";

  // 1local
  const orderId = paramOrLocal(gens.stroeOrdId);
  const kcvmdsf = getLocal(gens.storeRequestString);
  const findBask = getLocal(gens.storeBasketMedia);
  const orderConst = getLocal(gens.storeOrderConf);
  const currHighIDs = getLocal(gens.storeBasketHighlight);

  const currT = getLocal(typCurr);

  logs.logga("___ currT ___", currT);

  // 1type
  const asdfokr = currT && currT == "details" ? "media" : currT;

  const finTypoe =
    //
    "media";
  // asdfokr ? asdfokr : "media";

  // 1typeprocees
  const ijasew =
    //
    gens.FINALTRUE ? "final" : finTypoe;
  // "options";
  // "details";
  // "final";

  const [processComplete, setprocessComplete] = useState();
  const [processStage, setosepYp] = useState(ijasew);

  function setTypeProcess(typeo) {
    setosepYp(typeo);
  }

  const typeProcess =
    //
    //
    processStage;
  // "media";
  // "options";
  // "details";

  logs.logga("___ typeProcess ___", typeProcess);

  //
  function changeMainType(asdasds) {
    // logs.logga("___ changeMainType ___", changeMainType)
    segReport("changeMainType", asdasds);

    logs.logga("___ changeMainType ___", asdasds);
    logs.logga("___ currT ___", currT);

    setLocal(typCurr, asdasds);
    setTypeProcess(asdasds);
  }

  // 1success

  // 1REQUEST
  const sfdgrewt = getLocal("skipRequest");
  const [requestSaw, setrequestSaw] = useState(sfdgrewt);

  const rekkio = getParamVar("rq");
  const requestHigh = rekkio ? rekkio : kcvmdsf;

  const noreqqo = getLocal(gens.storeNoRequestBool);
  const [noRequest, sdfgerw] = useState(noreqqo);

  function passRequest() {
    setrequestSaw(true);
    setLocal("skipRequest", "y");
  }

  const fdsdfgr = mergeArrays(findBask, requestHigh);
  const [elBaskos, setelBaskos] = useState(fdsdfgr);
  const baskSlugs = elBaskos;

  logs.logga("___ baskSlugs ___", baskSlugs);

  //
  // 1useeffect
  useEffect(() => {
    // setLocal("skipRequest", "");
    // setLocal("gens.storeNoRequestBool", "");
    // setLocal(gens.storeBasketMedia, "");
    // setLocal(storeOrderConf, "");
    //   setLocal(gens.storeRequestString, "");
    //   setLocal(gens.storeBasketMedia, arrGrapLink);
    setLocal(gens.storeRequestString, requestHigh);
    // setCrisp();
  });

  function setnoRequest() {
    sdfgerw(true);
    setLocal(gens.storeNoRequestBool, "Y");
  }

  logs.logga("___ rekkio ___", rekkio);
  logs.logga("___ kcvmdsf ___", kcvmdsf);
  logs.logga("___ requestHigh ___", requestHigh);

  const asidjwe = {
    fetchFunction: initUser,
  };

  // 1graph REQUEST
  const { dataImport: graphRequest, loadtrue: reqLoado } =
    //
    // "";
    GraphPage("media", requestHigh, asidjwe);

  logs.logga("___ graphRequest ___", graphRequest);
  logs.logga("___ requestHigh ___", requestHigh);

  function fdigjer(dfsger) {
    logs.logga("___ getPaidORDER ___", dfsger);

    const sdfjir = dfsger.paidTrue;
    if (sdfjir) {
      resetOrder();
    } else {
      // setBasket(dfsger)
      // setcurrentOrder(dfsger)
    }
  }

  // 1ORDER 1graph
  const { dataImport: orderDat, loadtrue: arrLinkLoad, importErr: arrLinkErr } =
    //
    // "";
    getPaidOrder("", fdigjer);
  // GraphPage("order", orderId);
  // GraphFunctions(argFind);

  const currentOrder = orderDat;

  // 1series
  const seriesOrder = currentOrder?.orderSeries;
  const requestSeries =
    // serObre
    // ? serObre
    graphRequest && getFirstArr(graphRequest.videoSeries);

  const seriesOver = seriesOrder || requestSeries;

  const firstMedOrd = currentOrder && getFirstArr(currentOrder.media);
  const requestMedia = graphRequest ? graphRequest : firstMedOrd;

  logs.logga("___ requestMedia ___", requestMedia);

  //
  //
  // 1scribe
  const scrTrue = getParamVar("scribe");
  const showScr = currentOrder || requestSeries || scrTrue;
  const currentScribe = showScr && gens.ScribeAndy;

  // 1BASKET
  const xcnjsdd = [
    //
    "b220a65aa3",
    // "8f3f8d78-e88c-42d9-a177-2c4d3da1f753",
  ];

  const gfdtywerd = {
    // slugURL: requestHigh,
    slugURL_In: baskSlugs,
    // mediaLink: xvckbmcfd,
  };

  // 1graph BASKET
  const { dataImport: graphBasket, loadtrue: loadBasket } =
    //
    // "";
    GraphArgo("media", gfdtywerd);

  const currFinno =
    // graphBasket
    graphBasket ? graphBasket : currentOrder?.media;
  const [currMed, setcurrMed] = useState();

  // 1basket MEDIA 1currBasketMedia
  const currBasketMedia =
    //
    // currMed
    currMed ? currMed : matchModelArr(currFinno, graphRequest);
  const orderLength = getLength(currBasketMedia);

  // currBasketMedia, currHighIDs

  // 1edit
  // 1alter
  const fnbkmdr =
    //
    // gens.testScrpMd;
    "";

  const [sdofewr, weafaewr] = useState(fnbkmdr);
  const currMedia = sdofewr;
  const [mediaWithChapters, setmediaWithChapters] = useState();
  const [editRedirect, seteditRedirect] = useState();

  function setcurrMedia(medVar, messgVar) {
    const gfohkret = {
      medVar: medVar,
      messgVar: messgVar,
    };

    logs.logga("___ setitMedia ___", gfohkret);

    // segReport(messgVar, baseModel(medVar));
    weafaewr(medVar);
    setlistChapters(medVar.chapterList);

    const sjdesadf =
      //
      window.location.pathname + window.location.search;
    // window.location.pathname;
    // currentOrder ? "id=?" + currentOrder.id

    const xfsadfew =
      //
      "";
    // medLink ? medLink : sjdesadf;

    seteditRedirect(xfsadfew);
    changePage("edit");

    //
    //
  }

  const addPlsu =
    //
    currentOrder?.deliveryPlusHours;

  logs.logga("___ addPlsu ___", addPlsu);

  // 1delivery
  const {
    minuteDuration,
    fullDuration,
    hourDuration,
    hourDurationString,
    deliveryBase,
    deliveryDays,
    deliveryHours,
    deliveryDateDay,
    deliveryPython,
    inHoursTime,
  } = mediaDeliver(currBasketMedia, currentOrder?.deliveryPlusHours);

  const basketDuration = minuteDuration;

  function baskSet(azdsr) {
    const ikawea = mapArrAttr(azdsr, "slugURL");

    const ijaew = {
      currBasketMedia: currBasketMedia,
      azdsr: azdsr,
      ikawea: ikawea,
    };

    logs.logga("___ setBasket ___", ijaew);

    setLocal(gens.storeBasketMedia, ikawea);
    setcurrMed(azdsr);
  }

  function setBasket(dfsbomdf) {
    const azdsr = matchModelArr(currBasketMedia, dfsbomdf);
    baskSet(azdsr);
  }

  const allMeddos = checkFullArray(currBasketMedia);

  // 1details
  const [detailFill, setDetailFill] = useState();

  // 1pay
  const [fillComplete, setsdfij] = useState(getLocal(gens.detialGot));
  const detailCheck = fillComplete;

  const approvePay = allMeddos && fillComplete;

  logs.logga("___ currFinno ___", currFinno);
  logs.logga("___ currMed ___", currMed);

  // grapfetchMed

  const grapfetchMed =
    //
    !noRequest && graphRequest;

  logs.logga("___ grapfetchMed ___", grapfetchMed);

  function loadNo(mesio, loadSign, lotrue) {
    // logs.logga("___ loadSign ___", loadSign);
    logs.logga("___ LOADING: " + mesio, lotrue);

    const qoweka = loadSign && lotrue;
    // requestHigh
    return qoweka;
  }

  const sdofke =
    //
    // 1.3;
    1;
  // getRndInteger(1.5, 1.6);

  const timLoco = getLocal("perMinute");

  const [priceType, setpriceType] = useState("curated");
  const [orderTiem, changeCostOrder] = useState();
  // const
  // 1permiunte
  const xsdsd =
    //
    // "";
    // oksadawe;
    // 1.8;
    // 2;
    seriesOver?.perMinuteCost ? seriesOver?.perMinuteCost : sdofke;
  // overHour ? oksadawe - 0.5 : oksadawe;

  const perMinTimes = orderTiem ? orderTiem : xsdsd;

  function changePerMin(cost) {
    logs.logga("___ cost ___", cost);
    changeCostOrder(cost);

    console.log("___ perMinTimes ___", perMinTimes);
  }

  const costConfig = {
    typevar: "curate",
    perMinute: perMinTimes,
    // onChange: changePerMin,
  };

  // 1order graphBasket

  const plsda = {
    generalComment: "",
    optionsMedia: {},
    videoHighlights: [],
  };

  const okawes = {
    noteJSON: plsda,
  };

  const nrtrew = gens.FINALTRUE
    ? {
        ...okawes,
        ...gens.fdbmeiwree,
      }
    : orderConst
    ? orderConst
    : okawes;

  const [ordFields, seORds] = useState(nrtrew);
  const orderDetails = {
    returnDate: deliveryPython,
    ...ordFields,
  };

  function setorderDetails(ordos) {
    setLocal(gens.storeOrderConf, ordos);
    seORds(ordos);
    setDetailFill(true);
    changePage();
  }

  const fieldMains = {
    ...orderDetails,
  };

  // logs.logga("___ orderDetails ___", orderDetails);
  logs.logga("___ fieldMains ___", fieldMains);

  function orderStepChange(typevar) {
    //
    let ikeqwe = "";

    switch (typevar) {
      case "media":
        ikeqwe =
          //
          fillComplete ? "final" : "details";
        // "options";
        break;
      case "options":
        ikeqwe = "details";
        break;
      case "details":
        setsdfij(true);
        setLocal("fillComplete", true);

        ikeqwe =
          //
          "media";
        // fillComplete ? "final" : "media";

        break;
    }

    logs.logga("___ typevar ___", typevar);
    logs.logga("___ setTypeProcess ___", ikeqwe);

    setTypeProcess(ikeqwe);
  }

  // 1currency
  const orderCountry =
    //
    // requestOrdo("country");
    seriesOver?.country ? seriesOver?.country : orderDetails?.country;

  // 1either
  const eitherOrdReq = currentOrder || requestMedia;

  //
  // 1discount
  const discountOver = basketDuration > 30;
  const discExist =
    //
    requestMedia;
  // ;

  const showDiscount = discountOver && discExist;
  const ijsae = {
    US: 5,
    GB: 7.1,
  };

  const mainDiss = ijsae[orderCountry] ? ijsae[orderCountry] : 5;
  const discountVar =
    //
    // 30;
    showDiscount ? mainDiss : 0;

  const [mediaLinkLoad, setmediaLinkLoad] = useState();

  // function createHighlight() {
  //   function asodew(canvo) {}
  //   const sijawe = screenShotDiv("mainPlayerVar", asodew);

  //   const ijaew = getObject("highlight", ijsaewa);

  //   setcurrHighlight(ijaew);
  // }

  // 1chapter
  const [listChapters, setChapBase] = useState();

  function setlistChapters(listnew, mediaObj) {
    setChapBase(listnew);
    if (mediaObj) {
      saveChapters(mediaObj, listnew);
      // setcurrMedia(mediaObj);
    }
  }

  // getParamVar("hg");
  const sdfije = {
    description: null,
    duration: 7,
    endTime: 7,
    id: 12702,
    index: 1,
    name: "Intro",
    startTime: 0,
  };

  const sdesae =
    //
    "";
  // sdfije;

  // 1highlight
  const [mofdew, setcurrHighlight] = useState(sdesae);
  const currHighlight =
    //
    // getParamVar("h");
    mofdew;

  const [highView, sethighView] = useState();

  // 1load
  const loadPageit =
    //
    loadNo("REQUEST", requestHigh, reqLoado) ||
    loadNo("ORDER", "", arrLinkLoad);
  // ||
  const loadAny =
    //
    loadPageit ||
    // loadNo("ORDER", orderId, arrLinkLoad) ||
    loadNo("BASKET", baskSlugs, loadBasket);

  function removeOrderItem(ofkad) {
    logs.logga("___ ofkad ___", ofkad);

    const newList = currBasketMedia.filter((item) => item.id !== ofkad.id);
    // const newLinks = listLinks.filter((item) => item !== ofkad.id);

    const newLinkso = mapArrAttr(newList, "mediaFileLink");
    const rqNo = ofkad.slugURL == graphRequest?.slugURL;

    const gbldsfasdf = {
      newList: newList,
      ofkad: ofkad,
      newLinkso: newLinkso,
      //
      rqNo: rqNo,
    };

    logs.logga("___ orderContainer -- CANCEL FUNC ___", gbldsfasdf);

    if (rqNo) {
      setnoRequest(true);
      // setLocal(gens.storeNoRequestBool, "Y");
    }

    // setRemoveTrue(true)
    // setConfirmConnect(false);
    // vfgmfsas(newList);
    baskSet(newList);
  }

  // 1media
  // ALTER
  function alterMeddAdd(vidVals) {
    //

    logs.logga("___ vidVals ___", vidVals);

    const ijszaew = vidVals.orderNote;
    const sdfokwqe = vidVals.id;
    // "orderNote"
    const mergiot = {
      [sdfokwqe]: ijszaew,
    };

    logs.logga("___ mergiot ___", mergiot);

    orderDetails["noteJSON"]["optionsMedia"][sdfokwqe] = ijszaew;

    // optionsMedia: mergiot,

    weafaewr();
  }

  function changeHighs(highList) {}

  // function editSave()

  //
  // 1bootom
  // 1base

  const baseArgs = {
    mediaList: currBasketMedia,
  };

  const currencArg = "usd";

  const orderConfig = {
    ...baseArgs,
    discountVar: discountVar,
    perMinute: perMinTimes,
    countryVar: orderCountry,
    currencyVar: currencArg,
  };

  // 1player
  const [currStartTime, setcurrStartTime] = useState();
  const remoteRef = useRef(null);
  const playerRef = useRef(null);
  // const playCurr = getReffo(playerRef);

  const playerMedLink = "";
  const { fdgadsf, setplayerTime } = "";
  // const [playerTime, setplayerTime] = useState();
  const playerTime = () => {
    return getMedRef(remoteRef);
  };
  function getReffo(ref) {
    const reffo = ref ? ref : playerRef;
    return reffo;
  }

  function bothSeek(ref, plsvar = 0) {
    ref = getReffo(ref);
    const fsdwq = geFDrEF(ref);

    if (fsdwq) {
      const eqSeek = plsvar > 0 || plsvar == 0;
      const botho =
        //
        // fsdwq;
        eqSeek && fsdwq;

      // let timereos = cuuso ? fsdwq.getCurrentTime() : 0;

      const playerInt = fsdwq.player;

      const sdifj = {
        ref: ref,
        fsdwq: fsdwq,
        plsvar: plsvar,
        BOTH: botho,

        playerInt: playerInt,
      };

      logs.logga("___ bothSeek ___", sdifj);
      logs.logga("___ fsdwq ___", fsdwq);

      if (botho) {
        // eyweqes += plsvar;
        fsdwq.seekTo(plsvar);
        fsdwq.player.handlePlay();
      }
    }
  }

  function getCurrentTime(ref, plusTime = 0) {
    ref = getReffo(ref);
    const fsdwq = geFDrEF(ref);
    let eyweqes = fsdwq && (fsdwq.getCurrentTime() + plusTime).toFixed(2);

    return eyweqes;
  }

  function changeTimePlace(timeo) {
    timeo = timeo ? timeo : getCurrentTime();

    logs.logga("___ changeTimePlace ___", timeo);

    setcurrStartTime(timeo);
  }

  function addPlayerTime(plsvar, ref) {
    // ref = getReffo(ref);
    const eyweqes = getCurrentTime(ref, plsvar);
    bothSeek(ref, eyweqes);
    // return timereos;
  }

  const [playingTrue, setplayTrue] = useState();
  function changePlayerTime(plsvar = 0, ref) {
    bothSeek(ref, plsvar);

    // if (!playingTrue) {
    //   setplayTrue(true);
    // }
  }

  function playCurrentHighlight() {
    changePlayerTime(currHighlight.startTime);
  }

  function focusHighlight(sdferw, timevar) {
    const ijsewe = sdferw?.startTime;
    const endior = ijsewe;
    const medaos =
      //
      currMedia;
    // sdferw?.media ? sdferw?.media : currMedia;

    const eidao = highEditLink(sdferw);
    const addPotensh = {
      ...sdferw,
      // name: baseName,
    };

    logs.logga("___ sdferw?.media ___", sdferw?.media);
    logs.logga("___ medaos ___", medaos);

    const oldLIs = medaos["chapterList"];
    const newHighList = filterChapter(oldLIs, addPotensh);

    const nsidjfe = {
      ...medaos,
      chapterList: newHighList,
    };

    const bfigg = {
      endior: endior,
      medaos: medaos,
      nsidjfe: nsidjfe,
      eidao: eidao,
      oldLIs: oldLIs,
      newHighList: newHighList,
    };

    logs.logga("___ focuighlight ___", bfigg);

    changePlayerTime(endior);
    // setcurrHighlight(sdferw);

    if (medaos) {
      setmediaWithChapters(nsidjfe);
      // setcurrMedia(medaos);
    }

    changePage();

    return eidao;
  }

  function createcurrHighlight(sdferw) {
    const ijsde = createHighlight(sdferw);
    const htrert = focusHighlight(ijsde);

    const isjdwe = {
      sdferw: sdferw,
    };
    logs.logga("___ isjdwe ___", isjdwe);

    return htrert;
  }

  function screenShotVid(fnsaoa) {
    const elemnso = getPlayerElement(playerRef);

    logs.logga("___ elemnso ___", elemnso);

    // screenShotDiv(elemnso, fnsaoa);
  }

  const [currHighs, setcurrHighs] = useState();
  const [selectHighs, setselectHighs] = useState();
  // 1highlight
  const currBasketHighlights =
    //
    orderDetails["noteJSON"]["videoHighlights"];

  const orderTrue =
    //
    currentOrder || !isEmpty(currBasketMedia);

  // const asdwe = {
  //   playerObj,
  //   playerTime,
  //   playerRef,
  //   addPlayerTime,
  //   getCurrentTime,
  //   changePlayerTime,
  // };

  const editTrue =
    //
    true;
  // false;

  // 1console
  const baseScript = {
    // SCRIBE
    scribeObj: currentScribe,
    // MEDIA
    // currMed, currFinno
    //

    // PLAYER
    // player:
    // REQUEST
    request: {
      requestMedia: requestMedia,
      requestSeries: requestSeries,
    },
    // 1BASKET
    basket: {
      orderLength: orderLength,

      findBask: findBask,
      baskSlugs: baskSlugs,
      graphBasket: graphBasket,
      //
      //
      currBasketMedia: currBasketMedia,
      fullDuration: fullDuration,
      basketDuration: basketDuration,
    },
    // 1media 1chapters
    media: {
      currMedia: currMedia,
      mediaWithChapters: mediaWithChapters,
      editRedirect: editRedirect,
      EDITCHAPTERS: currMedia?.chapterList,
      listChapters: listChapters,
    },

    // 1ORDER
    order: {
      orderTrue: orderTrue,
      orderId: orderId,
      fillComplete: fillComplete,
      approvePay: approvePay,
      //
      //
      orderConfig: orderConfig,
      currentOrder: currentOrder,
      typeProcess: typeProcess,
      ordFields: ordFields,
      orderDetails: orderDetails,
    },
    // 1highlight
    highlight: {
      currHighlight: currHighlight,
      highView: highView,
    },
    // 1series
    series: {
      orderCountry: orderCountry,
    },
  };

  const vcxbfdf =
    //
    // "";
    // "highlight";
    "media";
  // "series";
  // "order";

  const jasdw =
    //
    // baseScript
    vcxbfdf ? baseScript[vcxbfdf] : baseScript;

  // 1console
  logs.logga(vcxbfdf + ": SCRIPTCONTEXT ___", jasdw);

  function checkItAll(sokew) {
    const akwe = {
      attrvas: sokew,
      ...baseScript,
    };

    logs.logga("___ akwe ___", akwe);

    return checkProcess(akwe);
  }

  // 1return
  const xvokbcv = {
    // MAIN
    setTypeProcess,
    //

    //
    //
    // 1HIGHLIGHT
    highView,
    sethighView,
    selectHighs,
    setselectHighs,
    currHighlight,
    setcurrHighlight,
    playCurrentHighlight,
    createcurrHighlight,
    mediaWithChapters,
    setmediaWithChapters,
    //
    // 1ORDER
    orderTrue,
    changeCostOrder,
    costConfig,
    orderDetails,
    orderConfig,
    setorderDetails,
    currentOrder,
    seriesOrder,
    fillComplete,
    orderStepChange,
    discountVar,
    //
    // 1BASKET
    currBasketMedia,
    orderLength,
    setBasket,
    //
    // 1EDIT
    currMedia,
    setcurrMedia,
    //
    // 1DISCOUNT
    //
    showDiscount,
    //
    // 1LINKS
    mediaLinkLoad,
    setmediaLinkLoad,
    //
    //
    // 1highlights
    setcurrHighs,
    currBasketHighlights,
    //
    editTrue,
    // 1LOAD
    loadPageit,
    loadAny,
    //
    // 1details
    detailFill,
    //
    // 1REQUEST
    passRequest,
    requestSaw,
    setrequestSaw,
    graphRequest,
    grapfetchMed,
    requestHigh,
    reqLoado,
    setnoRequest,
    checkItAll,
    loadBasket,
    typeProcess,
    requestMedia,
    requestSeries,
    changeMainType,
    approvePay,
    //
    // 1MEDIA
    //
    alterMeddAdd,
    //
    // 1PLAYER
    playerTime,
    changeTimePlace,
    playingTrue,
    setplayerTime,
    remoteRef,
    playerMedLink,
    playerRef,
    addPlayerTime,
    getCurrentTime,
    changePlayerTime,
    focusHighlight,
    screenShotVid,
    currStartTime,
    //
    //
    // 1SERIES
    seriesOver,
    seriesOrder,
    orderCountry,
    // 1CHAPTER
    listChapters,
    setlistChapters,
    setChapBase,
    //
    priceType,
    setpriceType,
    // 1EDIT
    editRedirect,
    seteditRedirect,
    //
    // 1duration
    hourDuration,
    hourDurationString,
    // 1delivery
    // deliveryString,
    deliveryHours,
    deliveryDateDay,
    //
    changePerMin,
    // SCRIBE
    currentScribe,
    // currenPage,
    // setcurrenPage,
    processComplete,
    setprocessComplete,
    seriesOrder,
    perMinTimes,
    // EXTRA,
    showYotubeAdd,
    setsetAddd,
    removeOrderItem,
    eitherOrdReq,
    setHistory,
  };

  // const xvokbcv = {
  //   //
  // };

  // 1return 1end
  return (
    <ScriptContext.Provider
      //
      value={xvokbcv}
    >
      {props.children}
    </ScriptContext.Provider>
  );
};
// }

export default ScriptContextProvider;
