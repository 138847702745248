import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import { useHistory } from "react-router";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  CenterObj,
  ImageTextList,
  //
} from "../functions/GlobalFunctions";
import PlayerBase from "./PlayerBase";
import { isEmpty } from "lodash";
import HighRightList from "./highlights/HighRightList";
import HighRightCreate from "./highlights/HighRightCreate";
import MediaList from "./MediaList";

export default function HighlightRight({
  //
  mediaObj,
  chapterList,
  currentOrder,
  highlightSelect,
  selectHighs,
  typevar = "",
  playerConfig,
  ...args
}) {
  // 1scriptcontext
  const {
    getCurrentTime,
    currMedia,
    currHighlight,
    editTrue,
    playCurrentHighlight,
    currStartTime,
    changeTimePlace,
    orderTrue,
  } = useContext(ScriptContext);

  // 1const
  let history = useHistory();

  const isawe = {
    fontSize: "20px",
    alignItems: "center",
    padding: "10px",
  };

  const saidjew = (
    <>
      {/* <AddTestos /> */}
      {/* <ButtonClick /> */}
    </>
  );

  const asijdwe = {
    obj: saidjew, // className,
  };

  const vsiow = {
    //
    padding: "10px",
    minHeight: "200px",
  };

  const aisdew = {
    // typevar:  "chapters",
  };

  const eldDict = highlightSelect
    ? HighRightCreate(aisdew)
    : HighRightList(aisdew);

  const asdinwe = {
    //
    width: "90%",
    padding: "10px",
    margin: "10px 0",
    fontSize: "24px",
  };

  const asduhwe = {
    // stretc
    style: asdinwe,
  };

  const sdffdge = {
    itemConfig: asduhwe,
    ...eldDict,
  };

  logs.logga("___ sdffdge ___", sdffdge);

  const saveos = <ImageTextList {...sdffdge} />;

  const bvcxfd = (
    <>
      {/*  */}
      {saveos}
      <CenterObj {...asijdwe} />
    </>
  );
  const nsaijw = {
    style: { padding: "10px 0" },
    obj: bvcxfd,
  };

  const aisdwe = <Divo {...nsaijw} />;

  const staijro =
    //
    highlightSelect ? highlightSelect.startTime : currStartTime;

  function PlayerGo() {
    //

    const kfdgd =
      //
      "35vh";
    // "100px";

    const fdogrer = {
      height: kfdgd,
      // width: "100%"
    };

    const asjwase = {
      mediaObj: mediaObj,
      controls: true,
      playing: true,
      startTime: staijro,
      ...fdogrer,
      ...playerConfig,
    };

    logs.logga("___ PlayerGo ___", asjwase);

    const playerObj =
      // playerTime,
      PlayerBase(asjwase);

    const jsadwe = (
      <>
        {playerObj}
        {/* {ijaw} */}
      </>
    );

    const dhytree = {
      background: "black",
      color: "white",
      padding: "20px 0",
      borderRadius: "25px",
    };

    const fdase = {
      //  ...dhytree,
      fontSize: "20px",
    };

    const aojde = {
      obj: jsadwe,
      style: fdase,
    };

    return <Divo {...aojde} />;
  }

  const diuet = (
    <>
      {/*  */}
      <PlayerGo />
      {aisdwe}
    </>
  );
  const ijaeww = {
    obj: diuet,
  };

  const okfdsd = <Divo {...ijaeww} />;

  args = {
    obj: okfdsd,
    ...args,
  };

  //   1console
  const wert = {
    mediaObj: mediaObj,
    currMedia: currMedia,
    highlightSelect: highlightSelect,
  };

  logs.logga("___ ORDER-RIGHT ___", wert);

  // 1HTML
  return <Divo {...args} />;
}
