import React, {
  //
  useState,
  useContext,
} from "react";
import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
// import PricingCards from "@bit/russeii.deephire.pricing-cards";
import { Flex } from "@chakra-ui/react";
import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  InputNumber,
  SlideNumber,
  setGlobalSource,
  //
} from "../functions/GlobalFunctions";

import PageBase from "../components/PageBase";
import PriceCards from "../containers/PriceCards";
import PriceBase from "../containers/PriceBase";
import PriceChak from "../containers/PriceChak";

export default function PricePage({
  //
  ...args
}) {
  // // 1context
  // 1scriptcontext
  // prettier-ignore
  const {
    orderDetails,        
            perMinTimes,
            seriesOver,
          } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");

  function asdij(jyft) {
    logs.logga("___ jyft ___", jyft);

    const fdkgdt = {
      monthTrue: true,
      ...jyft,
    };

    const sidfgre = (
      //
      // "";
      <PriceBase {...fdkgdt} />
      // <PriceChak {...asdijwe} />
    );

    return sidfgre;
  }

  const dsifs = {
    //
    margin: "20px",
  };

  const [setTimeCurro, setsetTimeCurro] = useState(1);

  const sijds = {
    obj: asdij,
    totalTime: setTimeCurro * 4,
    genConfig: { style: dsifs },
  };

  const sodkwe = {
    value: setTimeCurro,
    ariaLabel: "slider-ex-1",
    onChange: (valueString) => setsetTimeCurro(valueString),
    defaultValue: setTimeCurro,
  };

  const sjsdqwe = {
    obj: setTimeCurro,
    className: "bold",
    style: { padding: "0 20px", fontSize: "50px", marginTop: "-15px" },
    mx: 2,
  };

  // const gojorewer = <SlideNumber {...sodkwe} />
  const asdkjwe = (
    //
    // {/* <Divo spanTrue {...sjsdqwe} /> */}
    <InputNumber {...sodkwe} />
  );

  const sldArrange = <SlideNumber {...sodkwe} />;

  const oasjdwe = (
    <>
      <Flex>I release {asdkjwe} hours of content a week.</Flex>
      {/* {sldArrange} */}
    </>
  );

  const ifgjret = {
    obj: oasjdwe,
    centerTrue: true,
    flexTrue: true,
    style: {
      marginTop: "40px",
      fontSize: "25px",
    },
  };

  const okfdsd = (
    <>
      {/*  */}
      <Divo {...ifgjret} />
      <PriceCards {...sijds} />;{/* {titloss} */}
    </>
  );

  const qetwqw = "Pricing";

  const suwee =
    //
    // seriesOver
    "";

  const dsoksr = suwee
    ? "How much could you save with Bedia?"
    : "The best deal anywhere, at every level.";

  const wiejqwe = {
    titlevar: qetwqw,
  };

  args = {
    obj: okfdsd,
    helmetConfig: wiejqwe,
    titleConfig: { obj: dsoksr },
    // noTitle: !succTrue,
    // footerTrue: true,
    // footerConfig: {
    //   obj:  <Aceoa/>,
    // },
    ...args,
  };

  const jdfsa = <PageBase fadeTrue infoTrue {...args} />;
  // const jdfsa = <Divo {...dsfgsdawe} />;

  // 1HTML
  return jdfsa;
}
