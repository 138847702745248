import React, {
  //
  useState,
  useContext,
} from "react";
import ConfirmButton from "../components/ConfirmButton";
import ListMain from "../components/ListMain";

import AboutClients from "../containers/AboutClients";
import PageBase from "../components/PageBase";
import Divo from "../components/Divo";
import { ScriptContext } from "../contexts/ScriptContext";
// import HomeChannel from "../containers/homepage/HomeChannel";

export default function AboutPage({
  //
  ...args
}) {
  const { checkItAll } = useContext(ScriptContext);

  // 1const
  const [type, setType] = useState("");
  const [clientTrue, setclientTrue] = useState(true);

  function ClkntBase() {
    const okgbdsf = (
      <>
        <AboutClients />
      </>
    );

    return okgbdsf;
  }

  function ScbrBase() {
    const okgbdsf = (
      <>
        {/*  */}
        {/* <AboutS */}
      </>
    );

    return okgbdsf;
  }

  // function HomeConn(){

  // }

  // const bzxcvs= <>

  //   <HomeChannel {...xcvsadoadw} />
  // </>

  const xcvsadoadw = {};

  // const clientTrue
  const okfdsas = clientTrue ? <ClkntBase /> : <ScbrBase />;

  function ChoosTypo() {
    const xvcbefs = {
      bediaTrue: clientTrue,
      onlyText: "Our Clients",
      onClick: () => clientTrue(true),
    };

    const oksasd = {
      bediaTrue: !clientTrue,
      onlyText: "Our Scribes",
      onClick: () => clientTrue(false),
    };

    //
    const ijawe = {
      client: xvcbefs,
      scribe: oksasd,
    };

    const okaew = {
      divTrue: true,
      noGreen: true,
      style: {
        padding: "30px 10%",
      },
    };

    const okawesd = {
      // noIcon:
    };

    const uahwew = {
      listvar: ["client", "scribe"],
      dictvar: ijawe,
      // confirmTrue: true,
      // genConfig: okaew,
      ...okaew,
      obj: ConfirmButton,
      itemConfig: okawesd,
    };

    const aushdwq = (
      <ListMain
        //
        objFuncTrue
        horizTrue
        noSlider
        {...uahwew}
      />
    );

    const uxchad = {
      // s
    };

    const okawe = {
      obj: aushdwq,
      // style:
    };

    return aushdwq;
  }

  const asidjwe = {
    obj: okfdsas,
  };

  const okfdsd = (
    <>
      {/* <ChoosTypo /> */}
      <Divo fadeTrue {...asidjwe} />
      {/* <AboutTypes /> */}
      {/* <AboutBase /> */}
      {/* sssss */}
    </>
  );

  const qetwqw = "How Bedia Works";

  const wiejqwe = {
    titlevar: qetwqw,
  };

  function Aceoa() {
    const iawease = {};

    const iasjdwe = "We have open spots in our podcast!";
  }

  const iewq = {
    topText: checkItAll("titleTop"),
    bottomText: checkItAll("titleBottom"),
  };

  args = {
    obj: okfdsd,
    helmetConfig: wiejqwe,
    titleConfig: { obj: qetwqw },
    // subtitleConfig: iewq,
    noSubtitle: true,
    // noTitle: !succTrue,
    // footerTrue: true,
    // footerConfig: {
    //   obj:  <Aceoa/>,
    // },
    ...args,
  };

  const dsfgsdawe = {
    obj: "sdfokewqe",
  };

  const jdfsa = <PageBase fadeTrue infoTrue {...args} />;
  // const jdfsa = <Divo {...dsfgsdawe} />;

  // 1HTML
  return jdfsa;
}
