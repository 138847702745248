import React, {
  //
  useState,
  useContext,
} from "react";
import {
  Tab,
  Tabs,
  TabList,
  Button,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/core";
import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import {
  //
  ConfirmButton,
  ListDicteo,
  mapFunc,
  BediaTextDiv,
  ImageTextList,
} from "../functions/GlobalFunctions";
import Divo from "./Divo";
import LinkGlobal from "./LinkGlobal";

export default function TabMain({
  //
  className,
  noStretch,
  stretchTrue,
  styleTab,
  itemConfig,
  titleAttr,
  titleFunc,
  linkTrue,
  stepTrue,
  chosenItem = 0,
  onChange,
  listConfig,
  listvar,
  dictvar,
  ...arg
}) {
  const [currios, setcurrios] = useState(chosenItem);

  //   chosenItem = chosenItem ? chosenItem - 1 : "";

  function changeia(oqwe) {
    //   if (onChange){
    //   } else {
    //     setcurrios(oqwe)
    //   }
  }

  function PlusStepp({ textvar, ...sdfls }) {
    //
  }

  function PlusTabb({ linkConfig, checkTrue, textvar, ...obj }) {
    //

    textvar = titleFunc ? titleFunc(obj) : titleAttr ? obj[titleAttr] : textvar;

    logs.logga("___ obj ___", obj);

    // checkTrue = true;
    const cvxkbmdf =
      //
      //   "green";
      checkTrue && {
        background: "green",
        color: "white",
      };

    const tabStMa = {
      fontSize: "14px",
      //   margin: "20px",
      borderRadius: "5px",
      //   color: "red",
      // white:
      border: "2px solid black",
      //   ...cvxkbmdf,
      ...styleTab,
    };

    const tabStSel = {
      color: "white",
      bg: gens.appMainDark,
    };

    const endoasd = {
      _selected: tabStSel,
      ...itemConfig,
      ...obj,
      style: tabStMa,
      //   className: "blueBorder",
    };

    // logs.logga("___ styleTab ___", styleTab);
    // logs.logga("obj-aaaaaaz", obj, "09wqej12ks");
    logs.logga(textvar + "___TAB-LIST-", endoasd, "09wqej12ks");
    logs.logga("___ textvar ___", textvar);

    const sdfer = {
      // style:
      ...obj,
      obj: textvar,
    };

    const iajsdwqe = {
      ...linkConfig,
      ...sdfer,
    };

    logs.logga("___ sdfer ___", sdfer);

    const xockvsf = linkConfig ? (
      <LinkGlobal {...iajsdwqe} />
    ) : (
      <div {...obj}>{textvar}</div>
    );

    const tablloss = (
      <Tab {...endoasd}>
        {xockvsf}
        {/* <LinkGlobal {...sdfer} /> */}
        {/* {textvar} */}
        {/* <ImageTextList {...obj} /> */}
      </Tab>
    );

    const kjsdd = {
      ...obj,
      obj: tablloss,
    };

    const xjvdsf = <Divo {...kjsdd} />;

    return tablloss;
  }

  const strea =
    //
    "";
  //   stretchTrue ? gens.TabStretch : "";

  logs.logga("___ chostem AAAAAA ___", chosenItem);

  chosenItem =
    //
    // 0;
    // 1;
    chosenItem;
  // listvar.indexOf(chosenItem);
  // dictvar ? listvar.indexOf(chosenItem) : chosenItem;

  logs.logga("___ chosItem BBBBB ___", chosenItem);

  function isajdadw(aoskd) {
    const sijase = dictvar ? listvar[aoskd] : aoskd;
    if (onChange) {
      onChange(sijase);
    }
  }

  const sdf9jas = {
    defaultActiveKey: chosenItem,
    defaultIndex: chosenItem,
    onChange: isajdadw,
    variant: "unstyled",
    // variant:"enclosed",
    size: "md",
    isFitted: !noStretch,
    // orientation: "vertical",
    ...arg,
  };

  const aoskds = {
    mainConfig: sdf9jas,
  };

  function tabose(obj) {
    const xckmvs = dictvar ? dictvar[obj] : obj;

    const bijdsdfe = stepTrue ? PlusStepp(xckmvs) : PlusTabb(xckmvs);

    return bijdsdfe;
  }

  logs.logga("TAB-MAIN-", aoskds, "09wqej12ks");
  logs.logga("___ listvar ___", listvar);

  const ijasdwe = {
    //   listvar: listvar:
    obj: tabose,
    ...listConfig,
  };

  const clmvgf = listvar.map(tabose);

  const vckbfdsf = (
    <>
      <Tabs {...sdf9jas}>
        <TabList
        // {...endai}
        >
          {clmvgf}
        </TabList>
      </Tabs>
    </>
  );

  return vckbfdsf;
}
