import React, {
  //
  useState,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import Divo from "../components/Divo";
import * as logs from "../consts/logFuncs";

import { ScriptContext } from "../contexts/ScriptContext";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  addLinkArr,
  segReport,
  makeStripeArgs,
  postJSON,
} from "../functions/GlobalFunctions";
import ConfirmButton from "../components/ConfirmButton";
import { getPriceMedia } from "../functions/GetPriceMedia";

export default function OrderStripeBase({
  //
  dataImport,
  costvar,
  // approveTrue,
  stripeArgs,
  genConfig,
  failFunc,
  textvar,
  onClick,
  ...args
}) {
  const stripe = useStripe();

  const { approvePay, currentOrder, orderDetails, orderConfig } = useContext(
    ScriptContext
  );
  const approveTrue = approvePay;

  const {
    //
    priceMedia,
    priceFloat,
    listWithPrices,
  } = getPriceMedia(orderConfig);

  const saudhe = {
    email: orderDetails?.email,
    name: orderDetails?.name,
  };

  const ijwqew = {
    ...orderConfig,
    mediaList: listWithPrices,
  };

  logs.logga("___ listWithPrices ___", listWithPrices);

  const stripeBaseo = makeStripeArgs(ijwqew);

  const FINASTRIPO =
    //
    stripeBaseo;

  const yutrfd5 = {
    cost: priceFloat,
    id: currentOrder?.id,
    ...orderDetails,
  };

  const xcoksfd = {
    ...FINASTRIPO,
    customerDetails: saudhe,
    orderDetails: yutrfd5,
  };

  // 1const
  const [type, setType] = useState("");

  const sijdwe =
    //

    addLinkArr("checkout_create");
  // "sdfokweaw";

  const asudhwqe = {
    //

    // sdkosdas: "aijasd",
    ...xcoksfd,
  };

  const stripeos = postJSON(asudhwqe);

  const okswesdf = {
    asudhwqe: asudhwqe,
    stripeos: stripeos,
  };

  logs.logga("___ getStripPage ___", okswesdf);
  logs.logga("___ stripeos ___", stripeos);
  logs.logga("___ stripeBase ARGS ___", asudhwqe);

  function getStripPage() {
    //
    //
    const oasdew =
      //
      // ""
      "asqe";

    fetch(sijdwe, stripeos)
      .then(function (response) {
        logs.logge("___ response ___", response, oasdew);

        return response.json();
      })
      .then(function (session) {
        logs.logge("___ session ___", session, oasdew);

        const goShes =
          //
          // session.id;
          session;

        const qeuhqewq = { sessionId: goShes };
        const asuhdwe = stripe.redirectToCheckout(qeuhqewq);

        return asuhdwe;
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.

        logs.logge("___ result ___", result, oasdew);

        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function (error) {
        logs.logga("___ error ___", error);

        console.error("Error:", error);
      });
  }

  const [loStrip, setloStrip] = useState();

  function buttClik() {
    //
    //

    logs.logga("___ buttClik ___", onClick);

    segReport("Pay Button Clicked", asudhwqe);

    if (approveTrue) {
      setloStrip(true);
      getStripPage();
    } else if (onClick) {
      onClick();
    } else if (failFunc) {
      failFunc();
    }

    //
  }

  const ndTxos =
    //
    textvar;
  // loStrip ? loStrip : textvar;

  const asdokae = {
    onClick: buttClik,
    // textvar: ndTxos,
    onlyText: textvar,
    //
    // colorScheme: "teal",
    // size: "lg",
    isLoading: loStrip,
    loadingText: "Connecting...",
    ...args,
    disableTrue: !approveTrue,
  };

  const iaskew = {
    approveTrue: approveTrue,
    ...asdokae,
  };

  logs.logga("___ OrderStripeBase ___", iaskew);

  const okfdsd = (
    <>
      <ConfirmButton
        //
        noImage
        {...asdokae}
      />
    </>
  );

  {
    /* <Button
    isLoading
    loadingText="Submitting"
    colorScheme="teal"
    variant="outline"
  ></Button> */
  }

  // const isade = {
  // items={toggle}
  // from={{ position: 'absolute', opacity: 0 }}
  // enter={{ opacity: 1 }}
  // leave={{ opacity: 0 }}>
  // {toggle =>
  //   toggle
  //     ? props => <div style={props}>😄</div>
  //     : props => <div style={props}>🤪</div>
  // }
  // }

  //   const ujwqe = <Transition
  //   //
  // </Transition>

  const aisdjwe = {
    obj: okfdsd,
    ...genConfig,
  };

  // 1HTML
  return <Divo {...aisdjwe} />;
}
