import React, {
  //
  useState,
  useEffect,
  useContext,
} from "react";

import * as gens from "../consts/GenStyled";
import * as logs from "../consts/logFuncs";
import Divo from "../components/Divo";
import { useHistory } from "react-router-dom";
import TextFindMedia from "./TextFindMedia";

import { ScriptContext } from "../contexts/ScriptContext";
// import aaaa from "./components/aaaa";
import {
  getParamVar,
  joinString,
  saveModel,
  //
} from "../functions/GlobalFunctions";
import FormMain from "../components/FormMain";

export default function CreateHighlight({
  //
  ...args
}) {
  // 1scriptcontext
  let history = useHistory();

  const ijseasd = {
    singleTrue: true,
    // onSubmit: ftMedio,
    // listvar: fgotre,
    // inputConfig: dfjse,
    ...args,
  };

  const dsijfew = (
    <>
      {/* asdfkdsf */}
      <TextFindMedia {...ijseasd} />
    </>
  );

  return dsijfew;
}
